<div class="container">
  <div class="content-box">
    <div class="ag-header sticky">
      <div class="ag-header-title">
        <h3>{{ 'OPERATION_ALERTS.TITLE'|translate }}</h3>
      </div>
      <div class="ag-header-actions">
        <button type="button"
        (click)="createModal()" title="{{ 'OPERATION_ALERTS.ADD'|translate }" class="btn btn-agree"><i class="material-symbols-rounded hidden-xs hidden-sm">add</i> {{ 'OPERATION_ALERTS.ADD'|translate }}</button>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12">
        <p>{{ 'OPERATION_ALERTS.DESCRIPTION'|translate }}</p>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12">
        <div class="no-data" *ngIf="!alerts?.length">
          <i class="material-symbols-rounded">not_interested</i>
          {{ 'OPERATION_ALERTS.NO_ALERTS'|translate }}
        </div>
        <div class="horizontal-scroller">
          <table [sortable]="alerts" defaultSortBy="product" #tableSortable="tableSortable" class="table table-hover table-condensed" *ngIf="alerts?.length > 0">
            <!-- <caption></caption> -->
            <thead>
              <tr>
                <th class="text-center" sortBy="enabled">{{ 'OPERATION_ALERTS.TABLE.ACTIVE'|translate }}</th>
                <th class="hidden-xs hidden-sm" sortBy="type">{{ 'GLOBAL.TYPE'|translate }}</th>
                <th sortBy="product">{{ 'GLOBAL.PRODUCT'|translate }}</th>
                <!-- <th class="text-center" colspan="3">{{ 'OPERATION_ALERTS.TABLE.PRICE_RANGE'|translate }}</th> -->
                <th class="text-center">{{ 'GLOBAL.DELIVERY_PERIOD'|translate }}</th>
                <th>{{ 'GLOBAL.DELIVERY_PLACES'|translate }}</th>
                <th class="sap"></th>
              </tr>
            </thead>
            <tbody>
              <!-- Company row -->
              <tr class="tr-align-center" *ngFor="let alert of tableSortable.collection">
                <td class="text-center">
                  <label>
                    <input type="checkbox" checked="{{ alert.enabled }}">
                  </label>
                </td>
                <td class="hidden-xs hidden-sm">
                  <span *ngIf="alert.type" class="ag-label" [class.sell-bg]="alert.type === 'venta'" [class.buy-bg]="alert.type === 'compra'">{{ (alert.type === 'compra' ? 'GLOBAL.BID' : 'GLOBAL.SELL')|translate }}</span>
                  <mark *ngIf="!alert.type" class="text-warning">{{ 'OPERATION_ALERTS.TYPE.INDISTINCT'|translate }}</mark>
                </td>
                <td>
                  {{ alert.product }}
                  <div class="small visible-xs visible-sm">
                    <span *ngIf="alert.type" class="ag-label" [class.sell-bg]="alert.type === 'venta'" [class.buy-bg]="alert.type === 'compra'">{{ (alert.type === 'compra' ? 'GLOBAL.BID' : 'GLOBAL.SELL')|translate }}</span>
                    <mark *ngIf="!alert.type" class="text-warning">{{ 'OPERATION_ALERTS.TYPE.INDISTINCT'|translate }}</mark>
                  </div>
                </td>
                <!-- <td class="text-right">
                  <span *ngIf="alert.pfrom" tooltip="Desde">
                    <span class="small text-muted">USD</span>
                    {{ alert.pfrom }}
                  </span>
                  <mark *ngIf="!alert.pfrom" class="text-warning">0</mark>
                </td>
                <td class="td-icons text-center">
                  <i class="material-symbols-rounded">code</i> 
                </td>
                <td class="text-left">
                  <span *ngIf="alert.pto" tooltip="Hasta">
                    <span class="small text-muted">USD</span>
                    {{ alert.pto }}
                  </span>
                  <mark *ngIf="!alert.pto" class="text-warning">&#x221e;</mark>
                </td> -->
                <td class="text-center">
                  <date-range
                  *ngIf="alert.date"
                  [styles]="false"
                  from="2018-01-28"
                  to="2018-01-28"></date-range>
                  <mark *ngIf="!alert.date" class="text-warning">{{ 'OPERATION_ALERTS.TYPE.INDISTINCT'|translate }}</mark>
                </td>
                <td>
                  <span *ngIf="alert.delivery_places?.length">{{ alert.delivery_places.join(', ') }}</span>
                  <mark *ngIf="!alert.delivery_places?.length" class="text-warning">{{ 'OPERATION_ALERTS.TYPE.INDISTINCT'|translate }}</mark>
                </td>
                <td class="sap">
                  <a title="{{ 'OPERATION_ALERTS.DELETE.ACTION'|translate }}"
                  (onConfirm)="publish()"
                  [confirm-options]="{
                    title: 'OPERATION_ALERTS.DELETE.TITLE'|translate,
                    action: 'GLOBAL.YES_DELETE'|translate
                  }"><i class="material-symbols-rounded">delete</i></a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #createAlert>
  <!-- modal -->
  <div class="modal-header">
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">{{ 'OPERATION_ALERTS.MODAL.TITLE'|translate }}</h4>
  </div>
  <div class="modal-body">
    <div class="container-fluid">
      <div class="row">
        <p>{{ 'OPERATION_ALERTS.MODAL.DESCRIPTION'|translate }}</p>
        <div class="form-group">
          <label class="control-label">{{ 'OPERATION_ALERTS.MODAL.OPERATION_TYPE'|translate }}</label>
          <div class="btn-group btn-group-justified">
            <label class="btn btn-default active">
              <input type="radio" name="orderType" id="order-type-0">
              <span>{{ 'OPERATION_ALERTS.TYPE.INDISTINCT'|translate }}</span>
            </label>
            <label class="btn btn-default">
              <input type="radio" name="orderType" id="order-type-1">
              <span>{{ 'GLOBAL.BID'|translate }}</span>
            </label>
            <label class="btn btn-default">
              <input type="radio" name="orderType" id="order-type-2">
              <span>{{ 'GLOBAL.SELL'|translate }}</span>
            </label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="form-group">
          <label class="control-label">{{ 'GLOBAL.PRODUCT'|translate }}</label>
          <select class="form-control" >
            <option [selected]="true" [disabled]="true">{{ 'GLOBAL.SELECT_PRODUCT'|translate }}</option>
            <option *ngFor="let dt of ['Soja', 'Trigo', 'Maíz']">{{ dt }}</option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="form-group">
          <label class="control-label">{{ 'GLOBAL.DELIVERY_PLACES'|translate }}</label>
          <selectize 
            name="deliveryPlaces"
            placeholder="{{ 'OPERATION_ALERTS.MODAL.DELIVERY_PLACEHOLDER'|translate }}">
          </selectize>
          <span class="small help-block">{{ 'OPERATION_ALERTS.MODAL.PLACES_HELP'|translate }}</span>
        </div>
      </div>
      <div class="row">
        <div class="form-group">
          <label class="control-label">{{ 'GLOBAL.DELIVERY_PERIOD'|translate }}</label>
          <div class="input-group">
            <div class="input-group-addon">
              <i class="material-symbols-rounded">date_range</i>
            </div>
            <input type="text" name="deliveryDate" class="form-control" placeholder="{{ 'GLOBAL.RANGE_PLACEHOLDER'|translate }}" placement="left" bsDaterangepicker
            container=".app-content"
            [minDate]="today" [bsConfig]="{showWeekNumbers: false, rangeInputFormat: 'DD/MM/YYYY'}" #dpEntrega="bsDaterangepicker" required readonly>
          </div>
          <span class="small help-block">{{ 'OPERATION_ALERTS.MODAL.DATE_HELP'|translate }}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button (click)="modalRef.hide()" type="button" class="btn btn-link" data-dismiss="modal">{{ 'GLOBAL.CANCEL'|translate }}</button>
    <button type="button" class="btn btn-agree" [disabled]="true">{{ 'GLOBAL.YES_DELETE'|translate }}</button>
  </div>
</ng-template>
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

import { ThemeService } from './services/theme.service';

@Injectable()
export class ThemeResolver {

  constructor(
    private themeService: ThemeService,
    @Inject(DOCUMENT) private document: Document
  ) { }

  resolve(): void {
    // Temp hack
    if (this.document.location.hostname.includes('agreemarket.com')) {
      const destination = this.document.location.href.replace('agreemarket.com/', 'agree.ag/');
      window.location.href = destination;
    }

    this.themeService.applyTheme();
  }
}

<div class="container">
  <div class="content-box">
    <ol class="breadcrumb">
      <li><a routerLink="../setup">{{ 'GLOBAL.CONFIG_ASSISTANT'|translate }}</a></li>
      <li class="active">{{ 'GLOBAL.INTEGRATIONS'|translate }}</li>
    </ol>
    <div class="row">
      <div class="col-xs-12">
        <h4>{{ 'INTEGRATIONS.TITLE'|translate }}</h4>
        <p [innerHtml]="'INTEGRATIONS.API_DOCUMENTATION_HELP'|translate"></p>
      </div>
    </div>
    <div class="row tabset-margin" *ngIf="!loading; else loadingSpinner">
      <div class="col-xs-12">
        <tabset #staticTabs>
          <tab [active]="selectedTab === 'webhooks'" (selectedTab)="selectedTab = 'webhooks'" heading="Webhooks">
            <ag-webhooks [companyId]="companyId"></ag-webhooks>
          </tab>
          <tab [active]="selectedTab === 'api-keys'" (selectedTab)="selectedTab = 'api-keys'"
            *ngIf="account?.is.admin" heading="API Keys">
            <ag-api-keys [companyId]="companyId"></ag-api-keys>
          </tab>
          <tab [active]="selectedTab === 'client-credentials'" (selectedTab)="selectedTab = 'client-credentials'"
            *ngIf="account?.is.admin" heading="Client Credentials">
            <ag-client-credentials [companyId]="companyId"></ag-client-credentials>
          </tab>
        </tabset>
      </div>
    </div>
  </div>
</div>

<ng-template #loadingSpinner>
  <div class="loading">
    <spinner message="{{ 'GLOBAL.LOADING'|translate }}"></spinner>
  </div>
</ng-template>
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ClipboardService } from 'ngx-clipboard';
import { Subscription } from 'rxjs';

import { ApiKey } from '../../../../models/api-key.model';
import { ApplicationsProxyService } from '../../../services/applications-proxy.service';

@Component({
  selector: 'ag-api-keys',
  templateUrl: './api-keys.component.html',
  styleUrls: ['./api-keys.component.scss']
})
export class ApiKeysComponent implements OnInit, OnDestroy {

  @Input() private companyId: number;

  public typeArr: { [key: string]: 'password' | 'text' } = {};
  public apiKeys: ApiKey[];

  private subscriptions: Subscription[] = [];

  constructor(
    private applicationProxyService: ApplicationsProxyService,
    public clipboardService: ClipboardService
  ) { }

  ngOnInit(): void {
    this.getApiKeys();
  }

  // private generateApiKeys(): void {
  //   this.subscriptions.push(
  //     this.applicationProxyService.generateApiKey(this.companyId).subscribe(apiKey => {
  //       this.apiKeys.push(apiKey);
  //     })
  //   );
  // }

  private getApiKeys(): void {
    this.subscriptions.push(
      this.applicationProxyService.getApiKeys(this.companyId).subscribe(apiKeys => {
        this.apiKeys = apiKeys.filter(key => key.status !== 'DELETED');
      })
    );
  }

  public toggleType(key: string): void {
    this.typeArr[key] = this.typeArr[key] === 'password' ? 'text' : 'password';
  }

  public getType(key: string): string {
    return this.typeArr[key] ? this.typeArr[key] : this.typeArr[key] = 'password';
  }

  ngOnDestroy(): void {
    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}

import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { Company } from '../../../models/company.model';
import { GroupBy } from '../../../models/group-by.model';
import { Product } from '../../../models/product.model';
import { CompanyService } from '../../../services/company.service';
import { ComponentCommService } from '../../../services/component-comm.service';
import { IntercomService } from '../../../services/intercom.service';
import { MarketService } from '../../../services/market.service';
import { FilterSetComponent } from '../../../ui/components/filter-set/filter-set.component';
import { TableFilters } from '../../../utilities/table-filters';
import { Market } from '../../modules/commercial/models/market.model';

// declare const walkthrough: any;

@Component({
  selector: 'app-market',
  templateUrl: './market.component.html',
  styleUrls: ['./market.component.scss']
})
export class MarketComponent extends TableFilters implements OnInit, OnDestroy {

  @ViewChild('filterSet', { static: true }) public readonly selectedFilters: FilterSetComponent;

  public company: Company;
  public companyId: number;
  /** The language currently used. */
  public currentLang: string;
  public delivery_types: Array<string> = [];
  public extended = false;
  public loading = true;
  public marketDates: Array<string> = [];
  public marketSummary: GroupBy<Product, Market>[] = [];
  public quantities = [];
  public type: string;
  private pusherSubscription: Subscription;

  constructor(
    private companyService: CompanyService,
    private componentComm: ComponentCommService,
    private intercomService: IntercomService,
    private marketService: MarketService,
    public route: ActivatedRoute,
    public router: Router,
    private translateService: TranslateService
  ) {
    super(route, router);
  }

  ngOnInit(): void {
    this.currentLang = this.translateService.currentLang === 'es' ? undefined : this.translateService.currentLang;

    this.subscriptions.push(this.route.parent.params.subscribe(params => {
      this.companyId = parseInt(params['companyId']);
    }));
    this.componentComm.emit({ name: 'app-title', title: 'COMPANY.MARKET_SUMMARY' });

    this.intercomService.track('market-visited');
    // walkthrough('market', false, this.translateService);

    this.type = this.route.snapshot.data.type;

    this.subscriptions.push(this.companyService.watch().subscribe(company => {
      if (!company) return;

      this.company = company;

      if (!this.company.market.configuration.market_summary.enabled) {
        this.router.navigateByUrl('/companies');
        return;
      } else {
        // Filters based on URL
        this.onFiltersChange = this.loadData;
        this.setupFilters();
      }
    }));
  }

  public gotoWorkingOrders(data): void {
    let url = '/company/' + this.companyId + '/working-orders';
    let params = Array();

    for (let i in data) {
      if (data[i]) {
        params.push(i + '=' + data[i]);
      }
    }
    url += '?' + params.join("&");

    this.router.navigateByUrl(url);
  }

  private loadData(): void {
    this.loading = true;
    this.quantities = [];

    if (this.pusherSubscription) this.pusherSubscription.unsubscribe();

    this.pusherSubscription = this.marketService.watchMarketSummary(this.type, this.companyId, this.filters).subscribe(response => {
      this.parseMarketSummaryResponse(response);
    });
  }

  private parseMarketSummaryResponse(summary: any[]): void {
    this.loading = false;

    if (summary.length === 0) {
      this.marketSummary = [];
      return;
    }

    // fix para que tome el resumen de mercado del mercado actual.
    // Cuando se permitan ver multiples mercados vamos a tener que rehacer esta logica seguramente.
    this.marketSummary = summary[0].product;

    if (this.marketSummary[0]) {
      this.marketDates = Object.keys(this.marketSummary[0].values[0].dates).slice(1);
    }

    let delivery_types = [];

    summary[0].product.forEach(group => {
      group.values.forEach(value => {
        Object.keys(value.dates).forEach(date => {

          if (value.dates[date].offer) {
            let keys = Object.keys(value.dates[date].offer);
            for (let i in keys) {
              if (delivery_types.indexOf(keys[i]) === -1) {
                delivery_types.push(keys[i]);
              }
            }
          }

          if (!this.quantities[group.key.id]) {
            this.quantities[group.key.id] = {};
          }

          if (this.quantities[group.key.id][date]) {
            this.quantities[group.key.id][date] += value.dates[date].quantity;
          } else {
            this.quantities[group.key.id][date] = value.dates[date].quantity;
          }

        });
      });
    });

    this.delivery_types = delivery_types;
  }

  /** @ignore */
  ngOnDestroy(): void {
    // Unsubscribe from everything
    super.ngOnDestroy();
    if (this.pusherSubscription) this.pusherSubscription.unsubscribe();
  }
}

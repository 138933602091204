import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { Company } from '../../../models/company.model';
import { CompanyService } from '../../../services/company.service';
import { ComponentCommService } from '../../../services/component-comm.service';
import { CurrentDateService } from '../../../services/current-date.service';

@Component({
  selector: 'app-operation-alerts',
  templateUrl: './operation-alerts.component.html',
  styleUrls: ['./operation-alerts.component.scss']
})
export class OperationAlertsComponent implements OnInit, OnDestroy {

  @ViewChild('createAlert', { static: true }) private readonly createAlert: TemplateRef<any>;

  public modalRef: BsModalRef;
  public company: Company;
  public today: Date = this.currentDate.get();
  public alerts: Array<any> = [ // For mockup purposes
    { enabled: true, product: 'Trigo', type: 'compra', pto: '2.400', date: true, delivery_places: ['Lima/Zarate'] },
    { product: 'Soja', type: 'venta', pfrom: '1.200' },
    { enabled: true, product: 'Maíz', pfrom: '1.200', date: true, delivery_places: ['Rosario Norte'] },
    { enabled: true, product: 'Trigo', type: 'compra', pfrom: '1.200', pto: '2.400', date: true, delivery_places: ['Puerto Belgrano', 'Coronel Rosales'] },
    { enabled: true, product: 'Soja', type: 'venta', pfrom: '1.200' },
    { enabled: true, product: 'Maíz', pfrom: '1.200', date: true, delivery_places: ['Arias', 'Marcos Juarez'] }
  ];

  private environment = environment;
  private subscriptions: Subscription[] = [];

  constructor(
    private companyService: CompanyService,
    private componentComm: ComponentCommService,
    private currentDate: CurrentDateService,
    private modalService: BsModalService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.componentComm.emit({ name: 'app-title', title: 'OPERATION_ALERTS.TITLE' });

    this.subscriptions.push(this.companyService.watch().subscribe(company => {
      if (!company) return;

      this.company = company;

      // Kick user if module is not enabled
      if (!this.environment.modules.operations_alerts) this.router.navigateByUrl('/companies');
    }));
  }

  public createModal(): void {
    this.modalRef = this.modalService.show(this.createAlert);
  }

  /** @ignore */
  ngOnDestroy(): void {
    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}

import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { Company } from '../../../models/company.model';
import { CompanyService } from '../../../services/company.service';
import { ComponentCommService } from '../../../services/component-comm.service';

@Component({
  selector: 'app-execution',
  templateUrl: './execution.component.html',
  styleUrls: ['./execution.component.scss']
})
export class ExecutionComponent implements OnInit, OnDestroy {
  public modalRef: BsModalRef;
  public panelNum: number = 0;
  public contracts: Array<any> = [
    {
      product: 'Brazilian Soybeans',
      originator: 1,
      vessel: {
        name: 'MV SEA PEGASUS'
      },
      nominated: [
        {
          id: '53642',
          fulfillment: 'total',
          counterpart: 'Glencore',
          price: 350,
          quantity: 65000,
          date_from: '2019-03-01T18:19:16+00:00',
          date_to: '2019-03-31T18:19:16+00:00',
          status: 0
        }
      ],
      renominations: true
    },
    {
      product: 'Brazilian Soybeans',
      vessel: {
        name: 'MV SEA PEGASUS'
      },
      received: {
        id: '53642',
        fulfillment: 'total',
        counterpart: 'Marubeni',
        price: 350,
        quantity: 65000,
        date_from: '2019-03-01T18:19:16+00:00',
        date_to: '2019-03-31T18:19:16+00:00',
        status: 0,
        circle: 0
      },
      renominations: true
    },
    {
      product: 'Brazilian Soybeans',
      vessel: {
        name: 'MV SEA PEGASUS'
      },
      received: {
        id: '53642',
        fulfillment: 'total',
        counterpart: 'Marubeni',
        price: 350,
        quantity: 65000,
        date_from: '2019-03-01T18:19:16+00:00',
        date_to: '2019-03-31T18:19:16+00:00',
        status: 2
      }
    },
    {
      product: 'Brazilian Soybeans',
      vessel: {
        name: 'MV SEA PEGASUS'
      },
      received: {
        id: '53642',
        fulfillment: 'total',
        counterpart: 'Marubeni',
        price: 350,
        quantity: 65000,
        date_from: '2019-03-01T18:19:16+00:00',
        date_to: '2019-03-31T18:19:16+00:00',
        status: 1
      },
      renominations: true
    },
    {
      product: 'Brazilian Soybeans',
      vessel: {
        name: 'MV SEA PEGASUS'
      },
      received: {
        id: '53642',
        fulfillment: 'total',
        counterpart: 'Marubeni',
        price: 350,
        quantity: 65000,
        date_from: '2019-03-01T18:19:16+00:00',
        date_to: '2019-03-31T18:19:16+00:00',
        status: 1
      },
      renominations: true,
      shipper: 45000,
      nominated: [
        // {
        //   id: 'A-3219/16',
        //   fulfillment: 'total',
        //   counterpart: 'Agrograin',
        //   price: 398.14,
        //   quantity: 1000,
        //   status: 1
        // },
        // {
        //   id: 'A-3219/16',
        //   fulfillment: 'partial',
        //   counterpart: 'Cefetra SPA',
        //   price: 411.45,
        //   quantity: 2500,
        //   status: 1
        // },
        {
          id: 'A-3219/16',
          fulfillment: 'balance',
          counterpart: 'Amaggi',
          price: 349,
          quantity: 20000,
          status: 0
        },
        {
          id: '65654-FK',
          fulfillment: 'balance',
          counterpart: 'CHS',
          price: 348.5,
          quantity: 20000,
          status: 2
        }
      ]
    },
    {
      product: 'Brazilian Soybeans',
      vessel: {
        name: 'MV SEA PEGASUS'
      },
      received: {
        id: '53642',
        fulfillment: 'total',
        counterpart: 'Marubeni',
        price: 350,
        quantity: 65000,
        date_from: '2019-03-01T18:19:16+00:00',
        date_to: '2019-03-31T18:19:16+00:00',
        status: 1
      },
      renominations: true,
      shipper: 45000,
      shipped: true,
      disablerenominate: true,
      nominated: [
        // {
        //   id: 'A-3219/16',
        //   fulfillment: 'total',
        //   counterpart: 'Agrograin',
        //   price: 398.14,
        //   quantity: 1000,
        //   status: 1
        // },
        // {
        //   id: 'A-3219/16',
        //   fulfillment: 'partial',
        //   counterpart: 'Cefetra SPA',
        //   price: 411.45,
        //   quantity: 2500,
        //   status: 1
        // },
        {
          id: 'A-3219/16',
          fulfillment: 'balance',
          counterpart: 'Amaggi',
          price: 349,
          quantity: 20000,
          status: 4
        },
        {
          id: '65654-FK',
          fulfillment: 'balance',
          counterpart: 'CHS',
          price: 348.5,
          quantity: 20000,
          status: 2
        }
      ]
    },
    {
      product: 'Brazilian Soybeans',
      vessel: {
        name: 'MV SEA PEGASUS'
      },
      received: {
        id: 'A-3219/16',
        fulfillment: 'balance',
        counterpart: 'Glencore',
        price: 411.36,
        quantity: 1000,
        date_from: '2019-03-01T18:19:16+00:00',
        date_to: '2019-03-31T18:19:16+00:00',
        status: 0,
        circle: 1
      },
      renominations: true
    },
    {
      product: 'Brazilian Soybeans',
      originator: 1,
      vessel: {
        name: 'MV SEA PEGASUS'
      },
      nominated: [
        {
          id: '53642',
          fulfillment: 'total',
          counterpart: 'Glencore',
          price: 350,
          quantity: 65000,
          date_from: '2019-03-01T18:19:16+00:00',
          date_to: '2019-03-31T18:19:16+00:00',
          status: 1
        }
      ],
      renominations: true
    },
    {
      product: 'Brazilian Soybeans',
      vessel: {
        name: 'MV ESTELLE MAERSK'
      },
      received: {
        id: '53642',
        fulfillment: 'total',
        counterpart: 'Marubeni',
        price: 350,
        quantity: 65000,
        date_from: '2019-03-01T18:19:16+00:00',
        date_to: '2019-03-31T18:19:16+00:00',
        status: 3
      },
      shipper: 45000,
      renominations: true,
      nominated: [
        // {
        //   id: 'A-3219/16',
        //   fulfillment: 'total',
        //   counterpart: 'Agrograin',
        //   price: 398.14,
        //   quantity: 1000,
        //   status: 1
        // },
        // {
        //   id: 'A-3219/16',
        //   fulfillment: 'partial',
        //   counterpart: 'Cefetra SPA',
        //   price: 411.45,
        //   quantity: 2500,
        //   status: 1
        // },
        {
          id: 'A-3219/16',
          fulfillment: 'balance',
          counterpart: 'Amaggi',
          price: 349,
          quantity: 20000,
          status: 0
        },
        {
          id: '65654-FK',
          fulfillment: 'balance',
          counterpart: 'CHS',
          price: 348.5,
          quantity: 20000,
          status: 2
        }
      ]
    },
    {
      product: 'Brazilian Soybeans',
      vessel: {
        name: 'MV ESTELLE MAERSK'
      },
      received: {
        id: '53642',
        fulfillment: 'total',
        counterpart: 'Marubeni',
        price: 350,
        quantity: 65000,
        date_from: '2019-03-01T18:19:16+00:00',
        date_to: '2019-03-31T18:19:16+00:00',
        status: 1
      },
      shipper: 5000,
      renominations: true,
      nominated: [
        // {
        //   id: 'A-3219/16',
        //   fulfillment: 'total',
        //   counterpart: 'Agrograin',
        //   price: 398.14,
        //   quantity: 1000,
        //   status: 3
        // },
        // {
        //   id: 'A-3219/16',
        //   fulfillment: 'partial',
        //   counterpart: 'Cefetra SPA',
        //   price: 411.45,
        //   quantity: 2500,
        //   status: 3
        // },
        {
          id: 'A-3219/16',
          fulfillment: 'balance',
          counterpart: 'Amaggi',
          price: 349,
          quantity: 20000,
          status: 3
        },
        {
          id: '65654-FK',
          fulfillment: 'balance',
          counterpart: 'CHS',
          price: 348.5,
          quantity: 20000,
          status: 2
        }
      ]
    },
    {
      product: 'Brazilian Soybeans',
      originator: 1,
      washout: {
        status: 0,
        mine: [
          {
            id: '53642',
            fulfillment: 'total',
            counterpart: 'Glencore',
            price: 350,
            quantity: 65000
          }
        ],
        theirs: [
          {
            id: '53648',
            fulfillment: 'total',
            counterpart: 'Glencore',
            price: 377.17,
            quantity: 5000
          },
          {
            id: '53640',
            fulfillment: 'partial',
            counterpart: 'Glencore',
            price: 356.87,
            quantity: 60000
          }
        ]
      }
    },
    {
      product: 'Brazilian Soybeans',
      washout: {
        status: 2,
        mine: [
          {
            id: '53648',
            fulfillment: 'total',
            counterpart: 'Marubeni',
            price: 377.17,
            quantity: 5000
          },
          {
            id: '53640',
            fulfillment: 'partial',
            counterpart: 'Marubeni',
            price: 356.87,
            quantity: 60000
          }
        ],
        theirs: [
          {
            id: '53642',
            fulfillment: 'total',
            counterpart: 'Marubeni',
            price: 350,
            quantity: 65000
          }
        ]
      }
    },
    {
      product: 'Brazilian Soybeans',
      originator: 1,
      washout: {
        status: 1,
        mine: [
          {
            id: '53642',
            fulfillment: 'total',
            counterpart: 'Glencore',
            price: 350,
            quantity: 65000
          }
        ],
        theirs: [
          {
            id: '53648',
            fulfillment: 'total',
            counterpart: 'Glencore',
            price: 377.17,
            quantity: 5000
          },
          {
            id: '53640',
            fulfillment: 'partial',
            counterpart: 'Glencore',
            price: 356.87,
            quantity: 60000
          }
        ]
      }
    },
    {
      product: 'Brazilian Soybeans',
      originator: 1,
      washout: {
        status: 2,
        mine: [
          {
            id: '53642',
            fulfillment: 'total',
            counterpart: 'Glencore',
            price: 350,
            quantity: 65000
          }
        ],
        theirs: [
          {
            id: '53648',
            fulfillment: 'total',
            counterpart: 'Glencore',
            price: 377.17,
            quantity: 5000
          },
          {
            id: '53640',
            fulfillment: 'partial',
            counterpart: 'Glencore',
            price: 356.87,
            quantity: 60000
          }
        ]
      }
    }
  ];

  private company: Company;
  private subscriptions: Array<any> = [];

  constructor(
    private companyService: CompanyService,
    private componentComm: ComponentCommService,
    private modalService: BsModalService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.componentComm.emit({ name: 'app-title', title: 'COMPANY.EXECUTION' });

    this.subscriptions.push(this.companyService.watch().subscribe(company => {
      if (!company) return;

      this.company = company;

      // Kick user if module is not enabled
      if (!this.company.hasModule('execution')) this.router.navigateByUrl('/companies');
    }));
  }

  /** Generic Modal trigger. */
  public openModal(template: TemplateRef<any>, c: string = ''): void {
    this.modalRef = this.modalService.show(template, { class: c });
  }

  public nextPanel(): void {
    this.panelNum++;
  }
  public prevPanel(): void {
    if (this.panelNum > 0) this.panelNum--;
  }

  /** @ignore */
  ngOnDestroy(): void {
    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}

<!-- Legal Address -->
<div class="form-group" hasError>
  <label for="comDomicilio" class="control-label">{{ 'REGISTER_COMPANY.LEGAL_ADDRESS'|translate }}</label>
  <input id="comDomicilio" type="text" class="form-control input-lg" maxlength="512" name="address"
  [(ngModel)]="company.address.street" required
  [disabled]="disabled"
  #address="ngModel"
  placeholder="{{ 'REGISTER_COMPANY.STREET'|translate }}">
  <span class="glyphicon glyphicon-remove form-control-feedback" aria-hidden="true"></span>
  <error-list></error-list>
</div>

<!-- City / State -->
<div class="row">
  <div class="col-xs-12" [class.col-md-6]="company.market?.configuration?.company.address.state?.enabled"
    *ngIf="company.market?.configuration?.company.address.city?.enabled">
    <div class="form-group" hasError>
      <label for="comCity" class="control-label">{{ 'REGISTER_COMPANY.CITY'|translate }}</label>
      <input id="comCity" type="text" class="form-control input-lg" maxlength="256" name="city"
      [(ngModel)]="company.address.city" required
      [disabled]="disabled"
      #state="ngModel"
      placeholder="{{ 'REGISTER_COMPANY.CITY'|translate }}">
      <error-list></error-list>
    </div>
  </div>
  <div class="col-xs-12" [class.col-md-6]="company.market?.configuration?.company.address.city?.enabled"
    *ngIf="company.market?.configuration?.company.address.state?.enabled">
    <div class="form-group" hasError>
      <label for="comState" class="control-label">{{ 'REGISTER_COMPANY.STATE'|translate }}</label>
      <input id="comState" type="text" class="form-control input-lg" maxlength="256" name="state"
      [(ngModel)]="company.address.state" required
      [disabled]="disabled"
      #state="ngModel"
      placeholder="{{ 'REGISTER_COMPANY.STATE'|translate }}">
      <error-list></error-list>
    </div>
  </div>
</div>

<!-- Location -->
<div class="row" *ngIf="company.market?.configuration?.location.enabled">
  <div class="col-md-12">
    <div class="form-group" hasError>
      <label class="control-label">{{company.market?.configuration?.location.last_type.label}}</label>
      <ng-template #locationTemplate let-item="item">
        {{ item.name }} {{ item.levels[2]? '(' + item.levels[2].name + ')' : '' }} - {{ item.levels[1].name }}
      </ng-template>
      <!-- TODO: guardar localidades con id y nombre -->
      <search-input classes="input-lg" name="delivery" [(ngModel)]="company.address.localidad" required
      [disabled]="disabled"
      #localidad="ngModel" [search]="locationService.locationSolver(company.market.id)"
      groupBy="levels.1.name" [itemTemplate]="locationTemplate" type="object"></search-input>
      <error-list></error-list>
    </div>
  </div>
</div>

<!-- Postal Code -->
<div class="row">
  <div class="col-xs-12 col-sm-12" [hidden]="!company.market?.configuration.company.address?.postal_code?.enabled">
    <div class="form-group" hasError>
      <label for="comPostal" class="control-label">{{ 'REGISTER_COMPANY.POSTAL_CODE'|translate }}</label>
      <input id="comPostal" type="text" class="form-control input-lg" maxlength="36" name="postalCode"
        [(ngModel)]="company.address.postal_code"
        [required]="company.market?.configuration.address?.postal_code?.enabled"
        [disabled]="disabled"
                #postalCode="ngModel" placeholder="{{ 'REGISTER_COMPANY.POSTAL_CODE'|translate }}">
      <span class="glyphicon glyphicon-remove form-control-feedback" aria-hidden="true"></span>
      <error-list></error-list>
    </div>
  </div>
</div>
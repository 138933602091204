<ng-template #modalTemplate>
  <!-- modal -->
  <div class="modal-header">
    <button type="button" class="close" [disabled]="processing" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">{{ 'NEGOTIATION_ACTIONS.DELEGATE_TRADE'|translate }}</h4>
  </div>
  <div class="modal-body">
    <p class="text-warning" [innerHtml]="'NEGOTIATION_ACTIONS.DELEGATE_TRADE_HELP'|translate"></p>

    <div class="form-group" *ngIf="brokers; else loading">
      <label class="control-label">{{ 'GLOBAL.BROKER'|translate }}</label>
      <selectize
      *ngIf="!broker"
      required
      [maxItems]="1"
      [options]="brokers"
      [(ngModel)]="broker"
      name="broker"
      placeholder="{{ 'GLOBAL.ENTER_COMPANY'|translate }}"
      [disabled]="processing"></selectize>
      <div *ngIf="broker" class="editable-pill">
        <button type="button"
        [disabled]="processing" type="button" class="close" (click)="broker = undefined"><span aria-hidden="true">&times;</span></button>
        <company-info [company]="broker"></company-info>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" (click)="closeModal()" class="btn btn-link" [disabled]="processing" data-dismiss="modal">{{
      'GLOBAL.CANCEL'|translate }}</button>
    <button (onConfirm)="confirm()" type="button" class="btn btn-agree"
      [disabled]="processing || !broker">{{ 'NEGOTIATION_ACTIONS.DELEGATE'|translate }}</button>
  </div>
</ng-template>

<ng-template #loading>
  <div class="loading">
    <spinner message="{{ 'GLOBAL.LOADING'|translate }}"></spinner>
  </div>
</ng-template>
export class ManagerRequest {
  manager_account_id: number
  manager_role_id: number
  company_id: number

  constructor(data: Partial<ManagerRequest> = {}) {
    Object.assign(this, data);
  }
}

export class AddManager {
  account_id: number
  role_id: number

  constructor(data: Partial<AddManager> = {}) {
    Object.assign(this, data);
  }
}
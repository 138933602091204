import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { filter, switchMap } from 'rxjs/operators';

import { User } from '../../../auth/models/user.model';
import { LoginService } from '../../../auth/services/login.service';
import { Company } from '../../../models/company.model';
import { Counterpart } from '../../../models/counterpart.model';
import { Pagination } from '../../../models/pagination.model';
import { CompanyService } from '../../../services/company.service';
import { ComponentCommService } from '../../../services/component-comm.service';
import { IntercomService } from '../../../services/intercom.service';
import { SubscriptionManager } from '../../../utilities/subscription-manager';

@Component({
  selector: 'app-network',
  templateUrl: './network.component.html',
  styleUrls: ['./network.component.scss']
})
export class NetworkComponent extends SubscriptionManager implements OnInit, OnDestroy {

  public company: Company;
  public paginationData: Pagination;
  public selection: Counterpart[] = [];
  public user: User;

  /** @ignore */
  constructor(
    private companyService: CompanyService,
    private componentComm: ComponentCommService,
    private intercomService: IntercomService,
    private loginService: LoginService,
    private router: Router
  ) {
    super();
  }

  /** @ignore */
  ngOnInit(): void {
    this.componentComm.emit({ name: 'app-title', title: 'GLOBAL.SELLER_ADMIN' });

    this.subscriptions.push(this.loginService.getCurrentUser().pipe(
      filter(user => !!user),
      switchMap(params => {
        this.user = params;
        return this.companyService.watch();
      })
    ).subscribe(company => {
      if (!company) return;

      this.company = company;

      if (this.company.activation_level.id === 1) {
        this.router.navigateByUrl('/companies');
      }

      this.intercomService.track('counterparts-visited');
    }));
  }

  public tableLoaded(e: any): void {
    this.paginationData = e.pagination;
  }

  /** @ignore */
  ngOnDestroy(): void {
    // Unsubscribe from everything
    super.ngOnDestroy();
  }
}

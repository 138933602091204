import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';

import { CompanyService } from '../../../services/company.service';
import { Negotiation } from '../../modules/commercial/models/negotiation.model';

@Component({
  selector: 'ag-reference-number-picker',
  exportAs: 'agReferenceNumberPicker',
  templateUrl: './reference-number-picker.component.html',
  styleUrls: ['./reference-number-picker.component.css']
})
export class ReferenceNumberPickerComponent implements OnInit, OnDestroy {

  @ViewChild('referenceModal', { static: true }) private readonly referenceModal: TemplateRef<any>;

  @Input('company-id') private company_id: number;
  @Input('document-type') private document_type: string;

  @Output() readonly saveReference = new EventEmitter();

  /**
   * Flag used to enable/disable UI buttons and links when an API request is in
   * progress.
   */
  public processing: boolean;
  public reference_number: string;

  private negotiation_id: number;
  private original_reference: string;
  private subscriptions: Subscription[] = [];
  private modalRef: BsModalRef;
  private modalSub: Subscription;

  constructor(
    private modalService: BsModalService,
    private companyService: CompanyService
  ) { }

  ngOnInit(): void {
    this.setupOriginalReference();
  }

  setupOriginalReference(): void {
    if (this.reference_number) {
      this.original_reference = JSON.parse(JSON.stringify(this.reference_number));
    }
  }

  public show(negotiation?: Negotiation): void {
    if (negotiation) {
      this.negotiation_id = negotiation.id;

      if (negotiation.data_external.reference) this.reference_number = negotiation.data_external.reference.id;
    }

    this.openModal(this.referenceModal);
  }

  hasChangedContent(): boolean {
    if (this.reference_number !== this.original_reference) {
      return true;
    } else {
      return false;
    }
  }

  submit(): void {
    this.processing = true;

    let data = {
      'data_external': {
        'reference': {
          'id': this.reference_number
        }
      }
    };

    let document_type = '';
    switch (this.document_type) {
      case 'business_confirmation':
        document_type = 'business-confirmation';
        break;
      case 'contract_clauses':
        document_type = 'contract';
        break;
    }
    this.subscriptions.push(this.companyService.amendDocument(this.company_id, this.negotiation_id, document_type, data).subscribe(response => {
      this.saveReference.emit();
      this.setupOriginalReference();
      this.closeModal();
    }));
  }

  /** Generic Modal trigger. */
  private openModal(template: TemplateRef<any>, c: string = ''): void {
    this.modalRef = this.modalService.show(template, { class: c });

    this.modalSub = this.modalRef.onHide.subscribe((reason: string) => {
      this.modalSub.unsubscribe();
      this.modalRef = undefined;
      // Reset all values
      this.processing = false;
    });
  }

  /** Closes the most recent opened modal. */
  public closeModal(onHide: Function = null): void {
    if (this.modalRef) {
      this.modalRef.hide();
      if (onHide) this.modalRef.onHide.subscribe(onHide);
    } else {
      if (onHide) onHide();
    }
  }

  /** @ignore */
  ngOnDestroy(): void {
    this.closeModal();

    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}

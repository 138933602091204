import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';

import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';
import { Pagination, parsePagination } from '../../../models/pagination.model';
import { CompanyService } from '../../../services/company.service';
import { ComponentCommService } from '../../../services/component-comm.service';
import { IntercomService } from '../../../services/intercom.service';
import { FilterSetComponent } from '../../../ui/components/filter-set/filter-set.component';
import { TableFilters } from '../../../utilities/table-filters';
import { Negotiation } from '../../modules/commercial/models/negotiation.model';
import { OrderService } from '../../modules/commercial/services/order.service';

// declare const walkthrough: any;

@Component({
  selector: 'app-company-traded-orders',
  templateUrl: './company-traded-orders.component.html',
  styleUrls: ['./company-traded-orders.component.scss']
})
export class CompanyTradedOrdersComponent extends TableFilters implements OnInit, OnDestroy {

  @ViewChild('filterSet', { static: true }) public readonly selectedFilters: FilterSetComponent;

  /** The language currently used. */
  public currentLang: string;
  public dashOpened: boolean;
  public environment = environment;
  /** Flag used to indicate if the component is loading information. */
  public loading: boolean = true;
  public negotiations: Negotiation[] = [];
  public paginationData: Pagination;
  /**
   * Flag used to enable/disable UI buttons and links when an API request is in
   * progress.
   */
  public processing: boolean;
  public selection: Negotiation[] = [];
  public totalTons = 0;
  public groupByProduct: boolean = true;
  private pusherSubscription: Subscription;

  /** @ignore */
  constructor(
    private companyService: CompanyService,
    private componentComm: ComponentCommService,
    private intercomService: IntercomService,
    private orderService: OrderService,
    public router: Router,
    private translateService: TranslateService,
    public route: ActivatedRoute
  ) {
    super(route, router);
  }

  /** @ignore */
  ngOnInit(): void {
    this.currentLang = this.translateService.currentLang === 'es' ? undefined : this.translateService.currentLang;

    this.componentComm.emit({ name: 'app-title', title: 'COMPANY.COMPANY_TRADED_ORDERS' });

    // walkthrough('company_traded_orders', false, this.translateService);

    this.subscriptions.push(this.companyService.watch().subscribe(company => {
      if (!company) return;

      this.company = company;

      if (this.company.activation_level.id === 1) {
        this.router.navigateByUrl('/companies');
      }

      this.intercomService.track('company_traded_orders-visited');

      this.onFiltersChange = this.loadData;
      this.setupFilters();
    }));
  }

  private loadData(): void {
    this.processing = true;
    if (this.pusherSubscription) this.pusherSubscription.unsubscribe();

    this.pusherSubscription = this.orderService.watchCompanyNegotiations(this.company.id, this.filters).subscribe(response => {
      if (!response) return;

      this.paginationData = parsePagination(response.headers);
      this.dataLoaded(response.body);
    });
  }

  private dataLoaded(data: Negotiation[]): void {
    this.negotiations = data;
    this.totalTons = this.sumTotals();
    this.loading = false;
    this.processing = false;
  }

  public reload(): void {
    if (!this.processing) this.subscriptions.push(this.orderService.getCompanyNegotiations(this.company.id, this.filters).subscribe(response => {
      this.paginationData = parsePagination(response.headers);
      this.dataLoaded(response.body);
    }));
  }

  private sumTotals(): number {
    let total = 0;
    this.negotiations.forEach(negotiation => {
      if (negotiation.proposal.business_detail.quantity.value) {
        total += negotiation.proposal.business_detail.quantity.value;
      }
    });
    return total;
  }

  public exportToXLS(): void {
    this.processing = true;
    this.subscriptions.push(this.orderService.negotiationsToXSLS(this.company.id, this.filters).subscribe(response => {
      const blob = new Blob([response], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const objectUrl = window.URL.createObjectURL(blob),
        dateSuffix = (new Date()).toISOString().split('T')[0];

      const a: any = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      a.href = objectUrl;
      a.download = "Trades - " + dateSuffix + ".xlsx";
      a.click();

      window.URL.revokeObjectURL(objectUrl);
      this.processing = false;
    }));
  }

  /** @ignore */
  ngOnDestroy(): void {
    // Unsubscribe from everything
    super.ngOnDestroy();
    if (this.pusherSubscription) this.pusherSubscription.unsubscribe();
  }
}

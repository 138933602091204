import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { EMPTY, Observable } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';

import { CurrentDateService } from '../../../../services/current-date.service';
import { ErrorService } from '../../../../services/error.service';
import { Order } from '../models/order.model';
import { OrderService } from './order.service';

@Injectable()
export class OrderResolver {

  constructor(
    private orderService: OrderService,
    private router: Router,
    private currentDate: CurrentDateService,
    private errorService: ErrorService
  ) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<{} | Order> {

    const orderId = parseInt(route.paramMap.get('orderId'));
    const companyId = parseInt(route.parent.paramMap.get('companyId'));

    return this.orderService.getOrder(companyId, orderId).pipe(
      take(1),
      map(order => {
        if (order) {
          if (!order.id) {

            this.router.navigate(['/company/' + companyId + '/working-orders']);
            return;
          } else {

            // si es una subasta, que ya comenzó, la cual no soy el dueño y no estoy enrolled, no te dejo entrar.
            if (order.type.id === 4 && order.company.id !== companyId && !order.auction.company_enrolled) {
              const auction_date_from = new Date(order.auction.date_from);
              if (auction_date_from < this.currentDate.get()) {
                this.router.navigate(['/company/' + companyId + '/working-orders']);
                return;
              }
            }

            // en caso que sea order.type.id = 3 que es el template
            if (order.type.id === 3) {

              // si el template tiene un broker
              if (order.broker_company) {
                order.type = {
                  id: 2,
                  name: 'Pre-orden'
                }

                // si es una orden comun guardada como template, elimino el order.type para que luego no viaje a la API
              } else {
                delete order.type;
              }
            }

            return order;
          }
        } else {
          return null;
        }
      }),
      catchError((error, caught) => {
        this.errorService.handleError(error);
        return EMPTY;
      })
    );
  }
}

<div class="container">
  <div class="content-box">
    <div class="row">
      <div class="col-xs-12 text-center">
        <h3>
          {{ 'COMPANY_SETUP.HEADING'|translate }}
        </h3>
        <!-- <p [innerHtml]="'COMPANY_SETUP.WELCOME_1'|translate"></p> -->
        <!-- <p [innerHtml]="'COMPANY_SETUP.WELCOME_2'|translate"></p> -->
        <p class="small" [innerHtml]="'COMPANY_SETUP.SELECT_HELP'|translate"></p>
      </div>
    </div>
    <div class="loading" *ngIf="(!company || !account); else loaded">
      <spinner message="{{ 'GLOBAL.LOADING'|translate }}"></spinner>
    </div>
    <ng-template #loaded>
      <div class="row" *ngIf="cards; else noCards">
        <div *ngFor="let c of cards" class="col-xs-12 col-sm-4 col-md-3">
          <ng-container *ngTemplateOutlet="card; context: c"></ng-container>
        </div>
      </div>
      <div class="text-center">
        <add-phone [user]="user"></add-phone>
      </div>
    </ng-template>
  </div>
</div>

<ng-template #card let-icon="icon" let-title="title" let-description="description" let-link="link" let-params="params"
  let-glyphicon="glyphicon">
  <div class="card text-center">
    <div class="body" routerLink="{{ link }}" [queryParams]="params">
      <i *ngIf="icon" class="material-symbols-rounded">{{ icon }}</i>
      <i *ngIf="glyphicon" class="glyphicon glyphicon-{{ glyphicon }}"></i>
      <h4 class="line-clamp">{{ title|translate }}</h4>
      <div class="description line-clamp">
        {{ description|translate }}
      </div>
    </div>
  </div>
</ng-template>

<ng-template #noCards>
  <!-- No data -->
  <div class="no-data">
    <i class="material-symbols-rounded text-warning">pan_tool</i>
    <span [innerHtml]="'HEADER.BASIC_ACTIVATION'|translate"></span>
  </div>
</ng-template>
import { Component, EventEmitter, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, combineLatest } from 'rxjs';

import { CompanyActivity } from '../../../models/company-activity.model';
import { Company } from '../../../models/company.model';
import { Country } from '../../../models/country.model';
import { FiscalId, personType } from '../../../models/fiscal-id.model';
import { Media } from '../../../models/media.model';
import { PrimitiveUnit } from '../../../models/unit.model';
import { CompanyService } from '../../../services/company.service';
import { ComponentCommService } from '../../../services/component-comm.service';
import { DataDogLoggerService } from '../../../services/data-dog-logger.service';
import { IntercomService } from '../../../services/intercom.service';
import { LocationService } from '../../../services/location.service';
import { MarketService } from '../../../services/market.service';
import { Market } from '../../modules/commercial/models/market.model';

@Component({
  selector: 'app-register-company',
  templateUrl: './register-company.component.html',
  styleUrls: ['./register-company.component.css']
})
export class RegisterCompanyComponent implements OnInit, OnDestroy {

  @ViewChild(NgForm) private readonly form: NgForm;

  public activities: CompanyActivity[] = [];
  public company: Company;
  public countries: Country[] = [];
  public deletedFile = new EventEmitter();
  public fiscalPersonType: 'PERSON' | 'COMPANY' = 'COMPANY';
  public market: Market;
  /** Flag used to indicate if the component is loading information. */
  public loading: boolean;
  public logo_files: Media[] = [];
  public marketId: number;
  public mode: 'edit' | 'create';
  public positions: PrimitiveUnit[] = [];
  public processing = false;
  public errorMessage: string;

  private subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private componentComm: ComponentCommService,
    private marketService: MarketService,
    private companyService: CompanyService,
    private intercomService: IntercomService,
    public locationService: LocationService,
    private dataDogLoggerService: DataDogLoggerService
  ) { }

  ngOnInit(): void {
    const data = this.route.snapshot.data;

    this.mode = data && data.mode;
    this.company = new Company();

    let marketId = 0;

    if (this.mode === 'edit') {
      this.company = data.company;
      marketId = this.company.market.id;
      this.resolveFiscalType(this.company.fiscal_id.value);
    } else if (this.mode === 'create') {
      marketId = this.route.snapshot.params.marketId;
      this.company.market.id = marketId;
    }

    this.componentComm.emit({ name: 'app-title', title: (this.mode === 'edit' ? 'GLOBAL.EDIT_COMPANY' : 'GLOBAL.ADD_COMPANY') });

    // When creating a Company 'logo' is undefined
    if (this.company.logo && this.company.logo.indexOf('fas/default-thumbs/') === -1) {
      let name = this.company.logo.split("/").pop();
      this.logo_files.push({
        id: '1',
        uri: this.company.logo,
        name: name
      });
    }

    this.loadData();
  }

  private loadData(): void {
    this.loading = true;

    const marketId = this.company.market.id;

    this.subscriptions.push(combineLatest([
      this.marketService.get(marketId),
      this.marketService.getActivities(marketId),
      this.companyService.getPositions()
    ]).subscribe(response => {
      // Destructures the values ​​received from the observables, for readability
      const [
        market,
        activities,
        positions
      ] = response;

      this.loading = false;

      this.market = market;
      const company_config = this.market.configuration.company;

      this.company.market = this.market;
      if (company_config.address?.country && company_config.address.country.enabled) {
        this.subscriptions.push(this.locationService.getCountryCodes().subscribe(countries => {
          this.countries = countries;
        }));
      } else if (this.market.country_id) {
        this.subscriptions.push(this.locationService.getCountryCode(this.market.country_id).subscribe(country => {
          this.company.address.country = country;
        }));
      }

      if (this.mode === 'create' &&
        company_config.fiscal_id) this.company.fiscal_id.type = company_config.fiscal_id.type;

      this.activities = activities;
      this.positions = positions;
    }));
  }

  public validateIfCompanyExists(): void {
    if (!this.company.fiscal_id.value) return;

    this.processing = true;

    this.subscriptions.push(this.marketService.companyExists(this.company.market.id, this.company.fiscal_id.value).subscribe(response => {
      let errors = this.form.controls['cuit'].errors;
      if (response.exists) {
        if (errors) {
          errors['companyExists'] = false;
        } else {
          errors = {
            "companyExists": false
          }
        }
      }
      if (response.company) {
        this.company.name = response.company.name;
        this.company.activity = response.company.activity;
      }
      this.form.controls['cuit'].setErrors(errors);
      this.processing = false;
    }));
  }

  public submit(): void {
    if (this.processing) return;

    this.form.control.setErrors({
      "remote": false
    });

    if (this.mode === 'create') {
      this.create();
    } else if (this.mode === 'edit') {
      this.update();
    }
  }

  private create(): void {
    this.processing = true;

    this.subscriptions.push(this.companyService.create(this.company).subscribe({
      next: company => {
        this.intercomService.track('company-created', {
          company_id: company.id,
          company_name: company.name,
          company_activity: this.company.activity.name
        });

        this.processing = false;
        this.router.navigate(['/companies']);
      },
      error: error => {
        this.processing = false;
        this.form.control.setErrors({
          "remote": true
        });
        this.dataDogLoggerService.warn(error.message, error.error);
      }
    }));
  }

  private update(): void {
    if (typeof this.company.logo === 'string') {
      delete this.company.logo;
    }

    this.processing = true;
    this.subscriptions.push(this.companyService.update(this.company).subscribe({
      next: company => {
        this.processing = false;
        this.router.navigate(['/company', this.company.id, 'setup']);
      },
      error: error => {
        this.processing = false;
        this.form.control.setErrors({
          "remote": true
        });
        this.dataDogLoggerService.warn(error.message, error.error);
      }
    }));
  }

  public deleteLogo(): void {
    this.subscriptions.push(this.companyService.deleteLogo(this.company.id).subscribe(response => {
      this.companyService.checkCurrent(true);
      this.logo_files = [];
      this.deletedFile.emit();
      this.company.logo = ''
    }));
  }

  /** 
   * Customizes the default Angular option comparison algorithm
   * @ignore */
  public compareId(a: { id: string | number }, b: { id: string | number }): boolean {
    return (!a && !b) || (a && b && a.id === b.id);
  }

  public compareIdentity(a, b): boolean {
    return (!a && !b) || a === b;
  }

  public onChangeCountry(country: Country): void {
    if (country.fiscalIds[0]) {
      this.company.fiscal_id.type = country.fiscalIds[0].type;
    } else {
      this.company.fiscal_id.type = null;
    }
  }

  public onChangeFiscalId(fiscalNumber: string): void {
    this.resolveFiscalType(fiscalNumber);
  }

  private resolveFiscalType(fiscalNumber: string): void {
    const fiscalType = {
      2: 'cuit', // Arg
      6: 'rfc', // Mx
      7: 'curp' // Mx
    }[this.company.market.id];

    if (fiscalType) {
      const fiscalId = new FiscalId({ type: fiscalType, value: fiscalNumber });

      this.fiscalPersonType = personType(fiscalId);
    }
  }

  public concatExtraNames(): void {
    this.company.names = this.customTrim(this.company.names);
    this.company.first_surname = this.customTrim(this.company.first_surname);
    this.company.second_surname = this.customTrim(this.company.second_surname);

    this.company.name = this.customTrim(`${this.company.names} ${this.company.first_surname} ${this.company.second_surname}`);
  }

  private customTrim(text: string): string {
    return text ?
      text.replace(/\s{2,}/g, ' ').replace(/\s+$/, '') // delete last space and doble spaces
      : '';
  }

  public csfFileChange(files: FileList): void {
    if (files?.[0] && !this.processing) {
      this.processing = true;

      delete this.errorMessage;

      this.subscriptions.push(this.companyService.import(files[0]).subscribe({
        next: company => {
          if (!company) return;

          this.intercomService.track('company-created-file', {
            company_id: company.id,
            company_name: company.name,
            company_activity: company.activity.name
          });

          this.router.navigate(['/company', company.id, 'home']);
        },
        error: error => {
          this.errorMessage = error.error.data?.status == 'ALREADY_EXISTS' ? 'ERROR_LIST.COMPANY_EXISTS' : 'SIGNUP.UNKNOWN_ERROR';
          this.processing = false;
          this.dataDogLoggerService.warn(error.message, error.error);
        }
      }));
    }
  }

  /** @ignore */
  ngOnDestroy(): void {
    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}

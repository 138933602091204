import { Component, EventEmitter, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, forkJoin } from 'rxjs';

import { Account } from '../../../auth/models/account.model';
import { Role } from '../../../auth/models/role.model';
import { User } from '../../../auth/models/user.model';
import { Company } from '../../../models/company.model';
import { TeamMember } from '../../../models/teamMember.model';
import { TeamService } from '../../../services/team.service';
import { ManagersService } from '../../services/managers.service';

@Component({
  selector: 'ag-team-managers',
  templateUrl: './team-managers.component.html',
  styleUrls: ['./team-managers.component.scss']
})
export class TeamManagersComponent implements OnInit, OnDestroy {

  @Input() public company: Company;
  @Input() public user: User;
  @Input() public account: Account;

  /**
   * Flag used to enable/disable UI buttons and links when an API request is in
   * progress.
   */
  public processing: boolean;
  public selected: TeamMember[] = [];
  public managers: TeamMember[];
  public roles: Role[];
  public deletedMember = new EventEmitter();

  private subscriptions: Subscription[] = [];

  /** @ignore */
  constructor(
    private managerService: ManagersService,
    private teamService: TeamService,
    private translateService: TranslateService
  ) { }

  /** @ignore */
  ngOnInit(): void {
    this.loadData();
  }

  public loadData(): void {
    this.processing = true;
    const myRepresentatives = this.managerService.getMyRepresentatives(this.company.id);
    const representatives = this.managerService.getRepresentatives(this.company.id);

    this.subscriptions.push(
      forkJoin([
        myRepresentatives,
        representatives
      ]).subscribe(managers => {
        this.managers = [...managers[0], ...managers[1]];
        this.translateManagersRoles();
        this.processing = false;
      })
    );

    this.subscriptions.push(
      this.managerService.getRoles().subscribe(roles => this.roles = roles)
    );
  }

  public iRepresent(member: TeamMember): boolean {
    return member.parent && member.parent?.company.id === this.company.id
  }

  public acceptInvitation(invitationId: number): void {
    if (!this.processing) {
      this.processing = true;
      this.subscriptions.push(this.managerService.acceptInvitation(this.company.id, invitationId).subscribe(response => {
        this.loadData();
      }));
    }
  }

  /** Removes the specified [[Manager]]. */
  public deleteManager(member: TeamMember): void {
    this.processing = true;
    this.subscriptions.push(this.teamService.delete(member).subscribe(response => {
      this.deletedMember.emit();
      this.loadData();
    }));
  }

  public edit(member: TeamMember): void {
    this.processing = true;
    this.subscriptions.push(this.teamService.edit(member).subscribe(response => {
      this.processing = false;
    }));
  }

  public translateManagersRoles(): void {
    this.managers.forEach(manager => {
      const roleName = `ACCOUNT.ROLES.${manager.role.slug.replace('-', '_')}`
      manager.role.name = this.translateService.instant(roleName);
    })
  }

  /** @ignore */
  ngOnDestroy(): void {
    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}

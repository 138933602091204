import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { plainToInstance } from "class-transformer";
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription, of } from 'rxjs';
import { concat, switchMap, tap } from 'rxjs/operators';

import { environment } from '../../../../../../environments/environment';
import { MessengerComponent } from '../../../../../chat/components/messenger/messenger.component';
import { AuctionBid } from '../../../../../models/auction-bid.model';
import { Company } from '../../../../../models/company.model';
import { CompanyService } from '../../../../../services/company.service';
import { ComponentCommService } from '../../../../../services/component-comm.service';
import { CurrentDateService } from '../../../../../services/current-date.service';
import { DataDogLoggerService } from '../../../../../services/data-dog-logger.service';
import { Negotiation } from '../../models/negotiation.model';
import { Order } from '../../models/order.model';
import { MemoryStorageService } from '../../services/memory-storage.service';
import { OrderService } from '../../services/order.service';

@Component({
  selector: 'app-view-order',
  templateUrl: './view-order.component.html',
  styleUrls: ['./view-order.component.scss']
})
export class ViewOrderComponent implements OnInit, OnDestroy {

  @ViewChild('bookingModal', { static: true }) private readonly bookingModal: TemplateRef<any>;
  @ViewChild('unavailableModal', { static: true }) private readonly unavailableModal: TemplateRef<any>;
  @ViewChild('enrolledCompanies') private readonly enrolledCompanies: TemplateRef<any>;
  @ViewChild('auctionHistory') private readonly auctionHistoryModal: TemplateRef<any>;
  @ViewChild('messengerComponent') public readonly messengerComponent: MessengerComponent;

  private subAuction: Subscription;
  private subAuctionMoments: Subscription;
  private subscriptions: Subscription[] = [];

  public auction_history: AuctionBid[];
  public automatic_offer: number;
  public bid_limit_value: number;
  public canceling_automatic_offer: boolean = false;
  public company: Company;
  public companyId: number; // deprecated: should use this.company.id
  /** The language currently used. */
  public currentLang: string;
  public dateEnd: Date;
  public dateFrom: Date;
  public dateExtendTo: Date;
  public enrolling: boolean = false;
  public environment = environment;
  public isAuction: boolean = false;
  public isAuctionEnded: boolean = false;
  public isAuctionLive: boolean = false;
  public isEnrolled: boolean = false;
  public isNegotiationRequestAvailable: boolean = false;
  public isWinning: boolean = false;
  public is_limit_value_valid: boolean = false;
  public offer: any = {};
  public order: Order;
  public placing_bid: boolean = false;
  public processing = false;
  public qualityTranslate: any;
  public related_products: Array<any>; // FAS-1862
  public reviewing: boolean = false;

  retiredOrder = new EventEmitter();
  heldOrder = new EventEmitter();
  rejectedOrder = new EventEmitter();

  modalRef: BsModalRef;

  constructor(
    private companyService: CompanyService,
    private componentComm: ComponentCommService,
    private currentDate: CurrentDateService,
    private memoryStorageService: MemoryStorageService,
    private modalService: BsModalService,
    private orderService: OrderService,
    private route: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService,
    private dataDogLoggerService: DataDogLoggerService
  ) { }

  ngOnInit(): void {
    this.currentLang = this.translateService.currentLang === 'es' ? undefined : this.translateService.currentLang;
    this.companyId = this.route.parent.snapshot.params.companyId;

    this.subscriptions.push(this.companyService.watch().pipe(
      tap(company => {
        this.company = company;
        if (this.company) {
          if (this.company.activation_level.id === 1) {
            this.router.navigateByUrl('/companies');
          }
          this.companyId = this.company.id;
          this.isNegotiationRequestAvailable = this.company.market.configuration.order.allow_negotiation_request ? true : false;
        }
      }),
      switchMap(company => {
        return this.route.data;
      }),
      switchMap((data: { order: any }) => {
        return of(data.order).pipe(
          concat(this.orderService.watchOrder(this.companyId, data.order.id, false))
        );
      })
    ).subscribe(order => {
      this.componentComm.emit({ name: 'app-title', title: order.product.name + ' (#' + order.id + ')' });

      this.order = order;

      if ([4, 5].indexOf(this.order.type.id) !== -1) {
        // Order is an auction
        if (this.company) {
          if (this.subAuction) {
            this.subAuction.unsubscribe();
          }
          // esta subscripcion queda hecha asi pero lo ideal seria combinarla con la subscripcion "this.subs" para tener una sola
          this.subAuction = this.orderService.watchAuction(this.company.id, order.id).subscribe(response => {

            this.placing_bid = false;
            this.auction_history = response.bid_list;
            if (this.auction_history.length > 0 && this.auction_history[0].company && this.auction_history[0].company.id === this.company.id) {
              this.isWinning = true;
            } else {
              this.isWinning = false;
            }

            if (response.limit) {
              this.automatic_offer = response.limit.value;
            } else {
              this.automatic_offer = undefined;
            }

            this.bid_limit_value = this.getBidNumber();

            if (response.extend_to) {
              this.dateExtendTo = new Date(response.extend_to);
            }

            this.updateAuctionVariables();
          });
        }

        if (this.subAuctionMoments) {
          this.subAuctionMoments.unsubscribe();
        }
        this.subAuctionMoments = this.orderService.watchAuctionMoments(order.id).subscribe(response => {
          if (response) {
            if (response.data.event === 'start') {
              this.startAuction();
            } else if (response.data.event === 'end') {
              this.endAuction();
            }
          }
        });

        this.isAuction = true;
        if (this.order.auction.company_enrolled) {
          this.isEnrolled = true;
        }

        this.dateFrom = new Date(this.order.auction.date_from);
        this.dateEnd = new Date(this.order.auction.date_to);

        this.updateAuctionVariables();
      } else {
        this.isAuction = false;
      }
      this.subscriptions.push(this.orderService.getQualityTranslate(this.companyId, this.order.product.id, this.order.language).subscribe(quality => {
        this.qualityTranslate = this.prepareQualityData(quality);
      }));

    }));
  }

  private prepareQualityData(quality: any): void {
    let result: any = {};
    for (let i in quality) {
      let attribute = quality[i];
      if (attribute.element != "textarea") {
        if (attribute.empty) {
          result[i] = attribute.empty;
        } else {
          result[i] = attribute.values[0];
        }
      } else {
        result[i] = attribute.value;
      }

      if (attribute.order) {
        result[i].order = attribute.order;
      }
    }
    return result;
  }

  private updateAuctionVariables(): void {
    // Default values
    this.isAuctionLive = false;
    this.isAuctionEnded = false;

    const current = this.currentDate.get(),
      hasStarted = this.dateFrom < current,
      hasEnded = current > (this.dateExtendTo || this.dateEnd), // Check against original end or extended end
      live_period = hasStarted && !hasEnded;

    if (this.order.order_status.group.id === 1) { // Order active
      if (live_period) { // Auction live
        if (this.company && this.order.company.id != this.company.id && !this.order.auction.company_enrolled) { // Not enrolled
          this.router.navigate(['/company/' + this.company.id + '/working-orders']);
          return;
        } else { // Owner or enrolled
          this.startAuction();
        }
      } else { // Auction not live yet
      }
    } else { // Order inactive
      if (this.order.order_status.id === 4) { // Order user cancelled
      } else {
        if (hasEnded) { // Auction ended
          this.isAuctionEnded = true;
        }
      }
    }
  }

  public isLimitValueInvalid(): boolean {
    return !this.bid_limit_value || this.placing_bid || this.isAuctionEnded || (this.order.operation_type === 'compra' ? this.bid_limit_value < this.order.auction.step : this.bid_limit_value < this.getBidNumber()) || (this.order.operation_type === 'compra' ? this.bid_limit_value > this.getBidNumber() : false) || !this.isBidStepValid(this.bid_limit_value);
  }

  private isBidStepValid(n: number): boolean {
    let decimalStr = this.order.auction.step.toString().split(".")[1],
      decimalsCount = decimalStr ? decimalStr.length : 0, // Count number of decimals in a step
      decimalRnd = Math.pow(10, decimalsCount),
      remainder = ((n * decimalRnd) % (this.order.auction.step * decimalRnd)); // Check if the bid is multiple

    return remainder === 0;
  }

  private endAuction(): void {
    this.isAuctionLive = false;
    this.isAuctionEnded = true;
    this.router.navigate(['/company', this.company.id, 'my-operations'], {
      queryParams: {
        order_type: 4,
        product_id: this.order.product.id,
        order_by: '-updated_at'
      }
    });
  }

  private startAuction(): void {
    this.isAuctionLive = true;
  }

  public cancelAutomaticAuction(): void {
    if (this.canceling_automatic_offer) return;

    this.canceling_automatic_offer = true;

    this.subscriptions.push(this.orderService.cancelAutomaticAuction(this.company.id, this.order.id).subscribe({
      next: response => {
        this.canceling_automatic_offer = false;
      },
      error: error => {
        this.canceling_automatic_offer = false;
        this.dataDogLoggerService.warn(error.message, error.error);
      }
    }));
  }

  public showEnrolledCompanies(): void {
    this.modalRef = this.modalService.show(this.enrolledCompanies);
  }

  public showAuctionHistory(): void {
    this.modalRef = this.modalService.show(this.auctionHistoryModal);
  }

  public enrollToAuction(): void {
    this.enrolling = true;

    this.subscriptions.push(this.orderService.enrollToAuction(this.company.id, this.order).subscribe(response => {
      if (response.id) {
        this.isEnrolled = true;
      }
    }));
  }

  public placeBid(): void {
    this.placing_bid = true;
    this.subscriptions.push(this.orderService.placeBid(this.companyId, this.order.id, this.getBidNumber()).subscribe(resposne => {
    }));
  }

  public placeBidValue(): void {
    this.placing_bid = true;
    this.subscriptions.push(this.orderService.placeBid(this.companyId, this.order.id, this.getBidNumber(), this.bid_limit_value).subscribe(resposne => {
    }));
  }

  public getBidNumber(): number {
    if (this.auction_history && this.auction_history.length > 0) {
      if (this.order.operation_type === 'compra') {
        return this.auction_history[0].price.value - this.order.auction.step;
      } else {
        return this.auction_history[0].price.value + this.order.auction.step;
      }
    } else {
      return this.order.business_detail.price.value;
    }
  }

  public hasActiveNegotiations(): boolean {
    for (let index = 0; index < this.order.negotiations.length; index++) {
      // Iterate through visible negotiations
      const negotiation: Negotiation = this.order.negotiations[index];

      if ([1, 2].includes(negotiation.status.group.id) && // Open, Under Negotiation groups
        negotiation.status.id != 7) { // No booked status
        return true;
      }
    }

    return false;
  }

  public negotiationRequest(): void {
    this.processing = true;
    let negotiation: Negotiation = new Negotiation();
    negotiation.order = this.order;
    negotiation.proposal = this.orderService.cloneProposal(this.order);
    negotiation.company = this.company;

    this.orderService.negotiationRequest(negotiation, this.company.id).subscribe(negotiation => {
      this.router.navigate(['/company', this.company.id, 'my-operations'], {
        queryParams: {
          // order_type: 4,
          // product_id: this.order.product.id,
          order_by: '-updated_at'
        }
      });
    });

  }

  public book(quantity: number = 0, from: string = ''): void {
    this.processing = true;

    this.subscriptions.push(this.orderService.book(this.order, this.company, quantity).subscribe({
      next: negotiation => {
        this.processing = false;
        if (this.modalRef) {
          this.modalRef.hide();
          this.modalRef = null;
        }
        if (from === 'prebook') {
          this.router.navigateByUrl('/company/' + this.company.id + '/counter-order/' + negotiation.id);
        }
      },
      error: error => {
        this.handleActionError(error);
      }
    }));
  }

  public prebook(): void {
    if (this.order.business_detail.quantity.type.id === 2) {
      this.offer = {};
      this.modalRef = this.modalService.show(this.bookingModal);
    } else {
      this.book(0, 'prebook');
    }
  }

  public prebookDone(): void {
    this.book(this.offer.quantity, 'prebook');
  }

  public cancelOrder(): void {
    this.processing = true;

    this.subscriptions.push(this.orderService.cancel(this.order, this.companyId).subscribe(response => {
      this.subscriptions.push(this.orderService.getOrder(this.companyId, this.order.id).subscribe(order => {
        this.processing = false;
        this.order = order;
        this.updateAuctionVariables();
        this.retiredOrder.emit();
      }));
    }));
  }

  public rejectOrder(): void {
    this.subscriptions.push(this.orderService.reject(this.order, this.companyId).subscribe(response => {
      this.subscriptions.push(this.orderService.getOrder(this.companyId, this.order.id).subscribe(order => {
        this.order = order;
        this.rejectedOrder.emit();
      }));
    }));
  }

  public editOrder(): void {
    this.subscriptions.push(this.orderService.onHold(this.order, this.companyId, 1).subscribe(response => {
      this.heldOrder.emit();
      if ([4, 5].indexOf(this.order.type.id) !== -1) {
        this.router.navigate(['/company/' + this.companyId + '/edit-auction/' + this.order.id]);
      } else {
        this.router.navigate(['/company/' + this.companyId + '/edit-order/' + this.order.id]);
      }
    }));
  }

  public reviewOrder(): void {
    this.reviewing = true;

    this.subscriptions.push(this.orderService.ordersValidation(Array(this.order), this.companyId).subscribe(response => {
      this.memoryStorageService.data = {
        order: plainToInstance(Order, response.body)
      };
      this.router.navigate(['/company/' + this.companyId + '/create-order']);
    }));
    // this.router.navigate(['/company/' + this.companyId + '/create-order-from-preorders/' + this.order.id]);
  }

  public disclose(): void {
    this.processing = true;
    this.subscriptions.push(this.orderService.requestDisclosure(this.order, this.companyId).subscribe({
      next: negotiation => {
        this.processing = false;
        this.router.navigate(['/company/' + this.companyId + '/counter-order/' + negotiation.id]);
      },
      error: error => {
        this.handleActionError(error);
      }
    }));
  }

  private handleActionError(error: any): void {
    this.dataDogLoggerService.warn(error.message, error.error);
    this.processing = false;
    // TODO: Mostar alerta?

    if (
      error instanceof HttpErrorResponse &&
      error.status === 403
    ) {
      this.modalRef = this.modalService.show(this.unavailableModal, {
        ignoreBackdropClick: true
      });
    } else {
      throw error;
    }
  }

  /** @ignore */
  ngOnDestroy(): void {
    if (this.subAuction) {
      this.subAuction.unsubscribe();
    }

    if (this.subAuctionMoments) {
      this.subAuctionMoments.unsubscribe();
    }

    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgChartsModule } from 'ng2-charts';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { NgPipesModule, OrderByPipe, PairsPipe } from 'ngx-pipes';
import { NgxPrintModule } from 'ngx-print';

import { CompanyLocationService } from '../services/company-location.service';
import { MarketService } from '../services/market.service';
import { TrackRecordService } from '../services/trackrecord.service';
import { DiffMatchPatchModule } from '../ui/modules/ng-diff-match-patch/diffMatchPatch.module';
import { UiModule } from '../ui/ui.module';
import { CompanyRoutingModule } from './company-routing.module';
import { CompanyComponent } from './company.component';
import { CertificatesComponent } from './components/certificates/certificates.component';
import { ClosedNegotiationsTableComponent } from './components/closed-negotiations-table/closed-negotiations-table.component';
import { CommercialManagerComponent } from './components/commercial-manager/commercial-manager.component';
import { CompanyAddressFormComponent } from './components/company-address-form/company-address-form.component';
import { CompanyLocationsComponent } from './components/company-locations/company-locations.component';
import { CompanyTradedOrdersComponent } from './components/company-traded-orders/company-traded-orders.component';
import { CountdownComponent } from './components/countdown/countdown.component';
import { CreateCdComponent } from './components/create-cd/create-cd.component';
import { DocsCustomizationComponent } from './components/docs-customization/docs-customization.component';
import { EnvelopeBindedComponent } from './components/envelope-binded/envelope-binded.component';
import { EnvelopesComponent } from './components/envelopes/envelopes.component';
import { ExecutionComponent } from './components/execution/execution.component';
import { FuturesTableComponent } from './components/futures-table/futures-table.component';
import { HomeComponent } from './components/home/home.component';
import { ApiKeysComponent } from './components/integrations/api-keys/api-keys.component';
import { ClientCredentialsComponent } from './components/integrations/client-credentials/client-credentials.component';
import { IntegrationsComponent } from './components/integrations/integrations.component';
import { WebhooksComponent } from './components/integrations/webhooks/webhooks.component';
import { InviteComponent } from './components/invite/invite.component';
import { ManagersRequestModalComponent } from './components/managers-request-modal/managers-request-modal.component';
import { MarketPricesComponent } from './components/market-prices/market-prices.component';
import { MarketComponent } from './components/market/market.component';
import { MaxQuantityConfirmComponent } from './components/max-quantity-confirm/max-quantity-confirm.component';
import { NegotiationActionsComponent } from './components/negotiation-actions/negotiation-actions.component';
import { NegotiationDateComponent } from './components/negotiation-date/negotiation-date.component';
import { NegotiationDelegateComponent } from './components/negotiation-delegate/negotiation-delegate.component';
import { NegotiationLinkerComponent } from './components/negotiation-linker/negotiation-linker.component';
import { NegotiationsDashboardComponent } from './components/negotiations-dashboard/negotiations-dashboard.component';
import { NetworkComponent } from './components/network/network.component';
import { NewExecutionComponent } from './components/new-execution/new-execution.component';
import { NotesComponent } from './components/notes/notes.component';
import { OperationAlertsComponent } from './components/operation-alerts/operation-alerts.component';
import { OrderTemplatesComponent } from './components/order-templates/order-templates.component';
import { PaymentConditionInputComponent } from './components/payment-condition-input/payment-condition-input.component';
import { RedirectPublicApiComponent } from './components/redirect-public-api/redirect-public-api.component';
import { ReferenceNumberPickerComponent } from './components/reference-number-picker/reference-number-picker.component';
import { RegisterCompanyComponent } from './components/register-company/register-company.component';
import { SetupComponent } from './components/setup/setup.component';
import { TeamManagersComponent } from './components/team-managers/team-managers.component';
import { TeamUsersComponent } from './components/team-users/team-users.component';
import { TeamComponent } from './components/team/team.component';
import { ViewEnvelopeComponent } from './components/view-envelope/view-envelope.component';
import { CommercialModule } from './modules/commercial/commercial.module';
import { OrderService } from './modules/commercial/services/order.service';
import { ImportedDataModule } from './modules/imported-data/imported-data.module';
import { SharedModule } from './modules/shared/shared.module';
import { ReplaceWildcardsPipe } from './pipes/replace-wildcards.pipe';
import { CertificatesService } from './services/certificates.service';
import { CounterpartsService } from './services/counterparts.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    CompanyRoutingModule,
    ImportedDataModule,
    CommercialModule,
    UiModule,
    NgPipesModule,
    DiffMatchPatchModule,
    NgChartsModule,
    NgxJsonViewerModule,
    NgxPrintModule,
    ReactiveFormsModule,
    SharedModule
  ],
  declarations: [
    RegisterCompanyComponent,
    MarketComponent,
    NetworkComponent,
    OperationAlertsComponent,
    CompanyTradedOrdersComponent,
    CompanyComponent,
    TeamComponent,
    InviteComponent,
    CompanyLocationsComponent,
    OrderTemplatesComponent,
    ExecutionComponent,
    SetupComponent,
    ReplaceWildcardsPipe,
    MaxQuantityConfirmComponent,
    PaymentConditionInputComponent,
    NewExecutionComponent,
    ReferenceNumberPickerComponent,
    NegotiationActionsComponent,
    ReferenceNumberPickerComponent,
    CountdownComponent,
    CommercialManagerComponent,
    CertificatesComponent,
    MarketPricesComponent,
    ClosedNegotiationsTableComponent,
    EnvelopesComponent,
    FuturesTableComponent,
    NegotiationLinkerComponent,
    CreateCdComponent,
    NegotiationsDashboardComponent,
    ViewEnvelopeComponent,
    IntegrationsComponent,
    EnvelopeBindedComponent,
    NegotiationDateComponent,
    NegotiationDelegateComponent,
    DocsCustomizationComponent,
    TeamManagersComponent,
    ManagersRequestModalComponent,
    TeamUsersComponent,
    CompanyAddressFormComponent,
    NotesComponent,
    ApiKeysComponent,
    ClientCredentialsComponent,
    WebhooksComponent,
    RedirectPublicApiComponent,
    HomeComponent
  ],
  exports: [
    CompanyAddressFormComponent
  ],
  providers: [
    MarketService,
    OrderService,
    CertificatesService,
    OrderByPipe,
    PairsPipe,
    CounterpartsService,
    CompanyLocationService,
    TrackRecordService
  ]
})
export class CompanyModule { }

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthorizationToken, ClientCredentials } from '../models/oauth.model';
import { plainToInstance } from 'class-transformer';
import { Observable, map } from 'rxjs';
import { JSend } from '../../models/jsend.model';
import { Company } from '../../models/company.model';

@Injectable({
  providedIn: 'root'
})
export class OAuthService {
  private baseUlr: string = '/:apiOauth/';
  private credentiaslUrl: string = this.baseUlr + 'credentials/:companyId';
  private credentialByIdUrl: string = this.credentiaslUrl + '/:credentialId';
  private authorizationUrl: string = this.baseUlr + 'authorization'
  private companyUrl: string = this.baseUlr + 'companies/:clientId';

  constructor(private http: HttpClient) { }

  public getCredentials(companyId: Company['id']): Observable<ClientCredentials[]> {
    const url = this.credentiaslUrl.replace(':companyId', String(companyId));
    const stream = this.http.get<JSend<{ credentials: ClientCredentials[] }>>(url);

    return stream.pipe(
      map((response) => plainToInstance(ClientCredentials, response.data.credentials))
    )
  }

  public createCredentials(companyId: Company['id']): Observable<ClientCredentials> {
    const url = this.credentiaslUrl.replace(':companyId', String(companyId));
    const stream = this.http.post<JSend<{ credentials: ClientCredentials }>>(url, {});

    return stream.pipe(
      map((response) => plainToInstance(ClientCredentials, response.data.credentials))
    )
  };

  public deleteCredential(companyId: Company['id'], credential: ClientCredentials): Observable<ClientCredentials> {
    const url = this.credentialByIdUrl
    .replace(':companyId', String(companyId))
    .replace(':credentialId', String(credential.client_id));

    const stream = this.http.delete<JSend<{ credentials: ClientCredentials }>>(url);

    return stream.pipe(
      map((response) => plainToInstance(ClientCredentials, response.data.credentials))
    )
  }

  public authorize(clientId: ClientCredentials['client_id'], userId: number): Observable<AuthorizationToken>{
    const url = this.authorizationUrl;
    const body = { client_id: clientId, user_id: userId };
    const stream = this.http.post<JSend<{ data: AuthorizationToken }>>(url, body);

    return stream.pipe(
      map((response) => plainToInstance(AuthorizationToken, response.data))
    )
  }

  public getCompany(clientId: ClientCredentials['client_id']): Observable<Company>{
    const url = this.companyUrl.replace(':clientId', clientId);
    const stream = this.http.get<JSend<{ company: Company }>>(url);

    return stream.pipe(
      map((response) => plainToInstance(Company, response.data.company))
    )
  }
}

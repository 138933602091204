<div class="container">
  <div class="content-box">
    <div class="ag-header">
      <div class="ag-header-title">
        <h3 *ngIf="mode === 'create'">{{ 'GLOBAL.ADD_COMPANY'|translate }}</h3>
        <h3 *ngIf="mode === 'edit'">{{ 'GLOBAL.EDIT_COMPANY'|translate }}</h3>
      </div>
      <div class="ag-header-actions" *ngIf="mode === 'edit'">
        <div class="btn-toolbar">
          <div class="btn-group">
            <ag-docs-customization
            #docsCustomization
            [company]="company"></ag-docs-customization>
            <button type="button"
            class="btn btn-link" [disabled]="processing"
            *ngIf="company?.market.configuration.order.contract.type === 'business_confirmation'"
            (click)="docsCustomization.open()">
              <span class="material-symbols-rounded">picture_as_pdf</span>
              {{ 'DOC_CUSTOM.TITLE'|translate }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <ng-container *ngIf="!loading; else loadingIndicator">
      <form *ngIf="mode === 'create' &&
        market.configuration.company.fiscal_id.type === 'rfc' &&
        !company.id;
        else companyForm"
      id="import-form"
      #importForm="ngForm">
        <div class="row">
          <div class="col-sm-6">
            <p>El primer paso para dar de alta tu Empresa es adjuntar la <b>Constancia de Situación Fiscal</b>.</p>
            <div *ngIf="!processing; else processingIndicator"
            class="form-group" hasError>
              <label for="image" class="control-label">{{
                "AUTHORIZATIONS.FISCAL_PROOF" | translate
              }}</label>
              <file-input
              drop-area="#import-form"
              name="record"
              inputId="record"
              [max-size]="5242880"
              accept=".pdf"
              [disabled]="processing"
              ngModel
              (ngModelChange)="csfFileChange($event)"
              required></file-input>
              <error-list></error-list>
              <p class="help-block small">
                {{ "AUTHORIZATIONS.FISCAL_PROOF_HELP" | translate }}
              </p>
              <alert *ngIf="errorMessage"
              type="danger" [dismissible]="true" (onClosed)="errorMessage = undefined">
                <!-- <b>{{ 'IMPORTER.ERROR'|translate }}</b> -->
                <span [innerHtml]="errorMessage|translate"></span>
              </alert>
            </div>
          </div>
          <div class="col-sm-6">
            <img src="/assets/img/commons/sap-pdf.png" class="img-responsive" />
          </div>
        </div>
        <div class="row">
          <div class="col-xs-6 col-sm-4 col-md-3">
            <a routerLink="/companies" class="btn btn-lg btn-default btn-block">
              {{ 'GLOBAL.CANCEL'|translate }}
            </a>
          </div>
        </div>
      </form>
    </ng-container>
  </div>
</div>

<ng-template #loadingIndicator>
  <div class="loading">
    <spinner message="{{ 'GLOBAL.LOADING'|translate }}"></spinner>
  </div>
</ng-template>

<ng-template #processingIndicator>
  <div class="loading loading-sm">
    <spinner message="{{ 'GLOBAL.PROCESSING'|translate }}"></spinner>
  </div>
</ng-template>

<ng-template #companyForm>
  <form class="form-signin" (ngSubmit)="form.valid && submit()" #form="ngForm">
    <div class="row">
      <div class="col-xs-12 col-sm-6">

        <div class="form-group" hasError *ngIf="market?.configuration?.company.address?.country?.enabled">
          <label for="comCountry" class="control-label">{{ 'REGISTER_COMPANY.COUNTRY'|translate }}</label>
          <select id="comCountry" class="form-control input-lg has-placeholder"
          name="country" [(ngModel)]="company.address.country" required
          [compareWith]="compareId"
          [disabled]="mode === 'edit'"
          #country="ngModel"
          (ngModelChange)="onChangeCountry($event)">
            <option [ngValue]="null" [disabled]="true">{{ 'REGISTER_COMPANY.COUNTRY'|translate }}</option>
            <option *ngFor="let country of countries" [ngValue]="{id: country.id, name: country.name, iso: country.iso, fiscalIds: country.fiscalIds}">{{ country.name }}</option>
          </select>
        </div>
        
        <div class="form-group" hasError hasErrorOn="dirty">
          <label for="comCUIT" class="control-label">
            {{ market?.configuration?.company.fiscal_id ? market?.configuration?.company.fiscal_id.label : (company.address.country?.fiscalIds[0]?.label ?  company.address.country?.fiscalIds[0]?.label : 'REGISTER_COMPANY.FISCAL_IDENTIFICATION' |translate)}}
          </label>
          <input id="comCUIT" type="text" class="form-control input-lg"
          name="cuit" [(ngModel)]="company.fiscal_id.value" required
          [disabled]="mode === 'edit'"
          [mask]="market?.configuration?.company.fiscal_id ? market.configuration?.company.fiscal_id.mask : company.address.country?.fiscalIds[0]?.mask"
          [placeholder]="market?.configuration?.company.fiscal_id ? (market.configuration?.company.fiscal_id.mask ? market.configuration?.company.fiscal_id.mask : '') : (company.address.country?.fiscalIds[0]?.mask ? company.address.country?.fiscalIds[0]?.mask : '')"
          #cuit="ngModel"
          [validCuit]="(market?.configuration.company.fiscal_id?.type || company.address.country?.fiscalIds[0]?.type) === 'cuit'"
          [validRfc]="(market?.configuration.company.fiscal_id?.type || company.address.country?.fiscalIds[0]?.type) === 'rfc'"
          [validCURP]="(market?.configuration.company.fiscal_id?.type || company.address.country?.fiscalIds[0]?.type) === 'curp'"
          (ngModelChange)="onChangeFiscalId($event)"
          (blur)="validateIfCompanyExists()"
          autofocus>
          <span class="glyphicon glyphicon-remove form-control-feedback" aria-hidden="true"></span>
          <error-list></error-list>
        </div>

        <div *ngIf="fiscalPersonType === 'COMPANY'">
          <div class="form-group" hasError>
            <label for="comRazon" class="control-label">{{ 'REGISTER_COMPANY.NAME'|translate }}</label>
            <input id="comRazon" type="text" class="form-control input-lg"
            maxlength="256"
            name="name" [(ngModel)]="company.name" required
            [disabled]="mode === 'edit'"
            #name="ngModel">
            <span class="glyphicon glyphicon-remove form-control-feedback" aria-hidden="true"></span>
            <error-list></error-list>
          </div>
        </div>
        
        <div *ngIf="fiscalPersonType === 'PERSON'">
          <div class="form-group" hasError>
            <label for="comRazonNames" class="control-label">{{'REGISTER_COMPANY.NAMES'|translate}}</label>
            <input id="comRazonNames" type="text" class="form-control input-lg"
            maxlength="256"
            [(ngModel)]="company.names"
            name="names"
            (ngModelChange)="concatExtraNames()"
            [disabled]="mode === 'edit'"
            required>
            <span class="glyphicon glyphicon-remove form-control-feedback" aria-hidden="true"></span>
            <error-list></error-list>
          </div>
          <div class="form-group" hasError>
            <label for="comRazonFirstSurname" class="control-label">{{'REGISTER_COMPANY.FIRST_SURNAME'|translate}}</label>
            <input id="comRazonFirstSurname" type="text" class="form-control input-lg"
            maxlength="256"
            [(ngModel)]="company.first_surname"
            name="first_surname"
            (ngModelChange)="concatExtraNames()"
            [disabled]="mode === 'edit'"
            required>
            <span class="glyphicon glyphicon-remove form-control-feedback" aria-hidden="true"></span>
            <error-list></error-list>
          </div>
          <div class="form-group" hasError>
            <label for="comRazonSecondSurname" class="control-label">{{'REGISTER_COMPANY.SECOND_SURNAME'|translate}}</label>
            <input id="comRazonSecondSurname" type="text" class="form-control input-lg"
            maxlength="256"
            [(ngModel)]="company.second_surname"
            name="second_surname"
            (ngModelChange)="concatExtraNames()"
            [disabled]="mode === 'edit'"
            required>
            <span class="glyphicon glyphicon-remove form-control-feedback" aria-hidden="true"></span>
            <error-list></error-list>
          </div>
        </div>

        <div class="form-group" hasError>
          <label for="comActividad" class="control-label">{{ 'GLOBAL.MAIN_ACTIVITY'|translate }}</label>
          <select id="comActividad" class="form-control input-lg has-placeholder"
          name="activity" [(ngModel)]="company.activity" required
          [compareWith]="compareId"
          [disabled]="mode === 'edit'"
          #activity="ngModel">
            <option [ngValue]="null" [disabled]="true">{{ 'GLOBAL.SELECT'|translate }}</option>
            <option *ngFor="let act of activities" [ngValue]="act">{{ act.name }}</option>
          </select>
          <span class="glyphicon glyphicon-remove form-control-feedback" aria-hidden="true"></span>
          <error-list></error-list>
        </div>

        <ag-company-address-form
        *ngIf="market?.configuration?.company.address"
        [company]="company"
        [disabled]="mode === 'edit'"></ag-company-address-form>

        <div class="row">
          <div class="col-xs-12 col-sm-12">
            <div class="form-group" hasError>
              <label for="comTelefono" class="control-label">{{ 'REGISTER_COMPANY.TELEPHONE'|translate }}</label>
              <input id="comTelefono" type="text" class="form-control input-lg"
              maxlength="128"
              name="telephone" [(ngModel)]="company.phoneNumber" required
              [disabled]="mode === 'edit'"
              #telephone="ngModel" placeholder="{{ 'REGISTER_COMPANY.TELEPHONE'|translate }}">
              <span class="glyphicon glyphicon-remove form-control-feedback" aria-hidden="true"></span>
              <error-list></error-list>
            </div>
          </div>
        </div>
        
      </div>

      <div class="col-xs-12 col-sm-6">
        <div class="form-group" hasError>
          <label for="comCargo" class="control-label">{{ 'REGISTER_COMPANY.MY_POSITION'|translate }}</label>
          <select id="comCargo" class="form-control input-lg has-placeholder corredor"
          name="account" [(ngModel)]="company.account_owner.position_id" required
          [disabled]="mode === 'edit'"
          [compareWith]="compareIdentity"
          #account="ngModel">
            <option [ngValue]="null" [disabled]="true">{{ 'REGISTER_COMPANY.POSITION'|translate }}</option>
            <option *ngFor="let pos of positions" [ngValue]="pos.id">{{ pos.name }}</option>
          </select>
          <span class="glyphicon glyphicon-remove form-control-feedback" aria-hidden="true"></span>
          <error-list></error-list>
        </div>

        <ng-template #fileCol1 let-double="double">
          <div class="col-xs-12" id="re_logo" [class.col-md-6]="double" *ngIf="market?.configuration?.company.documents.includes('logo')">
            <div class="form-group" hasError hasErrorOn='always'>
              <label class="control-label">{{ 'REGISTER_COMPANY.LOGO'|translate }}</label>
              <file-input
              drop-area="#re_logo"
              name="file-1[]" 
              inputId="file-1" 
              [(ngModel)]="company.logo" 
              [max-size]="5242880" 
              accept="image/*"
              [files]="logo_files"
              [deletedFile]="deletedFile"
              #logo="ngModel"
              (delete)="deleteLogo()"></file-input>
              <error-list></error-list>
              <p class="help-block">{{ 'REGISTER_COMPANY.LOGO_DESCRIPTION'|translate }}</p>
            </div>
          </div>
        </ng-template>
        <ng-template #fileCol2 let-double="double">
          <div class="col-xs-12" id="re_statute" [class.col-md-6]="double" *ngIf="market?.configuration?.company.documents.includes('statute')">
            <div class="form-group" hasError>
              <label class="control-label">{{ 'REGISTER_COMPANY.STATUTE'|translate }}</label>
              <file-input
              drop-area="#re_statute"
              [max-files]="10"
              name="file-2[]" 
              inputId="file-2" 
              [(ngModel)]="company.statute" 
              [max-size]="5242880" 
              accept="image/*, .pdf, .doc, .docx"
              [disabled]="company?.has_statute"
              [hasAnomFile]="company?.has_statute"
              #statute="ngModel"></file-input>
              <error-list></error-list>
              <p class="help-block">{{ 'REGISTER_COMPANY.FILE_DESCRIPTION'|translate }}</p>
            </div>
          </div>
        </ng-template>
        <ng-template #fileCol3 let-double="double">
          <div class="col-xs-12" id="re_authorization" [class.col-md-6]="double" *ngIf="market?.configuration?.company.documents.includes('authorization')">
            <div class="form-group" hasError>
              <label class="control-label">{{ 'REGISTER_COMPANY.AUTHORIZATION'|translate }}</label>
              <file-input
              drop-area="#re_authorization"
              [max-files]="10"
              name="file-3[]" 
              inputId="file-3" 
              [(ngModel)]="company.authorization" 
              [max-size]="5242880" 
              accept="image/*, .pdf, .doc, .docx"
              [disabled]="company?.has_authorization"
              [hasAnomFile]="company?.has_authorization"
              #auth="ngModel"></file-input>
              <error-list></error-list>
              <p class="help-block">{{ 'REGISTER_COMPANY.FILE_DESCRIPTION'|translate }}</p>
            </div>
          </div>
        </ng-template>
        <ng-template #fileCol4 let-double="double">
          <div class="col-xs-12" id="re_id_card" [class.col-md-6]="double" *ngIf="market?.configuration?.company.documents.includes('id_card') && market?.configuration?.company.id_card">
            <div class="form-group" hasError>
              <label class="control-label">
                {{ 'REGISTER_COMPANY.IDENTIFICATION'|translate:{documento:market?.configuration?.company.id_card.label} }}
              </label>
              <file-input
              drop-area="#re_id_card"
              [max-files]="10"
              name="file-4[]"
              inputId="file-4"
              [(ngModel)]="company.id_card"
              [max-size]="5242880"
              accept="image/*, .pdf"
              [disabled]="company?.has_id_card"
              [hasAnomFile]="company?.has_id_card"
              #dni="ngModel"></file-input>
              <error-list></error-list>
              <p class="help-block">{{ 'REGISTER_COMPANY.FILE_DESCRIPTION'|translate }}</p>
            </div>
          </div>
        </ng-template>

        <div class="row">
          <ng-container *ngTemplateOutlet="fileCol1;context:{double: market?.configuration?.company.documents.includes('logo') && market?.configuration?.company.documents.includes('statute')}"></ng-container>
          <ng-container *ngTemplateOutlet="fileCol2;context:{double: market?.configuration?.company.documents.includes('logo') && market?.configuration?.company.documents.includes('statute')}"></ng-container>
        </div>
        <div class="row">
          <ng-container *ngTemplateOutlet="fileCol3;context:{double: market?.configuration?.company.documents.includes('authorization') && market?.configuration?.company.documents.includes('id_card')}"></ng-container>
          <ng-container *ngTemplateOutlet="fileCol4;context:{double: market?.configuration?.company.documents.includes('authorization') && market?.configuration?.company.documents.includes('id_card')}"></ng-container>
        </div>

        <div class="row"
        *ngIf="market?.configuration?.company.documents.includes('curp_proof')">
          <div class="col-xs-12" id="re_curp">
            <div class="form-group" hasError>
              <label class="control-label">
                {{ 'Constancia de Clave Única de Registro de Población'|translate }}
              </label>
              <file-input
              drop-area="#re_curp"
              name="file-5[]"
              inputId="file-5"
              [(ngModel)]="company.curp"
              [max-size]="5242880"
              accept=".pdf"
              [disabled]="company?.has_curp"
              [required]="mode === 'create'"
              [hasAnomFile]="company?.has_curp"
              #dni="ngModel"></file-input>
              <error-list></error-list>
              <p class="help-block">RENAPO</p>
            </div>
          </div>
        </div>

        <div class="row"
        *ngIf="market?.configuration?.company.documents.includes('tax_certificate')">
          <div class="col-xs-12" id="re_tax_certificate">
            <div class="form-group" hasError>
              <label class="control-label">
                {{ 'REGISTER_COMPANY.TAX_CERTIFICATE'|translate }}
              </label>
              <file-input
              drop-area="#re_tax_certificate"
              name="file-5[]"
              inputId="file-5"
              [(ngModel)]="company.tax_certificate"
              [max-size]="5242880"
              accept=".pdf"
              [disabled]="company?.has_tax_certificate"
              [required]="mode === 'create'"
              [hasAnomFile]="company?.has_tax_certificate"
              #dni="ngModel"></file-input>
              <error-list></error-list>
              <p class="help-block">{{ 'REGISTER_COMPANY.TAX_CERTIFICATE_HELP'|translate }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr>
    <alert type="danger" [dismissible]="true" *ngIf="form.invalid && form.submitted && form.errors?.remote">
      {{ 'REGISTER_COMPANY.ERROR_MESSAGE'|translate }}
    </alert>
    <div class="row">
      <div class="col-xs-6 col-sm-4 col-md-3">
        <a routerLink="/companies" class="btn btn-lg btn-default btn-block">
          {{ 'GLOBAL.CANCEL'|translate }}
        </a>
      </div>
      <div class="col-xs-6 col-sm-4 col-sm-offset-4 col-md-3 col-md-offset-6">
        <button class="btn btn-lg btn-agree btn-block" type="submit" [disabled]="processing" *ngIf="mode === 'create'">
          {{ 'GLOBAL.ADD'|translate }}
        </button>
        <button class="btn btn-lg btn-agree btn-block" type="submit" [disabled]="processing || form.pristine" *ngIf="mode === 'edit'">
          {{ 'GLOBAL.SAVE'|translate }}
        </button>
      </div>
    </div>
  </form>
</ng-template>
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '../auth/services/auth-guard.service';
import { CenterComponent } from '../chat/components/center/center.component';
import { MailVerifiedGuard } from '../services/mail-verified-guard.service';
import { ThemeResolver } from '../theme/theme.resolver';
import { LeaveGuard } from '../ui/services/leave-guard.service';
import { CompanyComponent } from './company.component';
import { CertificatesComponent } from './components/certificates/certificates.component';
import { CommercialManagerComponent } from './components/commercial-manager/commercial-manager.component';
import { CompanyLocationsComponent } from './components/company-locations/company-locations.component';
import { CompanyTradedOrdersComponent } from './components/company-traded-orders/company-traded-orders.component';
import { CreateCdComponent } from './components/create-cd/create-cd.component';
import { EnvelopesComponent } from './components/envelopes/envelopes.component';
import { ExecutionComponent } from './components/execution/execution.component';
import { HomeComponent } from './components/home/home.component';
import { IntegrationsComponent } from './components/integrations/integrations.component';
import { MarketPricesComponent } from './components/market-prices/market-prices.component';
import { MarketComponent } from './components/market/market.component';
import { NetworkComponent } from './components/network/network.component';
import { NewExecutionComponent } from './components/new-execution/new-execution.component';
import { NotesComponent } from './components/notes/notes.component';
import { OperationAlertsComponent } from './components/operation-alerts/operation-alerts.component';
import { OrderTemplatesComponent } from './components/order-templates/order-templates.component';
import { RegisterCompanyComponent } from './components/register-company/register-company.component';
import { SetupComponent } from './components/setup/setup.component';
import { TeamComponent } from './components/team/team.component';
import { ViewEnvelopeComponent } from './components/view-envelope/view-envelope.component';
import { CounterOrderComponent } from './modules/commercial/components/counter-order/counter-order.component';
import { CreateOrderComponent } from './modules/commercial/components/create-order/create-order.component';
import { MyOperationsComponent } from './modules/commercial/components/my-operations/my-operations.component';
import { PreordersComponent } from './modules/commercial/components/preorders/preorders.component';
import { TradedOrdersComponent } from './modules/commercial/components/traded-orders/traded-orders.component';
import { TradingOrdersComponent } from './modules/commercial/components/trading-orders/trading-orders.component';
import { ViewOrderComponent } from './modules/commercial/components/view-order/view-order.component';
import { MarketSummaryResolver } from './modules/commercial/services/marketsummary-resolver.service';
import { NegotiationResolver } from './modules/commercial/services/negotiation-resolver.service';
import { OrderGroupResolver } from './modules/commercial/services/order-group-resolver.service';
import { OrderResolver } from './modules/commercial/services/order-resolver.service';
import { ImportedDataComponent } from './modules/imported-data/components/imported-data/imported-data.component';
import { PresetsFiltersResolver } from './resolvers/presets-filters.resolver';
import { CompanyResolver } from './services/company-resolver.service';
import { InitPathResolver } from './services/initpath-resolver.service';

const routes: Routes = [
  {
    path: 'register-company/market/:marketId',
    canActivate: [AuthGuard, MailVerifiedGuard],
    resolve: {
      'theme': ThemeResolver
    },
    component: RegisterCompanyComponent,
    data: {
      mode: 'create'
    }
  }, {
    path: 'company/:companyId',
    canActivate: [AuthGuard],
    resolve: {
      'theme': ThemeResolver
    },
    runGuardsAndResolvers: 'always',
    component: CompanyComponent,
    children: [
      {
        path: 'edit',
        component: RegisterCompanyComponent,
        data: {
          mode: 'edit'
        },
        resolve: {
          company: CompanyResolver
        }
      },
      { path: '', redirectTo: 'market', pathMatch: 'full' },
      {
        path: 'market',
        component: MarketComponent,
        resolve: {
          company: MarketSummaryResolver
        }
      },
      {
        path: 'market/zone',
        component: MarketComponent,
        data: {
          type: 'zone'
        }
      },
      {
        path: 'market/location',
        component: MarketComponent,
        data: {
          type: 'location'
        }
      },
      { path: 'my-operations', component: MyOperationsComponent },
      { path: 'network', component: NetworkComponent },
      { path: 'notes', component: NotesComponent },
      { path: 'certificates', component: CertificatesComponent },
      { path: 'commercial-manager', component: CommercialManagerComponent },
      { path: 'chat', component: CenterComponent },
      { path: 'operation-alerts', component: OperationAlertsComponent },
      {
        path: 'working-orders', component: TradingOrdersComponent,
        /*
        resolve: {
          ordersByProduct: OrderGroupResolver
        }
         */
      },
      { path: 'traded-orders', component: TradedOrdersComponent },
      { path: 'company-traded-orders', component: CompanyTradedOrdersComponent },
      { path: 'create-cd', component: CreateCdComponent },
      {
        path: 'create-cd/:negotiationId',
        component: CreateCdComponent,
        runGuardsAndResolvers: 'always',
        resolve: {
          negotiation: NegotiationResolver
        }
      },
      {
        path: 'create-order',
        component: CreateOrderComponent,
        data: {
          mode: 'create'
        }
      },
      {
        path: 'create-auction',
        component: CreateOrderComponent,
        data: {
          mode: 'create',
          auction: true
        }
      },
      {
        path: 'order/:orderId',
        component: ViewOrderComponent,
        runGuardsAndResolvers: 'always',
        resolve: {
          order: OrderResolver
        }
      },
      {
        path: 'edit-order/:orderId',
        component: CreateOrderComponent,
        runGuardsAndResolvers: 'always',
        canDeactivate: [LeaveGuard],
        data: {
          mode: 'edit'
        },
        resolve: {
          order: OrderResolver
        }
      },
      {
        path: 'edit-auction/:orderId',
        component: CreateOrderComponent,
        runGuardsAndResolvers: 'always',
        canDeactivate: [LeaveGuard],
        data: {
          mode: 'edit',
          auction: true
        },
        resolve: {
          order: OrderResolver
        }
      },
      {
        path: 'republish-order/:orderId',
        component: CreateOrderComponent,
        runGuardsAndResolvers: 'always',
        data: {
          mode: 'republish'
        },
        resolve: {
          order: OrderResolver
        }
      },
      {
        path: 'republish-auction/:orderId',
        component: CreateOrderComponent,
        runGuardsAndResolvers: 'always',
        data: {
          mode: 'republish',
          auction: true
        },
        resolve: {
          order: OrderResolver
        }
      },
      {
        path: 'order/:orderId/create-counter-order',
        component: CounterOrderComponent,
        runGuardsAndResolvers: 'always',
        resolve: {
          order: OrderResolver
        }
      },
      {
        path: 'counter-order/:negotiationId/propose-amendments',
        component: CounterOrderComponent,
        runGuardsAndResolvers: 'always',
        data: {
          isAmendment: true
        },
        resolve: {
          negotiation: NegotiationResolver
        }
      },
      {
        path: 'counter-order/:negotiationId',
        component: CounterOrderComponent,
        runGuardsAndResolvers: 'always',
        resolve: {
          negotiation: NegotiationResolver
        }
      },
      { path: 'team', component: TeamComponent },
      { path: 'team/:collection', component: TeamComponent },
      { path: 'locations', component: CompanyLocationsComponent },
      { path: 'preorders', component: PreordersComponent },
      { path: 'templates', component: OrderTemplatesComponent },
      { path: 'execution', component: ExecutionComponent },
      { path: 'new-execution', component: NewExecutionComponent },
      { path: 'envelopes', component: EnvelopesComponent },
      { path: 'envelopes/:envelopeId', component: ViewEnvelopeComponent },
      { path: 'slots', loadChildren: () => import('./modules/slots/slots.module').then(m => m.SlotsModule) },
      { path: 'market-prices', component: MarketPricesComponent },
      { path: '', loadChildren: () => import('./modules/barters/barters.module').then(m => m.BartersModule) },
      { path: 'statistics', loadChildren: () => import('./modules/statistics/statistics.module').then(m => m.StatisticsModule) },
      { path: 'fintech', loadChildren: () => import('./modules/fintech/fintech.module').then(m => m.FintechModule) },
      { path: 'approvals', loadChildren: () => import('./modules/approvals/approvals.module').then(m => m.ApprovalsModule) },
      { path: 'integrations', component: IntegrationsComponent },
      { path: 'imported-data', component: ImportedDataComponent },
      {
        path: 'imported-data/:collection',
        component: ImportedDataComponent,
        resolve: {
          presetFilters: PresetsFiltersResolver
        },
        data: {
          sectionsToPreset: ['contracts']
        }
      },
      {
        path: 'contracts',
        loadChildren: () => import('./modules/contracts/contracts.module').then(m => m.ContractsModule)
      },
      { path: 'setup', component: SetupComponent },
      {
        path: 'home',
        component: HomeComponent,
        runGuardsAndResolvers: 'always',
        resolve: {
          account: InitPathResolver
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [
    CompanyResolver,
    OrderResolver,
    OrderGroupResolver,
    NegotiationResolver,
    InitPathResolver,
    PresetsFiltersResolver,
    ThemeResolver
  ]
})
export class CompanyRoutingModule { }

import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { Company } from '../../../models/company.model';
import { Envelope } from '../../../models/envelope.model';
import { CompanyService } from '../../../services/company.service';
import { ComponentCommService } from '../../../services/component-comm.service';
import { SignaturesService } from '../../../services/signatures.service';

@Component({
  selector: 'app-view-envelope',
  templateUrl: './view-envelope.component.html',
  styleUrls: ['./view-envelope.component.css']
})
export class ViewEnvelopeComponent implements OnInit, OnDestroy {

  public company: Company;
  /** Flag used to indicate if the component is loading information. */
  public loading: boolean;
  public envelope: Envelope;
  public envelopeUpdated = new EventEmitter();

  /** Unique identifier of the current [[Envelope]]. */
  private envelopeId: number;
  private subscriptions: Subscription[] = [];

  constructor(
    private companyService: CompanyService,
    private componentComm: ComponentCommService,
    private route: ActivatedRoute,
    private signaturesService: SignaturesService
  ) { }

  ngOnInit(): void {
    this.envelopeId = parseInt(this.route.snapshot.paramMap.get('envelopeId'));
    this.componentComm.emit({ name: 'app-title', title: 'SIGNATURES.ENVELOPE', params: { id: this.envelopeId } });

    this.subscriptions.push(this.companyService.watch().subscribe(company => {
      if (!company) return;

      this.company = company;

      this.loadEnvelope();
    }));
  }

  private loadEnvelope(): void {
    if (!this.envelope && this.envelopeId) {
      this.loading = true;

      this.subscriptions.push(this.signaturesService.watchEnvelope(this.company.id, this.envelopeId).subscribe(envelope => {
        if (!envelope) return;

        this.envelope = envelope;
        this.loading = false;
        this.envelopeUpdated.emit();
      }));
    }
  }

  /** @ignore */
  ngOnDestroy(): void {
    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable, map } from 'rxjs';

import { ApiKey } from '../../models/api-key.model';
import { JSend } from '../../models/jsend.model';

@Injectable({
  providedIn: 'root'
})
export class ApplicationsProxyService {

  private baseUrl = '/:apiApplicationProxy/';
  private apiKeysUrl = this.baseUrl + '/companies/:companyId/api/keys'

  constructor(private http: HttpClient) { }

  public getApiKeys(companyId: number): Observable<ApiKey[]> {
    const url = this.apiKeysUrl.replace(':companyId', String(companyId));

    return this.http.get<JSend<{
      keys: ApiKey[];
    }>>(url).pipe(map(response => plainToInstance(ApiKey, response.data.keys)));
  }

  public deleteApiKey(companyId: number, api_key: string): Observable<ApiKey> {
    const url = this.apiKeysUrl.replace(':companyId', String(companyId));

    return this.http.delete<JSend<{
      key: ApiKey;
    }>>(url, { body: { api_key } }).pipe(map(response => plainToInstance(ApiKey, response.data.key)));
  }

  public generateApiKey(companyId: number): Observable<ApiKey> {
    const url = this.apiKeysUrl.replace(':companyId', String(companyId));

    return this.http.post<JSend<{
      key: ApiKey;
    }>>(url, {}).pipe(map(response => plainToInstance(ApiKey, response.data.key)));
  }
}

import { Component, EventEmitter, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { Account } from '../../../auth/models/account.model';
import { Role } from '../../../auth/models/role.model';
import { User } from '../../../auth/models/user.model';
import { Company } from '../../../models/company.model';
import { TeamMember } from '../../../models/teamMember.model';
import { TeamService } from '../../../services/team.service';

@Component({
  selector: 'ag-team-users',
  templateUrl: './team-users.component.html',
  styleUrls: ['./team-users.component.scss']
})
export class TeamUsersComponent implements OnInit, OnDestroy {

  @Input() public company: Company;
  @Input() public user: User;
  @Input() public account: Account;

  public deletedMember = new EventEmitter();
  public members: TeamMember[];
  public processing: boolean;
  public roles: Role[];
  public selected: TeamMember[] = [];

  private subscriptions: Subscription[] = [];

  constructor(
    private teamService: TeamService
  ) { }

  ngOnInit(): void {
    this.getRoles();
  }

  public edit(member: TeamMember): void {
    this.processing = true;
    this.subscriptions.push(this.teamService.edit(member).subscribe(response => {
      this.processing = false;
    }));
  }

  /**
   * Removes the specified [[TeamMember|Team members]] from the [[Company]].
   */
  public deleteMember(member: TeamMember): void {
    this.processing = true;
    this.subscriptions.push(this.teamService.delete(member).subscribe(response => {
      this.deletedMember.emit();
      this.deletedMember = new EventEmitter();

      this.refresh();
    }));
  }

  /** Reloads [[Company]] [[TeamMember|Team members]]. */
  public refresh(): void {
    this.processing = true;
    this.subscriptions.push(this.teamService.get(this.company.id).subscribe(members => {
      this.members = members;
      this.processing = false;
    }));
  }

  private getRoles(): void {
    this.subscriptions.push(this.teamService.getRoles().subscribe(roles => {
      this.roles = roles;
      this.refresh();
    }));
  }

  /** @ignore */
  ngOnDestroy(): void {
    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}

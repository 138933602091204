<div class="row">
    <div class="col-xs-12">
      <h4>{{ 'INTEGRATIONS.WEBHOOKS'|translate }}</h4>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-8">
      <p [innerHtml]="'INTEGRATIONS.WEBHOOKS_HELP'|translate"></p>

      <table class="table table-hover table-condensed">
        <!-- <caption></caption> -->
        <thead>
          <tr>
            <th>URL</th>
            <th class="sap">{{ 'GLOBAL.STATUS'|translate }}</th>
            <th class="sap"></th>
          </tr>
        </thead>
        <tbody>
          <tr class="tr-align-center" [class.bg-danger]="!wh.active" *ngFor="let wh of webhooks">
            <td class="small"><samp>{{ wh.url }}</samp></td>
            <td class="sap"><input class="ios" type="checkbox" id="status_{{ wh.id }}"
              name="statusname_{{ wh.id }}"
              [(ngModel)]="wh.active" (ngModelChange)="editWebhook(wh)"
              [disabled]="processing"></td>
            <td class="sap">
              <a title="{{ 'TEMPLATES_VIEW.DELETE'|translate }}"
              (onConfirm)="deleteWebhook(wh)"
              [confirm-options]="{
                action: 'GLOBAL.YES_DELETE'|translate
              }"
              [class.disabled]="processing">
                <i class="material-symbols-rounded">delete</i>
              </a>
            </td>
          </tr>
          <tr class="tr-align-center" *ngIf="webhooks?.length < 5">
            <td>
              <form (ngSubmit)="webhookForm.valid && createWebhook()" #webhookForm="ngForm" id="webhookForm">
                <input type="url" class="form-control"
                name="url" [(ngModel)]="webhook.url" required
                [disabled]="processing"
                placeholder="https://example.com/webhook">
              </form>
            </td>
            <td colspan="2">
              <button type="submit" form="webhookForm" class="btn btn-agree btn-block" [disabled]="!webhookForm.valid || processing">{{ 'GLOBAL.ADD'|translate}}</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="col-sm-4">
      <pre class="small">class WebhookEvent {{ '{' }}
/** Agree's internal unique identifier. */
id: number;
/** Event date. */
date: Date;
/** Event slug. e.g. 'NEGOTIATION.BOOKED' */
event: string;
company_id: number;
/** System entity related to the event (optional). */
entity?: {{ '{' }}
  type: string;
  id: number | string;
{{ '}' }};
{{ '}' }}</pre>
        <div class="text-center text-muted small">{{ 'INTEGRATIONS.WEBHOOK_MODEL'|translate }}</div>
    </div>
  </div>
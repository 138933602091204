import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable, Subscription, of } from 'rxjs';
import { mergeMap, switchMap, tap } from 'rxjs/operators';

import { environment } from '../../../../../../environments/environment';
import { Account } from '../../../../../auth/models/account.model';
import { User } from '../../../../../auth/models/user.model';
import { LoginService } from '../../../../../auth/services/login.service';
import { MessengerComponent } from '../../../../../chat/components/messenger/messenger.component';
import { Company } from '../../../../../models/company.model';
import { CompanyService } from '../../../../../services/company.service';
import { ComponentCommService } from '../../../../../services/component-comm.service';
import { DataDogLoggerService } from '../../../../../services/data-dog-logger.service';
import { MaxQuantityConfirmComponent } from '../../../../components/max-quantity-confirm/max-quantity-confirm.component';
import { Negotiation } from '../../models/negotiation.model';
import { Order } from '../../models/order.model';
import { OrderService } from '../../services/order.service';
import { ProposalDetailComponent } from '../proposal-detail/proposal-detail.component';

@Component({
  selector: 'app-counter-order',
  templateUrl: './counter-order.component.html',
  styleUrls: ['./counter-order.component.scss']
})
export class CounterOrderComponent implements OnInit, OnDestroy {

  @ViewChild('proposalDetail', { static: true }) private readonly proposalDetail: ProposalDetailComponent;
  @ViewChild('unavailableModal', { static: true }) private readonly unavailableModal: TemplateRef<any>;
  @ViewChild('messengerComponent') public readonly messengerComponent: MessengerComponent;

  private account: Account;
  private isOwner: boolean;
  private negotiationSubs: Subscription;
  private subscriptions: Subscription[] = [];

  public attachmentAccept: string = "";
  public canEdit: boolean = false;
  public chambers: Location[] = [];
  public company: Company;
  public contract_ids: string;
  public contractsData: any[];
  /** The language currently used. */
  public currentLang: string;
  public editorAccount: boolean;
  public environment = environment;
  public filesToAttach;
  public formMode: boolean = false;
  public hasTurn: boolean = false;
  public hideRelated: boolean;
  public incoming: boolean = false;
  public isActive: boolean = false;
  public isAmendment: boolean = false;
  public isCancellation: boolean = false;
  public isFirst: boolean = false;
  public isNew: boolean = false;
  public isRepresented: boolean;
  public modalRef: BsModalRef;
  public negotiation: Negotiation = new Negotiation();
  public outgoing: boolean = false;
  public refreshTrackRecord = new EventEmitter();
  public relatedNegotiations: Negotiation[];
  public sending: boolean = false;
  public tableData: {
    [key: string]: any
  } = {};
  public user: User;

  constructor(
    private companyService: CompanyService,
    private componentComm: ComponentCommService,
    private modalService: BsModalService,
    private orderService: OrderService,
    private route: ActivatedRoute,
    private router: Router,
    private loginService: LoginService,
    private translateService: TranslateService,
    private dataDogLoggerService: DataDogLoggerService
  ) { }

  ngOnInit(): void {
    this.currentLang = this.translateService.currentLang === 'es' ? undefined : this.translateService.currentLang;

    this.subscriptions.push(this.companyService.watchAccount().subscribe(account => {
      if (account) {
        this.account = account;
        this.editorAccount = this.account.is.commercial || this.account.is.operations;
      }
    }));

    this.subscriptions.push(this.loginService.getCurrentUser().subscribe(user => {
      this.user = user;
    }));

    this.subscriptions.push(this.orderService.getExtraAttributes<Location>('camara_arbitral').subscribe(chambers => {
      this.chambers = chambers;
    }));

    this.subscriptions.push(this.companyService.watch().pipe(
      tap(company => {
        this.company = company;
      }),
      switchMap(company => {
        return this.route.data.pipe(
          tap((data: { order: Order, negotiation: Negotiation }) => {
            if (data.negotiation) {
              this.negotiation = data.negotiation;
              if (this.negotiation.contracts.length) {
                this.contract_ids = this.negotiation.contracts.map(c => c.id).join(",");
              }
              this.componentComm.emit({ name: 'app-title', title: this.negotiation.order.product.name + ' (' + this.translateService.instant('GLOBAL.COUNTERORDER') + ' #' + this.negotiation.id + ')' });
            } else if (data.order) {
              this.negotiation.order = data.order;
              this.negotiation.proposal = this.orderService.cloneProposal(data.order);
              this.negotiation.company = this.company;
              this.componentComm.emit({ name: 'app-title', title: this.negotiation.order.product.name + ' (' + this.translateService.instant('COUNTERORDER_VIEW.NEW') + ')' });
            }
          })
        );
      })
    ).subscribe(() => {
      this.checkStatus();
    }));
  }

  private loadRelated(): void {
    if (this.negotiation.status.id === 7 &&
      this.negotiation.linked_negotiations &&
      this.negotiation.linked_negotiations.length) {
      this.subscriptions.push(this.orderService.watchLinkedNegotiation(this.company.id, this.negotiation.id).subscribe(negotiations => {
        this.relatedNegotiations = negotiations;
      }));
    }
  }

  private watchNegotiation(): void {
    if (!this.negotiationSubs && this.negotiation.id) {
      this.negotiationSubs = this.orderService.watchNegotiation(this.company.id, this.negotiation.id)
        .subscribe((negotiation) => {
          this.parseNegotiation(negotiation);
        });
    }
  }

  private parseNegotiation(negotiation: Negotiation): void {
    this.negotiation = negotiation;
    this.contract_ids = this.negotiation.contracts.map(c => c.id).join(",");
    this.proposalDetail.form.form.markAsPristine();
    this.checkStatus();
    this.refreshTrackRecord.emit();
  }

  reload(): void {
    this.refreshTrackRecord.emit();
  }

  private resetVariables(): void {
    this.canEdit = false;
    this.formMode = false;
    this.hasTurn = false;
    this.incoming = false;
    this.isActive = false;
    this.isAmendment = false;
    this.isFirst = false;
    this.isNew = false;
    this.outgoing = false;
    this.isCancellation = false;
  }

  private checkStatus(): void {
    this.resetVariables();

    // La compañia puede no estar lista todavía, esperar el próximo check.
    if (this.company) {
      const status: number = this.negotiation.status.id;

      this.isAmendment = (this.negotiation.times_booked > 0 && ![7, 20, 21, 22].includes(status)) || //
        (this.route.snapshot.data && this.route.snapshot.data.isAmendment && status === 7); // New amendment

      this.isOwner = this.company.id === this.negotiation.company.id;
      this.isRepresented = !this.isOwner && (this.company.id !== this.negotiation.order.company.id);

      this.isNew = !this.negotiation.id || // There is no neegotiation ID
        (status === 7 && this.isAmendment); // It's a new Amendment

      this.isActive = [1, 2, 3, 12, 13, 20, 21].includes(status);

      this.formMode = (this.isNew || this.isActive) && !this.isRepresented;

      this.incoming =
        ([1, 3, 12, 20].includes(status) && !this.isOwner) ||
        ([2, 13, 21].includes(status) && this.isOwner);

      this.outgoing =
        ([1, 3, 12, 20].includes(status) && this.isOwner) ||
        ([2, 13, 21].includes(status) && !this.isOwner);

      this.isFirst = this.negotiation.previous_proposal === null;

      this.hasTurn = (this.isNew || (this.incoming && !this.negotiation.order.on_hold)) && !this.isRepresented;

      this.canEdit = !this.isRepresented &&
        (this.isNew ||
          (this.hasTurn &&
            (status === 1 || status === 2 || status === 13)
          ));

      this.isCancellation = [20, 21].includes(status);
      this.loadRelated();

      this.watchNegotiation();
    }
  }

  public getNegotiableMode(): 'hidden' | 'editable' | 'visible' {
    if (this.company && this.negotiation) {
      // Possible values: hidden, editable, visible

      if (this.isAmendment) return 'hidden';
      if (this.canEdit && this.company.id === this.negotiation.order.company.id) return 'editable';
      if (this.isActive || this.isNew) return 'visible';

      return 'hidden';
    }
  }

  public send(): void {
    this.subscriptions.push(this.confirmMaxQuantity().subscribe(value => {
      this.sending = true;

      this.orderService.counterOrder(this.negotiation, this.company.id).pipe(mergeMap(negotiation => {
        if (!this.isNew) {
          return this.orderService.getNegotiation(this.company.id, negotiation.id);
        } else {
          return of(negotiation);
        }
      })).subscribe({
        next: negotiation => {
          if (this.isNew) {
            // Redirect the user to the created counterorder
            this.router.navigate(['/company', this.company.id, 'counter-order', negotiation.id]);
          } else {
            this.sending = false;
            this.parseNegotiation(negotiation);
          }
        },
        error: error => {
          this.handleActionError(error);
        }
      });
    }));
  }

  public accept(): void {
    if ([20, 21].includes(this.negotiation.status.id)) {
      // es una cancelacion
      this.sending = true;
      this.subscriptions.push(this.orderService.acceptCancelation(this.negotiation, this.company.id).subscribe(response => {
        this.sending = false;
      }));

    } else if (this.negotiation.status.id === 12) {
      // es un nego request
      this.sending = true;
      this.subscriptions.push(this.orderService.acceptNegotiationRequest(this.negotiation, this.company.id).subscribe(response => {
        this.sending = false;
        // FAS-2899
        // this.router.navigate(['/company', this.company.id, 'my-operations'], {
        //   queryParams: {
        //     status_group_orders: 1,
        //     order_by: '-updated_at'
        //   }
        // });
      }));

    } else {
      // es una orden
      this.subscriptions.push(this.confirmMaxQuantity().subscribe(value => {
        this.sending = true;
        this.subscriptions.push(this.orderService.acceptNegotiation(this.negotiation, this.company.id).subscribe({
          next: response => {
            this.sending = false;

            this.router.navigate(['/company', this.company.id, 'company-traded-orders'], {
              queryParams: {
                order_by: '-original_booking_date',
                'filters[settled]': false
              }
            });
          },
          error: error => {
            this.handleActionError(error);
          }
        }));
      }));
    }
  }

  public reject(): void {
    this.sending = true;

    if (this.isNew) this.router.navigateByUrl('/company/' + this.company.id + '/order/' + this.negotiation.order.id);
    else {
      if (this.negotiation.status.id === 20 ||
        this.negotiation.status.id === 21) {
        // es un retiro de la cancelacion o un rechazo de cancelacion
        this.subscriptions.push(this.orderService.acceptNegotiation(this.negotiation, this.company.id).subscribe(response => {
          this.sending = false;
        }));
      }
      else if (this.negotiation.status.id === 12) {
        // es un nego request
        this.subscriptions.push(this.orderService.rejectNegotiationRequest(this.negotiation, this.company.id).subscribe(response => {
          this.sending = false;
        }));

      } else {
        // es una orden
        this.subscriptions.push(this.orderService.rejectNegotiation(this.negotiation, this.company.id).subscribe({
          next: response => {
            this.sending = false;

            if (this.isAmendment) {
              this.router.navigate(['/company', this.company.id, 'company-traded-orders'], {
                queryParams: {
                  order_by: '-original_booking_date',
                  'filters[settled]': false
                }
              });
            } else {
              this.router.navigate(['/company', this.company.id, 'my-operations'], {
                queryParams: {
                  order_by: '-updated_at',
                  status_group_orders: 1
                }
              });
            }
          },
          error: error => {
            this.handleActionError(error);
          }
        }));
      }
    }
  }

  private confirmMaxQuantity(): Observable<boolean> {
    if (
      this.negotiation.order.business_detail.quantity.type.id === 2 &&
      this.negotiation.order.company.id === this.company.id &&
      this.negotiation.order.totalBooked + this.negotiation.proposal.business_detail.quantity.value > this.negotiation.order.business_detail.quantity.value
    ) {
      this.modalRef = this.modalService.show(MaxQuantityConfirmComponent, {
        initialState: {
          negotiation: this.negotiation
        }
      });

      return this.modalRef.content.onConfirm;
    } else {
      return of(true);
    }
  }

  private handleActionError(error: any): void {
    this.dataDogLoggerService.warn(error.message, error.error);

    this.checkStatus();
    this.sending = false;

    if (
      error instanceof HttpErrorResponse &&
      error.status === 403
    ) {
      this.modalRef = this.modalService.show(this.unavailableModal, {
        ignoreBackdropClick: true
      });
    } else {
      throw error;
    }
  }

  // Amendments
  public cancelAmendment(): void {
    this.router.navigateByUrl('/company/' + this.company.id + '/counter-order/' + this.negotiation.id);
  }
  public rejectAmendment(): void {
    this.reject();
  }
  public sendAmendment(): void {
    this.send();
  }
  public acceptAmendment(): void {
    this.accept();
  }

  // External data
  public tableLoaded(e, key: string): void {
    if (e.data.length) this.tableData[key] = e;
    else this.tableData[key] = undefined;
  }

  public contractsLoaded(e): void {
    this.contractsData = e;
  }

  public changeFile(event): void {
    // Triggered by input component
    this.filesToAttach = event.target.files;
  }

  public unlink(negotiation: Negotiation): void {
    this.sending = true;
    this.subscriptions.push(this.orderService.unlinkNegotiation(this.company.id, this.negotiation.id, [negotiation.id]).subscribe(response => {
      this.sending = false;
    }));
  }

  public showSlotsRequested: boolean = false;
  public toggleSlotsRequested(): void {
    this.showSlotsRequested = !this.showSlotsRequested;
  }

  /** @ignore */
  ngOnDestroy(): void {
    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
    if (this.negotiationSubs) {
      this.negotiationSubs.unsubscribe();
      this.negotiationSubs = null;
    }
  }
}

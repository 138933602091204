import { Component, EventEmitter, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { plainToInstance } from "class-transformer";
import { Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { Company } from '../../../../../models/company.model';
import { GroupBy } from '../../../../../models/group-by.model';
import { Product } from '../../../../../models/product.model';
import { CompanyService } from '../../../../../services/company.service';
import { ComponentCommService } from '../../../../../services/component-comm.service';
import { DataDogLoggerService } from '../../../../../services/data-dog-logger.service';
import { IntercomService } from '../../../../../services/intercom.service';
import { ConfirmComponent } from '../../../../../ui/components/confirm/confirm.component';
import { FilterSetComponent } from '../../../../../ui/components/filter-set/filter-set.component';
import { Order } from '../../models/order.model';
import { MemoryStorageService } from '../../services/memory-storage.service';
import { OrderService } from '../../services/order.service';

@Component({
  selector: 'app-preorders',
  templateUrl: './preorders.component.html',
  styleUrls: ['./preorders.component.css']
})
export class PreordersComponent implements OnInit, OnDestroy {

  @ViewChild('filterSet', { static: true }) public readonly selectedFilters: FilterSetComponent;
  @ViewChild('agreeConfirm', { static: true }) private readonly agreeConfirm: ConfirmComponent;

  private subscriptions: Subscription[] = [];

  public cancelButton: string = '';
  public company: Company;
  public companyId: number;
  public confirmBody: string = '';
  public confirmButton: string = '';
  public confirmTitle: string = '';
  /** The language currently used. */
  public currentLang: string;
  public filters: Array<any>;
  public isAllSelected: boolean = false;
  /** Flag used to indicate if the component is loading information. */
  public loading: boolean = true;
  public preOrdersByProduct: GroupBy<Product, Order>[] = [];
  public preOrdersEnabled: boolean;
  public processedMassive = new EventEmitter();
  public reviewing: boolean = false;
  public selection: Order[] = [];

  constructor(
    private companyService: CompanyService,
    private componentComm: ComponentCommService,
    private memoryStorageService: MemoryStorageService,
    private intercomService: IntercomService,
    private orderService: OrderService,
    private route: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService,
    private dataDogLoggerService: DataDogLoggerService
  ) {
    this.subscriptions.push(this.route.parent.params.subscribe(params => {
      this.companyId = parseInt(params['companyId']);
    }));
  }

  ngOnInit(): void {
    this.currentLang = this.translateService.currentLang === 'es' ? undefined : this.translateService.currentLang;

    this.componentComm.emit({ name: 'app-title', title: 'COMPANY.PREORDERS' });

    this.getPreOrders();

    this.subscriptions.push(this.companyService.watch().subscribe(company => {
      if (!company) return;

      this.company = company;

      if (this.company.activation_level.id === 1) {
        this.router.navigateByUrl('/companies');
      } else {
        this.preOrdersEnabled = this.company.market.configuration.allowed_order_types.order.with_preorder;
        this.intercomService.track('preorders-visited');
      }
    }));
  }

  private getPreOrders(): void {
    this.loading = true;

    let preorders = this.selectedFilters.getFilters().pipe(
      switchMap(filters => {
        this.filters = filters;
        return this.orderService.watchPreOrders(this.companyId, this.filters);
      })
    );

    this.subscriptions.push(preorders.subscribe(preOrdersByProduct => {
      this.clearSelection();
      this.parsePreOrdersResponse(preOrdersByProduct);
    }));
  }

  private parsePreOrdersResponse(preOrdersByProduct: GroupBy<Product, Order>[]): void {
    this.preOrdersByProduct = preOrdersByProduct;
    this.loading = false;
  }

  public clearSelection(): void {
    this.selection = Array();
  }

  public massiveReview(): void {
    this.reviewing = true;

    this.subscriptions.push(this.orderService.ordersValidation(this.selection, this.companyId).subscribe({
      next: response => {
        this.reviewing = false;

        if (response.headers.get('x-status') === 'warning') {
          let data = {
            messages: JSON.parse(response.headers.get('x-status-description')),
            title: 'Atención',
            confirmBtn: 'Continuar',
            cancelBtn: 'Volver'
          };
          this.setupModalData(data);
          this.subscriptions.push(this.agreeConfirm.askConfirmation().subscribe(conf => {
            this.goToCreateOrderFromMerge(response.body);
          }));
        } else {
          this.goToCreateOrderFromMerge(response.body);
        }
      },
      error: error => {
        this.reviewing = false;

        let data = {
          messages: error.error.message.inputs,
          title: 'No se pueden revisar las pre-ordenes seleccionadas',
          confirmBtn: '',
          cancelBtn: 'Entendido'
        };
        this.setupModalData(data);
        this.agreeConfirm.askConfirmation();
        this.dataDogLoggerService.warn(error.message, error.error);
      }
    }));
  }

  public goToCreateOrderFromMerge(order): void {
    this.memoryStorageService.data = {
      order: plainToInstance(Order, order)
    };
    this.router.navigate(['/company/' + this.companyId + '/create-order']);
  }

  private setupModalData(data: {
    messages: any;
    title: string;
    confirmBtn: string;
    cancelBtn: string;
  }): void {
    // TODO: Translate this
    let messagesDictionary = {
      'operation_type': 'El tipo de operación debe coincidir entre todas las pre-ordenes.',
      'product_id': 'El producto debe coincidir entre todas las pre-ordenes.',
      'product_detail.quality': 'La calidad de los productos debe coincidir entre todas las pre-ordenes.',
      'business_detail.delivery.locations': 'Debe existir al menos una zona en común o una localidad en común entre todas las pre-ordenes.',
      'business_detail.harvest': 'La cosecha debe coincidir entre todas las pre-ordenes.',
      'business_detail.authorized_buyers': 'Debe existir al menos un comprador en común entre todas las pre-ordenes.',
      'business_detail.delivery': 'No existe superposición de los períodos de entrega entre todas las pre-ordenes.',
      'business_detail.price.unit': 'El tipo de moneda debe coincidir entre todas las pre-ordenes.',
      'business_detail.delivery.delivery_type': 'El tipo de entrega debe coincidir entre todas las pre-ordenes.',
      'business_detail.price.type': 'El tipo de precio debe coincidir entre todas las pre-ordenes.'
    };

    let body = '<ul>';
    for (let i in data.messages) {
      body += '<li>' + messagesDictionary[i] + '</li>';
    }
    body += '</ul>';

    this.confirmBody = body;
    this.confirmTitle = data.title;
    this.confirmButton = data.confirmBtn;
    this.cancelButton = data.cancelBtn;
  }

  public massiveReject(): void {
    this.changeStatusAndSubmit(3);
  }

  public massiveCancel(): void {
    this.changeStatusAndSubmit(4);
  }

  private changeStatusAndSubmit(id_status: number): void {
    const selection = [];

    for (let i in this.selection) {
      selection[i] = {
        'id': this.selection[i].id,
        'order_status': {
          'id': id_status
        }
      }
    }

    this.subscriptions.push(this.orderService.preOrderMassiveAction(selection, this.companyId).subscribe(response => {
      this.clearSelection();
      this.processedMassive.emit();
      this.getPreOrders();
    }));
  }

  public gotoOrder(event, order_id): void {
    event.stopImmediatePropagation();

    this.router.navigate(['/company/' + this.companyId + '/order/' + order_id]);
  }

  // TODO: This should be a pipe
  public countryAndPort(order: Order): string {
    return order.product.attributes.country.name + ' / ' + order.business_detail.port.name;
  }

  /** @ignore */
  ngOnDestroy(): void {
    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}

<div class="ag-container">
  <div class="ag-col-primary">
    <div class="container">
      <div class="content-box" #container>
        <div class="ag-header" [class.hidden]="!company?.market.configuration.location.zone.enabled">
          <div class="ag-header-title">
            <!-- Lugares de entrega:&nbsp; -->
            <div id="location-type-toggle" class="btn-group" data-toggle="buttons">
              <label class="btn btn-default btn-sm" [class.active]="type === 'zone' && company?.market.configuration.location.zone.enabled" [class.disabled]="loading || processing">
                <input [disabled]="loading || processing" type="radio" name="options" id="option1" [checked]="type === 'zone'">
                {{ 'MARKET_SUMMARY.ZONES'|translate }}
              </label>
              <label class="btn btn-default btn-sm" [class.active]="type === 'location'" [class.disabled]="loading || processing">
                <input [disabled]="loading || processing" type="radio" name="options" id="option2" [checked]="type === 'location'">
                {{ 'MARKET_SUMMARY.LOCATIONS'|translate }}
              </label>
            </div>
          </div>
          <div class="ag-header-actions">
            <button type="button"
            class="btn btn-agree" [disabled]="processing" (click)="editPrices(editPricesModal)">
              <i class="material-symbols-rounded hidden-xs hidden-sm">add</i>
              Informar Precios
            </button>
          </div>
        </div>
        <div class="loading" *ngIf="loading">
          <spinner message="{{ 'GLOBAL.LOADING'|translate }}"></spinner>
        </div>
        <div class="row" *ngIf="!loading">
          <div class="col-xs-12">
            <!-- No data -->
            <div class="no-data" *ngIf="products.length === 0">
              <i class="material-symbols-rounded">not_interested</i>
              {{ 'ORDER.NO_RECORDS'|translate }}
            </div>
            <!-- Data -->
            <div class="table-scroll" *ngIf="products.length > 0">
              <!-- https://www.w3.org/WAI/tutorials/tables/examples/threeheaders/ -->
              <table class="table table-condensed">
                <!-- Spot -->
                <thead>
                  <tr class="tr-align-center">
                    <th class="s t l g">{{ 'MARKET_SUMMARY.DELIVERY_LOCATION'|translate }}</th>
                    <ng-container *ngFor="let date of market_periods; let i = index">
                      <th class="s t g text-center">{{ i === 0 ? 'Disp.' : date | date:'MMM / yy':undefined:currentLang }}</th>
                    </ng-container>
                  </tr>
                </thead>
                <!-- Product tbody -->
                <tbody *ngFor="let product of products">
                  <!-- Product sub-header -->
                  <tr class="tr-align-center product-header">
                    <th class="s l text-uppercase" colspan="2">{{ product.name }}</th>
                    <th [attr.colspan]="market_periods.length - 1"></th>
                  </tr>
                  <!-- Order row -->
                  <tr class="hoverable" *ngFor="let location of product.delivery_places">
                    <th class="s l w"><div class="location truncate" title="{{ location.zone?.name || location.location?.name }}">{{ location.zone?.name || location.location?.name }}</div></th>
                    <!-- Add .highlight to the td element when updated -->
                    <ng-container *ngFor="let date of market_periods; let i = index">
                      <td>
                        <ng-container *ngTemplateOutlet="cellData;context:{
                          product: product.id,
                          date: date|date: 'Myyyy',
                          location: location.id
                        }"></ng-container>
                      </td>
                    </ng-container>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #cellData let-date="date" let-product="product" let-location="location">
  <div *ngIf="tableMatrix">
    <div class="price" *ngFor="let price of tableMatrix[product][location][date]"
      [popover]="popTemplate"
      containerClass="popFadeDelay width-800"
      triggers="mouseenter:mouseleave"
      placement="auto"
      container="body"
      [style.border-left-color]="getCompanyColor(price.company.id)"
      [class.faded]="hoveredCompany != null && hoveredCompany !== price.company.id"
      (mouseover)="hoveredCompany = price.company.id"
      (mouseout)="hoveredCompany = null"
      (click)="editPrices(editPricesModal, price.company.id, price.product.id, {location:location, date:date})">
      <span class="unit">{{ price.price.unit.name }}</span>
      {{ price.price.value|number:'1.2-2':currentLang }}
      <ng-template #popTemplate>
        <div class="container-fluid">
          <div class="row">
             <div class="col-xs-12">
                <company-info [company]="price.company"></company-info>
             </div>
          </div>
          <div class="row">
            <div class="col-md-4" *ngFor="let prop of [
            {name: 'Calidad', value: 'Cond. Cámara - Humedad Máx.: 15%'},
            {name: 'Observaciones', value: 'This article offers a list of events that can be sent; some are standard events defined in official specifications.'}
            ]">
              <table class="table table-detail table-condensed" [ngClass]="prop.class">
                <thead>
                  <tr>
                    <th>{{ prop.name }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ prop.value }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="row">
             <div class="col-xs-12 small text-right">
                <span class="text-muted">Última actualización:</span>
                11:47 hs.
                <span class="text-muted">por:</span>
                José María Mendiguren - <b>{{ company.name }}</b>
             </div>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</ng-template>

<ng-template #editPricesModal>
  <!-- modal -->
  <div class="modal-header">
    <button type="button" class="close" [disabled]="processing" (click)="modalRef.hide()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">{{ 'MY_OPERATIONS_VIEW.BULK_REPUBLISH.EDIT_PRICES'|translate }}</h4>
  </div>
  <div class="modal-body">
    <form id="priceForm" #priceForm>
      <div class="row">
        <div class="col-xs-12">
          <div class="form-group" hasError>
            <label for="company" class="control-label">{{ 'GLOBAL.COMPANY'|translate }}</label>
            <select class="form-control" name="company" [(ngModel)]="editingData.company"
            [disabled]="(editingData.company != null && editingData.product != null) || processing"
            (change)="switchPrices()">
              <option [ngValue]="null" [selected]="true" [disabled]="true">
                Seleccione una Empresa...
              </option>
              <option *ngFor="let company of data.companies" [ngValue]="company">{{ company.name }}</option>
            </select>
            <span class="glyphicon glyphicon-remove form-control-feedback" aria-hidden="true"></span>
            <error-list></error-list>
            <!-- <p class="help-block small">{{ 'CERTIFICATES.MODAL.HOLDER_HELP'|translate }}</p> -->
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12">
          <div class="form-group" hasError>
            <label for="product" class="control-label">{{ 'GLOBAL.PRODUCT'|translate }}</label>
            <select class="form-control" name="product" [(ngModel)]="editingData.product"
            [disabled]="(editingData.company != null && editingData.product != null) || processing"
            (change)="switchPrices()">
              <option [ngValue]="null" [selected]="true" [disabled]="true">
                Seleccione un Producto...
              </option>
              <option *ngFor="let product of data.products" [ngValue]="product">{{ product.name }}</option>
            </select>
            <span class="glyphicon glyphicon-remove form-control-feedback" aria-hidden="true"></span>
            <error-list></error-list>
            <!-- <p class="help-block small">{{ 'CERTIFICATES.MODAL.HOLDER_HELP'|translate }}</p> -->
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12">
          <table class="input-grid">
            <thead>
              <tr>
                <th>{{ 'GLOBAL.DELIVERY_PLACES'|translate }}</th>
                <ng-container *ngFor="let date of market_periods; let i = index">
                  <th class="text-center text-uppercase">{{ i === 0 ? 'Disp.' : date | date:'MMM/yy':undefined:currentLang }}</th>
                </ng-container>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let location of data.zones">
                <th class="truncate" [title]="location.name">{{ location.name }}</th>
                <ng-container *ngFor="let date of market_periods">
                  <td class="text-center">
                    <input
                      type="number" class="form-control"
                      name="p_{{ 'z_'+location.id + (date.getMonth() + 1) + date.getFullYear() }}"
                      id="p_{{ 'z_'+location.id + (date.getMonth() + 1) + date.getFullYear() }}"
                      min="0" step="0.1"
                      placeholder="0.00" autocomplete="off"
                      [disabled]="editingData.company == null || editingData.product == null || processing"
                      [(ngModel)]="editingData.locations['z_'+location.id][(date.getMonth() + 1)+''+date.getFullYear()]">
                  </td>
                </ng-container>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button (click)="modalRef.hide()" type="button" class="btn btn-link" [disabled]="processing" data-dismiss="modal">{{ 'GLOBAL.CANCEL'|translate }}</button>
    <button (click)="savePrices()" type="button" class="btn btn-agree" [disabled]="editingData.company == null || editingData.product == null || processing" data-dismiss="modal">{{ 'GLOBAL.SAVE'|translate }}</button>
  </div>
</ng-template>
<div class="ag-container">
  <div class="ag-col-secondary">
    <div class="filters">
      <div class="filters-header">
        <div class="switch">
          <button type="button"
          class="btn btn-default btn-block" title="Mostrar/Ocultar Filtros">
            <i class="material-symbols-rounded">ballot</i>
            <span class="sh">Mostrar</span>
            <span class="hd">Ocultar</span>
            Filtros
          </button>
        </div>
      </div>
      <div class="options">
        <ng-container *ngFor="let filter of [{
            name: 'Status',
            options: ['Acknowledgement pending',
                        'Acknowledged',
                        'Rejected',
                        'Substitution pending',
                        'Shipped',
                        'Acceptance pending',
                        'Accepted']
          },{
            name: 'Product',
            options: ['Argentine Corn',
                        'Argentine Crude Soybean Oil',
                        'Argentine Crude Sunflower Seed Oil',
                        'Argentine Feed Barley',
                        'Argentine Soybean Meal (Hi Pro)',
                        'Argentine Soybean Meal (Low Pro)',
                        'Argentine Wheat',
                        'Argentine Yellow Soybeans',
                        'Argentine/Uruguayan Yellow Soybeans',
                        'Brazilian Soybeans',
                        'Brazilian Soybeans Meal',
                        'Brazilian Wheat',
                        'Brazilian Yellow Corn',
                        'Uruguayan Wheat',
                        'Uruguayan Yellow Soybeans']
          },{
            name: 'Type',
            options: ['Vessel nomination',
                        'Wash-out']
          }]">
          <dl>
            <dt>{{ filter.name }}</dt>
            <dd *ngFor="let option of filter.options"><a>{{ option }}</a></dd>
          </dl>
        </ng-container>
        <dl>
          <dt>Period</dt>
          <dd class="range">
            <div class="form-group">
              <input class="form-control" type="date" placeholder="From">
              <i class="material-symbols-rounded">code</i>
              <input class="form-control" type="date" placeholder="To">
              <a href>
                <i class="material-symbols-rounded">play_arrow</i>
              </a>
            </div>
          </dd>
        </dl>
      </div>
    </div>
  </div>
  <div class="ag-col-primary">
    <div class="container-fluid">
      <div class="content-box">
        <div class="ag-header sticky">
          <div class="ag-header-title" (click)="prevPanel()">
            <div class="summary">
              Showing
              <span class="quantity">
                88
              </span>
              contract(s).
            </div>
          </div>
          <div class="ag-header-actions">
            <!-- <button (click)="openModal(newContract)" title="Start New Execution" class="btn btn-agree"> -->
            <button type="button"
            routerLink="../new-execution" title="Start New Execution" class="btn btn-agree">
              <i class="material-symbols-rounded hidden-xs hidden-sm">play_arrow</i> New Execution
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12">
            <!-- No data -->
            <!-- <div class="no-data">
              <i class="material-symbols-rounded">not_interested</i>
              No Contracts found.
            </div> -->
            <!-- Data -->
            <div class="panel panel-default" *ngFor="let contract of contracts; let i = index" [class.hidden]="i !== panelNum && panelNum < 14">
              <!-- Contract info -->
              <div class="panel-heading">
                <div class="row">
                  <div class="col-md-12">
                    <button type="button"
                    *ngIf="!contract.originator" (click)="openModal(trackRecord, 'modal-lg')" class="btn btn-link btn-xs pull-right" title="Track record"><i class="material-symbols-rounded">history</i></button>
                    <span class="panel-title" (click)="nextPanel()">{{ contract.product }}</span>
                    <span *ngIf="contract.originator" class="ag-label label-default">Originator</span>
                  </div>
                </div>
                <div *ngIf="contract.received" class="row">
                  <div class="col-sm-4"><span>Contract <a href>{{ contract.received.id }}</a> from <b>{{ contract.received.counterpart }}</b> </span></div>
                  <div class="col-md-2 text-right">
                    <span class="text-capitalize"><b>{{ contract.received.date_from ? (contract.received.date_from|date: 'MMM yy' : 'en-US' ) : 'Mar 19' }}</b></span>
                  </div>
                  <div class="col-md-2 text-right"><span><b>{{ contract.received.price | number }}</b> <small>USD/MT </small></span></div>
                </div>
              </div>
              <!-- Vessel Nomination info -->
              <div *ngIf="contract.vessel" class="panel-body">
                <div class="row">
                  <div class="col-sm-8">
                    <div *ngIf="contract.received?.circle">
                      <span class="ag-label label-warning">Possible Circle Detected</span>
                      Check <a (click)="openModal(executionStrings, 'modal-lg')">execution string</a>.
                    </div>
                    <span>Nomination
                      <span *ngIf="contract.received">of <b>{{ contract.received.quantity | number }} MT</b>
                      in <b>{{ contract.received.fulfillment }}</b> fulfillment</span>
                      on <a (click)="openModal(vesselInfo)"><b>{{ contract.vessel.name }}</b></a> - ETA: <b>03/10/2019</b>
                    </span>
                    <button *ngIf="!contract.received" (click)="openModal(enterComment)" class="btn btn-primary btn-xs" type="button" title="Modify Nomination"><i class="material-symbols-rounded">edit</i> Modify</button>
                    <button *ngIf="!contract.received" (click)="openModal(enterComment)" class="btn btn-primary btn-xs" type="button" title="Vessel Substitution"><i class="material-symbols-rounded">cached</i> Substitution</button>
                    <div><small class="text-muted">Created at 14:15 on Thursday 14, February 2019 (GMT)</small></div>
                  </div>
                  <div *ngIf="!contract.disablerenominate && contract.received?.status === 0" class="col-sm-4 text-right">
                    <p class="small"><a (click)="openModal(nominationDetail)">Nomination</a> acknowledgement pending</p>
                    <button (click)="openModal(enterComment)" class="btn btn-primary btn-xs" type="button"><i class="material-symbols-rounded">done</i> Send Acknowledgement</button>
                    <button (click)="openModal(enterComment)" class="btn btn-danger btn-xs" type="button"><i class="material-symbols-rounded">highlight_off</i> Reject</button>
                  </div>
                  <div *ngIf="contract.received?.status === 3" class="col-sm-4 text-right">
                    <p class="small"><a href>Substitution</a> acknowledgement pending</p>
                    <button class="btn btn-primary btn-xs" type="button"><i class="material-symbols-rounded">done</i> Send Acknowledgement</button>
                    <button class="btn btn-danger btn-xs" type="button"><i class="material-symbols-rounded">highlight_off</i> Reject</button>
                  </div>
                  <div *ngIf="!contract.disablerenominate && contract.received && contract.received.status === 1" class="col-sm-4 text-right">
                    <p class="small"><a (click)="openModal(nominationDetail)">Nomination</a> acknowledgement sent</p>
                    <button class="btn btn-primary btn-xs disabled" type="button"><i class="material-symbols-rounded">done</i> Send Acknowledgement</button>
                    <button class="btn btn-danger btn-xs" type="button"><i class="material-symbols-rounded">highlight_off</i> Reject</button>
                  </div>

                  <div *ngIf="contract.received && contract.received.status === 2" class="col-sm-4 co-status text-uppercase">
                    <div *ngIf="contract.received.status === 2" class="truncate status-danger"><span>Rejected</span></div>
                  </div>
                </div>
              </div>
              <!-- Shipper and renominations -->
              <div *ngIf="contract.renominations" class="panel-body" [class.disabled]="contract.received?.status === 3">
                <div class="table-responsive">
                  <table class="table table-condensed">
                    <thead>
                      <tr>
                        <th>Contract</th>
                        <th>Seller</th>
                        <th>Period </th>
                        <th class="text-right">USD/MT </th>
                        <th class="text-right">MT </th>
                        <th></th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngIf="contract.shipper">
                        <td><i class="material-symbols-rounded hidden-xs">directions_boat</i> <b>Shipper</b></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td class="text-right">{{ contract.shipper | number }}</td>
                        <td>
                          <button type="button"
                          class="btn btn-link btn-xs" title="Quantity modification"><i class="material-symbols-rounded">edit</i></button>
                        </td>
                        <td *ngIf="contract.shipped" class="co-status text-uppercase">
                          <div class="truncate status-success"><span>Shipped</span></div>
                        </td>
                        <td *ngIf="!contract.shipped">
                          <button (click)="openModal(declareShipped)" class="btn btn-primary btn-xs" type="button" title="Declare as shipped"><i class="material-symbols-rounded">done</i> Declare as shipped</button>
                        </td>
                        <td class="text-right">
                          <button (click)="openModal(enterComment)" class="btn btn-link btn-xs" type="button" title="Discard from this execution" [class.disabled]="contract.shipped"><i class="material-symbols-rounded">delete</i></button>
                          <button (click)="openModal(executionStrings, 'modal-lg')" class="btn btn-link btn-xs" type="button" title="Strings"><i class="material-symbols-rounded">shuffle</i></button>
                          <button (click)="openModal(trackRecord, 'modal-lg')" class="btn btn-link btn-xs" type="button" title="Track record"><i class="material-symbols-rounded">history</i></button>
                        </td>
                      </tr>
                      <tr *ngFor="let forwarded of contract.nominated" class="tr-align-center">
                        <td><i class="material-symbols-rounded hidden-xs">redo</i> <a href>{{ forwarded.id }}</a> in <b>{{ forwarded.fulfillment }}</b> fulfillment</td>
                        <th>{{ forwarded.counterpart }}</th>
                        <td>
                          <span class="text-capitalize">{{ forwarded.date_from ? (forwarded.date_from|date: 'MMM yy') : 'Mar 19' }}</span>
                        </td>
                        <td class="text-right">{{ forwarded.price | number }}</td>
                        <td class="text-right" [class.danger]="forwarded.status === 2">{{ forwarded.quantity | number }}</td>
                        <td>
                          <!-- <button class="btn btn-link btn-xs" title="Quantity modification"><i class="material-symbols-rounded">edit</i></button> -->
                        </td>
                        <td class="co-status text-uppercase">
                          <div *ngIf="forwarded.status === 0" class="truncate status-info"><span>Acknowledgement pending</span></div>
                          <div *ngIf="forwarded.status === 1" class="truncate status-success"><span>Acknowledged</span></div>
                          <div *ngIf="forwarded.status === 4" class="truncate status-success"><span>Shipped</span></div>
                          <div *ngIf="forwarded.status === 2" class="truncate status-danger"><span>Rejected</span></div>
                          <button *ngIf="forwarded.status === 3" (click)="openModal(enterComment)" class="btn btn-primary btn-xs" type="button" title="Send substitution"><i class="material-symbols-rounded">cached</i> Send substitution</button>
                        </td>
                        <td class="text-right">
                          <!-- <button (click)="openModal(enterComment)" class="btn btn-link btn-xs" type="button" title="Discard from this execution" [class.disabled]="forwarded.status === 4 || forwarded.status === 2"><i class="material-symbols-rounded">delete</i></button> -->
                          <button (click)="openModal(executionStrings, 'modal-lg')" class="btn btn-link btn-xs" type="button" title="Strings"><i class="material-symbols-rounded">shuffle</i></button>
                          <button (click)="openModal(trackRecord, 'modal-lg')" class="btn btn-link btn-xs" type="button" title="Track record"><i class="material-symbols-rounded">history</i></button>
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr *ngIf="contract.received">
                        <td colspan="4" class="text-right"><b>Balance</b></td>
                        <td class="text-right">
                          {{ (contract.received.quantity - (contract.shipper || 0) - (contract.nominated | pluck: 'quantity' | sum))*0 | number }}
                        </td>
                        <td colspan="3" class="text-right">
                          <button class="btn btn-primary btn-xs" [class.disabled]="contract.shipper" type="button"><i class="material-symbols-rounded">directions_boat</i> Declare as shipper</button>
                          <button (click)="openModal(newContract, 'modal-lg')" class="btn btn-primary btn-xs" [class.disabled]="contract.disablerenominate" type="button"><i class="material-symbols-rounded">redo</i> Renominate</button>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
              <!-- Wash out -->
              <div *ngIf="contract.washout" class="panel-body">
                <div class="row">
                  <div class="col-sm-7">
                    Wash-out with <b>{{ contract.washout.theirs[0].counterpart }}</b>
                    <div><small class="text-muted">Created at 14:15 on Thursday 14, February 2019 (GMT)</small></div>
                  </div>
                  <div *ngIf="!contract.originator" class="col-sm-5 text-right">
                    <p class="small"><a href>Wash-out proposal</a> acceptance pending</p>
                    <button class="btn btn-success btn-xs" type="button"><i class="material-symbols-rounded">done</i> Accept</button>
                    <button (click)="openModal(enterComment)" class="btn btn-danger btn-xs" type="button"><i class="material-symbols-rounded">highlight_off</i> Reject</button>
                  </div>
                  <div *ngIf="contract.originator" class="col-sm-5 co-status text-uppercase">
                    <div *ngIf="contract.washout.status === 0" class="truncate status-info"><span>Acceptance pending</span></div>
                    <div *ngIf="contract.washout.status === 1" class="truncate status-success"><span>Accepted</span></div>
                    <div *ngIf="contract.washout.status === 2" class="truncate status-danger"><span>Rejected</span></div>
                  </div>
                </div>
              </div>
              <div *ngIf="contract.washout" class="panel-body">
                <div class="table-responsive">
                  <table class="table table-condensed">
                    <thead>
                      <tr>
                        <th>Contract as buyer</th>
                        <th>Period</th>
                        <th class="text-right">USD/MT</th>
                        <th class="text-right">MT</th>
                        <th class="text-right">USD</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let mine of contract.washout.mine" class="tr-align-center">
                        <td><a>{{ mine.id }}</a> in <b>{{ mine.fulfillment }}</b> fulfillment</td>
                        <td>March 2019</td>
                        <td class="text-right">{{ mine.price | number }}</td>
                        <td class="text-right">{{ mine.quantity | number }}</td>
                        <td class="text-right">{{ mine.price * mine.quantity | number: '.2' }}</td>
                      </tr>
                    </tbody>
                    <thead>
                      <tr>
                        <th>Contract as seller</th>
                        <th>Period </th>
                        <th class="text-right">USD/MT </th>
                        <th class="text-right">MT </th>
                        <th class="text-right">USD </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let theirs of contract.washout.theirs" class="tr-align-center">
                        <td><a>{{ theirs.id }}</a> in <b>{{ theirs.fulfillment }}</b> fulfillment</td>
                        <td>March 2019</td>
                        <td class="text-right">{{ theirs.price | number }}</td>
                        <td class="text-right">{{ theirs.quantity | number }}</td>
                        <td class="text-right">{{ theirs.price * theirs.quantity | number: '.2' }}</td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colspan="4" class="text-right"><b>Balance</b> </td>
                        <td colspan="1" class="text-right"><span class="text-danger">-548.050,00</span></td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #executionStrings>
  <!-- modal -->
  <div class="modal-header">
    <button type="button" class="close" (click)="modalRef.hide()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">Execution Strings</h4>
  </div>
  <div class="modal-body">
    <div class="table-responsive">
      <table class="table table-striped table-hover table-condensed">
        <thead>
          <tr>
            <th>Buyer </th>
            <th>Seller </th>
            <th class="text-center">Circles </th>
            <th>Contract </th>
            <th>Period </th>
            <!-- <th class="text-right">USD/MT </th>
            <th class="text-right">Diff </th> -->
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Cofco International </td>
            <td class="info">Cefetra SPA</td>
            <td class="text-center"> <span class="text-success glyphicon glyphicon-triangle-right small"> </span><span class="badge">1</span></td>
            <td> <a href="#">A-3219/16</a> </td>
            <td>March 2019</td>
            <!-- <td class="text-right">411.36 </td>
            <td class="text-right"> <span class="text-danger">-1.37 </span></td> -->
          </tr>
          <tr>
            <td class="info">Cefetra SPA</td>
            <td>Cargill </td>
            <td class="text-center"> <span class="text-success glyphicon glyphicon-triangle-right small"> </span><span class="badge">2 </span></td>
            <td><a href="#">AGS 21492/16</a> </td>
            <td>March 2019</td>
            <!-- <td class="text-right">411.46 </td>
            <td class="text-right"> <span class="text-danger">-0.10 </span></td> -->
          </tr>
          <tr>
            <td>Cargill </td>
            <td>Glencore</td>
            <td> </td>
            <td><a href="#">AGS-21123/15</a> </td>
            <td>March 2019</td>
            <!-- <td class="text-right">340,72 </td>
            <td class="text-right"> <span class="text-success">70.74 </span></td> -->
          </tr>
          <tr>
            <td>Glencore</td>
            <td class="info">Cefetra SPA</td>
            <td class="text-center"> <span class="text-danger glyphicon glyphicon-triangle-left small"> </span><span class="badge">2 </span></td>
            <td><a href="#">AGS-21571/16</a> </td>
            <td>March 2019</td>
            <!-- <td class="text-right">441.31 </td>
            <td class="text-right"> <span class="text-danger">-100.59 </span></td> -->
          </tr>
          <tr>
            <td class="info">Cefetra SPA</td>
            <td>Sodrugestvo </td>
            <td> </td>
            <td><a href="#"> AGS-21530/16</a> </td>
            <td>March 2019</td>
            <!-- <td class="text-right">442.14 </td>
            <td class="text-right"> <span class="text-danger">-0.83 </span></td> -->
          </tr>
          <tr>
            <td>Sodrugestvo </td>
            <td>Gavilon </td>
            <td> </td>
            <td><a href="#"> 09/06/2019</a> </td>
            <td>March 2019</td>
            <!-- <td class="text-right">462.53 </td>
            <td class="text-right"> <span class="text-danger">-20.39 </span></td> -->
          </tr>
          <tr>
            <td>Gavilon </td>
            <td>CJ </td>
            <td> </td>
            <td><a href="#">CJIA-02/2019 </a> </td>
            <td>March 2019</td>
            <!-- <td class="text-right">425.51 </td>
            <td class="text-right"> <span class="text-success">37.02 </span></td> -->
          </tr>
          <tr>
            <td>CJ </td>
            <td>Cofco International </td>
            <td class="text-center"> <span class="text-danger glyphicon glyphicon-triangle-left small"> </span><span class="badge">1</span></td>
            <td><a href="#">MB-1187/2019</a> </td>
            <td>March 2019</td>
            <!-- <td class="text-right">460.70 </td>
            <td class="text-right"> <span class="text-danger">-35.19 </span></td> -->
          </tr>
          <tr>
            <td>Cofco International </td>
            <td>CHS </td>
            <td> </td>
            <td><a href="#">AGS-20938/15</a> </td>
            <td>March 2019</td>
            <!-- <td class="text-right">409.99 </td>
            <td class="text-right"> <span class="text-success">50.71 </span></td> -->
          </tr>
        </tbody>
        <caption>String MVSEAPEGASUS-20190331/001 - <b>2,000.000</b> MT</caption>
        <tfoot>
          <tr>
            <td>CHS </td>
            <td><b>Shipper</b> </td>
            <td colspan="2">Presents documentation to Cofco International<!--  at 409.99 USD/MT --></td>
            <td><button class="btn btn-primary btn-xs disabled" type="button" title="Propose Bypass"><i class="material-symbols-rounded">shuffle</i> Propose Bypass</button></td>
          </tr>
        </tfoot>
      </table>
    </div>
    <div class="table-responsive">
      <table class="table table-striped table-hover table-condensed">
        <thead>
          <tr>
            <th>Buyer </th>
            <th>Seller </th>
            <th class="text-center">Circles </th>
            <th>Contract </th>
            <th>Period </th>
            <!-- <th class="text-right">USD/MT </th>
            <th class="text-right">Diff </th> -->
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Cofco International </td>
            <td class="info">Cefetra SPA</td>
            <td> </td>
            <td> <a href="#">A-3219/16</a> </td>
            <td>March 2019</td>
            <!-- <td class="text-right">411.36 </td>
            <td class="text-right"> <span class="text-danger">-51.17 </span></td> -->
          </tr>
          <tr>
            <td class="info">Cefetra SPA</td>
            <td>Cargill </td>
            <td class="text-center"> <span class="text-success glyphicon glyphicon-triangle-right small"> </span><span class="badge">1</span></td>
            <td><a href="#">AGS 21492/16</a> </td>
            <td>March 2019</td>
            <!-- <td class="text-right">411.46 </td>
            <td class="text-right"> <span class="text-danger">-0.10 </span></td> -->
          </tr>
          <tr>
            <td>Cargill </td>
            <td>Glencore</td>
            <td> </td>
            <td><a href="#">AGS-21123/15</a> </td>
            <td>March 2019</td>
            <!-- <td class="text-right">340,72 </td>
            <td class="text-right"> <span class="text-success">70.74 </span></td> -->
          </tr>
          <tr>
            <td>Glencore</td>
            <td class="info">Cefetra SPA</td>
            <td class="text-center"> <span class="text-danger glyphicon glyphicon-triangle-left small"> </span><span class="badge">1</span></td>
            <td><a href="#">AGS-21571/16</a> </td>
            <td>March 2019</td>
            <!-- <td class="text-right">441.31 </td>
            <td class="text-right"> <span class="text-danger">-100.59 </span></td> -->
          </tr>
          <tr>
            <td class="info">Cefetra SPA</td>
            <td>Sodrugestvo </td>
            <td> </td>
            <td><a href="#"> AGS-21530/16</a> </td>
            <td>March 2019</td>
            <!-- <td class="text-right">442.14 </td>
            <td class="text-right"> <span class="text-danger">-0.83 </span></td> -->
          </tr>
          <tr>
            <td>Sodrugestvo </td>
            <td>Gavilon </td>
            <td> </td>
            <td><a href="#"> 09/06/2019</a> </td>
            <td>March 2019</td>
            <!-- <td class="text-right">462.53 </td>
            <td class="text-right"> <span class="text-danger">-20.39 </span></td> -->
          </tr>
          <tr class="hidden">
            <td>Gavilon </td>
            <td>CJ </td>
            <td>Cell 3</td>
            <td><a href="#">CJIA-02/2019 </a> </td>
            <td>March 2019</td>
            <!-- <td class="text-right">425.51 </td>
            <td class="text-right"> <span class="text-success">37.02 </span></td> -->
          </tr>
          <tr class="hidden">
            <td>CJ </td>
            <td>Cofco International </td>
            <td>Cell 3</td>
            <td><a href="#">MB-1187/2019</a> </td>
            <td>March 2019</td>
            <!-- <td class="text-right">460.70 </td>
            <td class="text-right"> <span class="text-danger">-35.19 </span></td> -->
          </tr>
          <tr class="hidden">
            <td>Cofco International </td>
            <td>CHS </td>
            <td>Cell 3</td>
            <td><a href="#">AGS-20938/15</a> </td>
            <td>March 2019</td>
            <!-- <td class="text-right">409.99 </td>
            <td class="text-right"> <span class="text-success">50.71 </span></td> -->
          </tr>
        </tbody>
        <caption>String MVSEAPEGASUS-20190331/002 - <b>1,000.000</b> MT</caption>
        <tfoot>
          <tr>
            <td>Gavilon </td>
            <td><b>Shipper</b> </td>
            <td colspan="2">Presents documentation to Cofco International<!--  at 462.53 USD/MT --></td>
            <td><button class="btn btn-primary btn-xs" type="button" title="Propose Bypass"><i class="material-symbols-rounded">shuffle</i> Propose Bypass</button></td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-link"><i class="material-symbols-rounded">save_alt</i> Download as XLS</button>
    <button (click)="modalRef.hide()" type="button" class="btn btn-default" data-dismiss="modal">{{ 'ASSIGNEES_MANAGER.MODAL.CLOSE'|translate }}</button>
  </div>
</ng-template>
<ng-template #trackRecord>
  <!-- modal -->
  <div class="modal-header">
    <button type="button" class="close" (click)="modalRef.hide()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">Track record</h4>
  </div>
  <div class="modal-body">
    <div class="table-responsive">
      <table class="table table-striped table-hover table-condensed">
        <tbody class="hidden">
          <tr>
            <td>20/Feb/18 3:58 PM </td>
            <td><a (click)="openModal(nominationDetail)">Nomination</a> from <b>Cofco International</b> to <b>Marubeni</b></td>
          </tr>
          <tr>
            <td>20/Feb/18 3:58 PM </td>
            <td><a (click)="openModal(nominationDetail)">Nomination acknowledgement</a> sent from <b>Marubeni</b> to <b>Cofco International</b></td>
          </tr>
          <tr>
            <td>20/Feb/18 3:58 PM </td>
            <td><a href="#">Substitution</a> sent from <b>Cofco International</b> to <b>Marubeni</b></td>
          </tr>
          <tr>
            <td>20/Feb/18 3:58 PM </td>
            <td><b>Marubeni</b> rejected substitution</td>
          </tr>
          <tr>
            <td>20/Feb/18 3:58 PM </td>
            <td><b>Marubeni</b> added a comment
              <blockquote>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.</p>
              </blockquote>
            </td>
          </tr>
          <tr>
            <td>20/Feb/18 3:58 PM </td>
            <td><b>Marubeni</b> <a href="#">nominated</a> <b>Bunge</b> in string</td>
          </tr>
          <tr>
            <td>20/Feb/18 3:58 PM </td>
            <td><b>Marubeni</b> declared as shipper for 4,000.000 MT</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>14/Feb/19 2:58 PM </td>
            <td><a (click)="openModal(nominationDetail)">Nomination</a> from <b>Marubeni</b> to <b>CHS</b></td>
          </tr>
          <tr>
            <td>14/Feb/19 3:07 PM </td>
            <td><a (click)="openModal(nominationDetail)">Nomination acknowledgement</a> sent from <b>CHS</b> to <b>Marubeni</b></td>
          </tr>
          <tr>
            <td>14/Feb/19 5:07 PM </td>
            <td><b>CHS</b> rejected</td>
          </tr>
          <tr>
            <td>14/Feb/19 5:07 PM </td>
            <td><b>CHS</b> added a comment
              <blockquote>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.</p>
              </blockquote>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row">
      <div class="col-xs-12">
        <p><textarea class="form-control"></textarea></p>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12 text-right">
        <button class="btn btn-agree" type="button">Add a comment</button>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-link"><i class="material-symbols-rounded">save_alt</i> Download as PDF</button>
    <button (click)="modalRef.hide()" type="button" class="btn btn-default" data-dismiss="modal">{{ 'ASSIGNEES_MANAGER.MODAL.CLOSE'|translate }}</button>
  </div>
</ng-template>
<ng-template #vesselInfo>
  <!-- modal -->
  <div class="modal-header">
    <button type="button" class="close" (click)="modalRef.hide()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">Vessel Information</h4>
  </div>
  <div class="modal-body">
    <div class="row row-main">
      <!-- Vessel data -->
      <div class="col-xs-4">
        <table class="table table-detail table-condensed">
          <thead>
            <tr>
              <th>
                <span>Vessel's Name</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <span>
                  <b>MV Sea Pegasus</b>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-xs-4">
        <table class="table table-detail table-condensed">
          <thead>
            <tr>
              <th>
                <span>Flag</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <span>
                  <b>Malta</b>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-xs-4">
        <table class="table table-detail table-condensed">
          <thead>
            <tr>
              <th>
                <span>Age</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <span>
                  <b>5 year(s)</b> <span class="small text-muted">(Built: 2014)</span>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row row-main">
      <div class="col-xs-6">
        <table class="table table-detail table-condensed">
          <thead>
            <tr>
              <th>
                <span>ETA</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <span>10/03/2019</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-xs-6">
        <table class="table table-detail table-condensed">
          <thead>
            <tr>
              <th>
                <span>Country of destination</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <span>-</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <hr>
    <div class="row">
      <!-- Port data -->
      <div class="col-xs-6 col-sm-3">
        <table class="table table-detail table-condensed">
          <thead>
            <tr>
              <th>
                <span>IMO</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <span>9598220</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-xs-6 col-sm-3">
        <table class="table table-detail table-condensed">
          <thead>
            <tr>
              <th>
                <span>MMSI</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <span>538005602</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-xs-6 col-sm-3">
        <table class="table table-detail table-condensed">
          <thead>
            <tr>
              <th>
                <span>Gross Tonnage</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <span>44,276</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-xs-6 col-sm-3">
        <table class="table table-detail table-condensed">
          <thead>
            <tr>
              <th>
                <span>Deadweight</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <span>81,614
                  <span class="text-muted small">t</span>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <hr>
    <div class="row">
      <!-- Port data -->
      <div class="col-xs-6 col-sm-4">
        <table class="table table-detail table-condensed">
          <thead>
            <tr>
              <th>
                <span>Agents at loading port</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <span>To be appointed</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-xs-6 col-sm-4">
        <table class="table table-detail table-condensed">
          <thead>
            <tr>
              <th>
                <span>Surveyor</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <span>To be appointed</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-xs-12 col-sm-4">
        <table class="table table-detail table-condensed">
          <thead>
            <tr>
              <th>
                <span>Demurrage rate <!-- <span class="small text-muted">(Optional)</span> --></span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <span>15,000
                  <span class="text-muted small">USD/pwwd pro rata</span>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button (click)="modalRef.hide()" type="button" class="btn btn-link" data-dismiss="modal">{{ 'ASSIGNEES_MANAGER.MODAL.CLOSE'|translate }}</button>
    <!-- <button type="button" class="btn btn-agree"><i class="material-symbols-rounded">play_arrow</i> Create and send to counterpart</button> -->
  </div>
</ng-template>
<ng-template #nominationDetail>
  <!-- modal -->
  <div class="modal-header">
    <button type="button" class="close" (click)="modalRef.hide()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">Nomination details</h4>
  </div>
  <div class="modal-body">
    <table class="table table-condensed borderless">
      <tbody>
        <tr>
          <th class="text-right"><b>From:</b></th>
          <td>Sender Company name
            <div class="text-muted small">&lt;no-reply@agreemarket.com&gt;</div>
          </td>
        </tr>
        <tr>
          <th class="text-right"><b>To:</b></th>
          <td>Recipient Company name
            <div class="text-muted small">&lt;no-reply@agreemarket.com&gt;</div>
          </td>
        </tr>
        <tr>
          <th class="text-right"><b>Subject:</b></th>
          <td>NOMINATION MV SEA PEGASUS for 65,000 t. Brazilian Soybeans - Marubeni</td>
        </tr>
      </tbody>
    </table>
    <div class="row">
      <div class="col-xs-12">
        <pre>Ref.: Contract A-3219/16
Brazilian Soybeans - Delivery period: March 2019
Buyer: Marubeni - Seller: Glencore

Following nomination to load 65,000 t. in total fulfillment.

Vessel name:
MV SEA PEGASUS
Flag:
Malta
ETA:
03/10/2019
Country of destination:
China
Demurrage rate:
USD 14,500.00
Agents at loading port:
N/A
Surveyor:
N/A</pre>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button (click)="modalRef.hide()" type="button" class="btn btn-link" data-dismiss="modal">{{ 'ASSIGNEES_MANAGER.MODAL.CLOSE'|translate }}</button>
    <!-- <button type="button" class="btn btn-agree"><i class="material-symbols-rounded">play_arrow</i> Create and send to counterpart</button> -->
  </div>
</ng-template>

<ng-template #declareShipped>
  <!-- modal -->
  <div class="modal-header">
    <button type="button" class="close" (click)="modalRef.hide()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">Declare as shipped</h4>
  </div>
  <div class="modal-body">
    <form>
      <div class="row">
        <div class="col-sm-6">
          <div class="form-group" hasError>
            <label class="control-label">Quantity</label>
            <div class="input-group input-quantity">
              <input type="number" placeholder="0" step="1" class="form-control" name="quantity" number>
              <span class="input-group-addon">t</span>
            </div><!-- /btn-group -->
            <error-list></error-list>
            <span class="small help-block">Please, specify the exact tonnes loaded on the ship.</span>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group" hasError>
            <label class="control-label">Date</label>
            <input type="date" placeholder="dd/mm/yy" class="form-control" name="date">
            <error-list></error-list>
            <!-- <span class="small help-block">Please, specify the exact tonnes loaded on the ship.</span> -->
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button (click)="modalRef.hide()" type="button" class="btn btn-link" data-dismiss="modal">Cancel</button>
    <button (click)="modalRef.hide()" type="button" class="btn btn-agree"><i class="material-symbols-rounded">done</i> Declare as shipped</button>
  </div>
</ng-template>
<ng-template #enterComment>
  <!-- modal -->
  <div class="modal-header">
    <button type="button" class="close" (click)="modalRef.hide()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">Add comments</h4>
  </div>
  <div class="modal-body">
    <form>
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group" hasError>
            <label class="control-label">Comments</label>
            <textarea class="form-control" rows="4"></textarea>
            <error-list></error-list>
            <span class="small help-block">Please, specify the reasons and rational for this action.</span>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button (click)="modalRef.hide()" type="button" class="btn btn-link" data-dismiss="modal">Cancel</button>
    <button (click)="modalRef.hide()" type="button" class="btn btn-agree"><i class="material-symbols-rounded">send</i> Send</button>
  </div>
</ng-template>
<ng-template #newContract>
  <!-- modal -->
  <div class="modal-header">
    <button type="button" class="close" (click)="modalRef.hide()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">Add a Contract to renominate</h4>
  </div>
  <div class="modal-body">
    <form>
      <div class="row">
        <div class="col-xs-12">
          <fieldset [disabled]="">
            <div class="form-group" hasError>
              <label class="control-label">Contract type</label>
              <span class="small help-block">You can either select an existing Contract within the platform or use an external one.</span>
              <div class="btn-group btn-group-justified">
                <label class="btn btn-default">
                  <input type="radio" name="existingWContract">
                  <span>Existing Contract</span>
                </label>
                <label class="btn btn-default">
                  <input type="radio" name="existingWContract">
                  <span>External Contract</span>
                </label>
              </div>
            </div>
          </fieldset>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button (click)="modalRef.hide()" type="button" class="btn btn-link" data-dismiss="modal">Cancel</button>
    <button (click)="modalRef.hide()" type="button" class="btn btn-agree" [disabled]="true"><i class="material-symbols-rounded">add</i> Add Contract</button>
  </div>
</ng-template>
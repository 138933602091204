<div class="container" [class.hidden]="previewMode">
  <div class="content-box">
    <div class="ag-header sticky">
      <div class="ag-header-title">
        <h3>{{ 'ORDER.NEW_CERTIFICATE'|translate }}</h3>
      </div>
      <div class="ag-header-actions">
        <div class="btn-toolbar">
          <div class="btn-group">
            <button type="button"
            class="btn btn-link" (click)="cancel()" [disabled]="processing">
              {{ 'GLOBAL.CANCEL'|translate }}
            </button>
          </div>
          <div class="btn-group">
            <button class="btn btn-agree" [disabled]="!form.valid || processing || loading" type="submit"
            form="create-cd">
              <i class="material-symbols-rounded hidden-xs hidden-sm">done</i>
              {{ 'ORDER.CREATE_CERTIFICATE'|translate }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="loading" *ngIf="loading">
      <spinner message="{{ 'GLOBAL.LOADING'|translate }}"></spinner>
    </div>

    <form id="create-cd" (ngSubmit)="form.valid && create()" #form="ngForm">
      <ng-container *ngIf="!loading">
        <div class="row step"
        [validateFormStep]="cd.product"
        #step01="validateFormStep"
        [nextStep]="step02">
          <div class="col-xs-12">
            <div class="line"></div>
            <span class="step-indicator">
              <span></span>
              <i class="material-symbols-rounded">done</i>
            </span>
            <div class="container-fluid" style="padding-left: 0px;">
              <div class="row">
                <!-- Product -->
                <div class="col-md-6">
                  <div class="form-group" hasError>
                    <label class="control-label">{{ 'GLOBAL.PRODUCT'|translate }}</label>
                    <select class="form-control" name="product"
                      [(ngModel)]="cd.product"
                      (ngModelChange)="changeProduct()"
                      required
                      [compareWith]="compareId"
                      [disabled]="processing || relatedNegotiation">
                      <option [ngValue]="undefined" [selected]="true" [disabled]="true">{{ 'GLOBAL.SELECT_PRODUCT'|translate }}</option>
                      <option *ngFor="let prod of products" [ngValue]="prod">{{ prod.name }}</option>
                    </select>
                    <error-list></error-list>
                    <a class="small"
                    (click)="hubSpotService.open()">{{ 'ORDER_FORM.PRODUCT.NOT_LISTED'|translate }}</a>
                  </div>
                </div>
              </div>
              <ng-container *ngIf="cd.product">
                <div class="row"><!-- Quality specs -->
                  <div class="col-sm-4" *ngFor="let attribute of cd.product.attributes.quality|pairs|orderBy:'1.order'">
                    <div class="form-group" hasError>
                      <label class="control-label">{{ attribute[1].name }}</label>
                        <textarea class="form-control"
                        *ngIf="attribute[1].element === 'textarea'"
                        name="quality_{{ attribute[0] }}"
                        [(ngModel)]="cd.product_detail.quality[attribute[0]]"
                        [disabled]="processing || relatedNegotiation"
                        maxlength="512"
                        rows="6"></textarea>
                        <select class="form-control"
                          *ngIf="attribute[1].element !== 'textarea'"
                          name="quality_{{ attribute[0] }}"
                          [(ngModel)]="cd.product_detail.quality[attribute[0]]"
                          [disabled]="processing || relatedNegotiation"
                          [compareWith]="compareId"
                          #quality="ngModel">
                          <option *ngIf="attribute[1].empty" [ngValue]="attribute[1].empty">
                            {{ attribute[1].empty.name }}
                          </option>
                          <option *ngFor="let option of attribute[1].values | orderBy: (attribute[1].empty ? '' : 'name') ; let first = first" [ngValue]="option" [selected]="first">
                            {{ option.name }}
                          </option>
                        </select>
                    </div>
                  </div>
                  <!-- Crop -->
                  <!-- <div class="col-sm-4" *ngIf="cd.product.attributes.harvest.enabled">
                    <div class="form-group" hasError>
                      <label class="control-label">{{ 'GLOBAL.CROP_YEAR'|translate }}</label>
                      <select class="form-control" name="harvest"
                        [(ngModel)]="cd.business_detail.harvest"
                        #cropSelect="ngModel"
                        [disabled]="processing">
                        <option [ngValue]="null" [selected]="true" [disabled]="true">{{ 'GLOBAL.CROP_YEAR_PLACEHOLDER'|translate }}</option>
                        <option *ngFor="let crop of cropList" [ngValue]="crop">{{ crop }}</option>
                      </select>
                      <error-list></error-list>
                    </div>
                  </div> -->
                </div>
                <ag-product-quality-info [order]="cd" [proposal]="cd" key="type">
                </ag-product-quality-info>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="row step" [validateFormStep]="step01.validateFormStep &&
        cd.business_detail.quantity.value"
        #step02="validateFormStep"
        [nextStep]="step03">
          <div class="col-xs-12">
            <div class="line"></div>
            <span class="step-indicator">
              <span></span>
              <i class="material-symbols-rounded">done</i>
            </span>
            <div class="container-fluid" style="padding-left: 0px;">
              <div class="row">
                <!-- Quantity -->
                <div class="col-sm-5">
                  <div class="form-group" hasError>
                    <label class="control-label">{{ cd.product?.attributes.order_quantity_label[cd.operation_type] || cd.product?.attributes.order_quantity_label || 'GLOBAL.QUANTITY'|translate }}</label>
                    <div class="input-group">
                      <!-- <div dropdown agSelect name="quantityType" [(ngModel)]="cd.business_detail.quantity.type" class="input-group-btn" *ngIf="cd.operation_type !== 'venta' && quantityTypes?.length > 1">
                        <button dropdownToggle type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          {{ cd.business_detail.quantity.type?.name }}
                          <span class="caret"></span>
                        </button>
                        <ul *dropdownMenu class="dropdown-menu">
                          <li *ngFor="let q of quantityTypes" [agOption]="q"><a>{{ q.name }}</a></li>
                        </ul>
                      </div> -->
                      <input type="number" placeholder="0" step="1" class="form-control" name="quantity" [(ngModel)]="cd.business_detail.quantity.value"
                      #quantity="ngModel" required number [min]="1"
                      [max]="9999999">
                      <div dropdown agSelect name="quantityUnit" class="input-group-btn" [(ngModel)]="cd.business_detail.quantity.unit"
                        [class.single-unit]="cd.product?.quantity_units?.length <= 1">
                        <button dropdownToggle type="button" class="btn btn-default dropdown-toggle"
                          data-toggle="dropdown"
                          aria-haspopup="true" aria-expanded="false">
                          {{ cd.business_detail.quantity?.unit.name_plural || cd.business_detail.quantity?.unit.abbrev || cd.business_detail.quantity?.unit.name }}
                          <span class="caret"></span>
                        </button>
                        <ul *dropdownMenu class="dropdown-menu dropdown-menu-right">
                          <li *ngFor="let u of cd.product?.quantity_units" [agOption]="u"><a>{{ u.name_plural }}</a></li>
                        </ul>
                      </div>
                    </div><!-- /btn-group -->    
                    <error-list></error-list>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row step" [validateFormStep]="step02.validateFormStep &&
        cd.destination &&
        cd.business_detail.delivery.range" #step03="validateFormStep">
          <!-- ((cd.business_detail.delivery.locations && cd.business_detail.delivery.locations[0]) ||
          (cd.business_detail.delivery.geolocations && cd.business_detail.delivery.geolocations[0])) && -->
          <div class="col-xs-12">
            <div class="line"></div>
            <span class="step-indicator">
              <span></span>
              <i class="material-symbols-rounded">done</i>
            </span>
            <div class="container-fluid" style="padding-left: 0px;">
              <div class="row">
                <!-- Delivery place -->
                <!-- <div class="col-sm-7" *ngIf="company.market.configuration.geolocation?.enabled">
                  <div class="form-group" hasError>
                    <label class="control-label">Lugar de depósito</label>
                    <div>
                      <google-places name="geolocations" [(ngModel)]="cd.business_detail.delivery.geolocations" #geolocations="ngModel" required></google-places>
                    </div>
                    <error-list></error-list>
                  </div>
                </div>
                <div class="col-sm-7" *ngIf="company.market.configuration.location?.enabled">
                  <div class="form-group" hasError>
                    <label class="control-label">Lugar de depósito</label>
                    <div>
                      <ag-location-picker name="localidad"
                      [(ngModel)]="cd.business_detail.delivery.locations[0]"
                      required
                      [market]="company.market"
                      title="{{ 'ORDER_FORM.DELIVERY_LOCATION.TITLE'|translate }}"></ag-location-picker>
                    </div>
                    <error-list></error-list>
                  </div>
                </div> -->
                <div class="col-sm-6" *ngIf="destinations">
                  <div class="form-group" hasError>
                    <label for="destination" class="control-label">{{ 'ORDER.DEPOSIT_LOCATION'|translate }}</label>
                    <ng-container *ngIf="!cd.destination else selectedDestination">
                      <selectize
                      required
                      labelField="label"
                      searchField="searchField"
                      [maxItems]="1"
                      [options]="destinations"
                      [(ngModel)]="cd.destination"
                      (ngModelChange)="destinationChanged()"
                      name="destination"
                      placeholder="{{ 'SLOTS.ADD_MODAL.ENTER_DESTINATION'|translate }}"
                      [disabled]="processing"></selectize>
                      <div class="help-block">
                        <a class="small"
                        (click)="hubSpotService.open()">{{ 'SLOTS.ADD_MODAL.DESTINATION_NOT_LISTED'|translate }}</a>
                      </div>
                    </ng-container>
                    <ng-template #selectedDestination>
                      <ul class="locations">
                        <li class="location">
                          <span class="location-text">
                            {{ cd.destination.name }},
                            {{ cd.destination.location.levels[0].name }}
                            {{ cd.destination.location.levels[2]? '(' + cd.destination.location.levels[2].name + ')' : '' }}
                          </span>
                          <span class="location-remove" [class.disabled]="processing" (click)="cd.destination = undefined">&times;</span>
                        </li>
                      </ul>
                    </ng-template>
                  </div>
                </div>
                <div class="col-sm-6" *ngIf="cd.destination">
                  <div class="row">
                    <div class="col-sm-9">
                      <div class="form-group">
                        <label class="control-label">{{ 'SLOTS.ADD_MODAL.ADDRESS'|translate }}</label>
                        <company-info [company]="cd.destination.company" [activity]="false"></company-info>
                        <div>
                          {{ cd.destination.address.street }}
                          <span *ngIf="cd.destination.address.postal_code" class="text-muted">({{ cd.destination.address.postal_code }})</span>
                        </div>
                        <div *ngIf="cd.destination.location?.levels; let levels" class="small">
                          {{ levels[0].name }}
                          {{ levels[2] ? '(' + levels[2].name + ')' : '' }}                  
                        </div>
                        <div *ngIf="cd.destination.terminal" class="small text-muted">{{ cd.destination.terminal }}</div>
                      </div>
                    </div>
                    <div class="col-sm-3 hidden-xs">
                      <map
                      [maxZoom]="12"
                      [addresses]="[
                        cd.destination.address.street + ', ' + (cd.destination.location.levels|pluck: 'name'|flatten).join(', ')
                      ]"
                      [rounded]="true"></map>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row"> 
                <!-- Delivery period -->
                <div class="col-sm-5">
                  <div class="form-group" hasError>
                    <label class="control-label">{{ company.market.configuration.order.business_detail.delivery.range.label }}</label>
                    <div class="input-group">
                      <div class="input-group-addon">
                        <i class="material-symbols-rounded">date_range</i>
                      </div>
                      <input type="text" name="deliveryDate" class="form-control" placeholder="{{ 'GLOBAL.RANGE_PLACEHOLDER'|translate }}"
                      required
                      bsDaterangepicker
                      container=".app-content"
                      [minDate]="today" [bsConfig]="{adaptivePosition: true, showWeekNumbers: false, rangeInputFormat: 'DD/MM/YYYY'}"
                      [(ngModel)]="cd.business_detail.delivery.range" readonly>
                    </div>
                    <error-list></error-list>
                    <a class="small" (click)="setDeliveryRange(30)">{{ company.market.configuration.market_summary.available.label }}</a>
                  </div>
                </div>
              </div>
              <!-- Observations -->
              <div class="row" *ngIf="company.market.configuration.order.observations?.enabled">
                <div class="col-xs-12">
                  <div class="form-group" hasError>
                    <label class="control-label">{{ 'GLOBAL.OBSERVATIONS'|translate }}
                      <small class="text-muted"> ({{ 'GLOBAL.OPTIONAL'|translate }})</small>
                    </label>
                    <textarea class="form-control" rows="6" name="obs"
                    maxlength="1024"
                    [(ngModel)]="cd.general_observations" #obs="ngModel"></textarea>
                    <span class="small help-block" [innerHtml]="'ORDER_FORM.OBSERVATIONS.HELP'|translate"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </form>
  </div>
</div>

<spinner *ngIf="processing" message="{{ 'ORDER_FORM.PUBLISHING'|translate }}" [block-ui]="true"></spinner>
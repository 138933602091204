import { Type } from "class-transformer";

import { Company } from "../../models/company.model";

export class ClientCredentials {
  client_id: string;
  client_secret: string;
  company?: {
    id: Company['id'];
    names?: Company['names'];
    first_surname?: Company['first_surname'];
    second_surname?: Company['second_surname'];
    name: Company['name'];
    logo: Company['logo'];
  };
  @Type(() => Date)
  created_at: Date;

  constructor(data: Partial<ClientCredentials> = {}) {
    Object.assign(this, data);
  }
}

export class AuthorizationToken {
  authorization_token: string;
};

export interface OAuthParams {
  client_id: string;
  callback_uri: string;
  client_secret?: string;
  grand_type?: string;
  scope?: string;
}
import { Component, Input, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { QuillToolbarConfig } from 'ngx-quill';
import { Observable, Subscription, forkJoin } from 'rxjs';

import { Company } from '../../../models/company.model';
import { DataDogLoggerService } from '../../../services/data-dog-logger.service';
import { DocsContent, DocsCustomizationService } from '../../services/docs-customization.service';

@Component({
  selector: 'ag-docs-customization',
  templateUrl: './docs-customization.component.html',
  styleUrls: ['./docs-customization.component.scss']
})
export class DocsCustomizationComponent implements OnDestroy {

  @ViewChild('docsModal', { static: true }) private readonly docsModal: TemplateRef<any>;

  @Input() public company: Company;

  public data: {
    [type: string]: {
      original?: string;
      model?: string;
    }
  };
  /** Flag used to indicate if the component is loading information. */
  public loading: boolean;
  public maxLength: number = 1024;
  /**
   * Flag used to enable/disable UI buttons and links when an API request is in
   * progress.
   */
  public processing: boolean;
  public toolbarOptions: QuillToolbarConfig = [
    ['bold', 'italic', 'underline'],        // toggled buttons
    // ['blockquote', 'code-block'],

    // [{ 'header': 1 }, { 'header': 2 }],               // custom button values
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
    // [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
    // [{ 'direction': 'rtl' }],                         // text direction

    // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
    // [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    // [{ 'font': [] }],
    // [{ 'align': [] }],

    ['clean']
  ];

  private modalRef: BsModalRef;
  private modalSub: Subscription;
  private subscriptions: Subscription[] = [];

  constructor(
    private modalService: BsModalService,
    private docsCustomizationService: DocsCustomizationService,
    private dataDogLoggerService: DataDogLoggerService
  ) { }

  private loadData(): void {
    this.data = {
      header: {},
      footer: {}
    };

    this.loading = true;

    let observables: Observable<DocsContent>[] = [];

    for (const section of Object.keys(this.data)) {
      observables.push(this.docsCustomizationService.get(this.company.id, section));
    }

    this.subscriptions.push(forkJoin(observables).subscribe({
      next: response => {
        response.forEach(r => {
          this.data[r.section] = {
            original: r.content,
            model: r.content
          }
        });

        this.loading = false;
      },
      error: error => {
        this.loading = false;
        this.dataDogLoggerService.warn(error.message, error.error);
      }
    }));
  }

  public open(): void {
    this.loadData();
    this.openModal(this.docsModal);
  }

  public save(): void {
    let observables = {};

    for (const type of Object.keys(this.data)) {
      const element = this.data[type];
      if (element.model !== element.original) {
        const hasContent = Boolean(element.model && element.model.length > 0);
        observables[type] = hasContent ?
          this.docsCustomizationService.update(this.company.id, type, element.model) :
          this.docsCustomizationService.delete(this.company.id, type);
      }
    }

    if (Object.keys(observables).length) {
      this.processing = true;

      this.subscriptions.push(forkJoin(observables).subscribe({
        next: response => {
          this.closeModal();
        },
        error: error => {
          this.processing = false;
          this.dataDogLoggerService.warn(error.message, error.error);
        }
      }));
    }
  }

  /** Generic Modal trigger. */
  private openModal(template: TemplateRef<any>, c: string = ''): void {
    this.modalRef = this.modalService.show(template, { class: c });

    this.modalSub = this.modalRef.onHide.subscribe((reason: string) => {
      this.modalSub.unsubscribe();
      this.modalRef = undefined;
      // Reset all values
      this.processing = false;
    });
  }

  /** Closes the most recent opened modal. */
  public closeModal(onHide: Function = null): void {
    if (this.modalRef) {
      this.modalRef.hide();
      if (onHide) this.modalRef.onHide.subscribe(onHide);
    } else {
      if (onHide) onHide();
    }
  }

  /** @ignore */
  ngOnDestroy(): void {
    this.closeModal();

    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}

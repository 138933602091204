<input type="text" class="form-control" [ngModel]="description" (focus)="showModal()" [disabled]="disabled">

<ng-template #paymentDetail>
  <!-- modal -->
  <div class="modal-header">
    <button type="button" class="close" (click)="modalRef.hide()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">{{ 'PAYMENT_CONDITION_INPUT.TITLE'|translate }}</h4>
  </div>
  <div class="modal-body">
    <form id="condition-form" #conditionForm="ngForm" (ngSubmit)="conditionForm.valid && accept()">
      <div class="row">
        <div class="col-sm-6">
          <div class="form-group">
            <label>{{ 'GLOBAL.PAYMENT_CONDITION'|translate }}</label>
            <select class="form-control" name="condition" [ngModel]="selection.payment_condition" (ngModelChange)="changePaymentCondition($event)" [compareWith]="compareId" [required]="true">
              <option [ngValue]="null" [disabled]="true">{{ 'PAYMENT_CONDITION_INPUT.SELECT_TERMS'|translate }}</option>
              <option *ngFor="let pc of paymentConditions" [ngValue]="pc"> {{ pc.name }} </option>
            </select>
          </div>
        </div>
        <div class="col-sm-6" [class.hidden]="selection.payment_condition?.id !== 3">
          <div class="form-group" hasError>
            <label class="control-label">{{ 'PAYMENT_CONDITION_INPUT.OTHER_TERM'|translate }}</label>
            <input type="text" name="paymentOther" class="form-control"
            maxlength="256"
            [(ngModel)]="selection.other" [required]="selection.payment_condition?.id === 3">
            <error-list></error-list>
          </div>
        </div>
        <div class="col-sm-6" [class.hidden]="selection.payment_condition?.id !== 2">
          <div class="form-group" hasError>
            <label class="control-label">{{ 'GLOBAL.DATE'|translate }}</label>
            <div class="input-group">
              <div class="input-group-addon">
                <i class="material-symbols-rounded">date_range</i>
              </div>
              <input type="text" name="paymentDate" class="form-control" bsDatepicker
                container=".app-content"
                [bsConfig]="{showWeekNumbers: false, isAnimated: true, dateInputFormat: 'DD/MM/YYYY'}"
                [minDate]="minPaymentDate"
                [(ngModel)]="selection.date"
                [required]="selection.payment_condition?.id === 2"
                #paymentDate="ngModel"
                readonly>
            </div>
            <error-list></error-list>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button (click)="modalRef.hide()" type="button" class="btn btn-link" data-dismiss="modal">{{ 'GLOBAL.CANCEL'|translate }}</button>
    <button type="submit" form="condition-form" class="btn btn-agree">{{ 'GLOBAL.SAVE_CHANGES'|translate }}</button>
  </div>
</ng-template>

<div class="container-fluid">
  <div class="content-box">
    <ol class="breadcrumb" *ngIf="company">
      <li><a [routerLink]="['/company', company.id, 'setup']">{{ 'GLOBAL.CONFIG_ASSISTANT'|translate }}</a></li>
      <li class="active">{{ 'GLOBAL.TEAM'|translate }}</li>
    </ol>
    <div class="ag-header sticky">
      <div class="ag-header-title">
        <h3 *ngIf="company">{{ 'TEAM_VIEW.NAME'|translate:{company: company?.name} }}</h3>
      </div>
    </div>

    <div class="row" *ngIf="company">
      <div class="col-xs-12">
        <tabset>
          <tab heading="{{ 'TEAM_VIEW.USERS'|translate }}"
          (selectTab)="navigateTo('users')"
          [active]="collectionSlug === 'users'">
            <ng-container *ngIf="collectionSlug === 'users'">
              <ag-team-users 
              [user]="user" 
              [account]="account" 
              [company]="company"
              ></ag-team-users>
            </ng-container>
          </tab>
          <tab heading="{{ 'MANAGERS.MANAGERS'|translate }}"
          (selectTab)="navigateTo('managers')"
          [active]="collectionSlug === 'managers'">
            <ng-container *ngIf="collectionSlug === 'managers'">
              <ag-team-managers 
              [user]="user"
              [account]="account"
              [company]="company"
              ></ag-team-managers>
            </ng-container>
          </tab>
          <tab heading="{{ 'SIGNATURES.SIGNERS'|translate }}"
          (selectTab)="navigateTo('signers')"
          [active]="collectionSlug === 'signers'">
            <ng-container *ngIf="collectionSlug === 'signers'">
              <ag-team-signers
              [user]="user"
              [account]="account"
              [company]="company"
              ></ag-team-signers>
            </ng-container>
          </tab>
        </tabset>
      </div>
    </div>
  </div>
</div>

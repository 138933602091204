import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription, combineLatest } from 'rxjs';

import { Router } from '@angular/router';
import { Account } from '../../../auth/models/account.model';
import { User } from '../../../auth/models/user.model';
import { LoginService } from '../../../auth/services/login.service';
import { Company } from '../../../models/company.model';
import { CompanyService } from '../../../services/company.service';
import { ComponentCommService } from '../../../services/component-comm.service';

@Component({
  selector: 'app-setup',
  templateUrl: './setup.component.html',
  styleUrls: ['./setup.component.scss']
})
export class SetupComponent implements OnInit, OnDestroy {

  public account: Account;
  public cards: SetupCard[];
  public company: Company;
  /** Current [[User]]. */
  public user: User;

  private _cards: SetupCard[];
  private subscriptions: Subscription[] = [];

  constructor(
    private companyService: CompanyService,
    private componentComm: ComponentCommService,
    private router: Router,
    private loginService: LoginService
  ) {
    this._cards = [
      {
        condition: () => this.account.is.commercial_with_users,
        icon: 'people_outline',
        title: 'GLOBAL.TEAM',
        description: 'COMPANY_SETUP.TEAM_HELP',
        link: '../team'
      },
      {
        condition: () => this.account.is.commercial_with_users && this.company.hasModule('commercial-manager'),
        icon: 'business_center',
        title: 'GLOBAL.COMMERCIAL_MANAGER',
        description: 'GLOBAL.COMMERCIAL_MANAGER_HELP',
        link: '../commercial-manager'
      },
      {
        condition: () => this.company.market.configuration.location.enabled && this.account.is.commercial && this.company.activity.buyer,
        icon: 'location_on',
        title: 'GLOBAL.DELIVERY_PLACES',
        description: 'COMPANY_SETUP.COMPANY_LOCATIONS_HELP',
        link: '../locations'
      },
      {
        condition: () => this.account.is.commercial_with_users || this.account.is.counterpart_admin,
        icon: 'apartment',
        title: 'GLOBAL.NOTES',
        description: 'COMPANY_SETUP.NOTES_HELP',
        link: '../notes',
        params: { order_by: '-fiscalId' }
      },
      {
        condition: () => (this.account.is.commercial_with_users || this.account.is.counterpart_admin) && this.company.hasModule('my-network'),
        icon: 'work_outline',
        title: 'GLOBAL.SELLER_ADMIN',
        description: 'COMPANY_SETUP.SELLER_ADMIN_HELP',
        link: '../network',
        params: { order_by: '-verification_date' }
      },
      {
        condition: () => this.company.market.configuration.certificates?.enabled && this.account.is.admin,
        glyphicon: 'certificate',
        title: 'GLOBAL.CERTIFICATES',
        description: 'COMPANY_SETUP.CERTIFICATES_HELP',
        link: '../certificates'
      },
      {
        condition: () => this.account.is.admin && this.company.hasModule('importer'),
        icon: 'api',
        title: 'GLOBAL.INTEGRATIONS',
        description: 'COMPANY_SETUP.INTEGRATIONS_HELP',
        link: '../integrations'
      }
    ];
  }

  ngOnInit(): void {
    this.componentComm.emit({ name: 'app-title', title: 'COMPANY_SETUP.HEADING' });

    this.subscriptions.push(combineLatest([
      this.companyService.watchAccount(),
      this.companyService.watch(),
      this.loginService.getCurrentUser()
    ]).subscribe(response => {
      if (response.some(item => item == null)) return;

      // Destructures the values ​​received from the observables, for readability
      const [
        account,
        company,
        user
      ] = response;

      this.account = account;
      this.company = company;
      this.user = user;

      if (this.company.activation_level.id === 1) {
        this.router.navigateByUrl('/companies');
      } else {
        this.setupCards();
      }
    }));
  }

  private setupCards(): void {
    this.cards = [];
    this._cards.forEach(card => {
      if (card.condition()) {
        this.cards.push(card);
      }
    });
  }

  /** @ignore */
  ngOnDestroy(): void {
    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}

class SetupCard {
  condition: Function;
  icon?: string;
  glyphicon?: string;
  title: string;
  description: string;
  link: string;
  params?: any;
}
<contract-modal #contractModal
[company]="company"></contract-modal>

<contract-linker #contractLinker
[company]="company"></contract-linker>

<!-- Data -->
<!-- https://www.w3.org/WAI/tutorials/tables/examples/threeheaders/ -->
<div class="horizontal-scroller" *ngIf="company">
  <div class="table-sticky-headers">
    <table class="table table-condensed" sortable defaultSortBy="-original_booking_date"
    [variableInUrl]="!predefinedFilters"
    AgMultiselection [(ngModel)]="selected">
      <thead>
        <tr class="tr-align-center">
          <th class="sap micro"
          *ngIf="enableSelection &&
          negotiations?.length > 0 &&
          editorAccount">
            <input type="checkbox" AgSelectall
            id="select-all-toggle"
            class="show-onhover no-margin"
            tooltip="{{ 'WORKING_ORDERS.SELECT_ALL'|translate }}"
            container="body" [adaptivePosition]="false">
          </th>
          <th class="sap hidden-xs" sortBy="original_booking_date">
            {{ 'GLOBAL.DATE'|translate }}
            <column-filter
            type="date"
            alignment="left"
            [filters]="filters"
            key="filters[date]"
            (onChange)="changeFilter($event)"></column-filter>
          </th>
          <ng-container *ngIf="!company.activity.broker">
            <th class="sap hidden-xs hidden-sm"></th>
            <th sortBy="company_name">
              {{ 'GLOBAL.COUNTERPART'|translate }}
              <!-- FAS-2034 -->
              <column-filter
              *ngIf="!hideFilters && !predefinedFilters"
              type="string"
              alignment="left"
              [filters]="filters"
              key="filters[company_name]"
              (onChange)="changeFilter($event)"></column-filter>
            </th>
            <th sortBy="broker_name">
              {{ 'BARTERS.BROKERS'|translate }}
              <column-filter
              *ngIf="!hideFilters && !predefinedFilters"
              type="string"
              alignment="left"
              [filters]="filters"
              key="filters[broker_name]"
              (onChange)="changeFilter($event)"></column-filter>
            </th>
          </ng-container>
          <ng-container *ngIf="company.activity.broker">
            <th>
              {{ 'GLOBAL.TRADE'|translate }}
            </th>
            <th sortBy="buyer_name">
              {{ 'ORDER_SUMMARY.BUYER'|translate }}
              <column-filter
              *ngIf="!hideFilters && !predefinedFilters"
              type="string"
              alignment="left"
              [filters]="filters"
              key="filters[buyer_name]"
              (onChange)="changeFilter($event)"></column-filter>
            </th>
            <th sortBy="seller_name">
              {{ 'ORDER_SUMMARY.SELLER'|translate }}
              <column-filter
              *ngIf="!hideFilters && !predefinedFilters"
              type="string"
              alignment="left"
              [filters]="filters"
              key="filters[seller_name]"
              (onChange)="changeFilter($event)"></column-filter>
            </th>
          </ng-container>
          <th *ngIf="!hideColumns.includes('product') && !groupByProduct">{{ 'GLOBAL.PRODUCT'|translate }}</th>
          <th
            *ngIf="company.market.configuration.location.enabled"
            id="loc" class="hidden-xs hidden-sm" sortBy="proposal.locationString">
            {{ 'GLOBAL.DELIVERY_PLACES'|translate }}
          </th>
          <th id="port" class="hidden-xs hidden-sm" sortBy="proposal.portString" *ngIf="company.market.configuration.order.business_detail.port.enabled">{{ 'GLOBAL.COUNTRY_PORT'|translate }}</th>
          <th class="sap hidden-xs hidden-sm" sortBy="contract_reference" *ngIf="!hideColumns.includes('contract')">
            {{ 'GLOBAL.CONTRACT'|translate }}
            <column-filter
            *ngIf="!hideFilters && !predefinedFilters"
            type="string"
            alignment="left"
            [filters]="filters"
            key="filters[contract]"
            (onChange)="changeFilter($event)"></column-filter>
          </th>
          <th class="sap hidden-xs hidden-sm"
            *ngIf="atLeastOneExternalReference"
            sortBy="data_external.reference.id">
            <abbr title tooltip="{{ 'GLOBAL.EXTERNAL_REFERENCE'|translate }}"
            container="body"
            [adaptivePosition]="false">{{ 'GLOBAL.EXTERNAL_REFERENCE_ABBR'|translate }}</abbr>
          </th>
          <th class="sap hidden-xs"
            *ngIf="company.market.configuration.order.business_detail.delivery.delivery_type.show_in_lists"
            sortBy="proposal.deliveryType">
            <abbr title tooltip="{{ 'GLOBAL.DELIVERY_TYPE'|translate }}"
            container="body"
            [adaptivePosition]="false">{{ 'GLOBAL.DELIVERY_TYPE_ABBR'|translate }}</abbr>
          </th>
          <th id="ton" class="dlb drb" sortBy="proposal.business_detail.quantity.value">
            <abbr title tooltip="{{ 'GLOBAL.QUANTITY'|translate }}"
            container="body"
            [adaptivePosition]="false">{{ 'GLOBAL.QUANTITY_ABBR'|translate }}</abbr>
          </th>
          <th class="sap text-center" sortBy="proposal.business_detail.delivery.intDateFrom">{{ 'GLOBAL.PERIOD'|translate }}</th>
          <th class="sap" sortBy="proposal.business_detail.price.value">
            {{ 'GLOBAL.PRICE'|translate }}
            <ng-container *ngIf="company.market.quantity_units.length === 1">/{{ company.market.quantity_units[0].abbrev }}</ng-container>
          </th>
          <th class="sap text-center" *ngIf="!hideColumns.includes('slots')">
            {{ 'COMPANY.SLOTS'|translate }}
          </th>
          <th class="sap text-center" *ngIf="!hideColumns.includes('fixations_data')">
            {{ 'CONTRACTS.FIXED'|translate }}
          </th>
          <th class="sap text-center dlb" *ngIf="!hideColumns.includes('unloads')">
            <abbr tooltip="{{ 'COMPANY_TRADED_VIEW.UNLOADED'|translate }}"
            container="body"
            [adaptivePosition]="false"><span class="material-symbols-rounded">local_shipping</span></abbr>
            <column-filter
            *ngIf="!predefinedFilters"
            type="boolean"
            [filters]="filters"
            key="filters[applied]"
            (onChange)="changeFilter($event)"></column-filter>
          </th>
          <th class="sap text-center dlb" sortBy="invoices.closed" *ngIf="!hideColumns.includes('balance')">
            <abbr tooltip="{{ 'COMPANY_TRADED_VIEW.LIQUIDATED'|translate }}"
            container="body"
            [adaptivePosition]="false"><span class="material-symbols-rounded">check</span></abbr>
            <column-filter
            *ngIf="!predefinedFilters"
            type="boolean"
            [filters]="filters"
            key="filters[settled]"
            (onChange)="changeFilter($event)"></column-filter>
          </th>
          <th *ngIf="!hideColumns.includes('actions')" class="sap hidden-xs dlb"></th>
          <th *ngIf="showUnlink"></th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngIf="groupByProduct && negotiationsByProduct">
          <ng-container *ngFor="let group of negotiationsByProduct | keyvalue">
            <!-- Product sub-header -->
            <tr *ngIf="showProductHeader" class="product-header">
              <th colspan="2" class="text-uppercase visible-xs">{{ group.value[0].order.product.name || 'GLOBAL.NOT_AVAILABLE_ABBR'|translate }}</th>
              <th colspan="3" class="text-uppercase hidden-xs">{{ group.value[0].order.product.name || 'GLOBAL.NOT_AVAILABLE_ABBR'|translate }}</th>
              <th *ngIf="enableSelection &&
              editorAccount"></th>
              <ng-container *ngIf="!company.activity.broker">
                <th class="hidden-xs hidden-sm"></th>
              </ng-container>
              <ng-container *ngIf="company.activity.broker">
                <th></th>
              </ng-container>
              <th *ngIf="company.market.configuration.location.enabled" class="hidden-xs hidden-sm"></th>
              <th class="hidden-xs hidden-sm" *ngIf="company.market.configuration.order.business_detail.port.enabled"></th>
              <th class="hidden-xs hidden-sm" *ngIf="!hideColumns.includes('contract')"></th>
              <th class="hidden-xs hidden-sm" *ngIf="atLeastOneExternalReference"></th>
              <th class="hidden-xs" *ngIf="company.market.configuration.order.business_detail.delivery.delivery_type.show_in_lists"></th>
              <th class="text-center sap">
                <span tooltip="{{ 'GLOBAL.NEGOTIATED_VOLUME'|translate }}"
                container="body"
                *ngIf="company.market.quantity_units.length === 1">
                  {{ group.value|pluck:'proposal.business_detail.quantity.value'|sum|number:undefined:currentLang}}
                  <small>{{ company.market.quantity_units[0].abbrev }}</small>
                </span>
              </th>
              <th></th>
              <th></th>
              <th *ngIf="!hideColumns.includes('slots')"></th><!-- Fijaciones -->
              <th *ngIf="!hideColumns.includes('fixations_data')"></th><!-- Fijaciones -->
              <th *ngIf="!hideColumns.includes('unloads')"></th><!-- Descargas -->
              <th *ngIf="!hideColumns.includes('balance')"></th><!-- Liquidado Final -->
              <th *ngIf="!hideColumns.includes('actions')" class="hidden-xs"></th>
              <th *ngIf="showUnlink"></th>
            </tr>
            <!-- Negotiation row -->
            <ng-container *ngFor="let negotiation of group.value">
              <tr class="tr-align-center"
              [popover]="negotiation.props?.length > 0 ? popoverTemplate : null"
              [popoverContext]="{negotiation: negotiation}"
              [adaptivePosition]="false"
              containerClass="popFadeDelay width-800"
              placement="bottom"
              triggers="mouseenter:mouseleave"
              container="body"
              #pop="bs-popover">
                <td class="sap micro"
                rowspan="2"
                *ngIf="enableSelection &&
                editorAccount">
                  <input
                  [AgCheckbox]="negotiation" type="checkbox"
                  class="show-onhover no-margin">
                </td>
                <td class="sap hidden-xs no-vertical-padding">
                  <ag-timestamp [date]="negotiation.original_booking_date"></ag-timestamp>
                </td>
                <ng-container *ngIf="!company.activity.broker">
                  <td class="sap hidden-xs hidden-sm no-vertical-padding">
                    <company-logo [company]="negotiation.counterpartiesOf(company.id)[0]"></company-logo>
                  </td>
                  <td class="no-vertical-padding">
                    <div class="visible-xs location-small">
                      <b>{{ negotiation.original_booking_date|date: 'HH:mm' }}</b>
                      - <date-md [date]="negotiation.original_booking_date"></date-md>
                    </div>
                    <a class="order-link truncate" routerLink="/company/{{ company.id }}/counter-order/{{ negotiation.id }}" title="#{{ negotiation.id }}">
                      <span class="truncate">
                        <samp class="small hidden-xs hidden-sm">#{{ negotiation.id }}:</samp>
                        <b *ngIf="negotiation.order.type.id === 8"> {{ 'GLOBAL.CERTIFICATE_DEPOSIT'|translate }}</b>
                        <ng-container *ngTemplateOutlet="companiesNames; context: {companies: negotiation.counterpartiesOf(company.id)[0]?.activity?.broker ? [] : negotiation.counterpartiesOf(company.id)}"></ng-container>
                      </span>
                    </a>
                    <div class="visible-xs visible-sm location-small">
                      <span *ngIf="company.market.configuration.location.enabled" class="clamp-2">{{ negotiation.proposal.business_detail.delivery.locations |locationString }}</span>
                      <span *ngIf="company.market.configuration.order.business_detail.port.enabled" class="clamp-2">{{ countryAndPort(negotiation) }}</span>
                      <span *ngIf="company.market.configuration.order.business_detail.delivery.delivery_type.show_in_lists" class="clamp-2">
                        ({{ negotiation.proposal.business_detail.delivery.delivery_type?.short_name || negotiation.proposal.business_detail.delivery.delivery_type?.name }})
                      </span>
                    </div>
                  </td>
                  <td class="no-vertical-padding">
                    <ng-container *ngTemplateOutlet="companiesNames; context: {companies: negotiation.brokers}"></ng-container>
                  </td>
                </ng-container>
                <ng-container *ngIf="company.activity.broker">
                  <td class="no-vertical-padding">
                    <div class="visible-xs location-small truncate">
                      <b>{{ negotiation.original_booking_date|date: 'HH:mm' }}</b>
                      - <date-md [date]="negotiation.original_booking_date"></date-md>
                    </div>
                    <a class="order-link truncate" routerLink="/company/{{ company.id }}/counter-order/{{ negotiation.id }}" title="#{{ negotiation.id }}">
                      <samp class="small">#{{ negotiation.id }}</samp>
                    </a>
                    <div class="visible-xs visible-sm location-small">
                      <span *ngIf="company.market.configuration.location.enabled" class="clamp-2">{{ negotiation.proposal.business_detail.delivery.locations |locationString }}</span>
                      <span *ngIf="company.market.configuration.order.business_detail.port.enabled" class="clamp-2">{{ countryAndPort(negotiation) }}</span>
                      <span *ngIf="company.market.configuration.order.business_detail.delivery.delivery_type.show_in_lists" class="clamp-2">
                        ({{ negotiation.proposal.business_detail.delivery.delivery_type?.short_name || negotiation.proposal.business_detail.delivery.delivery_type?.name }})
                      </span>
                    </div>
                  </td>
                  <td class="no-vertical-padding">
                    <ng-container *ngTemplateOutlet="companiesNames; context: {companies: (negotiation.buyer_represented?.length || negotiation.buyer.activity.broker ? negotiation.buyer_represented : [negotiation.buyer])}"></ng-container>
                  </td>
                  <td class="no-vertical-padding">
                    <ng-container *ngTemplateOutlet="companiesNames; context: {companies: (negotiation.seller_represented?.length || negotiation.seller.activity.broker ? negotiation.seller_represented : [negotiation.seller])}"></ng-container>
                  </td>
                </ng-container>
                <td headers="loc" class="hidden-xs hidden-sm small"
                rowspan="2"
                *ngIf="company.market.configuration.location.enabled">
                  <span class="clamp-2">{{ negotiation.proposal.business_detail.delivery.locations |locationString }}</span>
                </td>
                <td headers="port" class="hidden-xs hidden-sm small"
                rowspan="2"
                *ngIf="company.market.configuration.order.business_detail.port.enabled">
                  {{ countryAndPort(negotiation) }}
                </td>
                <td class="small sap hidden-xs hidden-sm text-right"
                rowspan="2"
                *ngIf="!hideColumns.includes('contract')">
                  <ng-container *ngIf="negotiation.contracts?.length">
                    <div *ngFor="let c of negotiation.contracts">
                      <samp><a (click)="contractModal.show(c.id)">{{ c.reference}}</a></samp>
                      <button type="button"
                      *ngIf="editorAccount && !hideColumns.includes('linker')" class="show-onhover btn btn-link btn-xs"
                      title="{{ 'LINKER.UNLINK'|translate }}"
                      (onConfirm)="contractLinker.unlink(c)"
                      [confirm-options]="{
                        title: 'LINKER.UNLINK_TITLE'|translate,
                        body: 'LINKER.UNLINK_BODY'|translate:{negotiation: negotiation.id, contract: c.reference},
                        action: 'LINKER.YES_UNLINK'|translate
                      }"><i class="material-symbols-rounded">link_off</i></button>
                    </div>
                  </ng-container>
                  <button type="button"
                  *ngIf="!hideColumns.includes('linker') &&
                  negotiation.counterpartiesOf(company.id)[0].id &&
                  !negotiation.contracts?.length &&
                  editorAccount" class="show-onhover btn btn-link btn-xs" title="{{ 'LINKER.TITLE'|translate }}"
                  (click)="contractLinker.link(negotiation.order.product, {buyer: negotiation.buyer_represented[0] || negotiation.buyer, seller: negotiation.seller_represented[0] || negotiation.seller}, {negotiation: negotiation})"><i class="material-symbols-rounded">link</i></button>
                </td>
                <td class="sap hidden-xs hidden-sm small"
                rowspan="2"
                *ngIf="atLeastOneExternalReference">
                  <div *ngIf="negotiation.data_external.reference?.id" class="flex-center-distributed">
                    <samp>{{ negotiation.data_external.reference.id }}</samp>
                    <button class="show-onhover btn btn-link btn-xs" title="{{ 'NEGOTIATION_ACTIONS.EXTERNAL_ID'|translate }}"
                    *ngIf="editorAccount"
                    (click)="referencePicker.show(negotiation)"><i class="material-symbols-rounded">edit</i></button>
                  </div>
                </td>
                <td class="sap hidden-xs small"
                rowspan="2"
                *ngIf="company.market.configuration.order.business_detail.delivery.delivery_type.show_in_lists">
                  {{ negotiation.proposal.business_detail.delivery.delivery_type?.short_name || negotiation.proposal.business_detail.delivery.delivery_type?.name }}
                </td>
                <td headers="ton" class="text-right dlb drb"
                rowspan="2">
                  <quantity-control [quantity]="negotiation.proposal.business_detail.quantity" [compact]="true"></quantity-control>
                </td>
                <td class="sap"
                rowspan="2">
                  <date-range
                  [from]="negotiation.proposal.business_detail.delivery.date_from"
                  [to]="negotiation.proposal.business_detail.delivery.date_to"></date-range>
                </td>
                <td class="sap"
                rowspan="2">
                  <div class="price-box booked-bg">
                    <div class="type"
                    *ngIf="negotiation.counterpartiesOf(company.id)[0].id"
                    [class.buy]="negotiation.buyers_id.includes(company.id)"
                    [class.sell]="negotiation.sellers_id.includes(company.id)">{{
                      (negotiation.buyers_id.includes(company.id) ? 'COMPANY_TRADED_VIEW.BOUGHT': 'COMPANY_TRADED_VIEW.SOLD')|translate
                    }}</div>
                    <div class="value">
                      <price-display [price]="negotiation.proposal.business_detail.price" [market]="company.market" [withoutYear]="true"></price-display>
                    </div>
                  </div>
                </td>
                <td class="sap text-right small" *ngIf="!hideColumns.includes('slots')"
                rowspan="2">
                  <ag-slots-details
                  [batches]="negotiation.batches"></ag-slots-details>
                </td>
                <td class="sap" *ngIf="!hideColumns.includes('fixations_data')"
                rowspan="2">
                  <ng-container *ngIf="negotiation.contracts[0]; let contract">
                    <div class="flex-center-distributed fixation-data">
                      <div *ngIf="contract.fixations_data?.period.to">
                        <div class="micro text-muted">{{ 'CONTRACTS.FIXATION_DEADLINE'|translate }}</div>
                        <date-md class="small" [date]="contract.fixations_data.period.to" [style]="true"></date-md>
                      </div>
                      <progress-circle
                      *ngIf="negotiation.proposal.business_detail.price.type === 'to_be_fixed'"
                      label="{{ 'CONTRACTS.FIXED'|translate }}"
                      [progress]="contract.fixations_data?.percentage"></progress-circle>
                    </div>
                  </ng-container>
                </td>
                <td *ngIf="!hideColumns.includes('unloads')" class="sap dlb text-center"
                rowspan="2">
                  <progress-circle
                  label="{{ 'COMPANY_TRADED_VIEW.UNLOADED'|translate }}"
                  [progress]="(negotiation.summary?.applied_volume && negotiation.contracts[0].quantity) ? (negotiation.summary.applied_volume.value / negotiation.contracts[0].quantity.value) : 0"></progress-circle>
                </td>
                <td *ngIf="!hideColumns.includes('balance')" class="sap dlb text-center"
                rowspan="2">
                  <progress-circle
                  label="{{ 'COMPANY_TRADED_VIEW.LIQUIDATED'|translate }}"
                  [progress]="negotiation.summary ? negotiation.summary.liquidated_volume / negotiation.contracts[0].quantity.value : 0"></progress-circle>
                </td>
                <td *ngIf="!hideColumns.includes('actions')" class="sap hidden-xs dlb"
                rowspan="2"
                (mouseenter)="pop.hide()">
                  <negotiation-actions
                  [negotiation]="negotiation"
                  [company]="company"
                  (saveReference)="reload()">
                  </negotiation-actions>
                </td>
                <td class="sap text-center"
                rowspan="2"
                *ngIf="showUnlink && editorAccount">
                  <button type="button"
                  class="btn btn-link btn-xs show-onhover"
                  title="{{ 'LINKER.UNLINK'|translate }}"
                  (click)="unlink.emit(negotiation)"><i class="material-symbols-rounded">link_off</i></button>        
                </td>
              </tr>
              <tr class="tr-align-center">
                <ng-container *ngIf="!company.activity.broker">
                  <td class="td-empty no-vertical-padding visible-xs" colspan="2"><ng-container *ngTemplateOutlet="labels; context: {negotiation: negotiation}"></ng-container></td>
                  <td class="td-empty no-vertical-padding visible-sm" colspan="3"><ng-container *ngTemplateOutlet="labels; context: {negotiation: negotiation}"></ng-container></td>
                  <td class="td-empty no-vertical-padding hidden-xs hidden-sm" colspan="4"><ng-container *ngTemplateOutlet="labels; context: {negotiation: negotiation}"></ng-container></td>
                </ng-container>
                <ng-container *ngIf="company.activity.broker">
                  <td class="td-empty no-vertical-padding visible-xs" colspan="3"><ng-container *ngTemplateOutlet="labels; context: {negotiation: negotiation}"></ng-container></td>
                  <td class="td-empty no-vertical-padding hidden-xs" colspan="4"><ng-container *ngTemplateOutlet="labels; context: {negotiation: negotiation}"></ng-container></td>
                </ng-container>
              </tr>
            </ng-container>
          </ng-container>
        </ng-container>
        <!-- Negotiation row -->
        <ng-container *ngIf="!groupByProduct">
          <ng-container *ngFor="let negotiation of negotiations">
            <tr class="tr-align-center"
            [popover]="negotiation.props?.length > 0 ? popoverTemplate : null"
            [popoverContext]="{negotiation: negotiation}"
            [adaptivePosition]="false"
            containerClass="popFadeDelay width-800"
            placement="bottom"
            triggers="mouseenter:mouseleave"
            container="body"
            #pop="bs-popover">
              <td class="sap micro"
              rowspan="2"
              *ngIf="enableSelection &&
              editorAccount">
                <input
                [AgCheckbox]="negotiation" type="checkbox"
                class="show-onhover no-margin">
              </td>
              <td class="sap hidden-xs no-vertical-padding">
                <ag-timestamp [date]="negotiation.original_booking_date"></ag-timestamp>
              </td>
              <ng-container *ngIf="!company.activity.broker">
                <td class="sap hidden-xs hidden-sm no-vertical-padding">
                  <company-logo [company]="negotiation.counterpartiesOf(company.id)[0]"></company-logo>
                </td>
                <td class="no-vertical-padding">
                  <div class="visible-xs location-small">
                    <b>{{ negotiation.original_booking_date|date: 'HH:mm' }}</b>
                    - <date-md [date]="negotiation.original_booking_date"></date-md>
                  </div>
                  <a class="order-link truncate" routerLink="/company/{{ company.id }}/counter-order/{{ negotiation.id }}" title="#{{ negotiation.id }}">
                    <span class="truncate">
                      <samp class="small hidden-xs hidden-sm">#{{ negotiation.id }}:</samp>
                      <b *ngIf="negotiation.order.type.id === 8"> {{ 'GLOBAL.CERTIFICATE_DEPOSIT'|translate }}</b>
                      <ng-container *ngTemplateOutlet="companiesNames; context: {companies: negotiation.counterpartiesOf(company.id)[0]?.activity?.broker ? [] : negotiation.counterpartiesOf(company.id)}"></ng-container>
                    </span>
                  </a>
                  <div class="visible-xs visible-sm location-small">
                    <span *ngIf="company.market.configuration.location.enabled" class="clamp-2">{{ negotiation.proposal.business_detail.delivery.locations |locationString }}</span>
                    <span *ngIf="company.market.configuration.order.business_detail.port.enabled" class="clamp-2">{{ countryAndPort(negotiation) }}</span>
                    <span *ngIf="company.market.configuration.order.business_detail.delivery.delivery_type.show_in_lists" class="clamp-2">
                      ({{ negotiation.proposal.business_detail.delivery.delivery_type?.short_name || negotiation.proposal.business_detail.delivery.delivery_type?.name }})
                    </span>
                  </div>
                </td>
                <td class="no-vertical-padding">
                  <ng-container *ngTemplateOutlet="companiesNames; context: {companies: negotiation.brokers}"></ng-container>
                </td>
              </ng-container>
              <ng-container *ngIf="company.activity.broker">
                <td class="no-vertical-padding">
                  <div class="visible-xs location-small truncate">
                    <b>{{ negotiation.original_booking_date|date: 'HH:mm' }}</b>
                    - <date-md [date]="negotiation.original_booking_date"></date-md>
                  </div>
                  <a class="order-link truncate" routerLink="/company/{{ company.id }}/counter-order/{{ negotiation.id }}" title="#{{ negotiation.id }}">
                    <samp class="small">#{{ negotiation.id }}</samp>
                  </a>
                  <div class="visible-xs visible-sm location-small">
                    <span *ngIf="company.market.configuration.location.enabled" class="clamp-2">{{ negotiation.proposal.business_detail.delivery.locations |locationString }}</span>
                    <span *ngIf="company.market.configuration.order.business_detail.port.enabled" class="clamp-2">{{ countryAndPort(negotiation) }}</span>
                    <span *ngIf="company.market.configuration.order.business_detail.delivery.delivery_type.show_in_lists" class="clamp-2">
                      ({{ negotiation.proposal.business_detail.delivery.delivery_type?.short_name || negotiation.proposal.business_detail.delivery.delivery_type?.name }})
                    </span>
                  </div>
                </td>
                <td class="no-vertical-padding">
                  <ng-container *ngTemplateOutlet="companiesNames; context: {companies: (negotiation.buyer_represented?.length || negotiation.buyer.activity.broker ? negotiation.buyer_represented : [negotiation.buyer])}"></ng-container>
                </td>
                <td class="no-vertical-padding">
                  <ng-container *ngTemplateOutlet="companiesNames; context: {companies: (negotiation.seller_represented?.length || negotiation.seller.activity.broker ? negotiation.seller_represented : [negotiation.seller])}"></ng-container>
                </td>
              </ng-container>
              <td class="small"
              rowspan="2"
              *ngIf="!hideColumns.includes('product')">
                <span class="clamp-2">{{ negotiation.order.product.name }}</span>
              </td>
              <td headers="loc" class="hidden-xs hidden-sm small"
              rowspan="2"
              *ngIf="company.market.configuration.location.enabled">
                <span class="clamp-2">{{ negotiation.proposal.business_detail.delivery.locations |locationString }}</span>
              </td>
              <td headers="port" class="hidden-xs hidden-sm small"
              rowspan="2"
              *ngIf="company.market.configuration.order.business_detail.port.enabled">
                {{ countryAndPort(negotiation) }}
              </td>
              <td class="small sap hidden-xs hidden-sm text-right"
              rowspan="2"
              *ngIf="!hideColumns.includes('contract')">
                <ng-container *ngIf="negotiation.contracts?.length">
                  <div *ngFor="let c of negotiation.contracts">
                    <samp><a (click)="contractModal.show(c.id)">{{ c.reference}}</a></samp>
                    <button type="button"
                    *ngIf="editorAccount && !hideColumns.includes('linker')" class="show-onhover btn btn-link btn-xs"
                    title="{{ 'LINKER.UNLINK'|translate }}"
                    (onConfirm)="contractLinker.unlink(c)"
                    [confirm-options]="{
                      title: 'LINKER.UNLINK_TITLE'|translate,
                      body: 'LINKER.UNLINK_BODY'|translate:{negotiation: negotiation.id, contract: c.reference},
                      action: 'LINKER.YES_UNLINK'|translate
                    }"><i class="material-symbols-rounded">link_off</i></button>
                  </div>
                </ng-container>
                <button type="button"
                *ngIf="!hideColumns.includes('linker') &&
                negotiation.counterpartiesOf(company.id)[0].id &&
                !negotiation.contracts?.length &&
                editorAccount" class="show-onhover btn btn-link btn-xs" title="{{ 'LINKER.TITLE'|translate }}"
                (click)="contractLinker.link(negotiation.order.product, {buyer: negotiation.buyer_represented[0] || negotiation.buyer, seller: negotiation.seller_represented[0] || negotiation.seller}, {negotiation: negotiation})"><i class="material-symbols-rounded">link</i></button>
              </td>
              <td class="sap hidden-xs hidden-sm small"
              rowspan="2"
              *ngIf="atLeastOneExternalReference">
                <div *ngIf="negotiation.data_external.reference?.id" class="flex-center-distributed">
                  <samp>{{ negotiation.data_external.reference.id }}</samp>
                  <button type="button"
                  class="show-onhover btn btn-link btn-xs" title="{{ 'NEGOTIATION_ACTIONS.EXTERNAL_ID'|translate }}"
                  *ngIf="editorAccount"
                  (click)="referencePicker.show(negotiation)"><i class="material-symbols-rounded">edit</i></button>
                </div>
              </td>
              <td class="sap hidden-xs small"
              rowspan="2"
              *ngIf="company.market.configuration.order.business_detail.delivery.delivery_type.show_in_lists">
                {{ negotiation.proposal.business_detail.delivery.delivery_type?.short_name || negotiation.proposal.business_detail.delivery.delivery_type?.name }}
              </td>
              <td headers="ton" class="text-right dlb drb"
              rowspan="2">
                <quantity-control [quantity]="negotiation.proposal.business_detail.quantity" [compact]="true"></quantity-control>
              </td>
              <td class="sap"
              rowspan="2">
                <date-range
                [from]="negotiation.proposal.business_detail.delivery.date_from"
                [to]="negotiation.proposal.business_detail.delivery.date_to"></date-range>
              </td>
              <td class="sap"
              rowspan="2">
                <div class="price-box booked-bg">
                  <div class="type"
                  *ngIf="negotiation.counterpartiesOf(company.id)[0].id"
                  [class.buy]="negotiation.buyers_id.includes(company.id)"
                  [class.sell]="negotiation.sellers_id.includes(company.id)">{{
                    (negotiation.buyers_id.includes(company.id) ? 'COMPANY_TRADED_VIEW.BOUGHT': 'COMPANY_TRADED_VIEW.SOLD')|translate
                  }}</div>
                  <div class="value">
                    <price-display [price]="negotiation.proposal.business_detail.price" [market]="company.market" [withoutYear]="true"></price-display>
                  </div>
                </div>
              </td>
              <td class="sap text-right small" *ngIf="!hideColumns.includes('slots')"
              rowspan="2">
                <ag-slots-details
                [batches]="negotiation.batches"></ag-slots-details>
              </td>
              <td class="sap" *ngIf="!hideColumns.includes('fixations_data')"
              rowspan="2">
                <ng-container *ngIf="negotiation.contracts[0]; let contract">
                  <div class="flex-center-distributed fixation-data">
                    <div *ngIf="contract.fixations_data?.period.to">
                      <div class="micro text-muted">{{ 'CONTRACTS.FIXATION_DEADLINE'|translate }}</div>
                      <date-md class="small" [date]="contract.fixations_data.period.to" [style]="true"></date-md>
                    </div>
                    <progress-circle
                    *ngIf="negotiation.proposal.business_detail.price.type === 'to_be_fixed'"
                    label="{{ 'CONTRACTS.FIXED'|translate }}"
                    [progress]="contract.fixations_data?.percentage"></progress-circle>
                  </div>
                </ng-container>
              </td>
              <td *ngIf="!hideColumns.includes('unloads')" class="sap text-center dlb"
              rowspan="2">
                <progress-circle
                label="{{ 'COMPANY_TRADED_VIEW.UNLOADED'|translate }}"
                [progress]="(negotiation.summary?.applied_volume && negotiation.contracts[0].quantity) ? (negotiation.summary.applied_volume.value / negotiation.contracts[0].quantity.value) : 0"></progress-circle>
              </td>
              <td *ngIf="!hideColumns.includes('balance')" class="sap text-center dlb"
              rowspan="2">
                <progress-circle
                label="{{ 'COMPANY_TRADED_VIEW.LIQUIDATED'|translate }}"
                [progress]="negotiation.summary ? negotiation.summary.liquidated_volume / negotiation.contracts[0].quantity.value : 0"></progress-circle>
              </td>
              <td *ngIf="!hideColumns.includes('actions')" class="sap hidden-xs dlb"
              rowspan="2"
              (mouseenter)="pop.hide()">
                <negotiation-actions
                [negotiation]="negotiation"
                [company]="company"
                (saveReference)="reload()">
                </negotiation-actions>
              </td>
              <td class="sap text-center"
              rowspan="2"
              *ngIf="showUnlink && editorAccount">
                <button type="button"
                class="btn btn-link btn-xs show-onhover"
                title="{{ 'LINKER.UNLINK'|translate }}"
                (click)="unlink.emit(negotiation)"><i class="material-symbols-rounded">link_off</i></button>        
              </td>
            </tr>
            <tr class="tr-align-center">
              <ng-container *ngIf="!company.activity.broker">
                <td class="td-empty no-vertical-padding visible-xs" colspan="2"><ng-container *ngTemplateOutlet="labels; context: {negotiation: negotiation}"></ng-container></td>
                <td class="td-empty no-vertical-padding visible-sm" colspan="3"><ng-container *ngTemplateOutlet="labels; context: {negotiation: negotiation}"></ng-container></td>
                <td class="td-empty no-vertical-padding hidden-xs hidden-sm" colspan="4"><ng-container *ngTemplateOutlet="labels; context: {negotiation: negotiation}"></ng-container></td>
              </ng-container>
              <ng-container *ngIf="company.activity.broker">
                <td class="td-empty no-vertical-padding visible-xs" colspan="3"><ng-container *ngTemplateOutlet="labels; context: {negotiation: negotiation}"></ng-container></td>
                <td class="td-empty no-vertical-padding hidden-xs" colspan="4"><ng-container *ngTemplateOutlet="labels; context: {negotiation: negotiation}"></ng-container></td>
              </ng-container>
            </tr>
          </ng-container>
        </ng-container>
      </tbody>
    </table>
  </div>

  <!-- No data -->
  <div class="no-data" *ngIf="negotiations && negotiations?.length === 0">
    <i class="material-symbols-rounded">not_interested</i>
    {{ 'COMPANY_TRADED_VIEW.NO_RECORDS'|translate }}
  </div>
</div>

<ag-reference-number-picker
#referencePicker="agReferenceNumberPicker"
[company-id]="company.id"
[document-type]="company.market.configuration.order.contract.type"
(saveReference)="forceReload()"></ag-reference-number-picker>

<ng-template #popoverTemplate let-negotiation="negotiation">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-4" *ngFor="let prop of negotiation.props">
        <table-detail [header]="prop.name">{{ prop.value }}</table-detail>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #companiesNames let-companies="companies">
  <span *ngIf="companies.length; else none"
  class="truncate"
  title="{{ companies|listFormatter }}">
    <b>
      <span [class.highlighted-text]="companies[0].id === company.id">{{ companies[0].name }}</span>
      <small *ngIf="companies.length > 1">&nbsp;(+{{ companies.length-1 }})</small>
    </b>
  </span>
</ng-template>

<ng-template #none>
  <span class="text-muted no-select">&#8212;</span>
</ng-template>

<ng-template #labels let-negotiation="negotiation">
  <span *ngIf="negotiation.proposal.business_detail.sustainable"
  class="ag-label label-success"><span class="glyphicon glyphicon-leaf" aria-hidden="true"></span> {{ 'ORDER_FORM.PRODUCT.SUSTAINABLE'|translate }}</span>
  <span *ngIf="negotiation.times_booked > 0 && negotiation.status.id !== 7"
  class="ag-label label-warning"><span class="glyphicon glyphicon-eye-open" aria-hidden="true"></span> {{ 'GLOBAL.REVIEWING'|translate }}</span>
  <span *ngFor="let label of negotiation.labels"
  class="ag-label" [style.backgroundColor]="label.color">{{ label.name }}</span>
</ng-template>
<ag-envelopes-modals #envelopesModals
[company]="company"></ag-envelopes-modals>

<div class="ag-container">
  <div class="ag-col-primary">
    <div class="container">
      <div class="content-box">
        <div class="loading" *ngIf="loading || !envelope; else content">
          <spinner message="{{ 'GLOBAL.LOADING'|translate }}"></spinner>
        </div>

        <ng-template #content>
          <div class="ribbon ribbon-right ribbon-sm"
          *ngIf="[30, 40].includes(envelope.status.id)"
          [class.ribbon-info]="envelope.status.id === 30"
          [class.ribbon-danger]="envelope.status.id === 40">
            <!-- <span *ngIf="envelope.status.id === 20">{{ 'SIGNATURES.STATUS.PENDING'|translate }}</span> -->
            <span *ngIf="envelope.status.id === 30">{{ 'SIGNATURES.STATUS.COMPLETED'|translate }}</span>
            <span *ngIf="envelope.status.id === 40">{{ 'SIGNATURES.STATUS.CANCELED'|translate }}</span>
          </div>

          <ol class="breadcrumb">
            <li><a [routerLink]="['../']"
              [queryParams]="{ order_by: '-updated_at' }">{{ 'COMPANY.SIGNATURES'|translate }}</a></li>
            <li class="active">{{ 'SIGNATURES.ENVELOPE'|translate: {id: envelope.id} }}</li>
          </ol>

          <div class="ag-header sticky">
            <div class="ag-header-title">
              <h3>{{ envelope.subject }}
                <span>
                  <ag-assignees-manager
                  *ngIf="company?.id === envelope.company.id"
                  [entity]="envelope.entity"
                  [entity-id]="envelope.id"
                  [company]="company"></ag-assignees-manager>
                </span>
              </h3>
            </div>
            <div class="ag-header-actions">
              <div class="btn-toolbar">
                <div class="btn-group">
                  <button type="button"
                  class="btn btn-link hidden-xs"
                  [useExistingCss]="true"
                  printSectionId="agree-main"
                  ngxPrint><span class="material-symbols-rounded">print</span></button>
                  <button type="button"
                  class="btn btn-link"
                  [class.disabled]="envelope?.company?.id !== company?.id ||
                  [30, 40].includes(envelope?.status.id)"
                  (click)="envelopesModals.cancel(envelope)">{{ 'GLOBAL.CANCEL'|translate }}</button>
                </div>
                <div class="btn-group">
                  <button type="button"
                  class="btn btn-agree"
                  [class.disabled]="[20, 30, 40].includes(envelope?.status.id) ||
                  !envelope.hasToSetSigners(company.id)"
                  (click)="envelopesModals.setSigners(envelope)">
                  <span class="material-symbols-rounded hidden-xs">history_edu</span> {{ 'SIGNATURES.DEFINE_SIGNERS'|translate }}</button>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-xs-12">
              <p class="small">
                {{ 'GLOBAL.DATE'|translate }}:
                <ag-timestamp class="text-muted" [date]="envelope.created_at" [inline]="true"></ag-timestamp>
                <br>
                {{ 'SIGNATURES.LAST_MODIFIED'|translate }}:
                <ag-timestamp class="text-muted" [date]="envelope.updated_at" [inline]="true"></ag-timestamp>
              </p>
            </div>
          </div>

          <div class="row">
            <div
            [class.col-sm-4]="envelope.binded_entities?.length > 0"
            [class.col-sm-6]="!envelope.binded_entities || envelope.binded_entities.length === 0"
            *ngIf="envelope.company">
              <company-table
              header="{{ 'SIGNATURES.SENDER'|translate }}"
              [companies]="[envelope.company]"></company-table>
            </div>
            <div
            [class.col-sm-4]="envelope.binded_entities?.length > 0"
            [class.col-sm-6]="!envelope.binded_entities || envelope.binded_entities.length === 0">
              <company-table
              header="{{ 'SIGNATURES.RECIPIENTS_COMPANIES'|translate }}"
              [companies]="envelope.companies"></company-table>
            </div>
            <div class="col-sm-4"
            *ngIf="envelope.binded_entities?.length > 0">
              <table-detail [header]="'SIGNATURES.LINKED_TO'|translate">
                <ag-envelope-binded
                [company]="company"
                [envelope]="envelope"></ag-envelope-binded>
              </table-detail>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-12">
              <p *ngIf="envelope.status.id === 10">
                <alert type="info" *ngIf="!envelope.hasToSetSigners(company.id)">
                  <span class="material-symbols-rounded">schedule</span> {{ 'SIGNATURES.STATUS.WAITING_SIGNERS'|translate }}
                </alert>  
                <alert type="warning" *ngIf="envelope.hasToSetSigners(company.id)">
                  <span class="material-symbols-rounded blinking">warning</span> {{ 'SIGNATURES.STATUS.DEFINE_SIGNERS'|translate }}
                </alert>
              </p>
              <p *ngIf="envelope.status.id === 20">
                <alert type="success">
                  <span class="material-symbols-rounded">schedule</span> {{ 'SIGNATURES.STATUS.SENT'|translate }}
                </alert>  
              </p>
              <envelope-status [company]="company" [envelope]="envelope"></envelope-status>
            </div>
          </div>

          <trackrecord
          entity="{{ envelope.entity }}"
          [ids]="[envelope.id]"
          [refreshOnEvent]="envelopeUpdated"></trackrecord>
      
        </ng-template>
      </div>
    </div>
  </div>
</div>
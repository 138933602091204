<div class="container">
  <div class="content-box">
    <ol class="breadcrumb">
      <li><a routerLink="../setup">{{ 'GLOBAL.CONFIG_ASSISTANT'|translate }}</a></li>
      <li class="active">{{ 'GLOBAL.DELIVERY_PLACES'|translate }}</li>
    </ol>
    <div class="ag-header sticky">
      <div class="ag-header-title">
        <h3 *ngIf="company">{{ 'COMPANY_LOCATIONS_VIEW.TITLE'|translate:{company:company?.name} }}</h3>
        <h3 *ngIf="!company">{{ 'GLOBAL.DELIVERY_PLACES'|translate }}</h3>
      </div>
      <div class="ag-header-actions">
        <button type="button"
        class="btn btn-agree" (click)="locationPicker.show()"><i
            class="material-symbols-rounded hidden-xs hidden-sm">add</i>
          {{ 'COMPANY_LOCATIONS_VIEW.ADD_LOCATION_BUTTON'|translate }}</button>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12">
        <p>{{ 'COMPANY_LOCATIONS_VIEW.HEADING'|translate }}</p>
        <p class="text-info small" [innerHtml]="'COMPANY_LOCATIONS_VIEW.INFO'|translate"></p>
      </div>
    </div>

    <div class="loading" *ngIf="!locations">
      <spinner message="{{ 'GLOBAL.LOADING'|translate }}"></spinner>
    </div>

    <div class="row" *ngIf="locations">
      <div class="col-xs-12">

        <div class="no-data" *ngIf="locations?.length === 0">
          <i class="material-symbols-rounded">not_interested</i>
          {{ 'COMPANY_LOCATIONS_VIEW.LOCATION_NOT_DEFINED'|translate }}
        </div>

        <table class="table table-hover" [sortable]="locations" #tableSortable="tableSortable"
          *ngIf="locations?.length > 0">
          <!-- <caption></caption> -->
          <thead>
            <tr>
              <th sortBy="zoneName" *ngIf="company?.market.configuration.location.zone.enabled">
                {{ 'COMPANY_LOCATIONS_VIEW.ZONE'|translate }}</th>
              <th sortBy="locationName">{{ 'COMPANY_LOCATIONS_VIEW.LOCATION'|translate }}</th>
              <th class="sap"></th>
            </tr>
          </thead>
          <tbody>
            <!-- Company row -->
            <tr class="tr-align-center" *ngFor="let location of tableSortable.collection">
              <th *ngIf="company?.market.configuration.location.zone.enabled">
                {{ location.zone ? location.zoneName : '-' }}
              </th>
              <td>
                <span *ngIf="location.location">{{ location.locationName }}</span>
                <span *ngIf="!location.location"
                  class="text-muted small">{{ 'COMPANY_LOCATIONS_VIEW.BUYER_OPTION'|translate }}</span>
              </td>
              <td class="sap">
                <a title="{{ 'COMPANY_LOCATIONS_VIEW.DELETE_CONFIRM.MODAL_TITLE'|translate }}"
                (onConfirm)="delete(location)"
                [confirm-options]="{
                  title: 'COMPANY_LOCATIONS_VIEW.DELETE_CONFIRM.TITLE'|translate,
                  action: 'GLOBAL.YES_DELETE'|translate
                }"
                [confirm-close]="deletedLocation">
                  <i class="material-symbols-rounded">delete</i>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<ag-location-picker #locationPicker="agLocationPicker"
[ngModel]="newLocation"
[market]="company?.market"
(ngModelChange)="newLocation = $event; add()"
[hidden]="true"
title="{{ 'COMPANY_LOCATIONS_VIEW.ADD_LOCATION_TITLE'|translate:{company:company?.name} }}"></ag-location-picker>
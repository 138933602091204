<div class="modal-header">
  <button type="button" class="close" (click)="cancel()" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
  <h4 class="modal-title">{{ 'GLOBAL.ARE_YOU_SURE'|translate }}</h4>
</div>
<div class="modal-body">
  <p>{{ 'MAX_QUANTITY.DESCRIPTION'|translate }}</p>
  <div class="table-responsive">
    <table class="table">
      <thead>
        <tr>
          <th></th>
          <th class="text-right">{{ 'GLOBAL.QUANTITY'|translate }}</th>
          <th class="text-right">{{ 'GLOBAL.PERCENTAGE'|translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ 'MAX_QUANTITY.MAX_QUANTITY'|translate }}</td>
          <td class="text-right">{{ negotiation.order.business_detail.quantity.value|number:undefined:currentLang }}
          {{ negotiation.order.business_detail.quantity.unit.abbrev }}</td>
          <td class="text-right"></td>
        </tr>
        <tr>
          <td>{{ 'MAX_QUANTITY.BOOKED'|translate }}</td>
          <td class="text-right">{{ negotiation.order.totalBooked|number:undefined:currentLang }}
          {{ negotiation.order.business_detail.quantity.unit.abbrev }}</td>
          <td class="text-right">{{ 100*negotiation.order.totalBooked/negotiation.order.business_detail.quantity.value|number:'1.2-2':currentLang }} %</td>
        </tr>
        <tr>
          <td>{{ 'MAX_QUANTITY.THIS_NEGOTIATION'|translate }}</td>
          <td class="text-right">{{ negotiation.proposal.business_detail.quantity.value|number:undefined:currentLang }}
          {{ negotiation.proposal.business_detail.quantity.unit.abbrev }}</td>
          <td class="text-right">{{ 100*negotiation.proposal.business_detail.quantity.value/negotiation.order.business_detail.quantity.value|number:'1.2-2':currentLang }} %</td>
        </tr>
        <tr>
          <th>{{ 'GLOBAL.TOTAL'|translate }}</th>
          <td class="text-right text-danger"><b>{{ negotiation.order.totalBooked + negotiation.proposal.business_detail.quantity.value|number:undefined:currentLang }}
          {{ negotiation.order.business_detail.quantity.unit.abbrev }}</b></td>
          <td class="text-right text-danger"><b>{{ 100*(negotiation.order.totalBooked+negotiation.proposal.business_detail.quantity.value)/negotiation.order.business_detail.quantity.value|number:'1.2-2':currentLang }} %</b></td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="modal-footer">
  <button (click)="cancel()" type="button" class="btn btn-link" data-dismiss="modal">{{ 'GLOBAL.CANCEL'|translate }}</button>
  <button (click)="confirm()" type="button" class="btn btn-agree">{{ 'MAX_QUANTITY.CONTINUE'|translate }}</button>
</div>

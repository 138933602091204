<ng-container *ngIf="data; else loadingIndicator">
  <div *ngIf="data.all?.total > 0; else noData" class="dashboard m-b2">
    <ng-container *ngTemplateOutlet="pie; context: {
      title: 'GLOBAL.NEGOTIATED_VOLUME'|translate|uppercase,
      data: [
        data.buy.volume[0]?.total || 0,
        data.sell.volume[0]?.total || 0
      ],
      labels: ['COMPANY_TRADED_VIEW.BOUGHT'|translate, 'COMPANY_TRADED_VIEW.SOLD'|translate]
    }"></ng-container>

    <ng-container *ngTemplateOutlet="pie; context: {
      title: 'DASHBOARDS.VOLUME_TBF'|translate|uppercase,
      data: [
        data.all.volume[0]?.fixed || 0,
        (data.all.volume[0]?.total || 0) - (data.all.volume[0]?.fixed || 0)
      ],
      labels: ['CONTRACTS.FIXED'|translate, 'DASHBOARDS.TO_BE_FIXED'|translate]
    }"></ng-container>

    <ng-container *ngTemplateOutlet="pie; context: {
      title: 'TRUCKS.TITLE'|translate|uppercase,
      data: [
        data.all.volume[0]?.applied || 0,
        (data.on_date.volume[0]?.total || 0) - (data.on_date.volume[0]?.applied || 0),
        (data.to_expire.volume[0]?.total || 0) - (data.to_expire.volume[0]?.applied || 0),
        (data.expired.volume[0]?.total || 0) - (data.expired.volume[0]?.applied || 0)
      ],
      labels: ['DASHBOARDS.DELIVERED'|translate, 'DASHBOARDS.PENDING'|translate, 'DASHBOARDS.PENDING_TO_EXPIRE'|translate, 'DASHBOARDS.EXPIRED_VOLUME'|translate]
    }"></ng-container>

    <div class="kpi">
      <ng-container *ngTemplateOutlet="kpi; context: {
        key: 'DASHBOARDS.EXPIRED_VOLUME'|translate,
        value: ((data.expired.volume[0]?.total || 0) - (data.expired.volume[0]?.applied || 0))|number:'1.0-0',
        unit: data.expired.volume[0]?.unit.abbrev,
        footer: 'DASHBOARDS.IN_CONTRACTS'|translate: {quantity: ((data.expired.total || 0)|number)}
      }"></ng-container>
      <ng-container *ngTemplateOutlet="kpi; context: {
        key: 'DASHBOARDS.VOLUME_TO_EXPIRE'|translate,
        value: ((data.to_expire.volume[0]?.total || 0) - (data.to_expire.volume[0]?.applied || 0))|number:'1.0-0',
        unit: data.to_expire.volume[0]?.unit.abbrev,
        footer: 'DASHBOARDS.IN_CONTRACTS'|translate: {quantity: ((data.to_expire.total || 0)|number)}
      }"></ng-container>
      <ng-container *ngTemplateOutlet="kpi; context: {
        key: 'COMPANY_TRADED_VIEW.LIQUIDATED'|translate,
        value: data.all.invoiced|number,
        unit: 'GLOBAL.CONTRACTS'|translate,
        footer: ('SLOTS.TABLE_HEADER.PENDING_SLOTS'|translate) + ': ' + ((data.all.total - data.all.invoiced)|number)
      }"></ng-container>
      <ng-container *ngTemplateOutlet="kpi; context: {
        key: 'DASHBOARDS.DELIVERED_WITHOUT_INVOICED'|translate,
        value: data.all.volume[0]?.total_applied_without_invoice|number,
        unit: data.all.volume[0]?.unit.abbrev,
        footer: 'DASHBOARDS.IN_CONTRACTS'|translate: {quantity: ((data.all.total_applied_without_invoice || 0)|number)}
      }"></ng-container>
    </div>
  </div>
</ng-container>

<!-- https://www.chartjs.org/docs/latest/ -->
<ng-template #pie let-title="title" let-data="data" let-labels="labels">
  <ng-container *ngIf="!allZero(data)">
    <div class="chart">
      <canvas baseChart type="pie" [data]="{
        datasets: [
          {
            data: data,
            backgroundColor: colors
          }
        ],
        labels: labels
      }"
      [options]="{
        title: {
          display: true,
          text: title,
          fontFamily: 'Lato',
          fontSize: 9
        },
        tooltips: {
          bodyFontFamily: 'Lato'
        },
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          position: 'bottom',
          labels: {
            boxWidth: 12
          }
        }
      }"></canvas>
    </div>
  </ng-container>
</ng-template>

<ng-template #kpi let-key="key" let-value="value" let-unit="unit" let-footer="footer">
  <table-detail [header]="key">
    <div class="bigger">
      {{ value }}
      <span *ngIf="unit" class="currency">{{ unit }}</span>
      <div *ngIf="footer" class="micro text-muted">{{ footer }}</div>
    </div>
  </table-detail>
</ng-template>

<ng-template #noData>
  <!-- No data -->
  <p class="no-data">
    <i class="material-symbols-rounded">not_interested</i>
    {{ 'FUTURES.NO_DATA'|translate }}
  </p>
</ng-template>

<ng-template #loadingIndicator>
  <div class="loading">
    <spinner message="{{ 'GLOBAL.LOADING'|translate }}"></spinner>
  </div>
</ng-template>
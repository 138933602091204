<ng-template #modalTemplate>
  <!-- modal -->
  <div class="modal-header">
    <button type="button" class="close" [disabled]="processing" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">{{ 'LINKER.TITLE'|translate }}</h4>
  </div>
  <div class="modal-body">
    <p class="text-muted" [innerHtml]="'LINKER.DESCRIPTION_NEGOTIATION'|translate"></p>
    <h5>
      <span class="hidden-xs">{{ (negotiation.status.id === 7 ? 'GLOBAL.TRADE' : 'GLOBAL.COUNTERORDER')|translate }}</span>
      #{{ negotiation.id }}:
      {{ negotiation.order.product.name || 'GLOBAL.NOT_AVAILABLE_ABBR'|translate }}
      <button type="button"
      class="btn btn-link btn-xs" (click)="show_details = !show_details" [disabled]="processing"
      [innerHtml]="(show_details ? 'GLOBAL.TOGGLE_HIDE' : 'GLOBAL.TOGGLE_SHOW')|translate"></button>
    </h5>
    <div [collapse]="!show_details" [isAnimated]="true">
      <counterparties *ngIf="negotiation.company?.id" [negotiation]="negotiation" [company]="company"></counterparties>
      <proposal-detail
      [proposal]="negotiation.proposal"
      [negotiation]="negotiation"
      [order]="negotiation.order"
      [condensed]="true"
      [editable]="false"
      [formMode]="false"
      [hideUndefined]="true"></proposal-detail>
    </div>
    <div [collapse]="show_details" [isAnimated]="true">
      <ng-container *ngTemplateOutlet="negotiationsTable;context:{
        negotiations: [negotiation],
        simple: true
      }"></ng-container>
    </div>
    <div class="row">
      <connector [linked]="selectedNegotiation"></connector>
    </div>

    <ng-container *ngIf="selectedNegotiation">
      <h5>{{ 'LINKER.SELECTED_TRADE'|translate }}
        <button type="button"
        class="btn btn-link btn-xs" (click)="selectedNegotiation = undefined"
        [disabled]="processing">{{ 'GLOBAL.CLEAR_SELECTION'|translate }}</button>
      </h5>
      <!-- <counterparties [negotiation]="selectedNegotiation" [company]="company"></counterparties> -->
      <!-- <proposal-detail
      [proposal]="selectedNegotiation.proposal"
      [negotiation]="selectedNegotiation"
      [order]="selectedNegotiation.order"
      [condensed]="true"
      [editable]="false"
      [formMode]="false"
      [hideUndefined]="true"></proposal-detail> -->
      <ng-container *ngTemplateOutlet="negotiationsTable;context:{
        negotiations: [selectedNegotiation]
      }"></ng-container>
    </ng-container>
    <ng-container *ngIf="!selectedNegotiation">
      <h5>{{ 'LINKER.TRADES'|translate }}</h5>
      <ng-container *ngIf="availableNegotiations; else loading">
        <ng-container *ngIf="availableNegotiations.length; else noNegotiations">
          <p class="text-muted" [innerHtml]="'LINKER.SELECT_TRADE_NEGOTIATION'|translate"></p>
          <ng-container *ngTemplateOutlet="negotiationsTable;context:{
            negotiations: availableNegotiations
          }"></ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
  <div class="modal-footer">
    <button type="button" (click)="closeModal()" class="btn btn-link" [disabled]="processing"
    data-dismiss="modal">{{ 'GLOBAL.CANCEL'|translate }}</button>
    <button type="button"
    (click)="linkSelected(selectedNegotiation)" class="btn btn-agree"
    [disabled]="processing || !selectedNegotiation">{{ 'LINKER.LINK_NEGOTIATION'|translate }}</button>
  </div>
</ng-template>

<ng-template #loading>
  <div class="loading">
    <spinner message="{{ 'GLOBAL.LOADING'|translate }}"></spinner>
  </div>
</ng-template>

<ng-template #noNegotiations>
  <div class="text-danger" [innerHtml]="'LINKER.NO_TRADES'|translate"></div>
</ng-template>

<ng-template #negotiationsTable let-negotiations="negotiations" let-simple="simple">
  <div class="horizontal-scroller">
    <table *ngIf="negotiations?.length > 0" class="table table-condensed" sortable defaultSortBy="-booking_date" #tableSortable="tableSortable">
      <thead *ngIf="!simple">
        <tr class="tr-align-center">
          <th class="sap" sortBy="booking_date">{{ 'GLOBAL.DATE'|translate }}</th>
          <th class="sap" sortBy="id"
          *ngIf="company.market.configuration.location.enabled">{{ 'GLOBAL.TRADE'|translate }}</th>
          <th sortBy="proposal.locationString">{{ 'GLOBAL.DELIVERY_PLACES'|translate }}</th>
          <th class="sap" sortBy="data_external.reference.id">
            <abbr tooltip="{{ 'GLOBAL.EXTERNAL_REFERENCE'|translate }}" [adaptivePosition]="false">{{ 'GLOBAL.EXTERNAL_REFERENCE_ABBR'|translate }}</abbr>
          </th>
          <th class="dlb drb" sortBy="proposal.business_detail.quantity.value">
            <abbr title tooltip="{{ 'GLOBAL.QUANTITY'|translate }}" [adaptivePosition]="false">{{ 'GLOBAL.QUANTITY_ABBR'|translate }}</abbr>
          </th>
          <th class="sap text-center" sortBy="proposal.business_detail.delivery.date_from">{{ 'GLOBAL.PERIOD'|translate }}</th>
          <th class="sap" sortBy="proposal.business_detail.price.value">
            {{ 'GLOBAL.PRICE'|translate }}
            <ng-container *ngIf="company.market.quantity_units.length === 1">/{{ company.market.quantity_units[0].abbrev }}</ng-container>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr class="tr-align-center"
        [class.clickable]="!selectedNegotiation && !simple"
        [class.selected]="selectedNegotiation?.id === n.id"
        (click)="(!selectedNegotiation && !simple) ? selectedNegotiation = n : null"
        *ngFor="let n of negotiations|orderBy: tableSortable.sortBy;">
          <td class="sap micro">
            <ag-timestamp [date]="n.booking_date"></ag-timestamp>
          </td>
          <td class="sap">
            <samp class="small hidden-xs hidden-sm">#{{ n.id }}:</samp>
            <b>
              <ng-container *ngIf="n.order.type.id === 8"> {{ 'GLOBAL.CERTIFICATE_DEPOSIT'|translate }}</ng-container>
              {{ n.counterpartiesOf(company.id)[0].name }}
              <small *ngIf="n.counterpartiesOf(company.id).length > 1">(+{{ n.counterpartiesOf(company.id).length-1 }})</small>
            </b>
          </td>
          <td class="small"
          *ngIf="company.market.configuration.location.enabled">
            <span class="clamp-2">{{ n.proposal.locationString }}</span>
          </td>
          <td class="sap small">
            <samp *ngIf="n.data_external?.reference?.id">{{ n.data_external.reference.id }}</samp>
          </td>
          <td class="text-right dlb drb sap">
            <quantity-control [quantity]="n.proposal.business_detail.quantity" [compact]="true"></quantity-control>
          </td>
          <td class="sap">
            <date-range
            [from]="n.proposal.business_detail.delivery.date_from"
            [to]="n.proposal.business_detail.delivery.date_to"></date-range>
          </td>
          <td class="sap">
            <div class="price-box booked-bg">
              <div class="type"
              *ngIf="n.counterpartiesOf(company.id)[0].id"
              [class.buy]="n.buyers_id.includes(company.id)"
              [class.sell]="n.sellers_id.includes(company.id)">{{
                (n.buyers_id.includes(company.id) ? 'COMPANY_TRADED_VIEW.BOUGHT': 'COMPANY_TRADED_VIEW.SOLD')|translate
              }}</div>
              <div class="value">
                <price-display [price]="n.proposal.business_detail.price" [market]="company.market" [withoutYear]="true"></price-display>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</ng-template>
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Token } from '../models/token.model';
import { User } from '../models/user.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  private baseUrl: string = '/:apiUsers';
  private meUrl: string = this.baseUrl + '/me';

  constructor(private http: HttpClient) { }

  public getUserByToken(token: Token['access_token']): Observable<{ user: User }> {
    const headers = new HttpHeaders({ authorization: `Bearer ${token}` });

    return this.http.get<{ user: User }>(this.meUrl, { headers })
  }
}

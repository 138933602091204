/**
 * Webhooks are HTTP POST calls defined by the [[Company|Companies]] that are
 * made on certain events.
 * 
 * Through this mechanism an external system is notified of events that occur
 * in Agree.
 */
export class Webhook {
  /** Agree's internal unique identifier. */
  readonly id: string;
  active?: boolean;
  url: string;

  constructor(data: Partial<Webhook> = {}) {
    this.active = true;
    
    Object.assign(this, data);
  }
}

/** Payload of HTTP POST calls made to [[Webhook|Webhooks]]. */
// class WebhookEvent {
//   /** Agree's internal unique identifier. */
//   readonly id: number;
//   /** Event date. */
//   readonly date: Date;
//   /** Event slug. e.g. 'NEGOTIATION.BOOKED' */
//   readonly event: string;
//   readonly company_id: number;
//   /** System entity related to the event (optional). */
//   readonly entity?: {
//     type: string;
//     id: number | string;
//   };
// }
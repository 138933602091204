import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ClipboardService } from 'ngx-clipboard';
import { Subscription } from 'rxjs';

import { ClientCredentials } from '../../../../auth/models/oauth.model';
import { OAuthService } from '../../../../auth/services/oauth.service';

@Component({
  selector: 'ag-client-credentials',
  templateUrl: './client-credentials.component.html',
  styleUrls: ['./client-credentials.component.scss']
})
export class ClientCredentialsComponent implements OnInit, OnDestroy {

  @ViewChild('createdCredentialsModal', { static: true }) private readonly createdCredentialsModal: TemplateRef<any>;

  @Input() private companyId: number;

  private subscriptions: Subscription[] = [];
  private modalRef: BsModalRef;
  public loading: boolean = true;
  public processing: boolean = false;
  public clientCredentials: ClientCredentials[];
  public createdCredential: ClientCredentials;
  public typeArr: { [key: string]: 'password' | 'text' } = {};

  constructor(
    private modalService: BsModalService,
    private oauthService: OAuthService,
    public clipboardService: ClipboardService

  ) { }

  ngOnInit(): void {
    this.loadData();
  }

  private loadData(): void {
    this.loading = true;

    this.subscriptions.push(this.oauthService.getCredentials(this.companyId).subscribe(credentials => {
      this.loading = false;
      this.processing = false;
      this.clientCredentials = credentials;
    }))
  }

  public create(): void {
    this.processing = true;

    this.subscriptions.push(
      this.oauthService.createCredentials(this.companyId).subscribe(credential => {
        this.show(credential);
        this.loadData();
      })
    );
  }

  public delete(credential: ClientCredentials): void {
    this.processing = true;

    this.subscriptions.push(
      this.oauthService.deleteCredential(this.companyId, credential).subscribe(() => {
        this.loadData();
      })
    );
  }

  // Modal methods
  private openModal(template: TemplateRef<any>, credential: ClientCredentials): void {
    this.modalRef = this.modalService.show(template, { class: '', ignoreBackdropClick: true });
    this.createdCredential = credential;

    this.subscriptions.push(this.modalRef.onHide.subscribe((reason: string) => {
      // Reset all values
      this.processing = false;
      this.modalRef = undefined;
    }))
  }

  public closeModal(onHide: Function = null): void {
    if (this.modalRef) {
      this.modalRef.hide();
      this.processing = false;
      if (onHide) this.subscriptions.push(this.modalRef.onHide.subscribe(onHide))
    } else {
      if (onHide) onHide();
    }
  }

  public show(credential): void {
    this.openModal(this.createdCredentialsModal, credential);
  }

  public toggleType(key: string): void {
    this.typeArr[key] = this.typeArr[key] === 'password' ? 'text' : 'password';
  }

  public getType(key: string): string {
    return this.typeArr[key] ? this.typeArr[key] : this.typeArr[key] = 'password';
  }

  ngOnDestroy(): void {
    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}

import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { Company } from '../../../../../models/company.model';
import { GroupBy } from '../../../../../models/group-by.model';
import { Pagination, parsePagination } from '../../../../../models/pagination.model';
import { Product } from '../../../../../models/product.model';
import { CompanyService } from '../../../../../services/company.service';
import { ComponentCommService } from '../../../../../services/component-comm.service';
import { IntercomService } from '../../../../../services/intercom.service';
import { FilterSetComponent } from '../../../../../ui/components/filter-set/filter-set.component';
import { TableFilters } from '../../../../../utilities/table-filters';
import { qualityToString } from '../../../../pipes/quality.pipe';
import { Order } from '../../models/order.model';
import { OrderService } from '../../services/order.service';

// declare const walkthrough: any;

@Component({
  selector: 'app-my-operations',
  templateUrl: './my-operations.component.html',
  styleUrls: ['./my-operations.component.scss']
})
export class MyOperationsComponent extends TableFilters implements OnInit, OnDestroy {

  @ViewChild('filterSet', { static: true }) public readonly selectedFilters: FilterSetComponent;

  public company: Company;
  public currentPage: number = 1;
  /** Flag used to indicate if the component is loading information. */
  public loading: boolean = true;
  public ordersByProduct: GroupBy<Product, Order>[] = [];
  public paginationData: Pagination;
  private pusherSubscription: Subscription;

  constructor(
    private companyService: CompanyService,
    private componentComm: ComponentCommService,
    private intercomService: IntercomService,
    private orderService: OrderService,
    public router: Router,
    public route: ActivatedRoute,
    private translateService: TranslateService
  ) {
    super(route, router);
  }

  ngOnInit(): void {
    this.componentComm.emit({ name: 'app-title', title: 'COMPANY.MY_OPERATIONS' });

    // walkthrough('my_operations', false, this.translateService);

    this.subscriptions.push(this.companyService.watch().subscribe(company => {
      if (!company) return;

      this.company = company;

      this.intercomService.track('my_operations-visited');

      // Default mode
      this.onFiltersChange = this.loadData;
      this.setupFilters();
    }));
  }

  private loadData(): void {
    this.loading = true;
    if (this.pusherSubscription) this.pusherSubscription.unsubscribe();

    this.pusherSubscription = this.orderService.watchOperatingOrders(this.company.id, this.filters).subscribe(response => {
      this.paginationData = parsePagination(response.headers);
      let ordersByProduct = response.body;
      this.parseOrdersResponse(ordersByProduct);
    });
  }

  private parseOrdersResponse(ordersByProduct: GroupBy<Product, Order>[]): void {
    this.ordersByProduct = ordersByProduct;

    // TODO: Mover quality string a otro lado
    this.ordersByProduct.forEach(group => {
      group.values.forEach(order => {
        order.quality_string = qualityToString(order.product_detail.quality, group.key);
      });
    });

    this.loading = false;
  }

  public reload(): void {
    if (this.company && !this.loading) {
      this.subscriptions.push(this.orderService.getOperatingOrders(this.company.id, this.filters).subscribe(response => {
        this.paginationData = parsePagination(response.headers);
        let ordersByProduct = response.body;
        this.parseOrdersResponse(ordersByProduct);
      }));
    }
  }

  // TODO: This should be a pipe
  public countryAndPort(order: Order): string {
    return order.product.attributes.country.name + ' / ' + order.business_detail.port.name;
  }

  /** @ignore */
  ngOnDestroy(): void {
    // Unsubscribe from everything
    super.ngOnDestroy();
    if (this.pusherSubscription) this.pusherSubscription.unsubscribe();
  }
}

<ng-template #requestModal>
    <!-- modal -->
    <div class="modal-header">
        <button type="button" class="close" [disabled]="processing" (click)="closeModal()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title">{{ 'MANAGERS.APPLY_TO_BE_MANAGER'|translate }}</h4>
    </div>
    <div class="modal-body">
        <!-- <p [innerHtml]="'TEAM_VIEW.INVITE_MODAL.BODY'|translate"></p> -->
        <form id="invite-form" (ngSubmit)="requestForm.valid && request()" #requestForm="ngForm">
            <!-- Company -->
            <div class="form-group" hasError>
                <label for="company" class="control-label">{{ 'GLOBAL.COMPANY'|translate }}</label>
                <ng-container *ngIf="!selectedCompany; else showCompany">
                    <selectize
                    [maxItems]="1"
                    [searchURL]="companyService.getCompanies(company.id, {
                        excluded_ids: [company.id]
                    })"
                    [(ngModel)]="selectedCompany"
                    name="company"
                    required
                    placeholder="{{ 'GLOBAL.ENTER_COMPANY'|translate }}"
                    [disabled]="processing"></selectize>
                </ng-container>
                <ng-template #showCompany>
                    <div class="editable-pill">
                        <button type="button" class="close" [disabled]="processing"
                            (click)="selectedCompany = undefined"><span aria-hidden="true">&times;</span></button>
                        <company-info [company]="selectedCompany"></company-info>
                    </div>
                </ng-template>

                <span class="glyphicon glyphicon-remove form-control-feedback" aria-hidden="true"></span>
                <error-list></error-list>
            </div>
            <alert type="danger" *ngFor="let error of errors" [dismissible]="true">{{ error|translate }}</alert>

            <!-- Profile -->
            <div class="form-group" hasError>
                <label for="role" class="control-label">{{ 'TEAM_VIEW.INVITE_MODAL.ROLE'|translate }}</label>
                <select class="form-control input-lg has-placeholder"
                [disabled]="processing" id="role" name="role" [(ngModel)]="selectedRole" required>
                    <option [ngValue]="undefined" [selected]="true" [disabled]="true">{{ 'TEAM_VIEW.INVITE_MODAL.SELECT_ROLE'|translate }}</option>
                    <option *ngFor="let role of roles" [ngValue]="role">{{ role.name }}</option>
                </select>
                <span class="glyphicon glyphicon-remove form-control-feedback" aria-hidden="true"></span>
                <error-list></error-list>
                <p class="help-block small" *ngIf="selectedRole?.description">{{ selectedRole.description }}</p>
            </div>

            <alert type="danger" *ngFor="let error of errors" [dismissible]="true">{{ error|translate }}</alert>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" (click)="closeModal()" class="btn btn-link" [disabled]="processing"
            data-dismiss="modal">{{ 'GLOBAL.CANCEL'|translate }}</button>
        <button type="submit" form="invite-form" class="btn btn-agree" [disabled]="processing">{{
            'MANAGERS.REQUEST.SEND'|translate }}</button>
    </div>
</ng-template>
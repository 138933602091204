import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';

import { Account } from '../../../auth/models/account.model';
import { Role } from '../../../auth/models/role.model';
import { User } from '../../../auth/models/user.model';
import { Company } from '../../../models/company.model';
import { ManagerRequest } from '../../../models/manager.model';
import { CompanyService } from '../../../services/company.service';
import { ManagersService } from '../../services/managers.service';

@Component({
  selector: 'ag-managers-request-modal',
  templateUrl: './managers-request-modal.component.html',
  styleUrls: ['./managers-request-modal.component.scss']
})
export class ManagersRequestModalComponent implements OnInit, OnDestroy {

  @ViewChild('requestModal', { static: true }) private readonly requestModal: TemplateRef<any>;

  @Input() public account: Account;
  @Input() public company: Company;
  @Input() public roles: Role[];
  @Input() public user: User;

  @Output() readonly submitted = new EventEmitter();

  // public managerRequest: ManagerRequest;
  // public companies: Company[];

  public selectedCompany: Company;
  public selectedRole: Role;
  /**
   * Flag used to enable/disable UI buttons and links when an API request is in
   * progress.
   */
  public processing: boolean;
  public errors = [];

  private modalRef: BsModalRef;
  private modalSub: Subscription;
  private subscriptions: Subscription[] = [];

  /** @ignore */
  constructor(
    public companyService: CompanyService,
    private managersService: ManagersService,
    private modalService: BsModalService
  ) { }

  /** @ignore */
  ngOnInit(): void {
    // this.getCompanies();
  }

  public show(): void {
    // this.managerRequest = new ManagerRequest();
    this.errors = [];
    this.openModal(this.requestModal);
  }

  public request(): void {
    this.processing = true;
    this.errors = [];

    const account: Account = this.user.accounts.find(account => account.company.id === this.company.id);

    const request = new ManagerRequest({
      company_id: this.selectedCompany.id,
      manager_account_id: account.id,
      manager_role_id: this.selectedRole.id as number
    });

    this.subscriptions.push(
      this.managersService.request(this.company.id, request).subscribe(response => {
        this.closeModal();
        this.submitted.emit();
      }, response => {
        if (typeof response.error.message.ext_company_team_exist !== 'undefined') {
          this.errors.push("TEAM_VIEW.INVITE_MODAL.ALREADY_EXISTS");
        } else {
          this.errors.push("SIGNUP.UNKNOWN_ERROR");
        }
        this.processing = false;
      })
    );
  }

  // private getCompanies(): void {
  //   this.subscriptions.push(
  //     this.companyService.getCounterparts(this.company.id,[],[]).subscribe(companies => this.companies = companies)
  //   )
  // }

  /** Generic Modal trigger. */
  private openModal(template: TemplateRef<any>, c: string = ''): void {
    this.modalRef = this.modalService.show(template, { class: c, ignoreBackdropClick: true });

    this.modalSub = this.modalRef.onHide.subscribe((reason: string) => {
      this.modalSub.unsubscribe();
      this.modalRef = undefined;
      // Reset all values
      this.processing = false;
    });
  }

  /** Closes the most recent opened modal. */
  public closeModal(onHide: Function = null): void {
    this.selectedCompany = null;
    this.selectedRole = null;

    if (this.modalRef) {
      this.modalRef.hide();
      if (onHide) this.modalRef.onHide.subscribe(onHide);
    } else {
      if (onHide) onHide();
    }
  }

  /** @ignore */
  ngOnDestroy(): void {
    this.closeModal();
    
    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { Company } from '../../../../models/company.model';
import { CompanyService } from '../../../../services/company.service';

@Injectable()
export class MarketSummaryResolver {

  constructor(
    private router: Router,
    private companyService: CompanyService
  ) { }

  resolve(
    route: ActivatedRouteSnapshot
  ): Observable<Company> {
    const companyId = parseInt(route.parent.paramMap.get('companyId'));
    return this.companyService.getRequestCompany(companyId).pipe(
      take(1),
      map(company => {
        const has_zones = company.market.configuration.location.zone.enabled;

        if (has_zones) {
          this.router.navigate(['/company/' + companyId + '/market/zone']);
        } else {
          this.router.navigate(['/company/' + companyId + '/market/location']);
        }
        return company;
      })
    );
  }
}

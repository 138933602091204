import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BehaviorSubject, Subscription } from 'rxjs';

import { CompanyCertificate } from '../../../models/company-certificate.model';
import { Company } from '../../../models/company.model';
import { CompanyService } from '../../../services/company.service';
import { ComponentCommService } from '../../../services/component-comm.service';
import { CurrentDateService } from '../../../services/current-date.service';
import { CertificatesService } from '../../services/certificates.service';

@Component({
  selector: 'app-certificates',
  templateUrl: './certificates.component.html',
  styleUrls: ['./certificates.component.scss']
})
export class CertificatesComponent implements OnInit, OnDestroy {

  public certificate_types: any[] = [];
  public certificates: any[] = [];
  public company_certificate: CompanyCertificate;
  public company: Company;
  public entities: any[] = [];
  /**
   * Flag used to enable/disable UI buttons and links when an API request is in
   * progress.
   */
  public processing: boolean; public reloadCertificates: BehaviorSubject<any> = new BehaviorSubject(null);
  public today: Date;

  private modalRef: BsModalRef;
  private modalSub: Subscription;
  private subscriptions: Subscription[] = [];

  constructor(
    private modalService: BsModalService,
    private companyService: CompanyService,
    private certificatesService: CertificatesService,
    private router: Router,
    private localeService: BsLocaleService,
    private componentComm: ComponentCommService,
    private translateService: TranslateService,
    private currentDate: CurrentDateService
  ) {
    this.today = this.currentDate.get();
  }

  private resetCompanyCertificate(): void {
    this.company_certificate = new CompanyCertificate();
    this.company_certificate.certificate_type = null;
    this.company_certificate.entity = null;
  }

  /** @ignore */
  ngOnInit(): void {
    this.localeService.use(this.translateService.currentLang);

    this.resetCompanyCertificate();

    this.componentComm.emit({ name: 'app-title', title: 'CERTIFICATES.TITLE' });

    this.subscriptions.push(this.companyService.watch().subscribe(company => {
      if (!company) return;

      this.company = company;
      if (!this.company.market.configuration.certificates.enabled) {
        this.router.navigateByUrl('/company/' + this.company.id + '/setup');
      }
    }));

    this.subscriptions.push(this.certificatesService.getTypes().subscribe(certificate_types => {
      this.certificate_types = certificate_types;
    }));

    this.subscriptions.push(this.certificatesService.getEntities().subscribe(entities => {
      this.entities = entities;
    }));
  }

  public typeChange(e): void {
    this.certificates = [];

    this.subscriptions.push(this.certificatesService.getByType(this.company_certificate.certificate_type.id).subscribe(certificates => {
      this.certificates = certificates;
      this.company_certificate.certificate = null;
    }));
  }

  submit(): void {
    this.processing = true;
    this.subscriptions.push(this.certificatesService.create(this.company.id, this.company_certificate).subscribe(response => {
      this.closeModal();
      this.reloadCertificates.next(null);
      this.resetCompanyCertificate();
    }));
  }

  /** Generic Modal trigger. */
  public openModal(template: TemplateRef<any>, c: string = ''): void {
    this.modalRef = this.modalService.show(template, { class: c });

    this.modalSub = this.modalRef.onHide.subscribe((reason: string) => {
      this.modalSub.unsubscribe();
      this.modalRef = undefined;
      // Reset all values
      this.processing = false;
    });
  }

  /** Closes the most recent opened modal. */
  public closeModal(onHide: Function = null): void {
    if (this.modalRef) {
      this.modalRef.hide();
      if (onHide) this.modalRef.onHide.subscribe(onHide);
    } else {
      if (onHide) onHide();
    }
  }

  /** @ignore */
  ngOnDestroy(): void {
    this.closeModal();

    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}

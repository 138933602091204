import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router, UrlSegment } from '@angular/router';
import { ReplaySubject, combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { LoginService } from '../../../auth/services/login.service';
import { UsersService } from '../../../auth/services/users.service';

@Component({
  selector: 'ag-redirect-public-api',
  templateUrl: './redirect-public-api.component.html',
  styleUrls: ['./redirect-public-api.component.scss']
})
export class RedirectPublicApiComponent implements OnInit, OnDestroy {

  private destroyed$ = new ReplaySubject(null);

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private loginService: LoginService,
    private userService: UsersService
  ) { }

  ngOnInit(): void {
    combineLatest({
      queryParams: this.route.queryParams,
      url: this.route.url
    })
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: ({ queryParams, url }) => this.handleNavigation(queryParams, url),
        error: error => console.error(error)
      });
  }

  private handleNavigation(queryParams: Params, urlSegments: any[]): Promise<boolean> {
    // Create a shallow copy to avoid mutation
    const parsedQueryParams = { ...queryParams };
    const redirectUrl = this.mapUrlSegments(urlSegments);

    if (!parsedQueryParams.token && !redirectUrl) {
      return this.router.navigate(['/']);
    }

    this.userService.getUserByToken(parsedQueryParams.token)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(({ user }) => {
        if (!user) {
          return this.router.navigate(['/']);
        }

        this.loginService.authenticateWithRedirectedToken({ token: parsedQueryParams.token, user });

        delete parsedQueryParams['token'];
        return this.router.navigate(redirectUrl, { queryParams: parsedQueryParams });
      });
  }

  private mapUrlSegments(urlSegments: UrlSegment[]): string[] {
    return urlSegments.map(url => url.path);
  }

  ngOnDestroy(): void {
    this.destroyed$.next(1);
    this.destroyed$.complete();
  }
}
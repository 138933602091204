import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgPipesModule } from 'ngx-pipes';

import { ChatModule } from '../../../chat/chat.module';
import { UiModule } from '../../../ui/ui.module';
import { SharedModule } from '../shared/shared.module';
import { CounterOrderComponent } from './components/counter-order/counter-order.component';
import { MyOperationsComponent } from './components/my-operations/my-operations.component';
import { NegotiationListComponent } from './components/negotiation-list/negotiation-list.component';
import { PreordersComponent } from './components/preorders/preorders.component';
import { TradedOrdersComponent } from './components/traded-orders/traded-orders.component';
import { TradingOrdersComponent } from './components/trading-orders/trading-orders.component';
import { ViewOrderComponent } from './components/view-order/view-order.component';

@NgModule({
  declarations: [
    TradingOrdersComponent,
    TradedOrdersComponent,
    MyOperationsComponent,
    PreordersComponent,
    ViewOrderComponent,
    NegotiationListComponent,
    CounterOrderComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    UiModule,
    FormsModule,
    NgPipesModule,
    SharedModule,
    RouterModule,
    ChatModule
  ]
})
export class CommercialModule { }

<p class="flex-center-distributed">
  <span class="grow">
    <span class="summary" *ngIf="selected.length > 0; else heading">
      <span [innerHtml]="'LABELS.SELECTED'|translate: {quantity: selected.length}"></span>
      &mdash;
      <a class="small" (click)="selected = []">{{ 'GLOBAL.CLEAR_SELECTION'|translate }}</a>
      <ag-commercial-zones-manager [(ngModel)]="selected" [company]="company"></ag-commercial-zones-manager>
    </span>
    <ng-template #heading>
      <span *ngIf="selected.length === 0">{{ 'TEAM_VIEW.HEADING'|translate }}</span>
    </ng-template>
  </span>
  <button type="button"
  class="btn btn-agree" (click)="agInvite.show()"
  [disabled]="!user?.mail_verified || !account?.is.commercial_with_users">
    <i class="material-symbols-rounded hidden-xs hidden-sm">add</i> {{ 'TEAM_VIEW.ADD_USER'|translate }}
  </button>
</p>
<ng-container *ngIf="members; else loading">
  <div class="horizontal-scroller">
    <div class="table-sticky-headers">
      <table class="table no-margin"
      [sortable]="members" defaultSortBy="email" #tableMembers="tableSortable"
      AgMultiselection [(ngModel)]="selected">
        <!-- <caption></caption> -->
        <thead>
          <tr>
            <th class="sap" *ngIf="members?.length > 0">
              <input type="checkbox" AgSelectall
              id="select-all-toggle"
              class="show-onhover no-margin"
              [disabled]="processing"
              tooltip="{{ 'WORKING_ORDERS.SELECT_ALL'|translate }}"
              container="body" [adaptivePosition]="false">
            </th>
            <th class="hidden-xs" sortBy="name">{{ 'TEAM_VIEW.NAME_LASTNAME'|translate }}</th>
            <th sortBy="email">{{ 'TEAM_VIEW.EMAIL'|translate }}</th>
            <th sortBy="role.name">{{ 'TEAM_VIEW.ROLE'|translate }}</th>
            <th class="sap">
              <abbr tooltip="{{ 'TEAM_VIEW.RESTRICT_VISIBILITY'|translate }}"
              container="body" [adaptivePosition]="false">
                <span class="material-symbols-rounded">visibility</span>
              </abbr>
            </th>
            <th></th>
            <th class="hidden-xs hidden-sm" sortBy="active">{{ 'GLOBAL.STATUS'|translate }}</th>
            <th class="sap" *ngIf="account.is.commercial_with_users"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let member of tableMembers.collection" [class.bg-warning]="!member.active">
            <td class="sap">
              <input *ngIf="member.active"
              [disabled]="processing"
              [AgCheckbox]="member"
              type="checkbox"
              name="chk-{{ member.id }}"
              class="show-onhover no-margin">
            </td>
            <td class="hidden-xs">
              <b [class.highlighted-text]="account.user.id === member.user?.id">{{ member.name }}</b>
            </td>
            <td>
              <div class="max-40 truncate">{{ member.email }}</div>
            </td>
            <td>
              <span
                *ngIf="!account.is.commercial_with_users || !member.user || member.user?.id === user.id; else editInPlace">
                {{member.role?.name }}</span>
              <ng-template #editInPlace>
                <edit-in-place [(ngModel)]="member.role" [options]="roles" (onChange)="edit(member)">
                </edit-in-place>
              </ng-template>
            </td>
            <td class="sap">
              <input *ngIf="member.active && !member.is.admin && member.commercial_zones?.length > 0"
              name="viewRestrictedToCommercialZones"
              [(ngModel)]="member.settings.viewRestrictedToCommercialZones"
              type="checkbox"
              [disabled]="processing"
              (change)="edit(member)"
              class="show-onhover no-margin">
            </td>
            <td>
              <span *ngFor="let zone of member.commercial_zones"
              class="ag-label"
              [class.label-default]="!member.settings.viewRestrictedToCommercialZones"
              [class.label-warning]="member.settings.viewRestrictedToCommercialZones">{{ zone.name }}</span>
            </td>
            <td class="hidden-xs hidden-sm">
              <span *ngIf="member.active" class="text-success">{{ 'TEAM_VIEW.ACTIVE'|translate }}</span>
              <span *ngIf="!member.active" class="text-warning">{{ 'TEAM_VIEW.INVITATION_SENT'|translate }}</span>
            </td>
            <td *ngIf="account.is.commercial_with_users" class="sap">
              <a *ngIf="member.user?.id !== user.id"
              title="{{ 'TEAM_VIEW.DELETE_CONFIRM.MODAL_TITLE'|translate }}"
              [class.disabled]="processing"
              (onConfirm)="deleteMember(member)"
              [confirm-options]="{
                title: 'TEAM_VIEW.DELETE_CONFIRM.TITLE'|translate,
                body: 'TEAM_VIEW.DELETE_CONFIRM.BODY'|translate,
                action: 'GLOBAL.YES_REMOVE'|translate
              }"
              [confirm-close]="deletedMember">
                <i class="material-symbols-rounded">delete</i>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</ng-container>

<ag-invite #agInvite="agInvite" [roles]="roles" [company]="company" (success)="refresh()"></ag-invite>

<ng-template #loading>
  <div class="loading">
    <spinner message="{{ 'GLOBAL.LOADING'|translate }}"></spinner>
  </div>
</ng-template>
<div class="ag-container">
  <div class="ag-col-secondary">
    <filter-set [marketId]="company?.market.id">
        <filter-list key="product_id" sortKey="name"></filter-list>
    </filter-set>
  </div>
  <div class="ag-col-primary">
    <div class="container-fluid">
      <div class="content-box">
        <ol class="breadcrumb">
          <li><a routerLink="../setup">{{ 'GLOBAL.CONFIG_ASSISTANT'|translate }}</a></li>
          <li class="active">{{ 'GLOBAL.COMMERCIAL_MANAGER'|translate }}</li>
        </ol>
        <div class="ag-header sticky">
          <div class="ag-header-title">
            <h3>{{ 'COMMERCIAL_MANAGER.TITLE'|translate }}</h3>
          </div>
          <div class="ag-header-actions">
            <!-- Operation type -->
            <div id="operation-type-toggle" class="btn-group" data-toggle="buttons">
              <label class="btn btn-default btn-sm active">
                <input type="radio" name="options" id="option1" autocomplete="off" checked>
                {{ 'COMMERCIAL_MANAGER.BUY_TRADES'|translate }}
              </label>
              <label class="btn btn-default btn-sm">
                <input type="radio" name="options" id="option2" autocomplete="off">
                {{ 'COMMERCIAL_MANAGER.SELL_TRADES'|translate }}
              </label>
            </div>
          </div>
        </div>

        <div class="loading" *ngIf="loading">
          <spinner message="{{ 'GLOBAL.LOADING'|translate }}"></spinner>
        </div>

        <div class="row" *ngIf="!loading">
          <div class="col-xs-12">

            <!-- No data -->
            <div class="no-data" *ngIf="members?.length === 0">
              <i class="material-symbols-rounded">not_interested</i>
              {{ 'COMMERCIAL_MANAGER.NO_TEAM'|translate }}
            </div>

            <ng-container *ngIf="members?.length > 0">
              <p>{{ 'COMMERCIAL_MANAGER.DESCRIPTION'|translate }}</p>

              <div class="fixedHead" [class.updating]="updating">
                <table class="table" [sortable]="members" defaultSortBy="-buy.traded.day" #tableSortable="tableSortable">
                  <!-- <caption></caption> -->
                  <thead>
                    <tr>
                      <th sortBy="name">{{ 'COMMERCIAL_MANAGER.TABLE.NAME_LASTNAME'|translate }}</th>
                      <th class="sap"></th>
                      <th sortBy="productsSorter">{{ 'COMMERCIAL_MANAGER.TABLE.PRODUCTS'|translate }}</th>
                      <th class="sap" sortBy="buy.stop.day">{{ 'COMMERCIAL_MANAGER.TABLE.DAILY_STOP'|translate }}</th>
                      <th class="text-center" sortBy="buy.traded.day"><abbr title="6/06/2019">{{ 'COMMERCIAL_MANAGER.TABLE.TODAY_BOUGHT'|translate }}</abbr></th>
                      <th class="sap" sortBy="buy.stop.week">{{ 'COMMERCIAL_MANAGER.TABLE.WEEKLY_STOP'|translate }}</th>
                      <th class="text-center" sortBy="buy.traded.week"><abbr title="3/06/2019 - 6/06/2019">{{ 'COMMERCIAL_MANAGER.TABLE.WEEK_BOUGHT'|translate }}</abbr></th>
                      <th class="sap" sortBy="buy.stop.month">{{ 'COMMERCIAL_MANAGER.TABLE.MONTHLY_STOP'|translate }}</th>
                      <th class="text-center" sortBy="buy.traded.week"><abbr title="June 2019">{{ 'COMMERCIAL_MANAGER.TABLE.MONTH_BOUGHT'|translate }}</abbr></th>
                      <th class="text-center" sortBy="buy.traded.total">{{ 'COMMERCIAL_MANAGER.TABLE.TOTAL_BOUGHT'|translate }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let member of tableSortable.collection; let tab = index;" class="tr-align-center">
                      <th>{{ member.name }}</th>
                      <td class="sap">
                        <a href="mailto:{{ member.email }}" title="{{ member.email }}">
                          <i class="material-symbols-rounded">mail</i>
                        </a>
                      </td>
                      <td class="small">
                        <a title="{{ 'COMMERCIAL_MANAGER.TABLE.EDIT_PRODUCTS'|translate }}" (click)="showProductSelector(member)">
                          <!-- <i class="material-symbols-rounded">edit</i> -->
                          {{ member.products.join(', ') }}
                        </a>
                      </td>
                      <td class="sap"
                      [class.success]="member.buy.stop.day === 0"
                      [class.danger]="member.buy.stop.day !== 0 && member.buy.traded.day / member.buy.stop.day >= 1"
                      [class.warning]="(member.buy.traded.day / member.buy.stop.day >= 0.9) && (member.buy.traded.day / member.buy.stop.day < 1)">
                        <div class="input-group input-group-sm input-tons">
                          <input type="number" placeholder="0" step="1" class="form-control" [(ngModel)]="member.buy.stop.day" number min="0"
                          [max]="9999999"
                          tabindex="{{ (tab * 3) + 1 }}">
                          <span class="input-group-addon">t</span>
                        </div>
                      </td>
                      <td class="text-center" [class.danger]="member.buy.stop.day !== 0 && member.buy.traded.day / member.buy.stop.day >= 1" [class.warning]="(member.buy.traded.day / member.buy.stop.day >= 0.9) && (member.buy.traded.day / member.buy.stop.day < 1)">
                        <div>{{ member.buy.traded.day|number }} <small>t. ({{ (member.buy.stop.day === 0 ? 0 : 100 * member.buy.traded.day / member.buy.stop.day) |number:'1.1-1' }}%)</small></div>
                        <small class="text-muted">{{ 'COMMERCIAL_MANAGER.TABLE.IN_TRADES_1'|translate }} {{ member.buy.trades.day|number }} {{ 'COMMERCIAL_MANAGER.TABLE.IN_TRADES_2'|translate }}</small>
                      </td>
                      <td class="sap"
                      [class.success]="member.buy.stop.week === 0"
                      [class.danger]="member.buy.stop.week !== 0 && member.buy.traded.week / member.buy.stop.week >= 1"
                      [class.warning]="(member.buy.traded.week / member.buy.stop.week >= 0.9) && (member.buy.traded.week / member.buy.stop.week < 1)">
                        <div class="input-group input-group-sm input-tons">
                          <input type="number" placeholder="0" step="1" class="form-control" [(ngModel)]="member.buy.stop.week" number min="0"
                          [max]="9999999"
                          tabindex="{{ (tab * 3) + 2 }}">
                          <span class="input-group-addon">t</span>
                        </div>
                      </td>
                      <td class="text-center" [class.danger]="member.buy.stop.week !== 0 && member.buy.traded.week / member.buy.stop.week >= 1" [class.warning]="(member.buy.traded.week / member.buy.stop.week >= 0.9) && (member.buy.traded.week / member.buy.stop.week < 1)">
                        <div>{{ member.buy.traded.week|number }} <small>t. ({{ (member.buy.stop.week === 0 ? 0 : 100 * member.buy.traded.week / member.buy.stop.week) |number:'1.1-1' }}%)</small></div>
                        <small class="text-muted">{{ 'COMMERCIAL_MANAGER.TABLE.IN_TRADES_1'|translate }} {{ member.buy.trades.week|number }} {{ 'COMMERCIAL_MANAGER.TABLE.IN_TRADES_2'|translate }}</small>
                      </td>
                      <td class="sap"
                      [class.success]="member.buy.stop.month === 0"
                      [class.danger]="member.buy.stop.month !== 0 && member.buy.traded.month / member.buy.stop.month >= 1"
                      [class.warning]="(member.buy.traded.month / member.buy.stop.month >= 0.9) && (member.buy.traded.month / member.buy.stop.month < 1)">
                        <div class="input-group input-group-sm input-tons">
                          <input type="number" placeholder="0" step="1" class="form-control" [(ngModel)]="member.buy.stop.month" number min="0"
                          [max]="9999999"
                          tabindex="{{ (tab * 3) + 3 }}">
                          <span class="input-group-addon">t</span>
                        </div>
                      </td>
                      <td class="text-center" [class.danger]="member.buy.stop.month !== 0 && member.buy.traded.month / member.buy.stop.month >= 1" [class.warning]="(member.buy.traded.month / member.buy.stop.month >= 0.9) && (member.buy.traded.month / member.buy.stop.month < 1)">
                        <div>{{ member.buy.traded.month|number }} <small>t. ({{ (member.buy.stop.month === 0 ? 0 : 100 * member.buy.traded.month / member.buy.stop.month) |number:'1.1-1' }}%)</small></div>
                        <small class="text-muted">{{ 'COMMERCIAL_MANAGER.TABLE.IN_TRADES_1'|translate }} {{ member.buy.trades.month|number }} {{ 'COMMERCIAL_MANAGER.TABLE.IN_TRADES_2'|translate }}</small>
                      </td>
                      <td class="text-center">
                        <div>{{ member.buy.traded.total|number }} <small>t.</small></div>
                        <small class="text-muted">{{ 'COMMERCIAL_MANAGER.TABLE.IN_TRADES_1'|translate }} {{ member.buy.trades.total|number }} {{ 'COMMERCIAL_MANAGER.TABLE.IN_TRADES_2'|translate }}</small>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </ng-container>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #productSelector>
  <!-- modal -->
  <div class="modal-header">
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">{{ 'COMMERCIAL_MANAGER.MODAL.TITLE'|translate }} {{ user_selected }}</h4>
  </div>
  <div class="modal-body">
    <div class="container-fluid">
      <div class="row">
        <span class="help-block">{{ 'COMMERCIAL_MANAGER.MODAL.DESCRIPTION'|translate }}</span>

        <selectize 
          name="userProducts"
          placeholder="{{ 'COMMERCIAL_MANAGER.MODAL.PLACEHOLDER'|translate }}"
          [options]="products"
          type="product"
          [(ngModel)]="products_selected"
          [disabled]="updating"
          #userProducts="ngModel">
        </selectize>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button (click)="modalRef.hide()" type="button" class="btn btn-link" data-dismiss="modal">{{ 'GLOBAL.CANCEL'|translate }}</button>
    <button class="btn btn-agree" [disabled]="userProducts.control.pristine" (click)="saveChanges()">{{ 'GLOBAL.SAVE_CHANGES'|translate }}</button>
  </div>
</ng-template>
import { Component, Input } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { Company } from '../../../models/company.model';
import { LocationService } from '../../../services/location.service';

@Component({
  selector: 'ag-company-address-form',
  templateUrl: './company-address-form.component.html',
  styleUrls: ['./company-address-form.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class CompanyAddressFormComponent {

  @Input() public company: Company;
  @Input() public disabled: boolean;

  constructor(
    public locationService: LocationService
  ) { }
}

import { Component, EventEmitter, Input, OnDestroy, Output, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';

import { Role } from '../../../auth/models/role.model';
import { Company } from '../../../models/company.model';
import { Invitation } from '../../../models/invitation.model';
import { TeamService } from '../../../services/team.service';

@Component({
  selector: 'ag-invite',
  exportAs: 'agInvite',
  templateUrl: './invite.component.html',
  styleUrls: ['./invite.component.css']
})
export class InviteComponent implements OnDestroy {

  @ViewChild('inviteModal', { static: true }) private readonly inviteModal: TemplateRef<any>;

  @Input() public company: Company;
  @Input() public roles: Role[];

  @Output() readonly success = new EventEmitter();

  public invitation: Invitation;
  /**
   * Flag used to enable/disable UI buttons and links when an API request is in
   * progress.
   */
  public processing: boolean;
  public errors = [];

  private modalRef: BsModalRef;
  private modalSub: Subscription;
  private subscriptions: Subscription[] = [];

  /** @ignore */
  constructor(
    private teamService: TeamService,
    private modalService: BsModalService
  ) { }

  public show(): void {
    this.invitation = new Invitation();
    this.errors = [];

    this.openModal(this.inviteModal);
  }

  public invite(): void {
    this.processing = true;
    this.errors = [];

    this.subscriptions.push(this.teamService.invite(this.company.id, this.invitation).subscribe(invitation => {
      this.closeModal();
      this.success.emit();
    }, response => {
      if (typeof response.error.message.ext_company_team_exist !== 'undefined') {
        this.errors.push("TEAM_VIEW.INVITE_MODAL.ALREADY_EXISTS");
      } else {
        this.errors.push("SIGNUP.UNKNOWN_ERROR");
      }
      this.processing = false;
    }));
  }

  /** Generic Modal trigger. */
  private openModal(template: TemplateRef<any>, c: string = ''): void {
    this.modalRef = this.modalService.show(template, { class: c });

    this.modalSub = this.modalRef.onHide.subscribe((reason: string) => {
      this.modalSub.unsubscribe();
      this.modalRef = undefined;
      // Reset all values
      this.processing = false;
    });
  }

  /** Closes the most recent opened modal. */
  public closeModal(onHide: Function = null): void {
    if (this.modalRef) {
      this.modalRef.hide();
      if (onHide) this.modalRef.onHide.subscribe(onHide);
    } else {
      if (onHide) onHide();
    }
  }

  /** @ignore */
  ngOnDestroy(): void {
    this.closeModal();

    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}

<ag-envelopes-modals #envelopesModals
[company]="company"></ag-envelopes-modals>

<div class="ag-container">
  <div class="ag-col-secondary">
    <filter-set [marketId]="company?.market.id">
      <filter-list key="envelope_status" sortKey="id"></filter-list>
    </filter-set>
  </div>
  <div class="ag-col-primary">
    <div class="container-fluid">
      <div class="content-box">
        <div class="ag-header sticky">
          <div class="ag-header-title">
            <div class="summary" *ngIf="envelopes?.length"
            [innerHtml]="'SIGNATURES.SUMMARIZE'|translate:{ quantity: envelopes.length, total: paginationData.total}"></div>
          </div>
          <div class="ag-header-actions">
            <div class="btn-toolbar">
              <div class="btn-group">
                <button type="button"
                [disabled]="processing" class="btn btn-agree" (click)="envelopesModals.create()">
                  <i class="material-symbols-rounded hidden-xs hidden-sm">add</i>
                  {{ 'SIGNATURES.CREATE_ENVELOPE'|translate }}
                </button>
              </div>
              <div class="btn-group"
              *ngIf="paginationData?.total_pages > 1">
                <paginator [data]="paginationData" [loading]="loading"></paginator>
              </div>
            </div>
          </div>
        </div>
        <div class="loading" *ngIf="loading">
          <spinner message="{{ 'GLOBAL.LOADING'|translate }}"></spinner>
        </div>
        <div class="row" *ngIf="!loading">
          <div class="col-xs-12">
            <!-- Data -->
            <!-- https://www.w3.org/WAI/tutorials/tables/examples/threeheaders/ -->
            <div class="horizontal-scroller">
              <div class="table-sticky-headers">
                <table class="table table-hover table-condensed" sortable
                #tableSortable="tableSortable"
                [variableInUrl]="true">
                  <thead>
                    <tr class="tr-align-center">
                      <th class="sap" sortBy="date">
                        {{ 'GLOBAL.DATE'|translate }}
                        <column-filter
                        type="date"
                        alignment="left"
                        [filters]="filters"
                        key="filters[date]"
                        (onChange)="changeFilter($event)"></column-filter>
                      </th>
                      <th sortBy="subject">
                        {{ 'SIGNATURES.SUBJECT'|translate }}
                        <column-filter
                        type="string"
                        alignment="left"
                        [filters]="filters"
                        key="filters[subject]"
                        (onChange)="changeFilter($event)"></column-filter>
                      </th>
                      <th>
                        {{ 'SIGNATURES.SENDER'|translate }}
                        <column-filter
                        type="string"
                        alignment="left"
                        [filters]="filters"
                        key="filters[company.name]"
                        (onChange)="changeFilter($event)"></column-filter>
                      </th>
                      <th class="hidden-xs hidden-sm"></th>
                      <th class="sap"></th>
                      <th class="hidden-xs" sortBy="status">{{ 'GLOBAL.STATUS'|translate }}</th>
                      <th class="hidden-xs hidden-sm" sortBy="updated_at">
                        {{ 'SIGNATURES.LAST_MODIFIED'|translate }}
                        <column-filter
                        type="date"
                        [filters]="filters"
                        key="filters[updated_at]"
                        (onChange)="changeFilter($event)"></column-filter>
                      </th>
                      <th class="sap"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="tr-align-center" *ngFor="let envelope of envelopes"
                    [class.tr-danger]="envelope.status.id === 40">
                      <!-- Envelope row -->
                      <ng-template #popTemplate>
                        <div class="container-fluid">
                          <div class="row">
                            <div class="col-xs-12">
                              <envelope-status [company]="company" [envelope]="envelope"></envelope-status>
                            </div>
                          </div>
                        </div>
                      </ng-template>
  
                      <td class="sap">
                        <ag-timestamp [date]="envelope.created_at"></ag-timestamp>
                      </td>
                      <td [popover]="popTemplate"
                      containerClass="popFadeDelay width-800"
                      container="body" triggers="mouseenter:mouseleave" placement="right">
                        <div><b><a [routerLink]="['/company', company.id, 'envelopes', envelope.id]">{{ envelope.subject }}</a></b></div>
                        <span class="small text-muted"
                        *ngIf="envelope.signers.length">{{ 'SIGNATURES.SIGNERS'|translate }}: {{ envelope.signers.length }}</span>
                      </td>
                      <td>
                        <company-info [company]="envelope.company"
                        [activity]="false"></company-info>
                      </td>
                      <td class="hidden-xs hidden-sm sap small">
                        <ag-envelope-binded
                        *ngIf="envelope.binded_entities?.length > 0"
                        [company]="company"
                        [envelope]="envelope"></ag-envelope-binded>
                      </td>
                      <td class="sap" [ngSwitch]="envelope.status.id">
                        <ng-container *ngSwitchCase="10">
                          <span class="text-info material-symbols-rounded" *ngIf="!envelope.hasToSetSigners(company.id)"
                          [tooltip]="'SIGNATURES.STATUS.WAITING_SIGNERS'|translate">schedule</span>
                          <span class="text-warning material-symbols-rounded blinking" *ngIf="envelope.hasToSetSigners(company.id)"
                          [tooltip]="'SIGNATURES.STATUS.DEFINE_SIGNERS'|translate">warning</span>  
                        </ng-container>
                        <span *ngSwitchCase="20" class="text-muted material-symbols-rounded"
                        [tooltip]="'SIGNATURES.STATUS.PENDING'|translate">schedule</span>
                        <span *ngSwitchCase="30" class="text-success material-symbols-rounded"
                        [tooltip]="'SIGNATURES.STATUS.COMPLETED'|translate">done</span>
                      </td>
                      <td class="hidden-xs"
                      [popover]="popTemplate"
                      containerClass="popFadeDelay width-800"
                      container="body"
                      triggers="mouseenter:mouseleave" placement="bottom"
                      [ngSwitch]="envelope.status.id">
                        <ng-container *ngSwitchCase="10">
                          <span class="text-info" *ngIf="!envelope.hasToSetSigners(company.id)">{{ 'SIGNATURES.STATUS.WAITING_SIGNERS'|translate }}</span>
                          <span class="text-warning" *ngIf="envelope.hasToSetSigners(company.id)">{{ 'SIGNATURES.STATUS.DEFINE_SIGNERS'|translate }}</span>  
                        </ng-container>
                        <span *ngSwitchCase="20" class="text-muted">{{ 'SIGNATURES.STATUS.PENDING'|translate }}</span>
                        <span *ngSwitchCase="30">{{ 'SIGNATURES.STATUS.COMPLETED'|translate }}</span>
                        <span *ngSwitchCase="40" class="text-danger">{{ 'SIGNATURES.STATUS.CANCELED'|translate }}</span>
                      </td>
                      <td class="hidden-xs hidden-sm sap">
                        <ag-timestamp [date]="envelope.updated_at"></ag-timestamp>
                      </td>
                      <td class="text-right sap">
                        <div class="btn-group" dropdown [isDisabled]="processing"
                        container="body">
                          <button dropdownToggle type="button" class="btn btn-link btn-xs dropdown-toggle"
                          aria-controls="dropdown-basic">
                            <i class="material-symbols-rounded">more_vert</i>
                          </button>
                          <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
                            <!-- <li role="menuitem" [class.disabled]="envelope.company?.id !== company?.id ||
                              [30, 40].includes(envelope.status.id)">
                                <a class="dropdown-item" (click)="createEnvelope(envelopeModal)">{{ 'GLOBAL.EDIT'|translate }}</a>
                              </li> -->
                            <li role="menuitem" [class.disabled]="envelope.company?.id !== company?.id ||
                            [30, 40].includes(envelope.status.id)">
                              <a class="dropdown-item" (click)="envelopesModals.cancel(envelope)">{{ 'GLOBAL.CANCEL'|translate }}</a>
                            </li>
                            <li role="separator" class="divider"></li>
                            <li role="menuitem">
                              <a class="dropdown-item" (click)="envelopesModals.history(envelope)">{{ 'SIGNATURES.HISTORY'|translate }}</a>
                            </li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <!-- No data -->
              <div class="no-data" *ngIf="!envelopes || envelopes?.length === 0">
                <i class="material-symbols-rounded">not_interested</i>
                {{ 'SIGNATURES.NO_DATA'|translate }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { User } from '../models/user.model';
import { LoginService } from './login.service';

@Injectable()
export class AuthGuard {

  /** Current [[User]]. */
  public user: User;

  constructor(
    private loginService: LoginService
  ) {
    this.loginService.getCurrentUser().subscribe(user => {
      this.user = user;
    });
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let url: string = state.url;

    return this.checkLogin(url);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(route, state);
  }

  private checkLogin(url: string): boolean {
    if (this.user) {
      if (this.user.mail_verified === 0) {
        this.loginService.checkMailVerification().subscribe(session => {
          this.user = session.user;
          return true;
        });
      }
      return true;
    } else {
      this.loginService.customLogout(url);
      return false;
    }
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { Account } from '../../../auth/models/account.model';
import { User } from '../../../auth/models/user.model';
import { LoginService } from '../../../auth/services/login.service';
import { Company } from '../../../models/company.model';
import { CompanyService } from '../../../services/company.service';
import { ComponentCommService } from '../../../services/component-comm.service';

@Component({
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss']
})
export class TeamComponent implements OnInit, OnDestroy {

  public account: Account;
  public collectionSlug: string;
  public company: Company;
  /** Current [[User]]. */
  public user: User;

  private companyId: number;
  private subscriptions: Subscription[] = [];

  /** @ignore */
  constructor(
    private companyService: CompanyService,
    private componentComm: ComponentCommService,
    private loginService: LoginService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.subscriptions.push(this.route.parent.params.subscribe(params => {
      this.companyId = parseInt(params['companyId']);
    }));

    this.subscriptions.push(this.route.paramMap.subscribe(params => {
      this.collectionSlug = params.get('collection');
      if (!this.collectionSlug) {
        // Default to first
        this.navigateTo('users');
      }
    }));
  }

  /** @ignore */
  ngOnInit(): void {
    if (this.collectionSlug) {
      this.componentComm.emit({ name: 'app-title', title: 'GLOBAL.TEAM' });

      this.subscriptions.push(this.companyService.watch().subscribe(company => {
        if (!company) return;

        this.company = company;

        if (this.company.activation_level.id === 1) {
          this.router.navigateByUrl('/companies');
        } else {
          this.getCurrentUser();

          this.subscriptions.push(this.companyService.watchAccount().subscribe(account => {
            if (account) this.account = account;
          }));
        }
      }));
    }
  }

  private getCurrentUser(): void {
    this.subscriptions.push(this.loginService.getCurrentUser().subscribe(user => {
      this.user = user;
    }));
  }

  /** Navigate to the different sections of the Team settings. */
  public navigateTo(slug: string): void {
    this.router.navigate(['company', this.companyId, 'team', slug]);
  }

  /** @ignore */
  ngOnDestroy(): void {
    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}

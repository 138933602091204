import { Component, EventEmitter, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { CompanyLocation } from '../../../models/company-location.model';
import { Company } from '../../../models/company.model';
import { GeoSelection } from '../../../models/geo-selection.model';
import { CompanyLocationService } from '../../../services/company-location.service';
import { CompanyService } from '../../../services/company.service';
import { ComponentCommService } from '../../../services/component-comm.service';
import { DataDogLoggerService } from '../../../services/data-dog-logger.service';
import { IntercomService } from '../../../services/intercom.service';
import { SortableDirective } from '../../../ui/directives/sortable.directive';

@Component({
  selector: 'app-company-locations',
  templateUrl: './company-locations.component.html',
  styleUrls: ['./company-locations.component.css']
})
export class CompanyLocationsComponent implements OnInit, OnDestroy {

  @ViewChild('tableSortable') private readonly table: SortableDirective;

  public company: Company;
  public locations: CompanyLocation[];
  public deletedLocation = new EventEmitter();
  public newLocation: GeoSelection;
  private subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    private componentComm: ComponentCommService,
    private companyService: CompanyService,
    private companyLocationService: CompanyLocationService,
    private intercomService: IntercomService,
    private dataDogLoggerService: DataDogLoggerService
  ) {

  }

  ngOnInit(): void {
    this.subscriptions.push(this.companyService.watch().subscribe(company => {
      if (!company) return;

      this.company = company;

      if (this.company.activation_level.id === 1) {
        this.router.navigateByUrl('/companies');
      }

      if (!this.company.market.configuration.location.enabled) {
        this.router.navigateByUrl('/company/' + this.company.id + '/setup');
      } else {
        this.componentComm.emit({ name: 'app-title', title: 'COMPANY_LOCATIONS_VIEW.TITLE', params: { company: this.company.name } });
        this.intercomService.track('locations-visited');
      }

      this.loadData();
    }));

  }

  private loadData(): void {
    this.locations = null;
    this.subscriptions.push(this.companyLocationService.get(this.company.id).subscribe(locations => {
      this.locations = locations;
    }));
  }

  public delete(location: CompanyLocation): void {
    this.subscriptions.push(this.companyLocationService.delete(this.company.id, location).subscribe(response => {
      for (let i in this.locations) {
        if (this.locations[i].id === location.id) {
          this.locations.splice(parseInt(i), 1);
          break;
        }
      }
      this.table.collection = this.locations;
      this.deletedLocation.emit();
    }));
  }

  public add(): void {
    this.subscriptions.push(this.companyLocationService.create(this.company.id, this.newLocation.zone, this.newLocation.location).subscribe({
      next: response => {
        const location = this.newLocation.zone?.name || this.newLocation.location.name;

        const metadata = {
          location_name: location,
          company_id: this.company.id,
          company_name: this.company.name
        };

        this.intercomService.track('location-added', metadata);
        this.loadData();
        this.newLocation = null;
      },
      error: error => {
        this.newLocation = null;
        this.dataDogLoggerService.warn(error.message, error.error);
      }
    }));
  }

  /** @ignore */
  ngOnDestroy(): void {
    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}

<ag-network-invite-modal #inviteModal
[company]="company"></ag-network-invite-modal>

<ag-commercial-zones-modal #commercialZonesModal
[company]="company"></ag-commercial-zones-modal>

<div class="ag-container">
  <div class="ag-col-secondary">
    <filter-set [marketId]="company?.market.id">
      <filter-list key="activity_id"></filter-list>
    </filter-set>
  </div>
  <div class="ag-col-primary">
    <div class="container-fluid">
      <div class="content-box">
        <ol class="breadcrumb">
          <li><a routerLink="../setup">{{ 'GLOBAL.CONFIG_ASSISTANT'|translate }}</a></li>
          <li class="active">{{ 'GLOBAL.SELLER_ADMIN'|translate }}</li>
        </ol>
        <div class="ag-header sticky">
          <div class="ag-header-title">
            <!-- <h3>{{ 'GLOBAL.SELLER_ADMIN'|translate }}</h3> -->
            <div class="summary" *ngIf="selection?.length > 0">
              <span [innerHtml]="'LABELS.SELECTED'|translate: {quantity: selection.length}"></span>
              &mdash;
              <a class="small" (click)="selection = []">{{ 'GLOBAL.CLEAR_SELECTION'|translate }}</a>
              <ag-commercial-zones-manager [(ngModel)]="selection" [company]="company"></ag-commercial-zones-manager>
            </div>
          </div>
          <div class="ag-header-actions">
            <div class="btn-toolbar">
              <div class="btn-group">
                <button type="button"
                class="btn btn-default"
                (click)="commercialZonesModal.open()">
                  <i class="material-symbols-rounded hidden-xs hidden-sm">work_outline</i>
                  {{ 'COMMERCIAL_ZONES.MANAGE'|translate }}
                </button>
              </div>
              <div class="btn-group">
                <button type="button"
                *ngIf="company?.hasModule('my-network')" class="btn btn-agree"
                (click)="inviteModal.open()">
                  <i class="material-symbols-rounded hidden-xs hidden-sm">add</i>
                  {{ 'COUNTERPARTIES.NETWORK.ADD'|translate }}
                </button>
              </div>
              <div class="btn-group" *ngIf="paginationData?.total > 0">
                <paginator [data]="paginationData"></paginator>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12">
            <p>{{ 'COUNTERPARTIES.HEADING'|translate }}</p>
          </div>
        </div>

        <ag-counterparties-table *ngIf="user && company" [(ngModel)]="selection" [enableSelection]="true"
          [company]="company" (load)="tableLoaded($event)"></ag-counterparties-table>
      </div>
    </div>
  </div>
</div>
<waybill-modal #waybillModal
[company]="company"></waybill-modal>

<contract-modal #contractModal
[company]="company"></contract-modal>

<div *ngFor="let bind of envelope.binded_entities">
  <ng-container [ngSwitch]="bind.entity.type">
    <a *ngSwitchCase="'negotiation'" [routerLink]="['/company', company.id, 'counter-order', bind.entity.id]">
      <span class="material-symbols-rounded">launch</span>
      {{ 'GLOBAL.TRADE'|translate }} <samp>#{{ bind.entity.id }}</samp></a>
    <a *ngSwitchCase="'waybill'" (click)="waybillModal.show(bind.entity.id)">{{ 'TRUCKS.WAYBILL'|translate }}</a>
    <a *ngSwitchCase="'credit-application'"
    [routerLink]="['/company', company.id, 'fintech', 'application', bind.entity.id]">
      <span class="material-symbols-rounded">launch</span>
      {{ 'FINTECH.APPLY.TITLE'|translate }}</a>
    <a *ngSwitchCase="'financial-application'"
    [routerLink]="['/company', company.id, 'fintech', 'applications', bind.entity.id]">
      <span class="material-symbols-rounded">launch</span>
      {{ 'FINTECH.APPLY.TITLE'|translate }}</a>
    <a *ngSwitchCase="'contract'" (click)="contractModal.show(bind.entity.id)">{{ 'GLOBAL.CONTRACT'|translate }}</a>
  </ng-container>
</div>
<div class="ag-container">
  <!-- <div class="ag-col-secondary">
    <filter-set #filterSet="filterSet" [marketId]="company?.market.id">
    </filter-set>
  </div> -->
  <div class="ag-col-primary">
    <div class="container-fluid">
      <div class="content-box">
        <div class="ag-header sticky">
          <div class="ag-header-title">
            <h3>{{ 'TEMPLATES_VIEW.TITLE'|translate }}</h3>
          </div>
        </div>

        <div class="row">
          <div class="col-xs-12">
            <p>{{ 'TEMPLATES_VIEW.DESCRIPTION'|translate }}</p>
          </div>
        </div>
        <div class="loading" *ngIf="loading">
          <spinner message="{{ 'GLOBAL.LOADING'|translate }}"></spinner>
        </div>

        <div class="row">
          <div class="col-xs-12">
            <!-- No data -->
            <div class="no-data" *ngIf="templatesByProduct?.length === 0">
              <i class="material-symbols-rounded">not_interested</i>
              {{ 'TEMPLATES_VIEW.NO_RECORDS'|translate }}
            </div>

            <!-- Data -->
            <!-- https://www.w3.org/WAI/tutorials/tables/examples/threeheaders/ -->
            <div class="horizontal-scroller" *ngIf="templatesByProduct?.length > 0">
              <table class="table table-condensed" sortable defaultSortBy="-updated_at" #tableSortable="tableSortable">
                <!-- <caption></caption> -->
                <thead>
                  <tr class="tr-align-center">
                    <th class="sap hidden-xs" sortBy="updated_at">{{ 'TEMPLATES_VIEW.TABLE_HEADER.DATE'|translate }}</th>
                    <th scope="col" id="ico"></th>
                    <th scope="col" id="name" sortBy="template.title">{{ 'GLOBAL.NAME'|translate }}</th>
                    <th scope="col" id="loc" class="hidden-xs hidden-sm" sortBy="locationString" *ngIf="company?.market.configuration.location.enabled">{{ 'GLOBAL.DELIVERY_PLACES'|translate }}</th>
                    <th scope="col" id="port" class="hidden-xs hidden-sm" sortBy="portString" *ngIf="company?.market.configuration.order.business_detail.port.enabled">{{ 'GLOBAL.COUNTRY_PORT'|translate }}</th>
                    <th scope="col" id="qua" class="hidden-xs" sortBy="quality_string">{{ 'GLOBAL.QUALITY'|translate }}</th>
                    <th scope="col" id="obs" class="hidden-xs hidden-sm">
                      <abbr title tooltip="{{ 'GLOBAL.OBSERVATIONS'|translate }}" [adaptivePosition]="false">{{ 'GLOBAL.OBSERVATIONS_ABBR'|translate }}</abbr>
                    </th>
                    <th scope="col" id="ton" class="dlb drb" sortBy="business_detail.quantity.valueString">
                      <abbr title tooltip="{{ 'GLOBAL.QUANTITY'|translate }}" [adaptivePosition]="false">{{ 'GLOBAL.QUANTITY_ABBR'|translate }}</abbr>
                    </th>
                    <th class="sap text-center" sortBy="business_detail.delivery.intDateFrom">{{ 'GLOBAL.PERIOD'|translate }}</th>
                    <th class="sap" sortBy="business_detail.price.value">
                      {{ 'GLOBAL.PRICE'|translate }}
                      <ng-container *ngIf="company?.market.quantity_units.length === 1">/{{ company?.market.quantity_units[0].abbrev }}</ng-container>
                    </th>
                    <th class="sap hidden-xs"></th>
                  </tr>
                </thead>
                <tbody *ngFor="let group of templatesByProduct; last as isLastGroup">
                  <!-- Product sub-header -->
                  <tr class="product-header">
                    <th scope="colgroup" id="product_id" class="text-uppercase" colspan="11">{{ group.key.name }}</th>
                  </tr>
                  <!-- Order row -->
                  <tr class="tr-align-center" *ngFor="let template of group.values|orderBy: tableSortable.sortBy; last as isLastTemplate"
                  [popover]="popTemplate"
                  containerClass="popFadeDelay width-800"
                  triggers="mouseenter:mouseleave"
                  placement="bottom"
                  container=".app-content"
                  #pop="bs-popover">

                    <ng-template #popTemplate>
                      <div class="container-fluid">
                        <div class="row">
                          <div class="col-md-4" *ngFor="let prop of template.props">
                            <table-detail [header]="prop.name">
                              {{ prop.value }}
                            </table-detail>
                          </div>
                        </div>
                      </div>
                    </ng-template>

                    <td class="sap hidden-xs">
                      <ag-timestamp [date]="template.updated_at"></ag-timestamp>
                    </td>
                    <td headers="product_id order_id_{{ template.id }} ico">
                      <scope-icon [entity]="template"></scope-icon>
                      <i tooltip="{{ template.general_observations }}" class="hidden-md hidden-lg material-symbols-rounded" *ngIf="template.general_observations">comment</i>
                    </td>
                    <th headers="product_id order_id_{{ template.id }} name">
                      <div class="visible-xs location-small text-uppercase">
                        <b>{{ template.updated_at|date: 'HH:mm' }}</b>
                        - <date-md [date]="template.updated_at"></date-md>
                      </div>
                      <a title="{{ 'TEMPLATES_VIEW.VIEW_TEMPLATE'|translate }}" routerLink="../republish-order/{{ template.id }}">{{ template.template?.title }}</a>
                      <div class="visible-xs visible-sm location-small" *ngIf="company?.market.configuration.location.enabled">
                        <span class="clamp-2">{{ template.business_detail.delivery.locations |locationString }}</span>
                      </div>
                      <div class="visible-xs visible-sm location-small" *ngIf="company?.market.configuration.order.business_detail.port.enabled">
                        {{ countryAndPort(template) }}
                      </div>
                    </th>
                    <td headers="product_id order_id_{{ template.id }} loc" class="small hidden-xs hidden-sm" *ngIf="company?.market.configuration.location.enabled">
                      <span class="clamp-2">{{ template.business_detail.delivery.locations |locationString }}</span>
                    </td>
                    <td headers="product_id order_id_{{ template.id }} port" class="small hidden-xs hidden-sm" *ngIf="company?.market.configuration.order.business_detail.port.enabled">
                      {{ countryAndPort(template) }}
                    </td>
                    <td headers="product_id order_id_{{ template.id }} qua" class="hidden-xs small">
                      {{ template.quality_string }}
                    </td>
                    <td headers="product_id order_id_{{ template.id }} obs" class="hidden-xs hidden-sm">
                      <i tooltip="{{ template.general_observations }}" class="material-symbols-rounded" *ngIf="template.hasObservations">comment</i>
                    </td>
                    <td headers="product_id order_id_{{ template.id }} ton" class="text-right">
                      <quantity-control [quantity]="template.business_detail.quantity"></quantity-control>
                    </td>
                    <td class="sap">
                      <date-range
                      [styles]="false"
                      [from]="template.business_detail.delivery.date_from"
                      [to]="template.business_detail.delivery.date_to"></date-range>
                    </td>
                    <td class="sap">
                      <div class="price-box" [class.sell-bg]="template.operation_type === 'venta'" [class.buy-bg]="template.operation_type === 'compra'">
                        <div class="type">{{ (template.operation_type === 'compra' ? 'GLOBAL.BID' : 'GLOBAL.SELL')|translate }}</div>
                        <div class="value">
                          <price-display [price]="template.business_detail.price" [market]="company?.market" [withoutYear]="true"></price-display>
                        </div>
                      </div>
                    </td>
                    <td class="hidden-xs sap" (mouseenter)="pop.hide()">
                      <!-- <button type="button" title="{{ 'TEMPLATES_VIEW.VIEW_TEMPLATE'|translate }}" class="btn btn-sm btn-agree" routerLink="../republish-order/{{ template.id }}"><i class="material-symbols-rounded">file_copy</i><span class="hidden-xs"> {{ 'TEMPLATES_VIEW.VIEW_TEMPLATE'|translate }}</span></button> -->
                      <button type="button" title="{{ 'TEMPLATES_VIEW.DELETE_CONFIRM.MODAL_TITLE'|translate }}" class="btn btn-link btn-sm"
                      (onConfirm)="delete(template)"
                      [confirm-options]="{
                        title: 'TEMPLATES_VIEW.DELETE_CONFIRM.TITLE'|translate,
                        action: 'GLOBAL.YES_DELETE'|translate
                      }"
                      [confirm-close]="deletedTemplate"><i class="material-symbols-rounded">delete</i></button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
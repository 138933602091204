<button type="button"
class="btn btn-link btn-xs"
title="{{ 'NEGOTIATION_ACTIONS.DOWNLOAD_TRACK_RECORD'|translate }}"
(click)="preview()"
[disabled]="processing"><i class="material-symbols-rounded">history</i></button>
<ng-container *ngIf="company?.market.configuration.order.contract.type === 'contract_clauses'">
  <ng-container *ngTemplateOutlet="fileButton; context: {
    title: 'NEGOTIATION_ACTIONS.DOWNLOAD_CONTRACT'|translate,
    key: 'contract',
    icon: 'list_alt',
    disabled: true
  }"></ng-container>
</ng-container>
<ng-container *ngIf="company?.market.configuration.order.contract.type === 'business_confirmation' && order.type.id !== 8">
  <ng-container *ngTemplateOutlet="fileButton; context: {
    title: 'NEGOTIATION_ACTIONS.DOWNLOAD_BUSINESS_CONFIRMATION'|translate,
    key: 'business-confirmation',
    icon: 'list_alt',
    disabled: true
  }"></ng-container>
</ng-container>

<div class="btn-group" dropdown container="body" placement="bottom right" 
  *ngIf="negotiation.times_booked > 0 && 
  (isRepresented || isOrderOwner || isNegotiationOwner)
  ">
  <button id="button-basic" dropdownToggle type="button" class="btn btn-link btn-xs dropdown-toggle"
  [class.disabled]="processing"
  aria-controls="dropdown-basic"><i class="material-symbols-rounded">more_vert</i></button>
  <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu"
  aria-labelledby="button-basic">
    <ng-container *ngIf="order.type.id !== 8 &&
    (environment.modules.chat ||
    (![1, 7].includes(company?.activity.id) && company?.hasModule('slots')))">
      <li role="menuitem" [class.disabled]="isRepresented"
      *ngIf="environment.modules.chat">
        <a class="dropdown-item" (click)="openMessenger()">{{ 'NEGOTIATION_ACTIONS.CHAT'|translate }}</a>
      </li>
      <li role="menuitem" [class.disabled]="(!account.is.logistics && !account.is.commercial) || !negotiation.buyers_id.includes(company?.id)"
      *ngIf="![1, 7].includes(company?.activity.id) && company?.hasModule('slots')">
        <a class="dropdown-item" (click)="slotsModals.assignBatchToNegotiation(negotiation)">{{ 'NEGOTIATION_ACTIONS.ASSIGN_SLOTS'|translate }}</a>
      </li>
      <li role="menuitem" [class.disabled]="(!account.is.logistics && !account.is.commercial)"
      *ngIf="negotiation.sellers_id.includes(company?.id)
      && company?.hasModule('slots')">
        <a class="dropdown-item" (click)="slotsModals.requestBatchToNegotiation(negotiation)">Solicitar Cupos</a>
      </li>

      <li role="separator" class="divider"></li>
    </ng-container>
    <ng-container *ngIf="order.type.id !== 8 && editorAccount">
      <li role="menuitem" [class.disabled]="negotiation.status.id !== 7 || isRepresented">
        <a class="dropdown-item" (click)="negotiationDate.edit(negotiation)">{{ 'NEGOTIATION_ACTIONS.EDIT_BOOKING_DATE'|translate }}</a>
      </li>
      <li role="menuitem" [class.disabled]="negotiation.status.id !== 7 || isRepresented">
        <a class="dropdown-item" (click)="goToProposeAmendments()">{{ 'NEGOTIATION_ACTIONS.AMENDMENTS'|translate }}</a>
      </li>
      <li role="menuitem" [class.disabled]="negotiation.status.id !== 7 || isRepresented"
      *ngIf="!company.activity.broker">
        <a class="dropdown-item" (click)="negotiationDelegate.delegate(negotiation)">{{ 'NEGOTIATION_ACTIONS.DELEGATE_TRADE'|translate }}</a>
      </li>
      <li role="menuitem" [class.disabled]="negotiation.status.id !== 7 || isRepresented || negotiation.buyer.id !== company.id"
      *ngIf="negotiation.order.type.id === 1">
        <a class="dropdown-item"
        [routerLink]="['/company', company.id, 'create-barter']"
        [queryParams]="{ negotiations: [negotiation.id] }">{{ 'NEGOTIATION_ACTIONS.TURN_BARTER'|translate }}</a>
      </li>
      <li role="menuitem" [class.disabled]="negotiation.status.id !== 7 || isRepresented">
        <a class="dropdown-item"
        (onConfirm)="cancelNegotiation()"
        [confirm-options]="{
          body: 'NEGOTIATION_ACTIONS.REQUEST_CANCELLATION_BODY'|translate,
          action: 'GLOBAL.YES_SEND'|translate
        }">{{ 'NEGOTIATION_ACTIONS.REQUEST_CANCELLATION'|translate }}</a>
      </li>
      <li role="separator" class="divider"></li>
    </ng-container>
    <li role="menuitem" [class.disabled]="negotiation.status.id !== 7 || isRepresented || !editorAccount">
      <a class="dropdown-item" (click)="negotiationLinker.link(negotiation)">{{ 'NEGOTIATION_ACTIONS.LINK_NEGOTIATIONS'|translate }}</a>
    </li>
    <li role="menuitem" [class.disabled]="!editorAccount">
      <a class="dropdown-item" (click)="referencePicker.show(negotiation)">{{ 'NEGOTIATION_ACTIONS.EXTERNAL_ID'|translate }}</a>
    </li>
    <ng-container *ngIf="company?.hasModule('execution') && editorAccount">
      <li class="divider"></li>
      <li role="menuitem">
        <a class="dropdown-item" [routerLink]="['/company', company.id, 'execution']">Execute</a>
      </li>
    </ng-container>
  </ul>
</div>

<ng-template #fileButton let-title="title" let-key="key" let-icon="icon" let-disabled="disabled">
  <button type="button"
  class="btn btn-link btn-xs"
  [title]="title"
  (click)="viewFile(key, title)"
  [disabled]="processing ||  
  disabledKeys[key] ||
  (negotiation.times_booked === 0 && disabled)"><i class="material-symbols-rounded">{{ icon }}</i></button>
</ng-template>

<ag-reference-number-picker #referencePicker="agReferenceNumberPicker"
[company-id]="company?.id"
[document-type]="company?.market.configuration.order.contract.type"
(saveReference)="onSaveReference()"></ag-reference-number-picker>

<ag-negotiation-linker #negotiationLinker
[company]="company"></ag-negotiation-linker>

<ag-negotiation-delegate #negotiationDelegate
[company]="company"></ag-negotiation-delegate>

<ag-negotiation-date #negotiationDate
[company]="company"></ag-negotiation-date>

<slots-modals #slotsModals
[company]="company"
[user]="user"></slots-modals>

<ag-file-preview #filePreviewer></ag-file-preview>

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { Company } from '../../models/company.model';
import { CompanyService } from '../../services/company.service';

@Injectable()
export class CompanyResolver {

  constructor(
    private companyService: CompanyService
  ) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<Company> {
    let companyId = parseInt(route.parent.paramMap.get('companyId'));
    return this.companyService.getRequestCompany(companyId);
  }
}

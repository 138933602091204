<ng-template #docsModal>
  <!-- modal -->
  <div class="modal-header">
    <button type="button" class="close" [disabled]="processing" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">{{ 'DOC_CUSTOM.TITLE'|translate }}</h4>
  </div>
  <div class="loading" *ngIf="loading">
    <spinner message="{{ 'GLOBAL.LOADING'|translate }}"></spinner>
  </div>
  <form (ngSubmit)="form.valid && submit()" #form="ngForm"
  *ngIf="!loading">
    <div class="modal-body">
      <p [innerHtml]="'DOC_CUSTOM.HELP'|translate"></p>

      <div class="row">
        <div class="col-xs-12">
          <div class="form-group">
            <label class="control-label">{{ 'DOC_CUSTOM.HEADER'|translate }}</label>
            <quill-editor
            placeholder="{{ 'SLOTS.ASSIGN_MODAL.OBSERVATIONS_PLACEHOLDER'|translate }}"
            [readOnly]="processing"
            [modules]="{toolbar: toolbarOptions}"
            [maxLength]="maxLength"
            #headerInput="ngModel"
            name="header"
            [(ngModel)]="data.header.model"></quill-editor>
            <span class="small text-danger"
            [class.invisible]="headerInput.valid">{{ 'ERROR_LIST.MAX_LENGTH'|translate: { max: maxLength } }}</span>
          </div>
        </div>
        <div class="col-xs-12">
          <div class="form-group">
            <label class="control-label">{{ 'DOC_CUSTOM.FOOTER'|translate }}</label>
            <quill-editor
            placeholder="{{ 'SLOTS.ASSIGN_MODAL.OBSERVATIONS_PLACEHOLDER'|translate }}"
            [readOnly]="processing"
            [modules]="{toolbar: toolbarOptions}"
            [maxLength]="maxLength"
            #footerInput="ngModel"
            name="footer"
            [(ngModel)]="data.footer.model"></quill-editor>
            <span class="small text-danger"
            [class.invisible]="footerInput.valid">{{ 'ERROR_LIST.MAX_LENGTH'|translate: { max: maxLength } }}</span>
          </div>
        </div>
      </div>

      <p class="small text-muted"[innerHtml]="'DOC_CUSTOM.INFO'|translate"></p>
    </div>
    <div class="modal-footer">
      <button type="button" (click)="closeModal()" class="btn btn-link" [disabled]="processing" data-dismiss="modal">{{ 'GLOBAL.CANCEL'|translate }}</button>
      <button type="submit" class="btn btn-agree" [disabled]="processing || form.invalid" (click)="save()">{{ 'GLOBAL.SAVE_CHANGES'|translate }}</button>
    </div>
  </form>
</ng-template>
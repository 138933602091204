<div class="container">
  <div class="content-box">
    <ol class="breadcrumb">
      <li><a routerLink="../setup">{{ 'GLOBAL.CONFIG_ASSISTANT'|translate }}</a></li>
      <li class="active">{{ 'GLOBAL.CERTIFICATES'|translate }}</li>
    </ol>
    <div class="ag-header sticky">
      <div class="ag-header-title">
        <h3>{{ 'CERTIFICATES.TITLE'|translate }}</h3>
      </div>
      <div class="ag-header-actions">
        <button type="button"
        class="btn btn-agree" (click)="openModal(certificateModal)"><i class="material-symbols-rounded hidden-xs hidden-sm">add</i> {{ 'CERTIFICATES.ADD_CERTIFICATE'|translate }}</button>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12">
        <p>{{ 'CERTIFICATES.HEADING'|translate }}</p>
      </div>
    </div>

    <certificates-table
      *ngIf="company"
      [company]="company"
      [reloadCertificates]="reloadCertificates"
      [adminMode]="true"></certificates-table>
  </div>
</div>

<!-- modal -->
<ng-template #certificateModal>
  <div class="modal-header">
    <button type="button" class="close" [disabled]="processing" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">{{ 'CERTIFICATES.MODAL.TITLE'|translate }}</h4>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-xs-12">
        <p [innerHtml]="'CERTIFICATES.MODAL.BODY'|translate"></p>
      </div>
    </div>
    <form id="certificate-form" (ngSubmit)="form.valid && submit()" #form="ngForm">
      <div class="row">
        <div class="col-xs-12">
          <div class="form-group" hasError>
            <label for="holder" class="control-label">{{ 'CERTIFICATES.MODAL.HOLDER'|translate }}</label>
            <input
            [disabled]="processing"
            maxlength="256"
            type="text" class="form-control" id="holder" name="holder" placeholder="{{ 'CERTIFICATES.MODAL.HOLDER_PLACEHOLDER'|translate }}" [(ngModel)]="company_certificate.holder"
            [modalFocus]="true" required>
            <span class="glyphicon glyphicon-remove form-control-feedback" aria-hidden="true"></span>
            <error-list></error-list>
            <p class="help-block small">{{ 'CERTIFICATES.MODAL.HOLDER_HELP'|translate }}</p>
          </div>
          <div class="form-group" hasError>
            <label for="type" class="control-label">{{ 'GLOBAL.TYPE'|translate}}</label>
            <select class="form-control" id="type" name="type" required (change)="typeChange($event)" [(ngModel)]="company_certificate.certificate_type">
              <option [ngValue]="null" [selected]="true" [disabled]="true">{{ 'CERTIFICATES.MODAL.TYPE_PLACEHOLDER'|translate}}</option>
              <option *ngFor="let t of certificate_types" [ngValue]="t">{{ t.name }}</option>
            </select>
            <span class="glyphicon glyphicon-remove form-control-feedback" aria-hidden="true"></span>
            <error-list></error-list>
          </div>
          <div class="form-group" hasError *ngIf="company_certificate.certificate_type?.id === 3">
            <label for="product_category" class="control-label">{{ 'CERTIFICATES.MODAL.PRODUCT_CATEGORY'|translate }}</label>
            <input
            [disabled]="processing"
            maxlength="256"
            type="text" class="form-control" id="product_category" name="product_category" placeholder="{{ 'CERTIFICATES.MODAL.PRODUCT_CATEGORY_PLACEHOLDER'|translate }}" [(ngModel)]="company_certificate.category">
            <span class="glyphicon glyphicon-remove form-control-feedback" aria-hidden="true"></span>
            <error-list></error-list>
          </div>
          <div class="form-group" hasError>
            <label for="number" class="control-label">{{ 'CERTIFICATES.MODAL.NUMBER'|translate }}</label>
            <input
            [disabled]="processing"
            maxlength="256"
            type="text" class="form-control" id="number" name="number" [(ngModel)]="company_certificate.number" placeholder="{{ 'CERTIFICATES.MODAL.NUMBER_PLACEHOLDER'|translate }}" required>
            <span class="glyphicon glyphicon-remove form-control-feedback" aria-hidden="true"></span>
            <error-list></error-list>
          </div>
        </div>
      </div>
      <ng-container *ngIf="company_certificate.certificate_type">
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group" hasError>
              <label for="name" class="control-label">{{ 'GLOBAL.NAME'|translate}}</label>
              <select class="form-control" id="name" name="name" required [(ngModel)]="company_certificate.certificate">
                <option [ngValue]="null" [selected]="true" [disabled]="true">{{ 'CERTIFICATES.MODAL.NAME_PLACEHOLDER'|translate }}</option>
                <option *ngFor="let t of certificates" [ngValue]="t">{{ t.name }}</option>
                <option [ngValue]="{id:0, name:''}">{{ 'CERTIFICATES.MODAL.OPTION_OTHER'|translate }}</option>
              </select>
              <span class="glyphicon glyphicon-remove form-control-feedback" aria-hidden="true"></span>
              <error-list></error-list>
            </div>
          </div>
          <div class="col-sm-6" *ngIf="company_certificate.certificate?.id === 0">
            <div class="form-group" hasError>
              <label for="other_name" class="control-label hidden-xs">&nbsp;</label>
              <input
              [disabled]="processing"
              maxlength="256"
              type="text" class="form-control" id="other_name" name="other_name" placeholder="{{ 'CERTIFICATES.MODAL.OTHER_NAME_PLACEHOLDER'|translate }}" [(ngModel)]="company_certificate.certificate.other" required>
              <span class="glyphicon glyphicon-remove form-control-feedback" aria-hidden="true"></span>
              <error-list></error-list>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group" hasError>
              <label for="issued_by" class="control-label">{{ 'GLOBAL.ISSUED_BY'|translate}}</label>
              <select class="form-control" id="entity" name="entity" required [(ngModel)]="company_certificate.entity">
                <option [ngValue]="null" [selected]="true" [disabled]="true">{{ 'CERTIFICATES.MODAL.ISSUED_BY_PLACEHOLDER'|translate}}</option>
                <option *ngFor="let t of entities" [ngValue]="t">{{ t.name }}</option>
                <option [ngValue]="{id:0, name:''}">{{ 'CERTIFICATES.MODAL.OPTION_OTHER'|translate }}</option>
              </select>
              <span class="glyphicon glyphicon-remove form-control-feedback" aria-hidden="true"></span>
              <error-list></error-list>
            </div>
          </div>
          <div class="col-sm-6" *ngIf="company_certificate.entity?.id === 0">
            <div class="form-group" hasError>
              <label for="other_issued_by" class="control-label hidden-xs">&nbsp;</label>
              <input
              [disabled]="processing"
              maxlength="256"
              type="text" class="form-control" id="other_issued_by" name="other_issued_by" placeholder="{{ 'CERTIFICATES.MODAL.OTHER_ISSUED_BY_PLACEHOLDER'|translate }}" [(ngModel)]="company_certificate.entity.other" required>
              <span class="glyphicon glyphicon-remove form-control-feedback" aria-hidden="true"></span>
              <error-list></error-list>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group" hasError>
              <label for="date_from" class="control-label">{{ 'CERTIFICATES.MODAL.DATE_FROM'|translate }}</label>
              <div class="input-group">
                <span class="input-group-addon">
                  <i class="material-symbols-rounded">today</i>
                </span>
                <input type="text"
                [disabled]="processing"
                name="date_from"
                class="form-control"
                [(ngModel)]="company_certificate.valid_from"
                bsDatepicker
                container="#certificate-form"
                [bsConfig]="{showWeekNumbers: false, isAnimated: true, dateInputFormat: 'DD/MM/YYYY'}"
                placeholder="{{ 'GLOBAL.DATE_PLACEHOLDER'|translate }}" 
                required readonly>
              </div><!-- /btn-group -->
              <error-list></error-list>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group" hasError>
              <label for="date_to" class="control-label">{{ 'CERTIFICATES.MODAL.DATE_TO'|translate }}</label>
              <div class="input-group">
                <span class="input-group-addon">
                  <i class="material-symbols-rounded">today</i>
                </span>
                <input type="text"
                [disabled]="processing"
                name="date_to"
                class="form-control"
                [(ngModel)]="company_certificate.valid_to"
                bsDatepicker
                container="#certificate-form"
                [bsConfig]="{showWeekNumbers: false, isAnimated: true, dateInputFormat: 'DD/MM/YYYY'}"
                [minDate]="today"
                placeholder="{{ 'GLOBAL.DATE_PLACEHOLDER'|translate }}" 
                required readonly>
              </div><!-- /btn-group -->
              <error-list></error-list>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12">
            <div class="form-group" hasError>
              <label for="scope" class="control-label">{{ 'CERTIFICATES.MODAL.SCOPE'|translate }}</label>
              <textarea class="form-control" id="scope" name="scope"
              maxlength="512"
              [disabled]="processing"
              placeholder="{{ 'CERTIFICATES.MODAL.SCOPE_PLACEHOLDER'|translate }}" [(ngModel)]="company_certificate.scope" required></textarea>
              <span class="glyphicon glyphicon-remove form-control-feedback" aria-hidden="true"></span>
              <error-list></error-list>
              <p class="help-block small">{{ 'CERTIFICATES.MODAL.SCOPE_HELP'|translate }}</p>
            </div>
            <div class="form-group" hasError>
              <label for="image" class="control-label">{{ 'CERTIFICATES.MODAL.IMAGE'|translate }}</label>
              <file-input
              drop-area="#certificate-form"
              name="file-1[]" 
              inputId="file-1" 
              [max-size]="5242880"   
              accept="image/*"
              [disabled]="processing"
              [(ngModel)]="company_certificate.image"
              required></file-input>
              <error-list></error-list>
              <p class="help-block small">{{ 'CERTIFICATES.MODAL.IMAGE_HELP'|translate }}</p>
            </div>
          </div>
        </div>
      </ng-container>
    </form>
  </div>

  <div class="modal-footer">
    <button type="button" (click)="closeModal()" [disabled]="processing" class="btn btn-link" data-dismiss="modal">{{ 'GLOBAL.CANCEL'|translate}}</button>
    <button [disabled]="processing" form="certificate-form" type="submit" class="btn btn-agree">{{ 'GLOBAL.SEND'|translate}}</button>
  </div>
</ng-template>
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { Company } from '../../../../../models/company.model';
import { Order } from '../../models/order.model';

@Component({
  selector: 'negotiation-list',
  templateUrl: './negotiation-list.component.html',
  styleUrls: ['./negotiation-list.component.scss']
})
export class NegotiationListComponent implements OnInit, OnChanges {

  @Input() public order: Order;
  // Viewing company
  @Input() public company: Company;

  @Output() readonly saveReference = new EventEmitter();

  /** @ignore */
  public totalBooked = 0;
  /** @ignore */
  public totalPending = 0;
  /** The language currently used. */
  public currentLang: string;

  /** @ignore */
  constructor(
    private router: Router,
    private translateService: TranslateService
  ) { }

  /** @ignore */
  ngOnInit(): void {
    this.currentLang = this.translateService.currentLang === 'es' ? undefined : this.translateService.currentLang;
    this.sumTotals();
  }

  /** @ignore */
  ngOnChanges(changes: SimpleChanges): void {
    this.sumTotals();
  }

  /** @ignore */
  public onSaveReference(): void {
    this.saveReference.emit();
  }

  /** @ignore */
  public gotoNegotiation(negotiation): void {
    this.router.navigateByUrl('/company/' + this.company.id + '/counter-order/' + negotiation.id);
  }

  private sumTotals(): void {
    this.totalBooked = this.order.totalBooked;

    this.totalPending = this.order.negotiations.reduce((total, negotiation) => {
      return total + ([1, 2, 3, 4].includes(negotiation.status.id) ? negotiation.proposal.business_detail.quantity.value : 0);
    }, 0);
  }

  /**
   * Avoid unnecessary rendering on ngFor
   */
  public trackByStatusId(index: number, item: any): number {
    return item.status.id;
  }
}

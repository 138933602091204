import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';

import { Company } from '../../../models/company.model';
import { Product } from '../../../models/product.model';
import { CompanyService } from '../../../services/company.service';
import { ComponentCommService } from '../../../services/component-comm.service';
import { MarketService } from '../../../services/market.service';
import { TeamService } from '../../../services/team.service';

@Component({
  selector: 'commercial-manager',
  templateUrl: './commercial-manager.component.html',
  styleUrls: ['./commercial-manager.component.scss']
})
export class CommercialManagerComponent implements OnInit, OnDestroy {

  @ViewChild('productSelector', { static: true }) private readonly productSelector: TemplateRef<any>;

  public company: Company;
  /** Flag used to indicate if the component is loading information. */
  public loading: boolean = true;
  public updating: boolean;
  public members = [];
  public modalRef: BsModalRef;
  public user_selected: string;
  public products_selected = [];
  public products: Product[] = [];

  private account_selected: number;
  private companyId: number;
  private subscriptions: Subscription[] = [];

  constructor(
    private modalService: BsModalService,
    private route: ActivatedRoute,
    private componentComm: ComponentCommService,
    private teamService: TeamService,
    private companyService: CompanyService,
    private router: Router,
    private marketService: MarketService
  ) { }

  ngOnInit(): void {
    this.componentComm.emit({ name: 'app-title', title: 'COMMERCIAL_MANAGER.TITLE' });

    this.subscriptions.push(this.route.parent.params.subscribe(params => {
      this.companyId = parseInt(params['companyId']);
    }));

    this.subscriptions.push(this.marketService.watchProducts().subscribe(response => {
      if (response) this.products = response.sort((a, b) => a.name.localeCompare(b.name)); // Sorts alphabetically
    }));

    this.subscriptions.push(this.companyService.watch().subscribe(company => {
      if (!company) return;

      this.company = company;

      // Kick user if module is not enabled
      if (!this.company.hasModule('commercial-manager')) this.router.navigateByUrl('/companies');
      else {
        this.subscriptions.push(this.teamService.getCommercials(this.companyId, true).subscribe(mermbers => {
          let data_members = this.setDataUsersScheme(mermbers);
          this.members = this.generateRandomData(data_members);
          this.loading = false;
        }));
      }
    }));
  }

  public showProductSelector(user_data): void {
    this.modalRef = this.modalService.show(this.productSelector);

    this.account_selected = user_data.account_id;
    this.user_selected = user_data.name;
    this.products_selected = user_data.products_complete;
  }

  private generateRandomData(data_members): any { // For mockup purposes
    let response: { account_id: number, name: string, email: string, products: string[], products_complete: any[], productsSorter: string, buy }[] = [],
      users = data_members,
      randomRange = function (max: number, min?: number, multiple?: number) {
        min = min || 0;
        multiple = multiple || 1;
        return ((Math.floor(Math.random() * max) * multiple) + min);
      },
      transformProducts = function (data_products) {
        let new_data = [];

        for (let x in data_products) {
          new_data.push(data_products[x].name);
        }

        return new_data;
      };

    users.forEach(function (value, index, set) {
      const tradesPerDay = randomRange(6),
        tradesPerWeek = (tradesPerDay * randomRange(5)) + tradesPerDay,
        tradesPerMonth = (randomRange(5) * randomRange(3)) + tradesPerWeek,
        totalTrades = (randomRange(5) * randomRange(3) * randomRange(24)) + tradesPerMonth,
        volumePerTrade = randomRange(10, 30, 30),
        // numberOfProducts = randomRange(3),
        stopPerDay = randomRange(50, 0, 100);

      response[index] = {
        account_id: set[index].account_id,
        name: set[index].name,
        email: set[index].email,
        products: (set[index].products.length > 0 ? transformProducts(set[index].products) : ['All products']),
        products_complete: (set[index].products.length > 0 ? set[index].products : ['All products']),
        productsSorter: '',
        buy: {
          stop: {
            day: stopPerDay,
            week: stopPerDay * randomRange(3),
            month: stopPerDay * randomRange(10)
          },
          traded: {
            day: tradesPerDay * volumePerTrade,
            week: tradesPerWeek * volumePerTrade,
            month: tradesPerMonth * volumePerTrade,
            total: totalTrades * volumePerTrade
          },
          trades: {
            day: tradesPerDay,
            week: tradesPerWeek,
            month: tradesPerMonth,
            total: totalTrades
          }
        }
      };

      response[index].productsSorter = response[index].products.join('');
    });

    return response;
  }

  private setDataUsersScheme(data_members): any[] {
    let users_data = [];

    for (let x in data_members) {
      users_data.push({
        account_id: data_members[x].id,
        name: data_members[x].name,
        email: data_members[x].email,
        products: data_members[x].products
      });
    }

    return users_data;
  }

  public saveChanges(): void {
    this.updating = true;
    this.hide();

    let data_send = this.transformProductsSelected();

    this.subscriptions.push(this.companyService.crudAccountProduct(this.companyId, data_send).subscribe(
      roles => {
        this.subscriptions.push(this.teamService.getCommercials(this.companyId, true).subscribe(mermbers => {
          let data_members = this.setDataUsersScheme(mermbers);
          this.members = this.generateRandomData(data_members);

          this.updating = false;
        }));
      }));
  }

  private transformProductsSelected(): any {
    let data_new = {
      'account': {
        'id': this.account_selected,
        'products': [],
        'products_complete': []
      }
    };

    if (this.products_selected.length > 0) {
      for (let x in this.products_selected) {
        data_new.account.products.push(this.products_selected[x].id);
        data_new.account.products_complete.push({
          'id': this.products_selected[x].id,
          'name': this.products_selected[x].name
        });
      }
    }

    return data_new;
  }

  private hide(): void {
    this.modalService.hide(1);
  }

  /** @ignore */
  ngOnDestroy(): void {
    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { instanceToInstance } from 'class-transformer';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

import { Company } from '../../../models/company.model';
import { FiscalId, validFiscalId } from '../../../models/fiscal-id.model';
import { Pagination, parsePagination } from '../../../models/pagination.model';
import { CompanyService } from '../../../services/company.service';
import { ComponentCommService } from '../../../services/component-comm.service';
import { multiSort } from '../../../utilities/array';
import { TableFilters } from '../../../utilities/table-filters';
import { CompanyNote } from '../../models/company-note.model';
import { DataColumn, WorkflowDataValue } from '../../modules/fintech/models/work-flow-data-field.model';
import { NotesService } from '../../services/notes.service';

@Component({
  selector: 'ag-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss']
})
export class NotesComponent extends TableFilters implements OnInit, OnDestroy {

  @ViewChild('columnModal', { static: true }) private readonly columnModal: TemplateRef<any>;
  @ViewChild('addModal', { static: true }) private readonly addModal: TemplateRef<any>;

  public changes: CompanyNote[];
  public columns: DataColumn[];
  public company: Company;
  public fiscalId: FiscalId;
  public newBatch: string;
  public newColumn: DataColumn;
  public notes: CompanyNote[];
  public paginationData: Pagination;
  /**
   * Flag used to enable/disable UI buttons and links when an API request is in
   * progress.
   */
  public processing: boolean;

  private modalRef: BsModalRef;
  private modalSub: Subscription;
  private newNotes: CompanyNote[];
  private pusherSubscription: Subscription;

  constructor(
    private companyService: CompanyService,
    private componentComm: ComponentCommService,
    private notesService: NotesService,
    // private loginService: LoginService,
    private modalService: BsModalService,
    public route: ActivatedRoute,
    public router: Router
  ) {
    super(route, router);
  }

  ngOnInit(): void {
    this.componentComm.emit({ name: 'app-title', title: 'GLOBAL.NOTES' });

    this.subscriptions.push(this.companyService.watch().subscribe(company => {
      if (!company) return;

      this.company = company;

      if (this.company.activation_level.id === 1) {
        this.router.navigateByUrl('/company/' + this.company.id + '/market');
      } else {
        if (this.company.market) {
          if (this.company.market.configuration.company.fiscal_id) {
            this.fiscalId = this.company.market.configuration.company.fiscal_id;
          }
        }

        this.subscriptions.push(this.notesService.getColumns(this.company.id).subscribe(columns => {
          this.columns = multiSort(columns || [], 'order');

          this.onFiltersChange = this.loadData;
          this.setupFilters();

          if (this.filters.begins) this.fiscalId_search = this.filters.begins;
        }));
      }
    }));
  }

  public addColumn(): void {
    this.newColumn = new DataColumn({
      uuid: uuidv4(),
      order: this.columns?.length > 0 ? this.columns[this.columns.length - 1].order + 1 : 1
    });
    this.openModal(this.columnModal);
  }

  public add(): void {
    this.newBatch = undefined;
    this.openModal(this.addModal);
  }

  public submitColumn(): void {
    // Generate unique key
    const newKey = this.newColumn.slug
      .toLowerCase()
      .replace(/[,.-;]/g, '')
      .replace(/\s+/g, "_");

    let uniqueKey = newKey;

    let counter = 0;
    const checkUniqueKey = () => {
      const exists = this.columns.findIndex(column => column.key === uniqueKey) !== -1;
      if (exists) {
        uniqueKey = newKey + '_' + ++counter;
        checkUniqueKey();
      }
    };
    checkUniqueKey();

    this.newColumn.key = uniqueKey;

    this.processing = true;

    this.subscriptions.push(this.notesService.createColumns(this.company.id, [this.newColumn]).subscribe(columns => {
      this.closeModal();
      this.columns = multiSort(this.columns.concat(columns), 'order');
      this.processing = false;
    }));
  }

  public fieldChange(value: any, note: CompanyNote, column: DataColumn): void {
    let newNote = instanceToInstance(note);

    newNote.notes = {
      ...(newNote.notes || {}),
      [column.key]: new WorkflowDataValue({
        slug: column.slug,
        type: column.type,
        value: value
      })
    };

    if (!this.changes) this.changes = [];
    const indexNote = this.changes.findIndex(n => n.fiscalId === note.fiscalId);

    if (indexNote !== -1) {
      this.changes[indexNote].notes = { ...this.changes[indexNote].notes, ...newNote.notes };
    } else this.changes.push(newNote);
  }

  public save(): void {
    this.processing = true;

    if (this.changes?.length > 0) {
      const nextNote = this.changes.pop();

      this.subscriptions.push(this.notesService.edit(this.company.id, nextNote).subscribe(note => {
        this.save();
      }));
    } else {
      this.processing = false;
      this.changes = undefined;
    }
  }

  public discard(): void {
    const temp = instanceToInstance(this.notes);
    this.notes = undefined;

    setTimeout(() => {
      this.notes = temp;
      this.changes = undefined;
    });
  }

  public fiscalId_search: string;
  public search(form: NgForm): void {
    // Remove the all non-numeric characters
    this.fiscalId_search = this.fiscalId_search.replace(/\D/g, '');
    if (this.fiscalId_search.length > 0) {
      this.changeFilter({
        key: 'begins',
        value: this.fiscalId_search
      });
    } else {
      form.resetForm();
    }
  }

  public resetSearch(form: NgForm): void {
    form.resetForm();
    this.fiscalId_search = undefined;
    this.changeFilter({
      key: 'begins',
      value: null
    });
  }

  public validate(): void {
    const parsedInput: string[] = this.newBatch.split(/\r\n|\r|\n|\s|,|;/).sort().map(v => {
      let clean = v;

      switch (this.fiscalId.type) {
        case 'cuit':
          clean = v.replace(/\D+/g, ''); // Removes all non digits
          break;

        case 'rfc':
          clean = v.replace(/[^a-z0-9]/gi, ''); // Removes all non alphanumerics
          break;
      }

      return clean;
    });

    const uniques: string[] = parsedInput.filter((fiscal_id, pos, arr) => {
      const validValue: string = validFiscalId(new FiscalId({
        ...this.fiscalId,
        value: fiscal_id
      }), true);
      const unique = (!pos || validValue !== arr[pos - 1]);

      return unique && // Distinct
        validValue != undefined; // Valid
    });

    this.newNotes = [];

    uniques.forEach(fiscal_id => {
      this.newNotes.push({
        fiscalId: fiscal_id,
        notes: {}
      });
    });

    this.submitNotes();
  }

  public delete(note: CompanyNote): void {
    this.processing = true;

    this.subscriptions.push(this.notesService.delete(this.company.id, note).subscribe(response => {
      this.notes = this.notes.filter(n => note.fiscalId !== n.fiscalId);
      this.processing = false;
    }));
  }

  public deleteColumn(column: DataColumn): void {
    this.processing = true;

    this.subscriptions.push(this.notesService.deleteColumn(this.company.id, column).subscribe(response => {
      this.columns = this.columns.filter(c => column.uuid !== c.uuid);
      this.processing = false;
    }));
  }

  private submitNotes(): void {
    this.processing = true;

    this.subscriptions.push(this.notesService.create(this.company.id, this.newNotes).subscribe(notes => {
      this.closeModal();
      this.processing = false;
      this.newNotes = undefined;
      this.loadData();
    }));
  }

  private loadData(): void {
    this.processing = true;
    if (this.pusherSubscription) this.pusherSubscription.unsubscribe();

    this.pusherSubscription = this.notesService.get(this.company.id, this.filters).subscribe(response => {
      this.paginationData = parsePagination(response.headers);
      this.dataLoaded(response.body);
    });
  }

  private dataLoaded(data: CompanyNote[]): void {
    this.notes = data || [];
    this.processing = false;
  }

  /** Generic Modal trigger. */
  private openModal(template: TemplateRef<any>, c: string = ''): void {
    this.modalRef = this.modalService.show(template, { class: c });

    this.modalSub = this.modalRef.onHide.subscribe((reason: string) => {
      this.modalSub.unsubscribe();
      this.modalRef = undefined;
      // Reset all values
      // this.processing = false;
    });
  }

  /** Closes the most recent opened modal. */
  public closeModal(onHide: Function = null): void {
    if (this.modalRef) {
      this.modalRef.hide();
      if (onHide) this.modalRef.onHide.subscribe(onHide);
    } else {
      if (onHide) onHide();
    }
  }

  /** @ignore */
  ngOnDestroy(): void {
    // Unsubscribe from everything
    this.closeModal();

    super.ngOnDestroy();
    if (this.pusherSubscription) this.pusherSubscription.unsubscribe();
  }
}

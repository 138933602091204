<div class="co-title" *ngIf="order.negotiations.length">
  <span class="pull-right">
    <ng-container *ngIf="company?.market.quantity_units.length === 1">
      {{ 'NEGOTIATION_LIST.BOOKED'|translate }}: <b>{{ totalBooked|number:undefined:currentLang }}</b>
      {{ company?.market.quantity_units[0].abbrev }}.
      <span *ngIf="
        order.operation_type === 'compra' &&
        order.business_detail.quantity.type.id === 2 &&
        order.business_detail.quantity.value > 0
      ">
        ({{ (100 * totalBooked/order.business_detail.quantity.value)|number:'1.2-2':currentLang }}%
        {{ 'NEGOTIATION_LIST.OF'|translate }}
        {{ order.business_detail.quantity.value|number:'1.0-0':currentLang }} {{ order.business_detail.quantity.unit.name }})
      </span>
      <span class="hidden-xs"> - {{ 'NEGOTIATION_LIST.UNDER_NEGOTIATION'|translate }}: <b>{{ totalPending|number:undefined:currentLang }}</b>
      {{ company?.market.quantity_units[0].abbrev }}.</span>
    </ng-container>
  </span>
  <span class="text-uppercase">
    <!-- <i class="material-symbols-rounded">subdirectory_arrow_right</i> -->
    <b>{{ order.negotiations.length }}</b> {{ 'NEGOTIATION_LIST.COUNTERORDERS'|translate }}
  </span>
</div>
<div class="tree" *ngIf="company">
  <div class="co-container" *ngFor="let negotiation of order.negotiations; trackBy: trackByStatusId">
    <div class="co-logo hidden-xs">
      <company-logo [company]="negotiation.company"></company-logo>
    </div>
    <div class="co-link truncate">
      <a href routerLink="/company/{{ company.id }}/counter-order/{{ negotiation.id }}" title="{{ negotiation.company.name }}">
        <samp class="small">#{{ negotiation.id }}:</samp>
        <b [class.highlighted-text]="company.id === negotiation.company.id">{{ negotiation.company.name }}</b>
      </a>
    </div>
    <div class="co-status text-uppercase" (click)="gotoNegotiation(negotiation)">
      <div class="truncate" *ngIf="![1, 2, 3].includes(negotiation.status.id)"
      [class.status-warning]="[4, 10, 11, 20, 21].includes(negotiation.status.id) ||
        (negotiation.status.id === 12 && negotiation.company.id !== company.id) ||
        (negotiation.status.id === 13 && negotiation.company.id === company.id)"
      [class.status-info]="negotiation.status.id === 9 ||
        (negotiation.status.id === 12 && negotiation.company.id === company.id) ||
        (negotiation.status.id === 13 && negotiation.company.id !== company.id)"
      [class.status-danger]="[5, 6, 8, 14, 22].includes(negotiation.status.id)"
      [class.status-success]="negotiation.status.id === 7">
        <span>{{ negotiation.status.name }}</span>
      </div>
      <ng-container
        *ngIf="negotiation.times_booked > 0; then amendmentStatus; else counterorderStatus">
      </ng-container>
      <ng-template #counterorderStatus>
        <div class="truncate status-info" *ngIf="(negotiation.status.id === 1 && negotiation.company.id === company.id) || (negotiation.status.id === 2 && negotiation.company.id !== company.id)">
          <span>{{ 'NEGOTIATION_LIST.COUNTERORDER_SENT'|translate }}</span>
        </div>
        <div class="truncate status-warning" *ngIf="(negotiation.status.id === 1 && negotiation.company.id !== company.id) || (negotiation.status.id === 2 && negotiation.company.id === company.id)">
          <span>{{ 'NEGOTIATION_LIST.COUNTERORDER_RECEIVED'|translate }}</span>
        </div>
      </ng-template>
      <ng-template #amendmentStatus>
        <div class="truncate status-info" *ngIf="(negotiation.status.id === 1 && negotiation.company.id === company.id) || (negotiation.status.id === 2 && negotiation.company.id !== company.id)">
          <span>{{ 'NEGOTIATION_LIST.AMENDMENT_SENT'|translate }}</span>
        </div>
        <div class="truncate status-warning" *ngIf="(negotiation.status.id === 1 && negotiation.company.id !== company.id) || (negotiation.status.id === 2 && negotiation.company.id === company.id)">
          <span>{{ 'NEGOTIATION_LIST.AMENDMENT_RECEIVED'|translate }}</span>
        </div>
      </ng-template>
      <div class="truncate status-info" *ngIf="negotiation.status.id === 3 && negotiation.company.id === company.id">
        <span>{{ 'NEGOTIATION_LIST.PREBOOK_REQUESTED'|translate }}</span>
      </div>
      <div class="truncate status-warning" *ngIf="negotiation.status.id === 3 && negotiation.company.id !== company.id">
        <span>{{ 'NEGOTIATION_LIST.PREBOOK_RECEIVED'|translate }}</span>
      </div>
    </div>
    <div class="co-button">
      <negotiation-actions
      [order]="order"
      [negotiation]="negotiation"
      [company]="company"
      (saveReference)="onSaveReference()">
      </negotiation-actions>
    </div>
    <!-- <div class="co-button">
      <button class="btn btn-link btn-xs" [class.disabled]="negotiation.status.id !== 7" title="Crear nueva orden"><i class="material-symbols-rounded">note_add</i></button>
      </div> -->
  </div>
</div>

<ng-template #inviteModal>
  <!-- modal -->
  <div class="modal-header">
    <button type="button" class="close" [disabled]="processing" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">{{ 'TEAM_VIEW.INVITE_MODAL.TITLE'|translate: {company: company?.name} }}</h4>
  </div>
  <div class="modal-body">
    <p [innerHtml]="'TEAM_VIEW.INVITE_MODAL.BODY'|translate"></p>
    <form id="invite-form" (ngSubmit)="inviteForm.valid && invite()" #inviteForm="ngForm">
      <div class="form-group" hasError>
        <label for="email" class="control-label">{{ 'TEAM_VIEW.INVITE_MODAL.EMAIL'|translate }}</label>
        <input type="email" class="form-control input-lg"
        [disabled]="processing" id="email" name="email" placeholder="email" [(ngModel)]="invitation.email" required email #email="ngModel"
        [modalFocus]="true">
        <span class="glyphicon glyphicon-remove form-control-feedback" aria-hidden="true"></span>
        <error-list></error-list>
      </div>
      <div class="form-group" hasError>
        <label for="role" class="control-label">{{ 'TEAM_VIEW.INVITE_MODAL.ROLE'|translate }}</label>
        <select class="form-control input-lg has-placeholder"
        [disabled]="processing" id="role" name="role" [(ngModel)]="invitation.role" required #role="ngModel">
          <option [ngValue]="undefined" [selected]="true" [disabled]="true">{{ 'TEAM_VIEW.INVITE_MODAL.SELECT_ROLE'|translate }}</option>
          <option *ngFor="let role of roles" [ngValue]="role">{{ role.name }}</option>
        </select>
        <span class="glyphicon glyphicon-remove form-control-feedback" aria-hidden="true"></span>
        <error-list></error-list>
        <p class="help-block small">{{ invitation?.role?.description }}</p>
      </div>
      <alert type="danger" *ngFor="let error of errors" [dismissible]="true">{{ error|translate }}</alert>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" (click)="closeModal()" class="btn btn-link" [disabled]="processing"
      data-dismiss="modal">{{ 'GLOBAL.CANCEL'|translate }}</button>
    <button type="submit" form="invite-form" class="btn btn-agree" [disabled]="processing">{{ 'TEAM_VIEW.INVITE_MODAL.ADD_USER_BUTTON'|translate }}</button>
  </div>
</ng-template>
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { GroupBy } from '../../../../models/group-by.model';
import { Product } from '../../../../models/product.model';
import { Order } from '../models/order.model';
import { OrderService } from './order.service';

@Injectable()
export class OrderGroupResolver  {

  constructor(
    private orderService: OrderService
  ) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<GroupBy<Product, Order>[]> {

    return this.orderService.getWorkingOrders(1);
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { Company } from '../../../models/company.model';
import { Envelope } from '../../../models/envelope.model';
import { Pagination, parsePagination } from '../../../models/pagination.model';
import { CompanyService } from '../../../services/company.service';
import { ComponentCommService } from '../../../services/component-comm.service';
import { SignaturesService } from '../../../services/signatures.service';
import { TableFilters } from '../../../utilities/table-filters';

@Component({
  selector: 'app-envelopes',
  templateUrl: './envelopes.component.html',
  styleUrls: ['./envelopes.component.scss']
})
export class EnvelopesComponent extends TableFilters implements OnInit, OnDestroy {

  public company: Company;
  public envelopes: Envelope[];
  /** Flag used to indicate if the component is loading information. */
  public loading: boolean = true;
  public paginationData: Pagination;
  /**
   * Flag used to enable/disable UI buttons and links when an API request is in
   * progress.
  */
  public processing: boolean;

  private pusherSubscription: Subscription;

  constructor(
    private companyService: CompanyService,
    private componentComm: ComponentCommService,
    /** @ignore */
    public router: Router,
    /** @ignore */
    public route: ActivatedRoute,
    private signaturesService: SignaturesService
  ) {
    super(route, router);
  }

  ngOnInit(): void {
    this.componentComm.emit({ name: 'app-title', title: 'COMPANY.SIGNATURES' });

    this.subscriptions.push(this.companyService.watch().subscribe(company => {
      if (!company) return;

      this.company = company;

      const { signatures } = this.company.market.configuration;

      if (signatures && signatures.enabled) { // FAS-2869
        // Filters based on URL
        this.onFiltersChange = this.loadData;
        this.setupFilters();
      } else {
        // Kick user if module is not enabled
        this.router.navigateByUrl('/companies');
      }
    }));
  }

  private loadData(): void {
    if (this.pusherSubscription) this.pusherSubscription.unsubscribe();

    this.pusherSubscription = this.signaturesService.watch(this.company.id, this.route.parent.snapshot.queryParams).subscribe(response => {
      if (!response) return;

      this.paginationData = parsePagination(response.headers);
      this.dataLoaded(response.body);
    });
  }

  private dataLoaded(data: Envelope[]): void {
    this.envelopes = data;
    this.loading = false;
  }

  /** @ignore */
  ngOnDestroy(): void {
    if (this.pusherSubscription) this.pusherSubscription.unsubscribe();
    super.ngOnDestroy();
  }
}

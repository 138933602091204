import { Type } from "class-transformer";

export class ApiKey {
  api_key: string;
  user_id: number;

  @Type(() => Date)
  created_at: Date;
  @Type(() => Date)
  deleted_at?: Date;

  status: "ACTIVE" | "INACTIVE" | "DELETED";

  constructor(data: Partial<ApiKey> = {}) {
    Object.assign(this, data);
  }
}
import { Component, Input, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { instanceToInstance } from 'class-transformer';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';

import { Company } from '../../../models/company.model';
import { CurrentDateService } from '../../../services/current-date.service';
import { Negotiation } from '../../modules/commercial/models/negotiation.model';
import { OrderService } from '../../modules/commercial/services/order.service';

@Component({
  selector: 'ag-negotiation-date',
  templateUrl: './negotiation-date.component.html',
  styleUrls: ['./negotiation-date.component.scss']
})
export class NegotiationDateComponent implements OnDestroy {

  @ViewChild('modalTemplate', { static: true }) public readonly modalTemplate: TemplateRef<any>;

  /**
   * [[Company]] that edits the date.
   */
  @Input() public company: Company;

  /**
   * [[Negotiation]] to edit.
   */
  public negotiation: Negotiation;
  /**
   * Flag used to enable/disable UI buttons and links when an API request is in
   * progress.
   */
  public processing: boolean;
  public today_end: Date;

  private modalRef: BsModalRef;
  private modalSub: Subscription;
  private subscriptions: Subscription[] = [];

  constructor(
    private currentDate: CurrentDateService,
    private modalService: BsModalService,
    private orderService: OrderService
  ) {
    this.today_end = this.currentDate.get();
    this.today_end.setHours(23, 59, 59, 0);
  }

  /**
   * Opens the modal with the link wizard.
   */
  public edit(negotiation: Negotiation): void {
    this.negotiation = instanceToInstance(negotiation);
    this.openModal(this.modalTemplate);
  }

  public submit(): void {
    this.processing = true;
    this.subscriptions.push(this.orderService.editBookingDate(this.company.id, this.negotiation.id, this.negotiation.original_booking_date).subscribe(response => {
      this.closeModal();
    }));
  }

  /** Generic Modal trigger. */
  private openModal(template: TemplateRef<any>, c: string = ''): void {
    this.modalRef = this.modalService.show(template, { class: c });

    this.modalSub = this.modalRef.onHide.subscribe((reason: string) => {
      this.modalSub.unsubscribe();
      this.modalRef = undefined;
      // Reset all values
      this.processing = false;
    });
  }

  /** Closes the most recent opened modal. */
  public closeModal(onHide: Function = null): void {
    if (this.modalRef) {
      this.modalRef.hide();
      if (onHide) this.modalRef.onHide.subscribe(onHide);
    } else {
      if (onHide) onHide();
    }
  }

  /** @ignore */
  ngOnDestroy(): void {
    this.closeModal();

    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class DocsCustomizationService {

  private baseUrl: string = '/:apiBase/companies/:companyId/:sectionSlug-pdf';

  constructor(
    private http: HttpClient
  ) { }

  /** Returns the specified custom section of the [[Company]]. */
  public get(companyId: number, sectionSlug: string): Observable<DocsContent> {
    let url = this.baseUrl
      .replace(':companyId', companyId + '')
      .replace(':sectionSlug', sectionSlug + '');

    return this.http.get<{
      content: string
    }>(url).pipe(map(response => {
      return {
        section: sectionSlug,
        content: response.content || undefined
      }
    }));
  }

  /** Updates the specified custom section of the [[Company]]. */
  public update(companyId: number, sectionSlug: string, text: string): Observable<DocsContent> {
    let url = this.baseUrl
      .replace(':companyId', companyId + '')
      .replace(':sectionSlug', sectionSlug + '');

    return this.http.post<{
      content: string;
    }>(url, {
      content: text
    }).pipe(map(response => {
      return {
        section: sectionSlug,
        content: response.content || undefined
      }
    }));
  }

  public delete(companyId: number, sectionSlug: string): Observable<any> {
    let url = this.baseUrl
      .replace(':companyId', companyId + '')
      .replace(':sectionSlug', sectionSlug + '');

    return this.http.delete<any>(url);
  }
}

export class DocsContent {
  section: string;
  content: string;
}

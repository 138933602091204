import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { Webhook } from '../../../../models/webhook.model';
import { WebhookService } from '../../../services/webhook.service';

@Component({
  selector: 'ag-webhooks',
  templateUrl: './webhooks.component.html',
  styleUrls: ['./webhooks.component.scss']
})
export class WebhooksComponent implements OnInit {

  @Input() private companyId: number;

  public webhooks: Webhook[];
  public webhook: Webhook;
  public processing: boolean;

  private subscriptions: Subscription[] = [];

  constructor(
    private webhookService: WebhookService
  ) {
    this.newWh();
  }

  ngOnInit(): void {
    this.loadWebhooks();
  }

  private newWh(): void {
    this.webhook = new Webhook();
  }

  private loadWebhooks(): void {
    this.processing = true;
    this.subscriptions.push(this.webhookService.get(this.companyId).subscribe(webhooks => {
      this.webhooksLoaded(webhooks);
    }));
  }

  private webhooksLoaded(webhooks: Webhook[]): void {
    this.webhooks = webhooks;
    this.processing = false;
  }

  public createWebhook(): void {
    this.processing = true;
    this.subscriptions.push(this.webhookService.create(this.companyId, this.webhook).subscribe(wh => {
      this.webhooks.push(wh);
      this.newWh();
      this.processing = false;
    }));
  }

  public editWebhook(webhook: Webhook): void {
    this.processing = true;
    this.subscriptions.push(this.webhookService.update(this.companyId, webhook).subscribe(wh => {
      this.processing = false;
    }));
  }

  public deleteWebhook(webhook: Webhook): void {
    this.processing = true;
    this.subscriptions.push(this.webhookService.delete(this.companyId, webhook).subscribe(response => {
      this.webhooks = this.webhooks.filter(w => w.id !== webhook.id);
      this.processing = false;
    }));
  }
}

<ng-template #modalTemplate>
  <!-- modal -->
  <div class="modal-header">
    <button type="button" class="close" [disabled]="processing" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">{{ 'NEGOTIATION_ACTIONS.EDIT_BOOKING_DATE'|translate }}</h4>
  </div>
  <div class="modal-body">
    <p class="text-warning" [innerHtml]="'NEGOTIATION_ACTIONS.EDIT_BOOKING_DATE_HELP'|translate"></p>

    <form id="booking-form"
    (ngSubmit)="bookingForm.valid && submit()" #bookingForm="ngForm">
      <div class="form-group">
        <label class="control-label" for="booking_date">{{ 'GLOBAL.DATE'|translate }}</label>
        <div class="input-group">
          <span class="input-group-addon">
            <i class="material-symbols-rounded">date_range</i>
          </span>
          <input
          name="booking_date" id="booking_date"
          [bsConfig]="{showWeekNumbers: false, isAnimated: true, dateInputFormat: 'DD/MM/YYYY'}"
          [maxDate]="today_end"
          readonly
          [(ngModel)]="negotiation.original_booking_date"
          bsDatepicker
          required
          [disabled]="processing"
          class="form-control"
          placeholder="{{ 'GLOBAL.DATE_PLACEHOLDER'|translate }}">
        </div>
      </div>
    </form>

  </div>
  <div class="modal-footer">
    <button type="button" (click)="closeModal()" class="btn btn-link" [disabled]="processing" data-dismiss="modal">{{
      'GLOBAL.CANCEL'|translate }}</button>
    <button type="submit" form="booking-form" class="btn btn-agree"
      [disabled]="processing || bookingForm.pristine">{{ 'GLOBAL.SAVE_CHANGES'|translate }}</button>
  </div>
</ng-template>
<div class="ag-container">
  <div class="ag-col-secondary">
    <filter-set #filterSet="filterSet" [marketId]="company?.market.id">
      <filter-list key="product_id" sortKey="name"></filter-list>
      <filter-list key="zone_id" sortKey="name" *ngIf="type === 'zone' && company?.market.configuration.location.zone.enabled"></filter-list>
    </filter-set>
  </div>
  <div class="ag-col-primary">
    <div class="container-fluid">
      <div class="content-box">
        <div class="ag-header" [class.hidden]="!company?.market.configuration.location.zone.enabled">
          <div class="ag-header-title">
            <!-- Lugares de entrega:&nbsp; -->
            <div id="location-type-toggle" class="btn-group" data-toggle="buttons">
              <label class="btn btn-default btn-sm" [class.active]="type === 'zone' && company?.market.configuration.location.zone.enabled" [class.disabled]="loading">
                <input [disabled]="loading" type="radio" name="options" id="option1" autocomplete="off" [checked]="type === 'zone'" routerLink="../zone">
                {{ 'MARKET_SUMMARY.ZONES'|translate }}
              </label>
              <label class="btn btn-default btn-sm" [class.active]="type === 'location'" [class.disabled]="loading">
                <input [disabled]="loading" type="radio" name="options" id="option2" autocomplete="off" [checked]="type === 'location'" routerLink="../location">
                {{ 'MARKET_SUMMARY.LOCATIONS'|translate }}
              </label>
            </div>
          </div>
        </div>
        <div class="loading" *ngIf="loading">
          <spinner message="{{ 'GLOBAL.LOADING'|translate }}"></spinner>
        </div>
        <div class="row" *ngIf="!loading">
          <div class="col-xs-12">
            <!-- No data -->
            <div class="no-data" *ngIf="marketSummary.length === 0">
              <i class="material-symbols-rounded">not_interested</i>
              {{ 'ORDER.NO_RECORDS'|translate }}
            </div>
            <!-- Data -->
            <div class="flex-container" *ngIf="marketSummary.length > 0">
              <div class="fixed-panel" [class.extra-col]="company?.market.configuration.order.business_detail.price.type.enabled">
                <!-- https://www.w3.org/WAI/tutorials/tables/examples/threeheaders/ -->
                <table class="table table-market">
                  <!-- Spot -->
                  <thead>
                    <tr class="tr-align-center">
                      <th>
                        {{ 'MARKET_SUMMARY.DELIVERY_LOCATION'|translate }}
                      </th>
                      <th colspan="4" class="text-center">{{ company?.market.configuration.market_summary.available.label }}</th>
                      <!-- <th colspan="3" class="text-center">Diferido</th> -->
                    </tr>
                  </thead>
                  <!-- Product tbody -->
                  <tbody *ngFor="let group of marketSummary">
                    <tr class="tr-align-center">
                      <th></th>
                      <th class="col-price">
                        {{ 'MARKET_SUMMARY.PURCHASE'|translate }}
                      </th>
                      <th class="col-price" *ngIf="!company?.market.configuration.order.business_detail.price.type.enabled">
                        {{ 'MARKET_SUMMARY.SALE'|translate }}
                      </th>
                      <ng-container  *ngIf="company?.market.configuration.order.business_detail.price.type.enabled">
                        <th class="col-price" *ngFor="let delivery_type of delivery_types">
                          {{ 'MARKET_SUMMARY.SALE'|translate }} ({{ delivery_type | uppercase }})
                        </th>
                      </ng-container>
                      <th class="col-price">
                        <abbr title tooltip="{{ 'MARKET_SUMMARY.LAST_NEGOTIATED'|translate }}">
                          {{ 'MARKET_SUMMARY.LAST_NEGOTIATED_ABBR'|translate }}
                        </abbr>
                      </th>
                    </tr>
                    <!-- Product sub-header -->
                    <tr class="tr-align-center product-header">
                      <th class="text-uppercase">{{ group.key.name }}</th>
                      <th class="text-center animated" colspan="4">
                        <small *ngIf="(group.sumarizer|values)[0].offer">
                          <abbr title tooltip="{{ 'MARKET_SUMMARY.OFFERED_VOLUME'|translate }}">{{ 'MARKET_SUMMARY.OFFERED_VOLUME_ABBR'|translate }}:</abbr>
                          {{ (group.sumarizer|values)[0].offer|number:undefined:currentLang }}
                          t
                        </small>
                        <ng-container *ngIf="!(group.sumarizer|values)[0].offer">
                          -
                        </ng-container>
                      </th>
                    </tr>
                    <!-- Order row -->
                    <tr class="tr-align-center" *ngFor="let location of group.values">
                      <th><div class="location truncate" title="{{ location.zone?.name || location.location?.name }}">{{ location.zone?.name || location.location?.name }}</div></th>
                      <!-- Add .highlight to the td element when updated -->
                      <td>
                        <div (click)="gotoWorkingOrders({'product_id':group.key.id,'operation_type':'compra','zone_id':location.zone?.id,'location_id': location.location?.id})" class="buy" *ngIf="(location.dates|values)[0].bid">
                          <price *ngIf="(location.dates|values)[0].bid.type === 'flat'"
                          [unit]="(location.dates|values)[0].bid.unit.name" [value]="(location.dates|values)[0].bid.value"></price>
                          <ng-container *ngIf="(location.dates|values)[0].bid.type === 'premiums'">
                            {{ (location.dates|values)[0].bid|price }}
                          </ng-container>
                        </div>
                        <div *ngIf="!(location.dates|values)[0].bid">-</div>
                      </td>
                      <td *ngIf="!company?.market.configuration.order.business_detail.price.type.enabled">
                        <div (click)="gotoWorkingOrders({'product_id': group.key.id, 'operation_type': 'venta', 'zone_id': location.zone?.id, 'location_id': location.location?.id})" class="sell" *ngIf="(location.dates|values)[0].offer">
                          <price *ngIf="(location.dates|values)[0].offer.type === 'flat'"
                          [unit]="(location.dates|values)[0].offer.unit.name" [value]="(location.dates|values)[0].offer.value"></price>
                          <ng-container *ngIf="(location.dates|values)[0].offer.type === 'premiums'">
                            {{ (location.dates|values)[0].offer|price }}
                          </ng-container>
                        </div>
                        <div *ngIf="!(location.dates|values)[0].offer">-</div>
                      </td>
                      <ng-container *ngIf="company?.market.configuration.order.business_detail.price.type.enabled">
                        <td *ngFor="let delivery_type of (location.dates|values)[0].offer|keys">
                          <div (click)="gotoWorkingOrders({'product_id': group.key.id, 'operation_type': 'venta', 'zone_id': location.zone?.id, 'location_id': location.location?.id})" class="sell">
                            <price *ngIf="(location.dates|values)[0].offer[delivery_type]?.type === 'flat'"
                            [unit]="(location.dates|values)[0].offer[delivery_type].unit.name" [value]="(location.dates|values)[0].offer[delivery_type].value"></price>
                            <ng-container *ngIf="(location.dates|values)[0].offer[delivery_type]?.type === 'premiums'">
                              {{ (location.dates|values)[0].offer[delivery_type]|price }}
                            </ng-container>
                          </div>

                          <div *ngIf="!(location.dates|values)[0].offer[delivery_type]">-</div>
                        </td>
                      </ng-container>
                      <td>
                        <div routerLink="../../traded-orders"
                        [queryParams]="{ order_by: '-original_booking_date' }"
                        class="text-success" *ngIf="(location.dates|values)[0].traded">{{ (location.dates|values)[0].traded|price }}</div>
                        <div *ngIf="!(location.dates|values)[0].traded">-</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="fillwidth-panel" *ngIf="marketDates.length > 0">
                <h4 class="visible-xs">
                  {{ 'MARKET_SUMMARY.DEFERRED'|translate }}
                </h4>
                <table class="table table-market">
                  <!-- Deferred -->
                  <thead>
                    <tr class="tr-align-center">
                      <th>Lugar de entrega</th>
                      <ng-container *ngFor="let date of marketDates">
                        <th [attr.colspan]="company?.market.configuration.order.business_detail.price.type.enabled ? '4' : '3'" class="text-center">{{ date | date:'MMMM yyyy':undefined:currentLang }}</th>
                      </ng-container>
                    </tr>
                  </thead>
                  <!-- Product tbody -->
                  <tbody *ngFor="let group of marketSummary">
                    <tr class="tr-align-center">
                      <th></th>
                      <ng-container *ngFor="let date of group.values[0].dates|keys|slice:1">
                        <th class="col-price">
                          {{ 'MARKET_SUMMARY.PURCHASE'|translate }}
                        </th>
                        <th class="col-price" *ngIf="!company?.market.configuration.order.business_detail.price.type.enabled">
                          {{ 'MARKET_SUMMARY.SALE'|translate }}
                        </th>
                        <ng-container *ngIf="company?.market.configuration.order.business_detail.price.type.enabled">
                          <th class="col-price" *ngFor="let delivery_type of delivery_types">
                            {{ 'MARKET_SUMMARY.SALE'|translate }} ({{ delivery_type | uppercase }})
                          </th>
                        </ng-container>
                        <th class="col-price">
                          <abbr title tooltip="{{ 'MARKET_SUMMARY.LAST_NEGOTIATED'|translate }}">
                            {{ 'MARKET_SUMMARY.LAST_NEGOTIATED_ABBR'|translate }}
                          </abbr>
                        </th>
                      </ng-container>
                    </tr>
                    <!-- Product sub-header -->
                    <tr class="tr-align-center product-header">
                      <th class="text-uppercase">{{ group.key.name }}</th>
                      <th class="text-center animated" [attr.colspan]="company?.market.configuration.order.business_detail.price.type.enabled ? '4' : '3'" *ngFor="let date of group.sumarizer|keys|slice:1">
                        <small *ngIf="group.sumarizer[date].offer">
                          <abbr title tooltip="{{ 'MARKET_SUMMARY.OFFERED_VOLUME'|translate }}">{{ 'MARKET_SUMMARY.OFFERED_VOLUME_ABBR'|translate }}:</abbr>
                          {{ group.sumarizer[date].offer|number:undefined:currentLang }} t
                        </small>
                        <ng-container *ngIf="!group.sumarizer[date].offer">
                          -
                        </ng-container>
                      </th>
                    </tr>
                    <!-- Order row -->
                    <tr class="tr-align-center" *ngFor="let location of group.values">
                      <th><div class="location truncate" title="{{ location.zone?.name || location.location?.name }}">{{ location.zone?.name || location.location?.name }}</div></th>
                      <ng-container *ngFor="let date of location.dates|keys|slice:1">
                        <!-- Add .td-highlight to the td element when updated -->
                        <td>
                          <div (click)="gotoWorkingOrders({'product_id':group.key.id,'operation_type':'compra','zone_id':location.zone?.id,'location_id': location.location?.id})" class="buy" *ngIf="location.dates[date].bid">
                            <price *ngIf="location.dates[date].bid.type === 'flat'"
                            [unit]="location.dates[date].bid.unit.name" [value]="location.dates[date].bid.value"></price>
                            <ng-container *ngIf="location.dates[date].bid.type === 'premiums'">
                              {{ location.dates[date].bid|price }}
                            </ng-container>
                          </div>
                          <div *ngIf="!location.dates[date].bid">-</div>
                        </td>

                        <td *ngIf="!company?.market.configuration.order.business_detail.price.type.enabled">
                          <div (click)="gotoWorkingOrders({'product_id': group.key.id, 'operation_type': 'venta', 'zone_id': location.zone?.id, 'location_id': location.location?.id})" class="sell" *ngIf="location.dates[date].offer">
                            <price *ngIf="location.dates[date].offer.type === 'flat'"
                            [unit]="location.dates[date].offer.unit.name" [value]="location.dates[date].offer.value"></price>
                            <ng-container *ngIf="location.dates[date].offer.type === 'premiums'">
                              {{ location.dates[date].offer|price }}
                            </ng-container>
                          </div>
                          <div *ngIf="!location.dates[date].offer">-</div>
                        </td>
                        <td *ngIf="company?.market.configuration.order.business_detail.price.type.enabled">
                          <div (click)="gotoWorkingOrders({'product_id': group.key.id, 'operation_type': 'venta', 'zone_id': location.zone?.id, 'location_id': location.location?.id})" class="sell" *ngIf="location.dates[date].offer?.fas">
                            <price *ngIf="location.dates[date].offer?.fas.type === 'flat'"
                            [unit]="location.dates[date].offer?.fas.unit.name" [value]="location.dates[date].offer?.fas.value"></price>
                            <ng-container *ngIf="location.dates[date].offer?.fas.type === 'premiums'">
                              {{ location.dates[date].offer?.fas|price }}
                            </ng-container>
                          </div>
                          <div *ngIf="!location.dates[date].offer?.fas">-</div>
                        </td>
                        <td *ngIf="company?.market.configuration.order.business_detail.price.type.enabled">
                          <div (click)="gotoWorkingOrders({'product_id': group.key.id, 'operation_type': 'venta', 'zone_id': location.zone?.id, 'location_id': location.location?.id})" class="sell" *ngIf="location.dates[date].offer?.fca">
                            <price *ngIf="location.dates[date].offer?.fca.type === 'flat'"
                            [unit]="location.dates[date].offer?.fca.unit.name" [value]="location.dates[date].offer?.fca.value"></price>
                            <ng-container *ngIf="location.dates[date].offer?.fca.type === 'premiums'">
                              {{ location.dates[date].offer?.fca|price }}
                            </ng-container>
                          </div>
                          <div *ngIf="!location.dates[date].offer?.fca">-</div>
                        </td>

                        <td>
                          <div routerLink="../../traded-orders"
                          [queryParams]="{ order_by: '-original_booking_date' }"
                          class="text-success" *ngIf="location.dates[date].traded">
                            <price [unit]="location.dates[date].traded.unit.name" [value]="location.dates[date].traded.value"></price>
                          </div>
                          <div *ngIf="!location.dates[date].traded">-</div>
                        </td>
                      </ng-container>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #referenceModal>
  <!-- modal -->
  <div class="modal-header">
    <button type="button" class="close" [disabled]="processing" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">{{ 'REFERENCE_NUMBER_PICKER.TITLE'|translate }}</h4>
  </div>
  <div class="modal-body">
    <div class="loading" *ngIf="processing">
      <spinner message="{{ 'GLOBAL.LOADING'|translate }}"></spinner>
    </div>
    <form id="reference-form" #referenceForm="ngForm" (ngSubmit)="referenceForm.valid && submit()" [class.hidden]="processing">
      <p>{{ 'REFERENCE_NUMBER_PICKER.DESCRIPTION'|translate }}</p>
      <div class="form-group">
        <input name="reference" [(ngModel)]="reference_number"
        [modalFocus]="true" type="text" class="form-control"
        maxlength="36"
        required placeholder="{{ 'REFERENCE_NUMBER_PICKER.PLACEHOLDER'|translate }}">
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" (click)="closeModal()" class="btn btn-link" data-dismiss="modal" [disabled]="processing">
      {{ 'GLOBAL.CANCEL'|translate }}
    </button>
    <button type="submit" form="reference-form" class="btn btn-agree" [disabled]="referenceForm.invalid || !hasChangedContent() || processing" >
      {{ 'GLOBAL.SAVE'|translate }}
    </button>
  </div>
</ng-template>
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

import { Negotiation } from '../../modules/commercial/models/negotiation.model';

@Component({
  selector: 'app-max-quantity-confirm',
  templateUrl: './max-quantity-confirm.component.html',
  styleUrls: ['./max-quantity-confirm.component.css']
})
export class MaxQuantityConfirmComponent implements OnInit {

  public modal: BsModalRef;
  public negotiation: Negotiation;
  /** The language currently used. */
  public currentLang: string;
  public onConfirm: Subject<boolean>;

  constructor(
    public bsModalRef: BsModalRef,
    private translateService: TranslateService
  ) {
    this.modal = bsModalRef;
  }

  ngOnInit(): void {
    this.currentLang = this.translateService.currentLang === 'es' ? undefined : this.translateService.currentLang;

    this.onConfirm = new Subject();
  }

  public confirm(): void {
    this.onConfirm.next(true);
    this.onConfirm.complete();
    this.modal.hide();
  }

  public cancel(): void {
    this.onConfirm.complete();
    this.modal.hide();
  }
}

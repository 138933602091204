import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Subscription, combineLatest } from 'rxjs';

import { Account } from '../../../auth/models/account.model';
import { CompanyService } from '../../../services/company.service';
import { ComponentCommService } from '../../../services/component-comm.service';

@Component({
  selector: 'ag-integrations',
  templateUrl: './integrations.component.html',
  styleUrls: ['./integrations.component.scss']
})
export class IntegrationsComponent implements OnInit, OnDestroy {

  public account: Account;
  public companyId: number;
  public loading: boolean;
  public selectedTab: string = 'webhooks';

  private subscriptions: Subscription[] = [];

  constructor(
    private componentComm: ComponentCommService,
    private route: ActivatedRoute,
    private companyService: CompanyService
  ) { }

  ngOnInit(): void {
    this.componentComm.emit({ name: 'app-title', title: 'INTEGRATIONS.TITLE' });

    this.loading = true;
    this.subscriptions.push(combineLatest({
      params: this.route.parent.params,
      account: this.companyService.watchAccount()
    }).subscribe((data: { params: Params, account: Account }) => {
      if (data.account) {
        this.account = data.account;
        this.companyId = parseInt(data.params['companyId']);
        this.loading = false;
      }
    }));
  }

  /** @ignore */
  ngOnDestroy(): void {
    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}

<div class="container">
  <div class="content-box">
    <div class="ag-header sticky">
      <div class="ag-header-title">
        <h3>New execution</h3>
      </div>
      <div class="ag-header-actions">
        <div class="btn-toolbar">
          <div class="btn-group">
            <button type="button"
            class="btn btn-link" routerLink="../execution" [disabled]="sending">{{ 'GLOBAL.CANCEL'|translate }}</button>
          </div>
          <div class="btn-group">
            <button title="Previsualizar" class="btn btn-agree" [disabled]="
            nomination.fulfillment.total == undefined ||
            nomination.washout.selected == undefined ||
            (nomination.washout.selected && nomination.washoutContracts.length === 0) ||
            (nomination.washout.selected == false && (nomination.vessel.name=='' || nomination.vessel.flag=='' || nomination.vessel.eta=='' || nomination.vessel.owner=='' || nomination.vessel.age<0))
            " type="button"
              [class.hidden]="preview"
              (click)="previewToggle()">
              <i class="material-symbols-rounded hidden-xs hidden-sm">remove_red_eye</i>
              Preview...
            </button>
            <button title="Previsualizar" class="btn btn-agree" type="button"
              [class.hidden]="!preview"
              (click)="previewToggle()">
              <i class="material-symbols-rounded hidden-xs hidden-sm">send</i>
              Create and send to counterpart
            </button>
          </div>
        </div>
      </div>
    </div>
    <form id="create-order" #form="ngForm" [class.hidden]="preview">
      <div class="row step" [validateFormStep]="
        nomination.contract.data?.id &&
        nomination.contract.data?.company &&
        nomination.contract.data?.product &&
        nomination.contract.data?.locationString &&
        nomination.contract.data?.quantity &&
        nomination.contract.data?.date_from && 
        nomination.contract.data?.date_to &&
        nomination.contract.data?.price
        "
        [nextStep]="step02">
        <div class="col-xs-12">
          <div class="line"></div>
          <span class="step-indicator">
            <span></span>
            <i class="material-symbols-rounded">done</i>
          </span>
          <div class="container-fluid" style="padding-left: 0px;">
            <div class="row">
              <div class="col-md-6">
                <fieldset [disabled]="">
                  <div class="form-group" hasError>
                    <label class="control-label">Contract type</label>
                    <span class="small help-block">You can either select an existing Contract within the platform or use an external one.</span>
                    <div class="btn-group btn-group-justified">
                      <label class="btn btn-default" [class.active]="nomination.contract.existing">
                        <input type="radio" (ngModelChange)="changeContractType($event)" name="existingContract" [ngModel]="nomination.contract.existing" [value]="true">
                        <span>Existing Contract</span>
                      </label>
                      <label class="btn btn-default" [class.active]="nomination.contract.existing == false">
                        <input type="radio" (ngModelChange)="changeContractType($event)" name="existingContract" [ngModel]="nomination.contract.existing" [value]="false">
                        <span>External Contract</span>
                      </label>
                    </div>
                    <!-- <span class="small help-block" *ngIf="existingContract">En las órdenes de <span class="buy">Compra</span>, los vendedores sólo podrán <b>solicitarte un pre-anote</b>, el cuál deberás aceptar para anotar.</span>
                    <span class="small help-block" *ngIf="!existingContract">Las órdenes de <span class="sell">Venta</span> <b>son en firme</b>. Cualquiera de los compradores autorizados que definas podrán anotar directamente.</span> -->
                  </div>
                </fieldset>
              </div>
            </div>
            <!-- Existing contract -->
            <div class="row" *ngIf="nomination.contract.existing">
              <div class="col-xs-12">
                <div class="form-group" hasError>
                  <label class="control-label">Contract information</label>
                  <span class="small help-block">Click on the Contract you want to execute.</span>
                </div>
                <div class="form-group">
                  <!-- <label for="exampleInputName2">Filter: </label> -->
                  <input type="text" class="form-control input-sm" id="exampleInputName2" placeholder="Input filtering keywords..." style="width: 40%;">
                </div>
                <div class="horizontal-scroller vertical-scroller">
                  <table class="table table-condensed table-hover">
                    <thead>
                      <tr class="tr-align-center">
                        <th class="sap hidden-xs">{{ 'GLOBAL.DATE'|translate }}</th>
                        <th scope="col" id="com">ID / Counterpart</th>
                        <th scope="col">{{ 'GLOBAL.PRODUCT'|translate }}</th>
                        <th scope="col" id="loc" class="hidden-xs">Country / Port</th>
                        <th class="sap hidden-xs">Parity</th>
                        <th scope="col" id="ton" class="dlb drb">
                          <abbr title="{{ 'GLOBAL.QUANTITY'|translate }}">{{ 'GLOBAL.QUANTITY_ABBR'|translate }}</abbr>
                        </th>
                        <th class="sap text-center">{{ 'GLOBAL.DELIVERY_PERIOD'|translate }}</th>
                        <th class="sap">Precio /t</th>
                        <th class="sap hidden-xs"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <!-- Order row -->
                      <tr class="tr-align-center" *ngFor="let order of [{
                          id: 53642,
                          booking_date: '2019-02-14T14:09:16+00:00',
                          company: {name: 'Glencore'},
                          product: 'Brazilian Soybeans',
                          locationString: 'Brazil, Santos',
                          parity: 'FOB',
                          quantity: '65000',
                          date_from: '2019-03-01T18:19:16+00:00',
                          date_to: '2019-03-31T18:19:16+00:00',
                          price: 350,
                          buyer: true
                        },{
                          id: 53647,
                          booking_date: '2018-12-11T18:19:16+00:00',
                          company: {name: 'Glencore'},
                          product: 'Brazilian Soybeans',
                          locationString: 'Brazil, Paranaguá',
                          parity: 'FOB',
                          quantity: '5000',
                          date_from: '2019-12-01T18:19:16+00:00',
                          date_to: '2019-12-31T18:19:16+00:00',
                          price: 371.34,
                          buyer: true
                        },{
                          id: 53648,
                          booking_date: '2018-12-11T18:19:16+00:00',
                          company: {name: 'Amaggi'},
                          product: 'Brazilian Yellow Corn',
                          locationString: 'Brazil, Rio Grande',
                          parity: 'FOB',
                          quantity: '20000',
                          date_from: '2019-12-01T18:19:16+00:00',
                          date_to: '2019-12-31T18:19:16+00:00',
                          price: 378.43,
                          buyer: true
                        },{
                          id: 53649,
                          booking_date: '2018-12-11T18:19:16+00:00',
                          company: {name: 'Cefetra SPA'},
                          product: 'Argentine Soybean Meal (Hi Pro)',
                          locationString: 'Argentina, Rosario',
                          parity: 'FOB',
                          quantity: '65000',
                          date_from: '2019-12-01T18:19:16+00:00',
                          date_to: '2019-12-31T18:19:16+00:00',
                          price: 380.53,
                          buyer: false
                        },{
                          id: 53640,
                          booking_date: '2018-12-11T18:19:16+00:00',
                          company: {name: 'Cofco International'},
                          product: 'Brazilian Wheat',
                          locationString: 'Brazil, Paranaguá',
                          parity: 'FOB',
                          quantity: '5000',
                          date_from: '2019-12-01T18:19:16+00:00',
                          date_to: '2019-12-31T18:19:16+00:00',
                          price: 367.96,
                          buyer: true
                        },{
                          id: 53641,
                          booking_date: '2018-12-11T18:19:16+00:00',
                          company: {name: 'Bunge'},
                          product: 'Argentine Yellow Soybeans',
                          locationString: 'Argentina, One up river port',
                          parity: 'FOB',
                          quantity: '65000',
                          date_from: '2019-12-01T18:19:16+00:00',
                          date_to: '2019-12-31T18:19:16+00:00',
                          price: 367.96,
                          buyer: false
                        },{
                          id: 53643,
                          booking_date: '2018-12-11T18:19:16+00:00',
                          company: {name: 'Sodrugestvo'},
                          product: 'Argentine Corn',
                          locationString: 'Argentina, One up river ',
                          parity: 'FOB',
                          quantity: '55000',
                          date_from: '2019-12-01T18:19:16+00:00',
                          date_to: '2019-12-31T18:19:16+00:00',
                          price: 356.87,
                          buyer: false
                        },{
                          id: 53644,
                          booking_date: '2018-12-11T18:19:16+00:00',
                          company: {name: 'Gavilon'},
                          product: 'Argentine Corn',
                          locationString: 'Argentina, Rosario',
                          parity: 'FOB',
                          quantity: '50000',
                          date_from: '2019-12-01T18:19:16+00:00',
                          date_to: '2019-12-31T18:19:16+00:00',
                          price: 377.17,
                          buyer: true
                        },{
                          id: 53645,
                          booking_date: '2018-12-11T18:19:16+00:00',
                          company: {name: 'CJ'},
                          product: 'Brazilian Soybeans',
                          locationString: 'Brazil, Santos',
                          parity: 'FOB',
                          quantity: '65000',
                          date_from: '2019-12-01T18:19:16+00:00',
                          date_to: '2019-12-31T18:19:16+00:00',
                          price: 394.44,
                          buyer: true
                        }]" (click)="changeContract(order)" [class.selected]="nomination.contract.data?.id === order.id">
                        <td class="sap hidden-xs">
                          <ag-timestamp [date]="order.booking_date"></ag-timestamp>
                        </td>
                        <th>
                          <div class="visible-xs location-small">
                            <b> {{ order.booking_date|date: 'HH:mm' }} </b>
                            - <date-md [date]="order.booking_date"></date-md>
                          </div>
                          <!-- <a href class="order-link truncate" routerLink="../counter-order/{{ order.id }}" title="{{ order.company.name }}"> -->
                            <samp class="small lighten hidden-xs hidden-sm">
                              #{{ order.id }}:
                            </samp>
                            {{ order.company.name }}
                          <!-- </a> -->
                          <div class="visible-xs location-small">
                            {{ order.locationString }}
                          </div>
                        </th>
                        <td>{{ order.product }}</td>
                        <td headers="loc" class="hidden-xs">{{ order.locationString }}</td>
                        <td class="sap hidden-xs">
                          {{ order.parity }}
                        </td>
                        <td headers="ton" class="text-right dlb drb">
                          {{ order.quantity|number }} t
                        </td>
                        <td class="sap">
                          <date-range
                          [from]="order.date_from"
                          [to]="order.date_to"></date-range>
                        </td>
                        <td class="sap">
                          <div class="price-box booked-bg">
                            <div class="type" [class.buy]="order.buyer" [class.sell]="!order.buyer">{{ order.buyer ? 'Bought' : 'Sold' }}</div>
                            <div class="value">
                              <price [unit]="USD" [value]="order.price"></price>
                            </div>
                          </div>
                        </td>
                        <td class="hidden-xs sap">
                          <button type="button"
                          class="btn btn-link btn-xs" title="Historial del negocio" (click)="viewFile(negotiation, 'track-record')">
                            <i class="material-symbols-rounded">history</i>
                          </button>
                          <button type="button"
                          class="btn btn-link btn-xs" (click)="viewFile(negotiation, 'contract')" title="Descargar Confirmación del negocio">
                            <i class="material-symbols-rounded">list_alt</i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- External contract -->
            <div *ngIf="nomination.contract.existing == false">
              <div class="row">
                <div class="col-sm-4">
                  <div class="form-group" hasError>
                    <label class="control-label">Contract Reference</label>
                    <input type="text" placeholder="Enter ID..." class="form-control" name="contractId" [(ngModel)]="nomination.contract.data.id">
                    <span class="small help-block">Document external ID.</span>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group" hasError>
                    <label class="control-label">Counterpart</label>
                    <input type="text" placeholder="Enter Company name..." class="form-control" name="companyName" [(ngModel)]="nomination.contract.data.company.name">
                    <!-- <span class="small help-block">Document external ID.</span> -->
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-4">
                  <div class="form-group" hasError>
                    <label class="control-label">{{ 'GLOBAL.PRODUCT'|translate }}</label>
                    <select class="form-control" name="product" [(ngModel)]="nomination.contract.data.product">
                      <option [ngValue]="null" [selected]="true" [disabled]="true">Select a product</option>
                      <option *ngFor="let prod of [
                        'Argentine Corn',
                        'Argentine Crude Soybean Oil',
                        'Argentine Crude Sunflower Seed Oil',
                        'Argentine Feed Barley',
                        'Argentine Soybean Meal (Hi Pro)',
                        'Argentine Soybean Meal (Low Pro)',
                        'Argentine Wheat',
                        'Argentine Yellow Soybeans',
                        'Argentine/Uruguayan Yellow Soybeans',
                        'Brazilian Soybeans',
                        'Brazilian Soybeans Meal',
                        'Brazilian Wheat',
                        'Brazilian Yellow Corn',
                        'Uruguayan Wheat',
                        'Uruguayan Yellow Soybeans'
                        ]" value="{{ prod }}">{{ prod }}</option>
                    </select>
                    <!-- <span class="small help-block">Document external ID.</span> -->
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group" hasError>
                    <label class="control-label">{{ 'GLOBAL.QUANTITY'|translate }}</label>
                    <div class="input-group">
                      <input type="number" placeholder="0" step="1" class="form-control" name="quantity" [(ngModel)]="nomination.contract.data.quantity" max="100000">
                      <span class="input-group-addon">t</span>
                    </div>
                    <!-- <span class="small help-block">Document external ID.</span> -->
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group" hasError>
                    <label class="control-label">Price</label>
                    <div class="input-group">
                      <input type="number" placeholder="0.00" step="0.01" class="form-control" name="price" [(ngModel)]="nomination.contract.data.price">
                      <span class="input-group-addon">USD/t</span>
                    </div>
                    <!-- <span class="small help-block">Document external ID.</span> -->
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-4">
                  <div class="form-group" hasError>
                    <label class="control-label">Country</label>
                    <input type="text" placeholder="Enter country..." class="form-control" name="country" [(ngModel)]="nomination.contract.data.country">
                    <!-- <span class="small help-block">Document external ID.</span> -->
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group" hasError>
                    <label class="control-label">Port</label>
                    <input type="text" placeholder="Enter port..." class="form-control" name="port" [(ngModel)]="nomination.contract.data.locationString">
                    <!-- <span class="small help-block">Document external ID.</span> -->
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group" hasError>
                    <label class="control-label">Delivery period</label>
                    <div>
                      <div class="row">
                        <div class="col-sm-6">
                          <input type="date" placeholder="dd/mm/yy" class="form-control" name="date_from" [(ngModel)]="nomination.contract.data.date_from">
                        </div>
                        <div class="col-sm-6">
                          <input type="date" placeholder="dd/mm/yy" class="form-control" name="date_to" [(ngModel)]="nomination.contract.data.date_to">
                        </div>
                      </div>
                    </div>
                    <!-- <span class="small help-block">Document external ID.</span> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row step" [validateFormStep]="
        nomination.fulfillment.total ||
        (nomination.fulfillment.total == false && nomination.fulfillment.quantity > 0)
        "
        #step02="validateFormStep" [nextStep]="step03">
        <div class="col-xs-12">
          <div class="line"></div>
          <span class="step-indicator">
            <span></span>
            <i class="material-symbols-rounded">done</i>
          </span>
          <div class="container-fluid" style="padding-left: 0px;">
            <div class="row">
              <div class="col-md-6">
                <fieldset [disabled]="">
                  <div class="form-group" hasError>
                    <label class="control-label">Fulfillment</label>
                    <span class="small help-block">Define fulfillment type.</span>
                    <div class="btn-group btn-group-justified">
                      <label class="btn btn-default" [class.active]="nomination.fulfillment.total">
                        <input type="radio" (ngModelChange)="changeFulfillment($event)" name="fulfillment" [ngModel]="nomination.fulfillment.total" [value]="true">
                        <span>Total</span>
                      </label>
                      <label class="btn btn-default" [class.active]="nomination.fulfillment.total == false">
                        <input type="radio" (ngModelChange)="changeFulfillment($event)" name="fulfillment" [ngModel]="nomination.fulfillment.total" [value]="false">
                        <span>Partial</span>
                      </label>
                    </div>
                    <span class="small help-block" *ngIf="nomination.fulfillment.total == false">Partial fulfillment requires to specify the quantity to execute.</span>
                  </div>
                </fieldset>
              </div>
            </div>
            <!-- Partial fulfillment -->
            <div class="row" *ngIf="nomination.fulfillment.total == false">
              <div class="col-sm-4">
                <div class="form-group" hasError>
                  <label class="control-label">{{ 'GLOBAL.QUANTITY'|translate }}</label>
                  <div class="input-group input-quantity">
                    <input type="number" placeholder="0" step="1" class="form-control" name="quantity" [(ngModel)]="nomination.fulfillment.quantity" number [min]="0" [max]="nomination.contract.data?.quantity">
                    <span class="input-group-addon">t</span>
                  </div><!-- /btn-group -->
                  <!-- <error-list></error-list> -->
                  <!-- <span class="small help-block">Please, specify the quantity to execute in MT.</span> -->
                </div>
              </div>
              <div class="col-sm-8">
                <div class="form-group" hasError>
                  <label class="control-label">&nbsp;</label>
                  <div class="checkbox">
                  <label>
                    <input type="checkbox" name="inbalance" [(ngModel)]="nomination.fulfillment.balance">
                    In balance
                  </label>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row step" [validateFormStep]="
        (nomination.fulfillment.total ||
        (nomination.fulfillment.total == false && nomination.fulfillment.quantity > 0)) &&
        ((nomination.washout.selected == false && nomination.vessel.name!='' && nomination.vessel.flag!='' && nomination.vessel.eta!='' && nomination.vessel.owner!='' && nomination.vessel.age>-1) ||
        (nomination.washout.selected && nomination.washoutContracts.length > 0))
        "
        #step03="validateFormStep" [nextStep]="step04">
        <div class="col-xs-12">
          <div class="line"></div>
          <span class="step-indicator">
            <span></span>
            <i class="material-symbols-rounded">done</i>
          </span>
          <div class="container-fluid" style="padding-left: 0px;">
            <div class="row">
              <div class="col-md-6">
                <fieldset [disabled]="">
                  <div class="form-group" hasError>
                    <label class="control-label">Execution</label>
                    <!-- <span class="small help-block">Define fulfillment type.</span> -->
                    <div class="btn-group btn-group-justified">
                      <label class="btn btn-default" [class.active]="nomination.washout.selected == false">
                        <input type="radio" (ngModelChange)="changeExecution($event)" name="washout" [ngModel]="nomination.washout.selected" [value]="false">
                        <span>Vessel nomination</span>
                      </label>
                      <label class="btn btn-default" [class.active]="nomination.washout.selected">
                        <input type="radio" (ngModelChange)="changeExecution($event)" name="washout" [ngModel]="nomination.washout.selected" [value]="true">
                        <span>Wash-out</span>
                      </label>
                    </div>
                    <!-- <span class="small help-block" *ngIf="nomination.fulfillment.total == false">Partial fulfillment requires to specify the quantity to execute.</span> -->
                  </div>
                </fieldset>
              </div>
            </div>
            <!-- Washout -->
            <div class="row" *ngIf="nomination.washout.selected">
              <div class="col-xs-12">
                <fieldset [disabled]="">
                  <div class="form-group" hasError>
                    <!-- <label class="control-label">Wash-out Contract information</label> -->
                    <p>
                      Wash-out for
                      <b>{{ (nomination.fulfillment.total ? nomination.contract.data.quantity : nomination.fulfillment.quantity) | number }}</b> <small>t.</small>
                      of
                      <b>{{ nomination.contract.data.product }}</b>
                      at
                      <b>{{ nomination.contract.data.price | number : '.2' }}</b> <small>USD/t</small>
                      with
                      <b>{{ nomination.contract.data.company.name }}</b>.
                    </p>

                    <div class="table-responsive">
                      <table class="table table-condensed">
                        <thead>
                          <tr>
                            <th>Contract as <span class="type" [class.buy]="!nomination.contract.data.buyer" [class.sell]="nomination.contract.data.buyer">{{ !nomination.contract.data.buyer ? 'buyer' : 'seller' }}</span></th>
                            <th class="text-center">Period</th>
                            <th class="text-right">USD/t</th>
                            <th class="text-right">t</th>
                            <th class="text-right">USD</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let mine of nomination.washoutContracts" class="tr-align-center">
                            <td>
                              <a>{{ mine.data.id }}</a> in <b>{{ mine.fulfillment.total ? 'total' : 'partial' }}</b> fulfillment
                              <span *ngIf="mine.fulfillment.balance">in balance</span>
                            </td>
                            <!-- <td>December 2016</td> -->
                            <td>
                              <date-range
                              [from]="mine.data.date_from"
                              [to]="mine.data.date_to"></date-range>
                            </td>
                            <td class="text-right">{{ mine.data.price | number: '.2' }}</td>
                            <td class="text-right">{{ mine.data.quantity | number }}</td>
                            <td class="text-right">{{ mine.data.price * mine.data.quantity | number: '.2' }}</td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <td colspan="3" class="text-right"><b>Balance</b> </td>
                            <td class="text-right">{{ ((nomination.fulfillment.total ? nomination.contract.data.quantity : nomination.fulfillment.quantity) - nomination.washoutTons) | number }}</td>
                            <td class="text-right"
                            [class.text-success]="(((nomination.fulfillment.total ? nomination.contract.data.quantity : nomination.fulfillment.quantity) * nomination.contract.data.price) - nomination.washoutBalance) >= 0"
                            [class.text-danger]="(((nomination.fulfillment.total ? nomination.contract.data.quantity : nomination.fulfillment.quantity) * nomination.contract.data.price) - nomination.washoutBalance) < 0">{{
                              (((nomination.fulfillment.total ? nomination.contract.data.quantity : nomination.fulfillment.quantity) * nomination.contract.data.price) - nomination.washoutBalance) | number : '.2'
                            }}</td>
                          </tr>
                          <tr>
                            <td colspan="5" class="text-right">
                              <button type="button"
                              (click)="openModal(newContract, 'modal-lg')" class="btn btn-agree btn-sm">
                                <i class="material-symbols-rounded hidden-xs hidden-sm">add</i> Add a Contract
                              </button>
                            </td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>

                    <!-- <span class="small help-block">You can either select an existing Contract within the platform or use an external one.</span>
                    <div class="btn-group btn-group-justified">
                      <label class="btn btn-default" [class.active]="nomination.washout.existing">
                        <input type="radio" (ngModelChange)="changeWashoutContractType($event)" name="existingWContract" [ngModel]="nomination.washout.existing" [value]="true">
                        <span>Existing Contract</span>
                      </label>
                      <label class="btn btn-default" [class.active]="nomination.washout.existing == false">
                        <input type="radio" (ngModelChange)="changeWashoutContractType($event)" name="existingWContract" [ngModel]="nomination.washout.existing" [value]="false">
                        <span>External Contract</span>
                      </label>
                    </div> -->
                    <!-- <span class="small help-block" *ngIf="existingContract">En las órdenes de <span class="buy">Compra</span>, los vendedores sólo podrán <b>solicitarte un pre-anote</b>, el cuál deberás aceptar para anotar.</span>
                    <span class="small help-block" *ngIf="!existingContract">Las órdenes de <span class="sell">Venta</span> <b>son en firme</b>. Cualquiera de los compradores autorizados que definas podrán anotar directamente.</span> -->
                  </div>
                </fieldset>
              </div>
            </div>
            <!-- Vessel nomination -->
            <div *ngIf="nomination.washout.selected == false">
              <div class="row">
                <div class="col-xs-12"><span class="small help-block">Fields required based on ANEC 41 specifications.</span></div>
              </div>
              <div class="row">
                <div class="col-sm-4">
                  <div class="form-group" hasError>
                    <label class="control-label">Vessel's IMO <small class="text-muted">(Optional)</small></label>
                    <input type="number" placeholder="7 digit number..." class="form-control" name="vesselIMO" [(ngModel)]="nomination.vessel.imo">
                    <!-- <span class="small help-block">Document external ID.</span> -->
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group" hasError>
                    <label class="control-label">Vessel's name</label>
                    <input type="text" placeholder="Enter Name..." class="form-control" name="vesselName" [(ngModel)]="nomination.vessel.name">
                    <!-- <span class="small help-block">Document external ID.</span> -->
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group" hasError>
                    <label class="control-label">Flag of the Vessel</label>
                    <input type="text" placeholder="Enter Country..." class="form-control" name="vesselFlag" [(ngModel)]="nomination.vessel.flag">
                    <!-- <span class="small help-block">Document external ID.</span> -->
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group" hasError>
                    <label class="control-label">Ownership</label>
                    <input type="text" placeholder="Enter Owner..." class="form-control" name="vesselOwner" [(ngModel)]="nomination.vessel.owner">
                    <!-- <span class="small help-block">Document external ID.</span> -->
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group" hasError>
                    <label class="control-label">Vessel's Age</label>
                    <input type="number" placeholder="Years..." step="1" class="form-control" name="vesselAge" [(ngModel)]="nomination.vessel.age">
                    <!-- <span class="small help-block">Document external ID.</span> -->
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-4">
                  <div class="form-group" hasError>
                    <label class="control-label">Vessel's ETA</label>
                    <input type="date" placeholder="dd/mm/yy" class="form-control" name="vesselETA" [(ngModel)]="nomination.vessel.eta">
                    <!-- <span class="small help-block">Document external ID.</span> -->
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group" hasError>
                    <label class="control-label">Demurrage rate <small class="text-muted">(Optional)</small></label>
                    <div class="input-group">
                      <input type="number" placeholder="0.00" step="0.01" class="form-control" name="vesselDemurrage" [(ngModel)]="nomination.vessel.demurrage">
                      <span class="input-group-addon">USD</span>
                    </div>
                    <!-- <span class="small help-block">Document external ID.</span> -->
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group" hasError>
                    <label class="control-label">Despatch rate</label>
                    <div class="input-group">
                      <input readonly type="number" placeholder="0.00" step="0.01" class="form-control" name="vesselDespatch" value="{{nomination.vessel.demurrage/2}}">
                      <span class="input-group-addon">USD</span>
                    </div>
                    <!-- <span class="small help-block">Document external ID.</span> -->
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group" hasError>
                    <label class="control-label">Detention rate <small class="text-muted">(Optional)</small></label>
                    <div class="input-group">
                      <input type="number" placeholder="0.00" step="0.01" class="form-control" name="vesseldetention">
                      <span class="input-group-addon">USD</span>
                    </div>
                    <!-- <span class="small help-block">Document external ID.</span> -->
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group" hasError>
                    <label class="control-label">Country of destination <small class="text-muted">(Optional)</small></label>
                    <input type="text" placeholder="Enter Country..." class="form-control" name="vesselCountry" [(ngModel)]="nomination.vessel.country">
                    <!-- <span class="small help-block">Document external ID.</span> -->
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group" hasError>
                    <label class="control-label">Agents at loading port <small class="text-muted">(Optional)</small></label>
                    <input type="text" placeholder="Enter Agents..." class="form-control" name="vesselAgents" [(ngModel)]="nomination.vessel.agents">
                    <!-- <span class="small help-block">Document external ID.</span> -->
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-4">
                  <div class="form-group" hasError>
                    <label class="control-label">Surveyor <small class="text-muted">(Optional)</small></label>
                    <input type="text" placeholder="Enter Surveyor..." class="form-control" name="vesselSurveyor" [(ngModel)]="nomination.vessel.surveyor">
                    <!-- <span class="small help-block">Document external ID.</span> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row step" [validateFormStep]="true" #step04="validateFormStep">
        <div class="col-xs-12">
          <span class="step-indicator">
            <span></span>
            <i class="material-symbols-rounded">done</i>
          </span>

          <div class="container-fluid" style="padding-left: 0px;" >
          </div>

          <div class="form-group" hasError>
            <label class="control-label">Observations <small>(Optional)</small></label>
            <textarea class="form-control" rows="6" name="obs"
            maxlength="1024"
            [(ngModel)]="nomination.observations">
            </textarea>
            <span class="small help-block">Use this field to add any additional information.</span>
          </div>
        </div>
      </div>
    </form>

    <div class="row" [class.hidden]="!preview">
      <div class="col-xs-12">
        <table class="table table-condensed">
          <tbody>
            <tr>
              <th class="text-right">From:</th>
              <td>no-reply@agreemarket.com</td>
            </tr>
            <tr>
              <th class="text-right">To:</th>
              <td>recipient@counterpart.com</td>
            </tr>
            <tr>
              <th class="text-right">CC:</th>
              <td>sender@mycompany.com</td>
            </tr>
            <tr>
              <th class="text-right">Subject:</th>
              <td>NOMINATION {{ nomination.vessel.name | uppercase }} for {{ (nomination.fulfillment.total ? nomination.contract.data.quantity : nomination.fulfillment.quantity) | number }} MT {{ nomination.contract.data.product }} - {{ nomination.contract.data.company.name }}</td>
            </tr>
          </tbody>
        </table>
        <div class="panel panel-default">
          <div class="panel-body">
            <p>Ref.: Contract {{ nomination.contract.data.id }}<br>
            {{ nomination.contract.data.product }} - Delivery period: {{ nomination.contract.data.date_from | date }} - {{ nomination.contract.data.date_to | date }}<br>
            Buyer: My Company - Seller: {{ nomination.contract.data.company.name }}</p>

            <p>Following nomination to load {{ nomination.vessel.name | uppercase }} for {{ (nomination.fulfillment.total ? nomination.contract.data.quantity : nomination.fulfillment.quantity) | number }} MT in {{ nomination.fulfillment.total ? 'total' : 'balance' }} fulfillment.</p>

            <p>
              <span>Vessel name: {{ nomination.vessel.name | uppercase }}<br></span>
              <span>Flag: {{ nomination.vessel.flag }}<br></span>
              <span>ETA: {{ nomination.vessel.eta }}<br></span>
              <span>Age: {{ nomination.vessel.age }} year(s) (Built: {{ 2019 - nomination.vessel.age}})<br></span>
              <span>Ownership: {{ nomination.vessel.owner }}<br></span>
              <span>Country of destination: {{ nomination.vessel.country !== '' ? nomination.vessel.country : '-' }}<br></span>
              <span *ngIf="nomination.vessel.demurrage">Demurrage rate: USD {{ nomination.vessel.demurrage | number : '.2' }}<br></span>
              <span>Agents at loading port: {{ nomination.vessel.agents !== '' ? nomination.vessel.agents : 'To be appointed' }}<br></span>
              <span>Surveyor: {{ nomination.vessel.surveyor !== '' ? nomination.vessel.surveyor : 'To be appointed' }}<br></span>
            </p>

            <hr>
            <p *ngIf="nomination.observations!=''">
              <b>Observations:</b><br>
              {{ nomination.observations }}
            </p>

            <p><a>To acknowledge or reject this nomination click here</a></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #newContract>
  <!-- modal -->
  <div class="modal-header">
    <button type="button" class="close" (click)="modalRef.hide()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">Add a Contract for wash-out</h4>
  </div>
  <div class="modal-body">
    <form>
      <div class="row">
        <div class="col-xs-12">
          <fieldset [disabled]="">
            <div class="form-group" hasError>
              <label class="control-label">Contract type</label>
              <span class="small help-block">You can either select an existing Contract within the platform or use an external one.</span>
              <div class="btn-group btn-group-justified">
                <label class="btn btn-default" [class.active]="nomination.washout.existing">
                  <input type="radio" (ngModelChange)="changeWashoutContractType($event)" name="existingWContract" [ngModel]="nomination.washout.existing" [value]="true">
                  <span>Existing Contract</span>
                </label>
                <label class="btn btn-default" [class.active]="nomination.washout.existing == false">
                  <input type="radio" (ngModelChange)="changeWashoutContractType($event)" name="existingWContract" [ngModel]="nomination.washout.existing" [value]="false">
                  <span>External Contract</span>
                </label>
              </div>
            </div>
          </fieldset>
        </div>
      </div>
      <!-- Existing contract -->
      <div class="row" *ngIf="nomination.washout.selected && nomination.washout.existing">
        <div class="col-xs-12">
          <div>
            Showing
            <!-- Simulo traer operaciones del mismo producto -->
            <b>{{ nomination.contract.data.product }}</b>
            Contracts as
            <!-- Simulo traer operaciones de compra si el washout es de vent y viceversa -->
            <span class="type" [class.buy]="!nomination.contract.data.buyer" [class.sell]="nomination.contract.data.buyer">{{ !nomination.contract.data.buyer ? 'buyer' : 'seller' }}</span>
            with
            <!-- Simulo traer operaciones de la misma compañía -->
            <b>{{ nomination.contract.data.company.name }}</b>.
          </div>
          <span class="small help-block">Click on the Contract you want to select.
            <!-- <b>You can select more than one.</b> -->
          </span>
          <div class="form-group">
            <!-- <label for="exampleInputName2">Filter: </label> -->
            <input type="text" class="form-control input-sm" id="exampleInputName3" placeholder="Input filtering keywords..." style="width: 40%;">
          </div>
          <div class="horizontal-scroller vertical-scroller">
            <table class="table table-condensed table-hover">
              <thead>
                <tr class="tr-align-center">
                  <th class="sap hidden-xs">{{ 'GLOBAL.DATE'|translate }}</th>
                  <th scope="col" id="com">ID</th>
                  <th scope="col" id="loc" class="hidden-xs">Country / Port</th>
                  <th scope="col" id="d_type" class="hidden-xs">Parity</th>
                  <th scope="col" id="ton" class="dlb drb">
                    <abbr title="{{ 'GLOBAL.QUANTITY'|translate }}">{{ 'GLOBAL.QUANTITY_ABBR'|translate }}</abbr>
                  </th>
                  <th class="sap text-center">{{ 'GLOBAL.DELIVERY_PERIOD'|translate }}</th>
                  <th class="sap">Precio /t</th>
                  <th class="sap hidden-xs"></th>
                </tr>
              </thead>
              <tbody>
                <!-- Order row -->
                <tr class="tr-align-center" *ngFor="let order of [{
                    id: 53647,
                    booking_date: '2018-12-11T18:19:16+00:00',
                    company: {name: 'Cefetra SPA'},
                    product: 'Brazilian Soybeans',
                    locationString: 'Brazil, Paranaguá',
                    parity: 'FOB',
                    quantity: '65000',
                    date_from: '2019-12-01T18:19:16+00:00',
                    date_to: '2019-12-31T18:19:16+00:00',
                    price: 394.44
                  },{
                    id: 53648,
                    booking_date: '2018-12-11T18:19:16+00:00',
                    company: {name: 'Cefetra SPA'},
                    product: 'Brazilian Soybeans',
                    locationString: 'Brazil, Santos',
                    parity: 'FOB',
                    quantity: '5000',
                    date_from: '2019-12-01T18:19:16+00:00',
                    date_to: '2019-12-31T18:19:16+00:00',
                    price: 377.17 
                  },{
                    id: 53649,
                    booking_date: '2018-12-11T18:19:16+00:00',
                    company: {name: 'Cefetra SPA'},
                    product: 'Brazilian Soybeans',
                    locationString: 'Brazil, Paranaguá',
                    parity: 'FOB',
                    quantity: '10000',
                    date_from: '2019-12-01T18:19:16+00:00',
                    date_to: '2019-12-31T18:19:16+00:00',
                    price: 376.77
                  },{
                    id: 53640,
                    booking_date: '2018-12-11T18:19:16+00:00',
                    company: {name: 'Cefetra SPA'},
                    product: 'Brazilian Soybeans',
                    locationString: 'Brazil, Paranaguá',
                    parity: 'FOB',
                    quantity: '65000',
                    date_from: '2019-12-01T18:19:16+00:00',
                    date_to: '2019-12-31T18:19:16+00:00',
                    price: 356.87
                  },{
                    id: 53641,
                    booking_date: '2018-12-11T18:19:16+00:00',
                    company: {name: 'Cefetra SPA'},
                    product: 'Brazilian Soybeans',
                    locationString: 'Brazil, Rio Grande',
                    parity: 'FOB',
                    quantity: '65000',
                    date_from: '2019-12-01T18:19:16+00:00',
                    date_to: '2019-12-31T18:19:16+00:00',
                    price: 367.96
                  },{
                    id: 53642,
                    booking_date: '2018-12-11T18:19:16+00:00',
                    company: {name: 'Cefetra SPA'},
                    product: 'Brazilian Soybeans',
                    locationString: 'Brazil, Paranaguá',
                    parity: 'FOB',
                    quantity: '20000',
                    date_from: '2019-12-01T18:19:16+00:00',
                    date_to: '2019-12-31T18:19:16+00:00',
                    price: 367.96
                  },{
                    id: 53643,
                    booking_date: '2018-12-11T18:19:16+00:00',
                    company: {name: 'Cefetra SPA'},
                    product: 'Brazilian Soybeans',
                    locationString: 'Brazil, Paranaguá',
                    parity: 'FOB',
                    quantity: '12500',
                    date_from: '2019-12-01T18:19:16+00:00',
                    date_to: '2019-12-31T18:19:16+00:00',
                    price: 380.53
                  },{
                    id: 53644,
                    booking_date: '2018-12-11T18:19:16+00:00',
                    company: {name: 'Cefetra SPA'},
                    product: 'Brazilian Soybeans',
                    locationString: 'Brazil, Paranaguá',
                    parity: 'FOB',
                    quantity: '65000',
                    date_from: '2019-12-01T18:19:16+00:00',
                    date_to: '2019-12-31T18:19:16+00:00',
                    price: 378.43
                  },{
                    id: 53645,
                    booking_date: '2018-12-11T18:19:16+00:00',
                    company: {name: 'Cefetra SPA'},
                    product: 'Brazilian Soybeans',
                    locationString: 'Brazil, Paranaguá',
                    parity: 'FOB',
                    quantity: '65000',
                    date_from: '2019-12-01T18:19:16+00:00',
                    date_to: '2019-12-31T18:19:16+00:00',
                    price: 371.34
                  }]" (click)="changeWashoutContract(order)" [class.selected]="nomination.washout.data?.id == order.id">
                  <td class="sap hidden-xs">
                    <ag-timestamp [date]="order.booking_date"></ag-timestamp>
                  </td>
                  <th>
                    <div class="visible-xs location-small">
                      <b>{{ order.booking_date|date: 'HH:mm' }}</b>
                      - <date-md [date]="order.booking_date"></date-md>
                    </div>
                    <!-- <a href class="order-link truncate" routerLink="../counter-order/{{ order.id }}" title="{{ order.company.name }}"> -->
                      <span>#{{ order.id }}</span>
                    <!-- </a> -->
                    <div class="visible-xs location-small">
                      {{ order.locationString }}
                    </div>
                  </th>
                  <td headers="loc" class="hidden-xs">{{ order.locationString }}</td>
                  <td headers="d_type" class="hidden-xs">
                    {{ order.parity }}
                  </td>
                  <td headers="ton" class="text-right dlb drb">
                    {{ order.quantity|number }} t
                  </td>
                  <td class="sap">
                    <date-range
                    [from]="order.date_from"
                    [to]="order.date_to"></date-range>
                  </td>
                  <td class="sap">
                    <div class="price-box booked-bg">
                      <!-- Simulo traer operaciones de compra si el washout es de vent y viceversa -->
                      <div class="type" [class.buy]="!nomination.contract.data.buyer" [class.sell]="nomination.contract.data.buyer">{{ !nomination.contract.data.buyer ? 'Bought' : 'Sold' }}</div>
                      <div class="value">
                        <price [unit]="USD" [value]="order.price"></price>
                      </div>
                    </div>
                  </td>
                  <td class="hidden-xs sap">
                    <button type="button"
                    class="btn btn-link btn-xs" title="Historial del negocio" (click)="viewFile(negotiation, 'track-record')">
                      <i class="material-symbols-rounded">history</i>
                    </button>
                    <button type="button"
                    class="btn btn-link btn-xs" (click)="viewFile(negotiation, 'contract')" title="Descargar Confirmación del negocio">
                      <i class="material-symbols-rounded">list_alt</i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- Fulfillment -->
      <div class="row" *ngIf="nomination.washout.data">
        <div class="col-xs-12">
          <fieldset [disabled]="">
            <div class="form-group" hasError>
              <label class="control-label">Fulfillment</label>
              <span class="small help-block">Define fulfillment type.</span>
              <div class="btn-group btn-group-justified">
                <label class="btn btn-default" [class.active]="nomination.washout.fulfillment.total">
                  <input type="radio" name="fulfillment" [(ngModel)]="nomination.washout.fulfillment.total" [value]="true">
                  <span>Total</span>
                </label>
                <label class="btn btn-default" [class.active]="nomination.washout.fulfillment.total == false">
                  <input type="radio" name="fulfillment" [(ngModel)]="nomination.washout.fulfillment.total" [value]="false">
                  <span>Partial</span>
                </label>
              </div>
              <span class="small help-block" *ngIf="nomination.washout.fulfillment.total == false">Partial fulfillment requires to specify the quantity to execute.</span>
            </div>
          </fieldset>
        </div>
      </div>
      <!-- Partial fulfillment -->
      <div class="row" *ngIf="nomination.washout.fulfillment.total == false">
        <div class="col-sm-6">
          <div class="form-group" hasError>
            <label class="control-label">{{ 'GLOBAL.QUANTITY'|translate }}</label>
            <div class="input-group input-quantity">
              <input type="number" placeholder="0" step="1" class="form-control" name="quantity" [(ngModel)]="nomination.washout.fulfillment.quantity" number [min]="0" [max]="nomination.contract.data?.quantity">
              <span class="input-group-addon">t</span>
            </div><!-- /btn-group -->
            <error-list></error-list>
            <!-- <span class="small help-block">Please, specify the quantity to execute in MT.</span> -->
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group" hasError>
            <label class="control-label">&nbsp;</label>
            <div class="checkbox">
            <label>
              <input type="checkbox" name="inbalance" [(ngModel)]="nomination.washout.fulfillment.balance">
              In balance
            </label>
          </div>
          </div>
        </div>
      </div>
      <!-- Contrato externo -->
      <div class="row" *ngIf="nomination.washout.selected && nomination.washout.existing == false">
        <div class="col-xs-12">Externo</div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button (click)="modalRef.hide()" type="button" class="btn btn-link" data-dismiss="modal">Cancel</button>
    <button (click)="addContract()" type="button" class="btn btn-agree" [disabled]="
      nomination.washout.fulfillment.total == undefined ||
      (nomination.washout.fulfillment.total == false && nomination.washout.fulfillment.quantity == undefined)
    "><i class="material-symbols-rounded">add</i> Add Contract</button>
  </div>
</ng-template>
import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { Company } from '../../../models/company.model';
import { CompanyService } from '../../../services/company.service';

@Component({
  selector: 'app-new-execution',
  templateUrl: './new-execution.component.html',
  styleUrls: ['./new-execution.component.scss']
})
export class NewExecutionComponent implements OnInit, OnDestroy {

  public modalRef: BsModalRef;
  public sending;
  public preview: boolean = false;
  public nomination: any = {
    contract: {
      existing: undefined,
      data: {
        company: {}
      }
    },
    fulfillment: {
      total: undefined,
      quantity: undefined,
      balance: false
    },
    washout: {
      fulfillment: {
        total: undefined,
        quantity: undefined,
        balance: false
      },
      existing: undefined,
      selected: undefined,
      data: undefined
    },
    washoutContracts: [],
    washoutTons: 0,
    washoutBalance: 0,
    vessel: {
      name: '',
      flag: '',
      eta: '',
      owner: '',
      age: 0,
      country: '',
      agents: '',
      surveyor: ''
    },
    observations: ""
  };

  private company: Company;
  private subscriptions: Array<any> = [];

  constructor(
    private companyService: CompanyService,
    private modalService: BsModalService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.subscriptions.push(this.companyService.watch().subscribe(company => {
      if (!company) return;

      this.company = company;

      // Kick user if module is not enabled
      if (!this.company.hasModule('execution')) this.router.navigateByUrl('/companies');
    }));
  }

  /** Generic Modal trigger. */
  public openModal(template: TemplateRef<any>, c: string = ''): void {
    this.modalRef = this.modalService.show(template, { class: c });
  }

  changeContractType(value: boolean) {
    this.nomination.contract.existing = value;
    // this.nomination.contract.data = undefined;
    this.nomination.fulfillment.total = undefined;
    this.nomination.washout.selected = undefined;

    // this.nomination.contract.data = this.nomination.contract.existing ? undefined : {
    //   id: undefined
    // };
  }
  changeContract(value: any) {
    this.nomination.contract.data = value;
    this.nomination.fulfillment.total = undefined;
    // this.nomination.fulfillment.quantity = this.nomination.contract.data.quantity;
  }
  changeFulfillment(value: boolean) {
    this.nomination.fulfillment.total = value;
  }
  changeExecution(value: boolean) {
    this.nomination.washout.selected = value;
    this.nomination.washoutContracts = [];
    this.nomination.washoutTons = 0;
    this.nomination.washoutBalance = 0;
  }
  changeWashoutContractType(value: boolean) {
    this.nomination.washout.existing = value;
  }
  changeWashoutContract(value: any) {
    this.nomination.washout.data = value;
  }

  previewToggle(): void {
    this.preview = !this.preview;
    this.topFunction();
  }
  topFunction(): void {
    if (this.preview) document.querySelector('.app-content').scrollTo(0, 0);
  }

  addContract(): void {
    const c = JSON.parse(JSON.stringify(this.nomination.washout));
    if (!c.fulfillment.total) {
      // Partial
      c.data.quantity = c.fulfillment.quantity;
    }

    this.nomination.washoutTons += parseInt(c.data.quantity);
    this.nomination.washoutBalance += (parseInt(c.data.quantity) * c.data.price);

    this.nomination.washoutContracts.push(c);
    this.modalRef.hide();

    // Reset
    this.nomination.washout.fulfillment = {
      total: undefined,
      quantity: undefined,
      balance: false
    };
    this.nomination.washout.existing = undefined;
    this.nomination.washout.data = undefined;
  }

  ngOnDestroy(): void {
    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}

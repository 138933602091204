import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

import { User } from '../auth/models/user.model';
import { LoginService } from '../auth/services/login.service';

@Injectable()
export class MailVerifiedGuard {

  /** Current [[User]]. */
  public user: User;

  constructor(
    private loginService: LoginService,
    private router: Router
  ) {
    this.loginService.getCurrentUser().subscribe(user => {
      this.user = user;
    });
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let url: string = state.url;

    return this.checkMailVerified(url);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(route, state);
  }

  checkMailVerified(url: string): boolean {
    if (this.user.mail_verified === 0) {

      this.loginService.setRedirectUrl(url);
      this.router.navigate(['/login']);

      return false;
    } else {
      return true;
    }
  }
}

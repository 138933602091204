<div class="container">
  <div class="content-box">
    <ol class="breadcrumb" *ngIf="company">
      <li><a [routerLink]="['/company', company.id, 'setup']">{{ 'GLOBAL.CONFIG_ASSISTANT'|translate }}</a></li>
      <li class="active">{{ 'GLOBAL.NOTES'|translate }}</li>
    </ol>
    <div class="ag-header sticky">
      <div class="ag-header-title">
        <!-- <h3 *ngIf="company">{{ 'GLOBAL.NOTES'|translate }}</h3> -->
        <form
        #searchForm="ngForm"
        (ngSubmit)="searchForm.valid && search(searchForm)"
        class="form-inline">
          <div class="form-group has-button no-margin">
            <input type="text" class="form-control"
            name="fiscal_id"
            autocomplete="off"
            [(ngModel)]="fiscalId_search"
            required
            [disabled]="processing"
            placeholder="{{ 'FINTECH.SEARCH_ID'|translate: { id: fiscalId ? fiscalId.label : 'ID' } }}"
            [readonly]="searchForm.submitted">
            <span *ngIf="!processing" (click)="resetSearch(searchForm)" class="glyphicon glyphicon-remove-sign form-control-button" aria-hidden="true"></span>
          </div>
          <button class="btn btn-link" type="button" [disabled]="processing" (click)="add()">
            <span class="material-symbols-rounded">add</span>
          </button>
        </form>
      </div>
      <div class="ag-header-actions">
        <div class="btn-group"
        *ngIf="changes">
          <button class="btn btn-link" type="button"
          [disabled]="processing" (onConfirm)="discard()">
            {{ 'NEGOTIABLE_GROUP.DISCARD'|translate }}
          </button>
        </div>
        <div class="btn-group">
          <button class="btn btn-agree" type="button"
          [disabled]="processing || !changes" (click)="save()">
            {{ 'GLOBAL.SAVE_CHANGES'|translate }}
          </button>
        </div>
        <div class="btn-toolbar">
          <div class="btn-group"
          *ngIf="paginationData?.total_pages > 1">
            <paginator [data]="paginationData" [loading]="loading"></paginator>
          </div>
        </div>
      </div>
    </div>

    <div class="row"  *ngIf="notes; else loading">
      <div class="col-xs-12">
        <div *ngIf="notes.length > 0; else noData" class="horizontal-scroller">
          <div class="table-sticky-headers">
            <table class="table table-condensed no-margin" sortable [variableInUrl]="true">
              <!-- <caption></caption> -->
              <thead>
                <tr>
                  <th sortBy="fiscalId">{{ fiscalId?.label || 'REGISTER_COMPANY.FISCAL_IDENTIFICATION' |translate }}</th>
                  <th *ngFor="let column of columns"
                  title="{{ column.key }}">
                    {{ column.slug }}
                    <a title="{{ 'TEMPLATES_VIEW.DELETE'|translate }}"
                    class="show-onhover"
                    [class.disabled]="processing"
                    (onConfirm)="deleteColumn(column)"
                    [confirm-options]="{
                      action: 'GLOBAL.YES_DELETE'|translate
                    }"><i
                      class="material-symbols-rounded">delete</i></a>
                  </th>
                  <th class="sap">
                    <a title="{{ 'NOTES.ADD_COLUMN'|translate }}"
                    [class.disabled]="processing"
                    (click)="addColumn()"><span class="material-symbols-rounded">add_circle</span></a>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let note of notes"
                class="tr-align-center">
                  <td class="small">
                    <samp>{{ note.fiscalId| mask: fiscalId.mask }}</samp>
                    <button class="show-onhover btn btn-link btn-xs" title="{{ 'TEMPLATES_VIEW.DELETE'|translate }}"
                    type="button"
                    (onConfirm)="delete(note)"
                    [confirm-options]="{
                      action: 'GLOBAL.YES_DELETE'|translate
                    }"
                    [disabled]="processing"><i
                      class="material-symbols-rounded">delete</i></button>
                  </td>
                  <ng-container *ngFor="let column of columns">
                    <ng-container *ngTemplateOutlet="tdValue; context: {
                      note: note,
                      column: column
                    }"></ng-container>
                  </ng-container>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #tdValue let-note="note" let-column="column">
  <td [ngSwitch]="column.type" class="note sap">
    <input *ngSwitchCase="'NUMBER'"
    (keyup)="fieldChange($event.target.value, note, column)"
    type="number"
    [disabled]="processing"
    autocomplete="off"
    placeholder="{{ column.attr?.placeholder || '' }}"
    [min]="column.attr?.min"
    [max]="column.attr?.max"
    [step]="column.attr?.step"
    [value]="note.notes ? note.notes[column.key]?.value : undefined">
    <input *ngSwitchCase="'TEXT'"
    (keyup)="fieldChange($event.target.value, note, column)"
    type="text"
    [disabled]="processing"
    autocomplete="off"
    placeholder="{{ column.attr?.placeholder || '' }}"
    maxlength="{{ column.attr?.maxlength || 64 }}"
    [value]="note.notes ? note.notes[column.key]?.value : ''">
    <input *ngSwitchCase="'BOOLEAN'"
    (change)="fieldChange($event.target.checked, note, column)"
    type="checkbox"
    [disabled]="processing"
    [checked]="note.notes ? note.notes[column.key]?.value : false">
  </td>
</ng-template>

<ng-template #loading>
  <div class="loading">
    <spinner message="{{ 'GLOBAL.LOADING'|translate }}"></spinner>
  </div>
</ng-template>

<ng-template #noData>
  <!-- No data -->
  <div class="no-data">
    <i class="material-symbols-rounded">not_interested</i>
    {{ 'GLOBAL.NO_DATA'|translate }}
  </div>
</ng-template>

<ng-template #columnModal>
  <!-- modal -->
  <div class="modal-header">
    <button type="button" class="close" [disabled]="processing" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">{{ 'NOTES.ADD_COLUMN'|translate }}</h4>
  </div>
  <div class="modal-body">
    <form id="column-form" #columnForm="ngForm" (ngSubmit)="columnForm.valid && submitColumn()">
      <div class="row">
        <div class="col-xs-12">
          <div class="form-group" hasError>
            <label class="control-label">{{ 'GLOBAL.NAME'|translate }}</label>
            <input type="text" class="form-control input-lg"
            [modalFocus]="true"
            name="slug" [(ngModel)]="newColumn.slug" required
            maxlength="48"
            [disabled]="processing">
            <span class="glyphicon glyphicon-remove form-control-feedback" aria-hidden="true"></span>
            <error-list></error-list>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12">
          <div class="form-group" hasError>
            <label class="control-label">{{ 'NOTES.TYPE'|translate }}</label>
            <select class="form-control input-lg"
            name="type"
            required
            [(ngModel)]="newColumn.type"
            [disabled]="processing">
              <option [ngValue]="undefined" [selected]="true" [disabled]="true">{{ 'GLOBAL.SELECT'|translate }}</option>
              <option *ngFor="let option of ['TEXT', 'NUMBER', 'BOOLEAN']"
              [ngValue]="option">{{ option }}</option>
            </select>
            <error-list></error-list>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" (click)="closeModal()" [disabled]="processing" class="btn btn-link" data-dismiss="modal">
      {{ 'GLOBAL.CANCEL'|translate }}
    </button>
    <button type="submit" form="column-form" class="btn btn-agree" [disabled]="processing">
      {{ 'GLOBAL.ADD'|translate }}
    </button>
  </div>
</ng-template>

<ng-template #addModal>
  <!-- modal -->
  <div class="modal-header">
    <button type="button" class="close" [disabled]="processing" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">{{ 'GLOBAL.ADD_COMPANY'|translate }}</h4>
  </div>
  <div class="modal-body">
    <form (ngSubmit)="idsForm.valid && validate()" #idsForm="ngForm"
    id="fiscal_ids_form">
      <p [innerHtml]="'NOTES.ADD_COMPANY_HELP'|translate: { fiscal_id: fiscalId.label }"></p>

      <div class="form-group" hasError>
        <label for="fiscal_ids" class="control-label">{{ 'FINTECH.SCREENING_FISCAL_ID'|translate: { fiscal_id: fiscalId.label } }}</label>
        <textarea class="form-control"
        id="fiscal_ids" name="fiscal_ids"
        [modalFocus]="true"
        [disabled]="processing"
        [(ngModel)]="newBatch"
        rows="10" required
        placeholder="{{ fiscalId.mask || '1234567890' }},&#10;{{ fiscalId.mask || '1234567890' }},&#10;{{ fiscalId.mask || '1234567890' }},&#10;...,&#10;{{ fiscalId.mask || '1234567890' }}"></textarea>
        <error-list></error-list>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" (click)="closeModal()" [disabled]="processing" class="btn btn-link" data-dismiss="modal">
      {{ 'GLOBAL.CANCEL'|translate }}
    </button>
    <button type="submit" form="fiscal_ids_form" class="btn btn-agree" [disabled]="processing">
      {{ 'GLOBAL.ADD'|translate }}
    </button>
  </div>
</ng-template>
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Company } from '../../../models/company.model';
import { Envelope } from '../../../models/envelope.model';

@Component({
  selector: 'ag-envelope-binded',
  templateUrl: './envelope-binded.component.html',
  styleUrls: ['./envelope-binded.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EnvelopeBindedComponent {

  @Input() public company: Company;
  @Input() public envelope: Envelope;

  constructor() { }

}

import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { Company } from '../../../models/company.model';
import { GroupBy } from '../../../models/group-by.model';
import { Product } from '../../../models/product.model';
import { CompanyService } from '../../../services/company.service';
import { ComponentCommService } from '../../../services/component-comm.service';
import { IntercomService } from '../../../services/intercom.service';
import { setProp } from '../../../utilities/setProp';
import { Order } from '../../modules/commercial/models/order.model';
import { OrderService } from '../../modules/commercial/services/order.service';
import { qualityToString } from '../../pipes/quality.pipe';

@Component({
  selector: 'app-order-templates',
  templateUrl: './order-templates.component.html',
  styleUrls: ['./order-templates.component.css']
})
export class OrderTemplatesComponent implements OnInit, OnDestroy {

  // @ViewChild('filterSet', { static: true }) private readonly selectedFilters: FilterSetComponent;

  public companyId: number;
  public company: Company;
  public filters: Array<any>;
  /** Flag used to indicate if the component is loading information. */
  public loading: boolean = true;
  public templatesByProduct: GroupBy<Product, Order>[];
  public deletedTemplate = new EventEmitter();

  private subscriptions: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    private orderService: OrderService,
    private intercomService: IntercomService,
    private componentComm: ComponentCommService,
    private translateService: TranslateService,
    private companyService: CompanyService,
    private router: Router
  ) {
    this.subscriptions.push(this.route.parent.params.subscribe(params => {
      this.companyId = parseInt(params['companyId']);
    }));
  }

  ngOnInit(): void {
    this.componentComm.emit({ name: 'app-title', title: 'TEMPLATES_VIEW.TITLE' });

    this.intercomService.track('templates-visited');

    this.subscriptions.push(this.companyService.watch().subscribe(company => {
      if (!company) return;

      this.company = company;

      if (this.company.activation_level.id === 1 ||
        (!company.activity.broker && !company.activity.buyer && !company.activity.seller)) {
        this.router.navigateByUrl('/companies');
      }
    }));

    this.getTemplates();
  }

  private getTemplates(): void {
    // descomentar y quitar la linea siguiente al comentario cuando se apliquen los filtros en esta seccion
    /*let templates = this.selectedFilters.getFilters().pipe(
      switchMap(filters => {
        this.loading = true;

        this.filters = filters;

        return this.orderService.watchTemplates(this.companyId, this.filters);
      })
      );*/
    let templates = this.orderService.watchTemplates(this.companyId, this.filters);

    this.subscriptions.push(templates.subscribe(templatesByProduct => {
      this.parseTemplatesResponse(templatesByProduct);
    }));
  }

  private parseTemplatesResponse(templatesByProduct: GroupBy<Product, Order>[]): void {
    this.templatesByProduct = templatesByProduct;

    // TODO: evitar campos calculados de esta manera, agregar getter o pipe
    this.templatesByProduct.forEach(group => {
      group.values.forEach(template => {
        setProp(template, this.translateService, 'TEMPLATES_VIEW');
        template.quality_string = qualityToString(template.product_detail.quality, group.key);
      });
    });

    this.loading = false;
  }

  public delete(template): void {
    this.subscriptions.push(this.orderService.deleteTemplate(this.companyId, template.id).subscribe(response => {
      this.deletedTemplate.emit();
      this.getTemplates();

      this.deletedTemplate = new EventEmitter();
    }));
  }

  // TODO: This should be a pipe
  public countryAndPort(template): string {
    return template.product.attributes.country.name + ' / ' + template.business_detail.port.name;
  }

  ngOnDestroy(): void {
    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
